import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Collapse,
    Divider,
    Empty,
    Form,
    Input,
    notification,
    Row,
    Skeleton,
    Steps,
    Tooltip
} from "antd";

import {findCrmCustomerDefaultAddress} from "../../data/Customer-Service-Repo";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import {
    doReceiveOmsCouponCode,
    findOmsCouponByOrderList,
    findOmsOrder,
    payOrder,
    submitOrder
} from "../../data/Order-Service-Repo";

import {bucketURL} from "../../data/env";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePayment from "./component/StripePayment";
import AddressSelector from "../../components/AddressSelector";
import {addressFormatterWithUnit} from "../../data/tool";
import {
    LeftOutlined,
    RightOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import GlobalCouponUIBuilder from "../../components/GlobalCouponUIBuilder";

const { REACT_APP_STRIPE_LIVE_PK, REACT_APP_STRIPE_TEST_PK } = process.env

// Stripe Key
const stripePromise = loadStripe(REACT_APP_STRIPE_LIVE_PK, {
    locale: 'zh',
});

const Confirm = (props) => {
    const [form] = Form.useForm();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // ------------------ 用来 rerender sub header ------------------
    useEffect(() => {
        // ----- 初始 -----
        props.setForceRender(props.forceRender+1);

        // ----- 离开页面 -----
        const handleBeforeUnload = () => {
            // 值不可以跟上面一开始的一样，不然系统识别不到 state change 请求
            props.setForceRender(props.forceRender-1);
        };

        // handles when page is unloaded
        window.addEventListener("beforeunload", handleBeforeUnload);

        // cleanup function handles when component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            handleBeforeUnload();
        };
    }, [])



    // steps
    const [currentStep, setCurrentStep] = useState(0);

    // 加载项
    const [isLoading, setIsLoading] = useState(true); // loading status
    const [isVisible, setIsVisible] = useState(true); // visible status
    const [errorMessage, setErrorMessage] = useState(""); // error message
    const [pricingLoading, setPricingLoading] = useState(false); // 支付明细的加载状态

    // 存储
    const [order, setOrder] = useState([]);
    const [pickedAddress, setPickedAddress] = useState([]);

    // 优惠券
    const [coupon, setCoupon] = useState([]); // 所有优惠券
    const [couponCheckbox, setCouponCheckbox] = useState([]); // 优惠券的 checkbox state


    // Final Data，最后给后端的数据
    const [storeOrderRemark, setStoreOrderRemark] = useState([]); // 商铺订单备注合集
    const [usedCouponId, setUsedCouponId] = useState([]); // 使用的优惠券Id合集
    const [receiver, setReceiver] = useState({
        receiverName: "",
        receiverAddress: "",
        receiverMobile: ""
    }); // 收货人信息


    // ------------------ 请求数据 ------------------
    useEffect(() => {
        async function load() {

            console.log()

            // ------------------------- 请求用户默认地址数据 -------------------------
            var addressResult = await findCrmCustomerDefaultAddress();

            // 如果地址正确且有地址，就存储
            if(addressResult.code === 200 && addressResult.data !== null) {
                addressResult = addressResult.data;
                setPickedAddress(addressResult);
            }

            // 先取得并结合地址信息
            var completeAddress = "";
            if(addressResult.data !== null){
                completeAddress = addressResult.address + ", " + addressResult.suburb + " " + addressResult.state + " " + addressResult.postcode;
            }



            // ------------------------- 请求订单数据 -------------------------
            var orderResult = await fetchOrderData([], completeAddress);


            // 针对默认地址不支持配送的处理
            if(orderResult.code === -5001){
                setPickedAddress([]);
                await fetchOrderData([], "");
            }


            // 建立备注数组
            var storeOrderRemark = [];
            orderResult.data.stores.map((storeBuilder, index) => {
                storeOrderRemark.push({
                    buyerRemark: "",
                    storeId: storeBuilder.storeId
                });
            })
            setStoreOrderRemark(storeOrderRemark)

            // 设置手续费
            setPaymentSurcharge(0)


            // 检查订单状态
            if (orderResult.data.orderStatus === 10) {
                // 如果是待付款订单直接前往步骤3（针对去付款设计的）
                setCurrentStep(2);

            } else if (orderResult.data.orderStatus !== 0){
                // 订单状态不是预售订单，也不是待付款，因此用户不应该看到这个页面
                setErrorMessage("订单已完成结算并支付，请前往订单详情查看此订单！");
                setIsLoading(false);
                setIsVisible(false);
                return ;
            }

        }
        load();
    }, [])


    async function fetchOrderData(coupon, completeAddress) {
        // 设置 loading
        setPricingLoading(true);

        // 取订单信息
        var orderResult = await findOmsOrder(coupon, params.id, completeAddress);

        // 正确再保存，错误显示错误信息并不允许查看
        if (orderResult.code === 200) { // 正确
            // 保存订单信息，解除 loading
            setOrder(orderResult.data);
            setIsLoading(false);
            setPricingLoading(false);
            return orderResult;

        } else if (orderResult.code === -5001) { //-5001 错误 配送地址不在服务范围内，详细请参考帮助中心

            setPricingLoading(false);
            notificationApi.error({
                message: "错误",
                description:
                    <>
                        配送地址不在服务范围内，详细请参考<Link className={"color-shopmy-orange"} to={"https://redirect-app.shopmy.top/logistic-unavailable"} target="_blank">帮助中心说明</Link> 或请您重新选取其他地址。
                        <br/>

                    </>,
                duration: 5,
                placement: "bottomRight",
            });

            return orderResult;

        } else if (orderResult.code < -4020 && orderResult.code > -4040){ // 优惠券相关错误 assume are -4020 to -4040
            setPricingLoading(false);
            notificationApi.error({
                message: "错误",
                description: orderResult.message,
                duration: 5,
                placement: "bottomRight",
            });

            return orderResult;

        } else { // 其他错误，例如订单状态异常等等..
            setErrorMessage(orderResult.message);
            setIsLoading(false);
            setIsVisible(false);
            setPricingLoading(false);
        }
    }


    // 当选择了地址后，自动填写 receiver 信息
    useEffect(() => {
        if(pickedAddress !== []){
            setReceiver({
                receiverName: pickedAddress.name,
                receiverAddress: addressFormatterWithUnit(pickedAddress),
                receiverMobile: pickedAddress.mobile
            })
        }
    }, [pickedAddress])




    // ------------------------------------------------------ 备注 ------------------------------------------------------
    // 因为后端没有返回 store 的总价，所以需要自己计算
    function getStoreTotalAmountByStoreId(storeId){
        var totalPrice = 0;
        order.products.map((productBuilder, index) => {
            if(productBuilder.storeId === storeId){
                totalPrice += productBuilder.productSellerPrice * productBuilder.productNum;
            }
        })

        return totalPrice.toFixed(2);
    }



    // 修改商铺备注
    function editStoreOrderRemark(storeId, remark){
        var tempStoreOrderRemark = [];
        storeOrderRemark.map((storeOrderRemarkBuilder, index) => {
            if(storeOrderRemarkBuilder.storeId === storeId){
                tempStoreOrderRemark.push({
                    buyerRemark: remark,
                    storeId: storeId
                })

            } else {
                tempStoreOrderRemark.push(storeOrderRemarkBuilder)
            }
        })
        setStoreOrderRemark(tempStoreOrderRemark)
    }




    // ------------------------------------------------------ 优惠券 ------------------------------------------------------
    const [addCouponButtonLoading, setAddCouponButtonLoading] = useState(false); // 添加优惠券按钮 loading

    useEffect(() => {
        async function load() {
            // 当移动到第二步(Step1)，代表开始选择优惠券，这时候需要加载优惠券数据
            if (currentStep === 1) {
                fetchCouponByOrder();
            }
        }
        load();
    }, [currentStep])



    async function fetchCouponByOrder() {
        setCoupon([]);

        var result = await findOmsCouponByOrderList(params.id, usedCouponId);

        if(result.code === 200){
            setCoupon(result.data);

            // 判断一下，如果 tempCouponCheckbox 是空，才初始化
            if(couponCheckbox.length === 0){
                // 配置上优惠券的 checkbox state
                var tempCouponCheckbox = [];

                result.data.availables.map((couponBuilder, index) => {
                    var newCoupon = {
                        receiveId: couponBuilder.receiveId,
                        checked: false
                    };

                    tempCouponCheckbox.push(newCoupon)
                })

                // add new coupon to couponCheckbox
                setCouponCheckbox(tempCouponCheckbox)
            }

        } else {
            notificationApi.error({
                message: "错误",
                description:
                    <>
                        优惠券加载失败，请稍后再试。
                        <br/>
                    </>,
                duration: 5,
                placement: "bottomRight",
            });
        }
    }




    const [checkboxCouponDisabled, setCheckboxCouponDisabled] = useState(false); // 优惠券 checkbox 是否禁用

    async function handleCouponClick(checked, id){

        // set checkbox disabled
        setCheckboxCouponDisabled(true)

        // if true add the id to usedCoupon
        if(checked){
            // temp used coupon id
            var tempUsedCouponId = [...usedCouponId, id];

            // 先发报文确定可以使用
            var result = await fetchOrderData(tempUsedCouponId, pickedAddress.address + ", " + pickedAddress.suburb + " " + pickedAddress.state + " " + pickedAddress.postcode);

            if(result.code === 200){
                // Final Data: set id for final data
                setUsedCouponId([...usedCouponId, id])

                // Checkbox: find the coupon from couponCheckbox by id and set checked to true
                setCouponCheckboxById(id, checked)
            }

        } else {
            // Final Data: if false remove the id from usedCoupon
            var tempUsedCouponId = [];
            usedCouponId.map((usedCouponIdBuilder, index) => {
                if(usedCouponIdBuilder !== id){
                    tempUsedCouponId.push(usedCouponIdBuilder)
                }
            })
            setUsedCouponId(tempUsedCouponId)

            // Checkbox: find the coupon from couponCheckbox by id and set checked to false
            setCouponCheckboxById(id, checked)

            // 刷新订单（使用tempUsedCouponId 函数避免不即时 ）
            await fetchOrderData(tempUsedCouponId, pickedAddress.address + ", " + pickedAddress.suburb + " " + pickedAddress.state + " " + pickedAddress.postcode)
        }

        // 刷新优惠券列表交给 usedCouponId useEffect 来触发


        // set checkbox enabled
        setCheckboxCouponDisabled(false)
    }


    // find the coupon from couponCheckbox by id and set checked to its value
    function setCouponCheckboxById(id, checked){
        var tempCouponCheckbox = [];
        couponCheckbox.map((couponCheckboxBuilder, index) => {
            if(couponCheckboxBuilder.receiveId === id){
                tempCouponCheckbox.push({
                    receiveId: couponCheckboxBuilder.receiveId,
                    checked: checked
                })
            } else {
                tempCouponCheckbox.push(couponCheckboxBuilder)
            }
        })
        setCouponCheckbox(tempCouponCheckbox)
    }


    // usedCouponId 有变动时才刷新优惠券列表
    // 第二步（currentStep 1）才需要刷优惠券，所以不是这个 step 都不刷
    useEffect(() => {
        if(currentStep === 1){
            // 刷新优惠券列表
            fetchCouponByOrder()
        }
    }, [usedCouponId])





    // ------------------------------------------------------ 提交订单 ------------------------------------------------------
    // 第三步代表提订单
    useEffect(() => {
        async function load() {
            // 当移动到第三步骤且订单是一笔预售订单（尚未提交）才允许提交订单
            if (currentStep === 2 && order.orderStatus === 0) {
                var request = {
                    id: order.id,
                    couponReceiveIds: usedCouponId,
                    stores: storeOrderRemark,
                    receiverName: receiver.receiverName,
                    receiverAddress: receiver.receiverAddress,
                    receiverMobile: receiver.receiverMobile,
                }

                var result = await submitOrder(request)

                if (result.code !== 200) {
                    // 成功，代表订单可以准备支付
                    // 不成功，跳回步骤二，并且出错误信息
                    setCurrentStep(2);
                    notificationApi.error({
                        message: `未知错误`,
                        description: result.message,
                        placement: "bottomRight",
                        duration: 5,
                    });
                    return;
                }
            }

            // 与上述区分
            if(currentStep === 2){
                // 重拿订单数据，以获得支付手续费
                await fetchOrderData([], pickedAddress.address + ", " + pickedAddress.suburb + " " + pickedAddress.state + " " + pickedAddress.postcode);

                // 预先加载 stripe 支付权杖
                handlePaymentMethodOnChange("3");

                // 预先选取 stripe
                setPaymentMethod("3");

            }

        }
        load();

    }, [currentStep])






    // ------------------------------------------------------ 支付 ------------------------------------------------------
    // Stripe
    const [clientSecret, setClientSecret] = useState("");

    const appearance = {
        theme: 'flat',
        variables: {
            colorPrimary: '#f46e12',
        },
        rules: {
            '.Tab': {
                border: '1px solid #E0E6EB',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.TabIcon--selected': {
                fill: 'var(--colorTextSecondary)',
                color: 'var(--colorTextSecondary)',
            },

            '.TabLabel--selected': {
                fill: 'var(--colorTextSecondary)',
                color: 'var(--colorTextSecondary)',
            },

            '.Tab--selected': {
                border: '1px solid #E0E6EB',
                backgroundColor: "var(--colorBackground)",
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            // See all supported class names and selector syntax below
        }
    };

    const options = {
        clientSecret,
        appearance,
        loader: 'always'
    };


    // 锁定支付方式 collapse
    // 避免重点导致 miss match
    const [collapseDisabled, setCollapseDisabled] = useState("");

    // 支付手续费
    const [paymentSurcharge, setPaymentSurcharge] = useState(0); // payment surcharge

    // 选择的支付方式
    // （1：微信、2：支付宝、3：信用卡、4：Google支付、5：Apple支付）
    const [paymentMethod, setPaymentMethod] = useState("0"); // payment method

    // 支付方式改变，向后端索取支付权杖
    async function handlePaymentMethodOnChange(key) {
        // 开始加载，禁止重点
        setCollapseDisabled("disabled");

        // 开始加载
        setPricingLoading(true);

        // 要归零，不然会定位错 pi secret
        setClientSecret("");

        if (key.length !== 0) {
            var result = await payOrder(order.id, key[0]);

            if (result.code === 200) {
                // 成功，识别要怎么处理权杖
                switch (key[0]) {
                    case "3":
                        setClientSecret(result.data.cardSdkParams.clientSecret);
                        setPaymentMethod(key[0]);
                        break;

                    default:
                        break;
                }

            } else {
                setIsVisible(false)
                setErrorMessage(result.message)
            }
        } else {
            // 没有支付方式
            setPaymentMethod("0")
        }


        // 加载完成，解除禁止重点
        setCollapseDisabled("");
    }


    // 当订单 order state 改变，同时有已经选择的支付方式，更新手续费
    useEffect(() => {
        switch (paymentMethod) {
            case "0":
                setPaymentSurcharge(0);
                break;

            case "3":
                setPaymentSurcharge(order.orderStripeServicePrice);
                break;
        }
        setPricingLoading(false);
    }, [paymentMethod])


    // payment items
    const paymentItems = [
        {
            key: '3',
            label: <span className={"title"}>银行卡 / Google Pay / Apple Pay</span>,
            children:
                <>
                    {clientSecret
                        ?
                            (
                                <Elements options={options} stripe={stripePromise}>
                                    <br/>
                                    <StripePayment orderId={order.id} totalPrice={(order.orderPrice + paymentSurcharge).toFixed(2)}/>
                                    <br/>
                                </Elements>
                            )
                        :
                            (
                                <Loading/>
                            )
                    }
                </>,
        }
    ];


    // ------------------------------------------------------ 主 controller ------------------------------------------------------
    // step page and content
    const stepItems = [
        {
            title: '选择配送地点',
            content:
                <>
                    <span>选择已保存的地址或添加新地址</span>
                    <div className={"tab-container"}>
                        <AddressSelector
                            source={"checkout"}
                            selectedAddressId={pickedAddress !== [] && pickedAddress.id}
                            onAddressSelected={async (item) => {
                                // 这边需要处理重选地址判断是否支持配送
                                // 先查一下确认地址可以套用在订单上
                                var result = await fetchOrderData(usedCouponId, item.address + ", " + item.suburb + " " + item.state + " " + item.postcode)

                                // 最后订单是 200，代表没问题，就设置上地址
                                if (result.code === 200) {
                                    setPickedAddress(item)
                                }

                            }}
                            disabled={pricingLoading}
                        />
                    </div>
                    <br/><br/>
                </>,
        },
        {
            title: '选择优惠券',
            content:
                <div className={"coupon"}>
                    <br/>
                    <Form
                        className={"container"}
                        form={form}
                        layout="vertical"
                        onFinish={async () => {
                            setAddCouponButtonLoading(true);

                            var result = await doReceiveOmsCouponCode(form.getFieldValue("couponCode"))

                            if(result.code === 200){
                                notificationApi.success({
                                    message: "成功",
                                    description: "优惠券领取成功。请前往下方列表手动选取该优惠券",
                                    placement: "bottomRight",
                                    duration: 5,
                                })

                                fetchCouponByOrder();


                            } else {
                                notificationApi.error({
                                    message: "失败",
                                    description: result.message,
                                    placement: "bottomRight",
                                    duration: 5,
                                })
                            }

                            form.setFieldValue("couponCode", "");
                            setAddCouponButtonLoading(false);
                        }}
                    >
                        <strong>输入优惠码来添加新优惠券: </strong>
                        <Form.Item name="couponCode">
                            <Input placeholder="请输入优惠码..." />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType={"submit"} style={{width: "100%"}} loading={addCouponButtonLoading}>领取优惠券</Button>
                        </Form.Item>
                    </Form>

                    <div
                        className={"container"}
                        style={{width: "588px"}}
                    >
                        <strong>选择已领取的优惠券: </strong>
                        <div
                            style={{
                                width: "586px",
                                maxHeight: "700px",
                                overflow: "auto",
                                overflowX: "hidden",
                                overflowY: "scroll",
                            }}
                        >
                            <Divider plain>本次可使用的优惠券</Divider>

                            {
                                coupon.length !== 0
                                    ?
                                    <>
                                        {
                                            coupon.availables.length !== 0
                                                ?
                                                <>
                                                    {
                                                        coupon.availables.map((item, index) => {
                                                            var checkValue = false;

                                                            couponCheckbox.map((item2, index) => {
                                                                if(item2.receiveId === item.receiveId){
                                                                    checkValue = item2.checked;
                                                                }
                                                            })


                                                            return (
                                                                <GlobalCouponUIBuilder
                                                                    source={"checkout"}
                                                                    coupon={item}
                                                                    status={"available"}
                                                                    handleCouponClick={(checked, id)=>{
                                                                        handleCouponClick(checked, id)
                                                                    }}
                                                                    checked={checkValue}
                                                                    disabled={checkboxCouponDisabled}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                    description={
                                                        <span>
                                                暂无可用优惠券
                                            </span>
                                                    }
                                                />
                                        }
                                    </>

                                    :
                                    <>
                                        {
                                            // loop twice
                                            [1,2].map((item, index) => {
                                                return (
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${varBucketURL}/assets/background_checkout_store_3.svg)`,
                                                            height: "126px",
                                                            backgroundSize: "99.3",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginBottom: "10px"
                                                        }}
                                                        className={"coupon-container"}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "0px 46px 0px 46px"
                                                            }}
                                                        >
                                                            <Skeleton.Avatar active size={55} style={{marginBottom: "4px"}}/>
                                                            <Skeleton active paragraph={false}/>
                                                        </div>
                                                        <Skeleton.Input active size={60}/>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Skeleton.Input active size={60}/>
                                                        &nbsp;&nbsp;
                                                        <div
                                                            style={{
                                                                marginRight: "30px",
                                                                marginLeft: "auto",
                                                            }}
                                                        >
                                                            <Skeleton.Button active size={30}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }

                            <br/>
                            <Divider plain>本次不可使用的优惠券</Divider>
                            {
                                coupon.length !== 0
                                    ?
                                    <>
                                        {
                                            coupon.unavailables.length !== 0
                                                ?
                                                <>
                                                    {
                                                        coupon.unavailables.map((item, index) => {
                                                            return (
                                                                <GlobalCouponUIBuilder
                                                                    source={"checkout"}
                                                                    coupon={item}
                                                                    status={"unavailable"}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                    description={
                                                        <span>
                                                暂无可用优惠券
                                            </span>
                                                    }
                                                />
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            // loop twice
                                            [1,2].map((item, index) => {
                                                return (
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${varBucketURL}/assets/background_checkout_store_3.svg)`,
                                                            height: "126px",
                                                            backgroundSize: "99.3",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginBottom: "10px"
                                                        }}
                                                        className={"coupon-container"}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "0px 46px 0px 46px"
                                                            }}
                                                        >
                                                            <Skeleton.Avatar active size={55} style={{marginBottom: "4px"}}/>
                                                            <Skeleton active paragraph={false}/>
                                                        </div>
                                                        <Skeleton.Input active size={60}/>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Skeleton.Input active size={60}/>
                                                        &nbsp;&nbsp;
                                                        <div
                                                            style={{
                                                                marginRight: "30px",
                                                                marginLeft: "auto",
                                                            }}
                                                        >
                                                            <Skeleton.Button active size={30}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                        <br/><br/>
                    </div>

                </div>,
        },
        {
            title: '选择支付方式',
            content:
                <>
                    <br/>
                    <Collapse
                        className={"payment-method"}
                        collapsible={collapseDisabled}
                        accordion
                        items={paymentItems}
                        defaultActiveKey={['3']}
                        bordered={false}
                        onChange={handlePaymentMethodOnChange}
                    />
                    <br/><br/>
                    <Divider dashed/>
                    <div
                        className={"payment-provider"}
                    >
                        <span>由以下收款商提供服务</span>
                        <div
                            style={{
                                gap:"20px",
                                display: "flex",
                                marginTop: "20px",
                            }}
                        >
                            <img src={varBucketURL + "/assets/icon-paymeny-stripe.svg"} height={"40px"} onClick={()=>{window.open('https://stripe.com/en-au', '_blank')}}></img>
                            <img src={varBucketURL + "/assets/icon-paymeny-omipay.svg"} height={"40px"} onClick={()=>{window.open('https://www.omipay.net/AU/About/', '_blank')}}></img>
                        </div>
                    </div>
                </>,
        },
    ];



    return (
        <>
            {notificationContextHolder}
            {
                isLoading
                    ?
                    <>
                        <Loading/>
                    </>
                    :
                    <>
                        {
                            isVisible
                                ?
                                <Row className={"safe-area"} id={"checkout-confirm-page"}>
                                    <Col span={14} className={"item-container"} id={"checkout"}>
                                        <Steps current={currentStep} items={stepItems} />
                                        <div className={"content"}>{stepItems[currentStep].content}</div>
                                        <Divider dashed />
                                        {
                                            currentStep === 1 &&
                                            <>
                                                <Alert message="点击「提交订单」后您将不可以变更配送地址、优惠券、商品规格数量及备注，请确认所有内容无误后再提交订单" type="warning" showIcon />
                                                <br/><br/>
                                            </>
                                        }
                                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                                            {
                                                (currentStep == 1) &&
                                                <Button
                                                    onClick={() => {
                                                        setCurrentStep(currentStep-1);
                                                    }}
                                                    style={{
                                                        width: "150px"
                                                    }}
                                                >
                                                    <LeftOutlined /> 返回上一步
                                                </Button>
                                            }

                                            {
                                                currentStep !== 2 &&
                                                <Tooltip
                                                    placement="top"
                                                    title={pickedAddress.length === 0 && "请选择地址"}
                                                >
                                                    <Button
                                                        type={"primary"}
                                                        onClick={() => {
                                                            setCurrentStep(currentStep+1);
                                                        }}
                                                        disabled={
                                                            pickedAddress.length === 0
                                                        }
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "0",
                                                            width: "150px"
                                                        }}
                                                    >
                                                        {
                                                            currentStep === 0
                                                                ?
                                                                "下一步"
                                                                :
                                                                "提交订单"
                                                        }
                                                         <RightOutlined />
                                                    </Button>
                                                </Tooltip>
                                            }
                                        </div>



                                    </Col>

                                    <Col span={1}>

                                    </Col>

                                    <Col span={9}>
                                        <Col span={24} className={"container item-container"} id={"receipt"}>
                                            <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>购买明细</h3>
                                            {
                                                order.stores.map((storeBuilder, index) => {
                                                    return (
                                                        <div className={"order-group"}>
                                                            <div className={"store-info"}>
                                                                <img
                                                                    src={storeBuilder.storeIco}
                                                                    width={"25px"}
                                                                    height={"25px"}
                                                                    style={{borderRadius: "100px"}}
                                                                />
                                                                <span
                                                                    style={{margin: "0px 0px 0px 12px"}}>{storeBuilder.storeName}
                                                                </span>
                                                            </div>
                                                            <div className={"order-item"}>
                                                                {
                                                                    storeBuilder.products.map((productBuilder, index) => {
                                                                        return (
                                                                            <div className={"product-item"}>
                                                                                <img
                                                                                    src={productBuilder.productImgurl}
                                                                                    width={"70px"}
                                                                                    height={"70px"}
                                                                                    style={{boxShadow: "1px 1px 0 rgb(5 145 255 / 10%)"}}
                                                                                />
                                                                                <div className={"product-detail"} style={{width: "256px"}}>
                                                                                    <div
                                                                                        className={"product-title"}>{productBuilder.productName}</div>
                                                                                    <div
                                                                                        className={"product-sku grey-sub-font"}>{productBuilder.productAttributes}</div>
                                                                                    <div
                                                                                        className={"product-amount grey-sub-font"}>x{productBuilder.productNum}</div>
                                                                                </div>
                                                                                <div className={"product-item-price"}>
                                                                                    ${(productBuilder.productSellerPrice).toFixed(2)}
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div className={"list"}>
                                                                    <span className={"left"}>配送方式</span>
                                                                    <span className={"right"}>商铺自行配送</span>
                                                                </div>
                                                                <div className={"list"}>
                                                                    <span className={"left"}>买家备注</span>
                                                                    <span className={"right"}>
                                                                        {
                                                                            storeOrderRemark.map((remarkBuilder, index) => {
                                                                                if(remarkBuilder.storeId === storeBuilder.storeId){
                                                                                    if(currentStep < 2){
                                                                                        // 只有第1,2 步骤可以修改内容
                                                                                        return (
                                                                                            <Input
                                                                                                className={"store-remark"}
                                                                                                bordered={false}
                                                                                                placeholder={"点击输入备注内容"}
                                                                                                defaultValue={remarkBuilder.buyerRemark}
                                                                                                onChange={(e)=>{editStoreOrderRemark(remarkBuilder.storeId, e.target.value)}}
                                                                                            />
                                                                                        );

                                                                                    } else {
                                                                                        // 第3 步骤只能 read only
                                                                                        if(remarkBuilder.buyerRemark === ""){
                                                                                            return ("无");
                                                                                        } else {
                                                                                            return (remarkBuilder.buyerRemark);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={"list"}>
                                                                    <span className={"left"}>Total</span>
                                                                    <span className={"right color-shopmy-orange"}>$ {getStoreTotalAmountByStoreId(storeBuilder.storeId)}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                // 最后一个不显示
                                                                index !== order.stores.length - 1 &&
                                                                <Divider style={{
                                                                    margin: "17px 0px",
                                                                    marginright: "10px",
                                                                }}/>
                                                            }

                                                        </div>
                                                    );


                                                })

                                            }
                                        </Col>

                                        <Col span={24} className={"container item-container"} id={"transaction"}>
                                            <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>支付明细</h3>
                                            {
                                                pricingLoading
                                                    ?
                                                        <>
                                                            <br/>
                                                            <Skeleton active />
                                                        </>
                                                    :
                                                        <>
                                                            <div className={"list"}>
                                                                <span className={"left"}>商品总金额</span>
                                                                <span className={"right"}>$ {order.orderProductPrice.toFixed(2)}</span>
                                                            </div>
                                                            <div className={"list"} style={{marginBottom: `${order.coupons !== null && "3px"} `}}>
                                                                <span className={"left"}>优惠券折扣</span>
                                                                <span className={"right"}>-$ {order.orderDiscountPrice.toFixed(2)}</span>
                                                            </div>
                                                            {
                                                                order.coupons !== null &&
                                                                <div className={"coupon-detail"}>
                                                                    {
                                                                        order.coupons.map((couponBuilder, index) => {
                                                                            return (
                                                                                <div className={"list"}>
                                                                                    <span className={"left"}>•  {couponBuilder.title}</span>
                                                                                    <span className={"right"}>- $ {couponBuilder.price.toFixed(2)}</span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }

                                                            <div className={"list"}>
                                                                <span className={"left"}>实付运费</span>
                                                                <span className={"right"}>
                                                                    {
                                                                        pickedAddress.length === 0
                                                                        ?
                                                                            "未选择地址"
                                                                        :
                                                                            "$ " + order.orderPostPrice.toFixed(2)
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className={"list"}>
                                                                <span className={"left"}>支付手续费</span>
                                                                <span className={"right"}>{paymentSurcharge === 0 ? "支付时计算" : "$ " + paymentSurcharge}</span>
                                                            </div>
                                                            <div className={"list"}>
                                                                <span className={"left"}>订单总金额</span>
                                                                <span className={"right"}>$ {(order.orderPrice + paymentSurcharge).toFixed(2)}</span>
                                                            </div>
                                                        </>
                                            }
                                        </Col>
                                    </Col>
                                </Row>

                                :
                                <>
                                    <NotFound message={errorMessage}/>
                                </>
                        }
                    </>
            }
        </>
    );
}


export default Confirm;