import React, {useEffect, useState} from "react";

// import seller store components
import StoreList from './List.js';
import StoreSubmitted from './Submitted.js';
import StoreApproved from './Approved.js';
import {findSellStore, getUserInfo} from "../../data/Customer-Service-Repo";


const StoreMain = (props) => {

    // check user's seller state before render
    // 店铺申请审核状态（0：未提交、1：已提交、2：再审核(已拒绝)、3：通过）
    const [sellerState, setSellerState] = useState(0);

    // store detail
    const [sellerStore, setSellerStore] = useState([]);

    // run count
    const [runCount, setRunCount] = useState(0);

    useEffect(() => {
        async function load(){

            // only run once
            if(props.token != null){
                // get user's info (get fresh data instead using localstorage to avoid update happens in these terms)
                const result = await getUserInfo(props.token, 'app');

                if(result.code === 200){
                    // if success, set seller state
                    setSellerState(result.data.storeIscheck)

                    // if the store exist(state= 2, 3, 4), find seller store detail
                    if(result.data.storeIscheck !== 0){
                        const result = await findSellStore();
                        setSellerStore(result)
                    }

                }
            }


            setRunCount(1)
        }

        load();
    },[props.token]);

    return (

        <>
            {
                (sellerState === 0 || sellerState === 2) &&
                <StoreList store={sellerStore} />
            }

            {
                (sellerState === 1) &&
                <StoreSubmitted store={sellerStore}/>
            }

            {
                (sellerState === 3) &&
                <StoreApproved store={sellerStore}/>
            }

        </>


    );
}


export default StoreMain;