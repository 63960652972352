function bucketURL() {
    return "https://app-apsoutheast2-static.shopmy.com.au/shopmy_buyer_desktop";
}


// function requireLogin(targetComponentName){
//     const componentName = [
//         "Profile",
//         "Chat",
//         "Cart",
//         "Coupon"
//     ]
//
//     return componentName;
// }

function didUserLogin(){

}


export {
    bucketURL
}