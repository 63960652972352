import React, {useEffect, useState} from "react";
import {Menu, Row, Input, Divider, Button, Pagination, message, Skeleton, Spin, notification, Modal} from "antd";
import {DownOutlined, LoadingOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {doOmsOrderReceipt, findOmsOrderList, saveOmsOrderCarByOrder} from "../../../data/Order-Service-Repo";
import {useNavigate} from "react-router-dom";
import {identifyOrderTipsList, orderStatusMapping} from "../../../data/tool";
import {bucketURL} from "../../../data/env";
import NoData from "../../../components/NoData";
import Countdown from "react-countdown";

import {Prompt} from 'react-router';

const {Search} = Input;

const OrderList = (props) => {

    var navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });



    // once load
    const [orderList, setOrderList] = useState([]);
    useEffect(() => {
        async function load() {
            if (props.token !== null) {

                // 显示上一次 render 放在 params 上的信息
                if (params.msg !== null) {
                    notificationApi.success({
                        message: `提示信息`,
                        description: params.msg,
                        placement: "bottomRight",
                        duration: 5,
                    });
                }


                var key = ""
                var orderStatus = ""
                var page = 1

                // identify is there a params in URL
                if (params.key !== null) {
                    key = params.key;
                }
                if (params.orderStatus !== null) {
                    orderStatus = params.orderStatus;
                }
                if (params.page !== null) {
                    page = parseInt(params.page);
                }


                setCurrentOrderStatus(orderStatus);

                await fetchData(key, orderStatus, page)
            }
        }

        load();

    }, [props.token]);


    // order status controller
    // status item
    const items = [
        {
            label: '全部',
            key: '',
        },
        {
            label: '待付款',
            key: '10',
        },
        {
            label: '待出货',
            key: '20',
        },
        {
            label: '配送中',
            key: '30',
        },
        {
            label: '待完成',
            key: '40',
        },
        {
            label: '待评价',
            key: '50',
        },
        {
            label: '退货/退款',
            key: '60',
        }
    ];

    // status selector
    const [currentOrderStatus, setCurrentOrderStatus] = useState('');
    const onClickStatusFilter = async (value) => {
        // change status
        setCurrentOrderStatus(value.key);

        // fetch data
        await fetchData("", value.key, 1);

        // reset page
        setOrderListCurrentPage(1);

        // scroll to top
        window.scrollTo(0, 0);
    };

    // search controller
    const onSearch = async (value) => {
        // fetch data
        await fetchData(value, "", 1);

        // reset page
        setOrderListCurrentPage(1);

        // reset status
        setCurrentOrderStatus("");

        // scroll to top
        window.scrollTo(0, 0);
    };


    // paging controller
    const [orderListTotalSize, setOrderListTotalSize] = useState(0);
    const [orderListCurrentPage, setOrderListCurrentPage] = useState(0)
    const onChangeProductPage = async (value) => {
        // set page
        setOrderListCurrentPage(value);

        // fetch data
        await fetchData("", currentOrderStatus, value);

        // scroll to top
        window.scrollTo(0, 0);

    };

    // set when to display loading
    const [display, setDisplay] = useState("flex");

    // fetch data
    async function fetchData(key, orderStatus, page) {
        // reset loader
        setDisplay("flex");
        setOrderListTotalSize(0);

        // load result
        var result = await findOmsOrderList(key, orderStatus, page)

        setDisplay("none");


        // fetch data
        setOrderList(result.data);

        // fetch size
        setOrderListTotalSize(result.page.total);

        // set current page
        setOrderListCurrentPage(page);

        // add param to url without reload
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?key=' + key + '&orderStatus=' + orderStatus + '&page=' + page;
        window.history.pushState({path: newurl}, '', newurl);
    }

    // 因为在待付款订单时，没有所谓的商家对象，因此要判定商铺名称和头像该如何显示
    // returnType 传 1：反 商铺头像
    // returnType 传 2：反 商铺名称
    function identifyStoreNameAndHead(returnType, orderStatusPay, storeHead, storeName) {
        var result = {
            storeHead: storeHead,
            storeName: storeName
        }

        // 0 代表没付款款，属于带付款订单或已取消的待付款订单
        if (orderStatusPay === 0) {
            result = {
                storeHead: varBucketURL + "/assets/icon-order-orange-cart.png",
                storeName: "一笔待付款订单"
            }
        }

        if (result.storeHead === null) {
            result = {
                storeHead: varBucketURL + "/assets/icon-olly-placeholder.svg",
                storeName: storeName
            }
        }

        // identify return type
        if (returnType === 1) {
            return result.storeHead;
        } else if (returnType === 2) {
            return result.storeName;
        }
    }

    const [confirmReceivedLoading, setConfirmReceivedLoading] = useState(false);

    function identifyOrderButton(order) {
        switch (order.orderStatus) {

            case 10:
                return (<Button type="primary" onClick={() => navigate("/checkout/confirm?id=" + order.id)}
                                size={"large"}>去付款</Button>);

            case 20:
                return (<Button type="primary" onClick={() => {window.open("/chat/portal?conversationUserId=" + order.storeCustomerId, "_blank")}}
                                size={"large"}>联络商铺</Button>);


            // 暂时没有配送中状态，不放链接
            case 30:
                return (<Button type="primary" onClick={() => navigate("/")}
                                size={"large"}>追踪物流</Button>);

            case 40:
                return (
                    <Button
                        type="primary"
                        onClick={()=>{
                            setConfirmReceivedModalOpen(true)
                            setConfirmReceivedOrderId(order.id)
                        }}
                        size={"large"}>确认收货</Button>
                );

            case 50:
                return (<Button type="primary" onClick={() => navigate("/profile/order/comment?id=" + order.id)}
                                size={"large"}>去评价</Button>);

            case 60:
                return (<Button type="primary" onClick={() => navigate("/profile/order/refund-detail?id=" + order.id)}
                                size={"large"}>退款详情</Button>);

            case 70:
                return (
                    <Button
                        type="primary"
                        onClick={async () => {
                            var result = await saveOmsOrderCarByOrder(order.id);
                            if(result.code === 200){
                                notificationApi.success({
                                    message: `提示信息`,
                                    description: "加入购物车成功",
                                    placement: "bottomRight",
                                    duration: 5,
                                });
                            }
                        }}
                        size={"large"}
                    >
                        加入购物车
                    </Button>
                );


        }

        // 判断已完成 要不要显示修改评价
        if (order.orderStatus === 80 && (order.orderCommentUpdateCount !== 0 && order.orderCommentExpectedDate >= Date.now())) {
            return (<Button type="primary" onClick={() => navigate("/profile/order/comment?id=" + order.id)}
                            size={"large"}>修改评价</Button>);
        } else {
            return (
                <Button
                    type="primary"
                    onClick={async () => {
                        var result = await saveOmsOrderCarByOrder(order.id);
                        if(result.code === 200){
                            notificationApi.success({
                                message: `提示信息`,
                                description: "加入购物车成功",
                                placement: "bottomRight",
                                duration: 5,
                            });
                        }
                    }}
                    size={"large"}
                >
                    加入购物车
                </Button>
            );
        }
    }


    // Renderer timer callback with condition
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        var timer = days + " 天 " + hours + " 小时 " + minutes + " 分 " + seconds + " 秒";

        if (days === 0) {
            timer = hours + " 小时 " + minutes + " 分 " + seconds + " 秒 ";
        }

        if (days === 0 && hours === 0) {
            timer = minutes + " 分 " + seconds + " 秒 ";
        }

        return ("剩馀 " + timer + " 自动确认收货");
    };



    // 确认收货弹出框控制件
    const [confirmReceivedModalOpen, setConfirmReceivedModalOpen] = useState(false);

    // 因为不可以把 modal 放在 loop 里，但是又要给订单id，所以直能用 state 的方式从按钮传 id 到 modal 里
    const [confirmReceivedOrderId, setConfirmReceivedOrderId] = useState("");

    return (
        <>
            {/*确认收货弹出框*/}
            <Modal title="您要确认收货吗？"
                   open={confirmReceivedModalOpen}
                   onOk={async () => {
                       // 让按钮 loading
                       setConfirmReceivedLoading(true);

                       // 请求接口
                       var result = await doOmsOrderReceipt(confirmReceivedOrderId)

                       if (result.code === 200) {
                           // 把成功信息放在 params 上，让下一次 rendering 显示
                           window.location.href = window.location.href + "&msg=" + "确认收货成功";
                       } else {
                           notificationApi.error({
                               message: `错误信息`,
                               description: result.message,
                               placement: "bottomRight",
                               duration: 8,
                           });
                           setConfirmReceivedLoading(false);
                       }
                   }}
                   okButtonProps={{
                       loading: confirmReceivedLoading
                   }}
                   okText={"确认收货"}
                   cancelText={"取消"}
                   onCancel={()=>{setConfirmReceivedModalOpen(false)}}
            >
                <p>确认收货后，代表商品无误，且同意享买拨款给商铺。确认后不可取消或申请退款。</p>
            </Modal>


            {messageContextHolder}
            {notificationContextHolder}
            <Row id={"order-page"}>
                <Menu onClick={onClickStatusFilter} selectedKeys={[currentOrderStatus]} mode="horizontal" items={items}
                      id={"order-filter"}/>
                <div
                    id={"order-search"}
                    style={{
                        width: "100%",
                        marginTop: "15px",
                    }}
                >
                    {
                        currentOrderStatus === ""
                        &&
                        <Search
                            placeholder="您可以通过商品名称、商铺名称、订单编号来搜索订单..."
                            enterButton
                            allowClear
                            onSearch={onSearch}
                            prefix={<SearchOutlined/>}
                            style={{
                                margin: "0px 0px 15px 0px",
                            }}
                        />
                    }
                </div>

                {
                    orderListTotalSize !== 0
                        ?
                        <>
                            {
                                orderList.map((orderListBuilder, index) => {
                                    return (
                                        <div className={"order-item"}>
                                            <div className={"info"}>
                                                <div className={"info-left"}
                                                     style={orderListBuilder.orderStatusPay !== 0 ? {cursor: "pointer"} : {cursor: "auto"}}
                                                     onClick={() => {
                                                         // 0 代表没付款款，属于待付款
                                                         if (orderListBuilder.orderStatusPay !== 0) {
                                                             navigate("/store?id=" + orderListBuilder.storeId)
                                                         }
                                                     }}>
                                                    <img
                                                        src={identifyStoreNameAndHead(1, orderListBuilder.orderStatusPay, orderListBuilder.storeHead, orderListBuilder.storeName)}
                                                        width={"25px"}
                                                        height={"25px"}
                                                        style={{borderRadius: "100px"}}
                                                    />
                                                    <span
                                                        style={{
                                                            margin: "2px 0px 0px 12px",
                                                            color: "#494949",
                                                            fontWeight: "500",
                                                        }}>{identifyStoreNameAndHead(2, orderListBuilder.orderStatusPay, orderListBuilder.storeHead, orderListBuilder.storeName)}&nbsp;&nbsp;
                                                        <span
                                                            style={{fontSize: "10px"}}><RightOutlined/></span></span>

                                                </div>

                                                <div className={"info-right"}
                                                     style={{
                                                         marginRight: "0px",
                                                         marginLeft: "auto"
                                                     }}
                                                >
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        color: "#494949"
                                                    }}>
                                                    订单编号：{orderListBuilder.orderCode}
                                                </span>
                                                    <span className={"color-shopmy-orange"}
                                                          style={{
                                                              fontSize: "14px",
                                                              marginLeft: "20px",
                                                              fontWeight: "500"
                                                          }}>{orderStatusMapping(orderListBuilder.orderStatus)}</span>
                                                </div>
                                            </div>
                                            <Divider/>
                                            {/* 最多显示二个商品*/}
                                            <div className={"product"}
                                                 onClick={() => navigate("/profile/order/order-detail?id=" + orderListBuilder.id)}>
                                                {
                                                    orderListBuilder.products.map((innerProductLoader, index) => {
                                                        return (
                                                            index < 2 &&
                                                            <div className={"product-item"}>
                                                                <img
                                                                    src={innerProductLoader.productImgurl}
                                                                    width={"80px"}
                                                                    height={"80px"}
                                                                    style={{
                                                                        boxShadow: "1px 1px 0 rgb(5 145 255 / 10%)",
                                                                        zIndex: "1000",
                                                                        objectFit: "cover"
                                                                    }}
                                                                />
                                                                <Skeleton.Image
                                                                    active={true}
                                                                    style={{
                                                                        position: "absolute",
                                                                        left: "27px",
                                                                        width: "80px",
                                                                        height: "80px",
                                                                        borderRadius: "9px"
                                                                    }}
                                                                />
                                                                <div className={"product-detail"}>
                                                                    <div
                                                                        className={"product-title"}>{innerProductLoader.productName}</div>
                                                                    <div
                                                                        className={"product-sku grey-sub-font"}>{innerProductLoader.productAttributes}</div>
                                                                    <div
                                                                        className={"product-amount grey-sub-font"}>x{innerProductLoader.productNum}</div>
                                                                </div>
                                                                <div className={"product-item-price"}>
                                                                    ${innerProductLoader.productSellerPrice.toFixed(2)}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }

                                                {
                                                    orderListBuilder.products.length > 2 &&
                                                    <span className={"product-item-more"}
                                                          onClick={() => navigate("/profile/order/order-detail?id=" + orderListBuilder.id)}>
                                                    查看其他 {orderListBuilder.products.length - 2} 件商品 &nbsp;
                                                        <span style={{fontSize: "10px"}}><DownOutlined/></span>
                                                </span>
                                                }
                                            </div>

                                            <Divider/>
                                            <div className={"detail"}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <span className={"total"}> 共 {orderListBuilder.products.length} 件 合计：
                                                        <span>${(orderListBuilder.orderPrice + orderListBuilder.orderStripeServicePrice).toFixed(2)}</span>
                                                    </span>

                                                    <span className={"info-text grey-sub-font"}>
                                                        {identifyOrderTipsList(orderListBuilder)}
                                                            {/*确认收货倒计时并需在此处理，不可以呼叫其他方法*/}
                                                            {
                                                                orderListBuilder.orderStatus === 40 &&
                                                                <Countdown
                                                                    date={orderListBuilder.orderGrantPriceExpectedDate}
                                                                    renderer={renderer}
                                                                />
                                                            }
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        margin: "0px 0px 0px auto",
                                                        display: "flex",
                                                        alignItems: "center"

                                                    }}
                                                >
                                                    {identifyOrderButton(orderListBuilder)}

                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}
                        </>
                        :
                        <>
                            <div
                                id={"loading"}
                                style={{
                                    width: "1255px",
                                    display: display,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "500px"
                                }}
                            >
                                {<Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin/>}/>}
                                <h6>加载中...</h6>
                            </div>
                            <div
                                style={{
                                    display: display === "flex" ? "none" : "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}
                            >
                                <NoData message={"没有相关数据"}/>
                            </div>
                        </>
                }


                <Pagination
                    showQuickJumper
                    current={orderListCurrentPage}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    total={orderListTotalSize}
                    showSizeChanger={false}
                    onChange={onChangeProductPage}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "20px"
                    }}
                />

            </Row>
        </>
    );
}

export default OrderList;