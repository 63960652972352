import React, {useState} from "react";
import {CardElement, CardNumberElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Alert, Button} from "antd";


/**
 *
 * Doc comment
 * 这边会重支付页面传两个参数
 * props.totalPrice -> 最后总计价钱（包含手续费）
 * props.orderId -> 订单 id
 */

const StripePayment = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    // error
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(null);

    // stripe loading
    const [stripeLoading, setStripeLoading] = useState(false);


    const handleSubmit = async (event) => {

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setStripeLoading(true);

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.origin + '/checkout/result?orderId=' + props.orderId,
            },
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
            setError(error);

            console.log(error)

        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setStripeLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
        // layout: "accordion",
        terms : {
            card: "never"
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement options={paymentElementOptions}/>
            <br/>

            {
                // 重发时隐藏错误信息
                (errorMessage && !stripeLoading) &&
                <Alert
                    message={
                        error.code === "payment_intent_authentication_failure" || errorMessage === '您的银行卡被拒绝了。'
                            ?
                                <span>
                                    支付失败，享买仅支持澳大利亚发行的 Visa 或 Master 卡，同时必须完成短信验证，详细请参阅&nbsp;
                                    <a href={"https://redirect-app.shopmy.top/card"} target="_blank" style={{textDecoration: "underline"}} className={"color-shopmy-orange"}>帮助中心</a>。
                                    如需帮助请截图此页：{error.payment_intent.id}
                                </span>
                            :
                                errorMessage
                    }
                    type="error"
                    showIcon
                />
            }

            <br/>
            <Button
                disabled={!stripe}
                type="primary"
                style={{width: '100%'}}
                htmlType="submit"
                size={"large"}
                loading={stripeLoading}
            >
                确认支付&nbsp;&nbsp;<span style={{fontWeight: "600"}}>${props.totalPrice}</span>
            </Button>
            {/* Show error message to your customers */}
        </form>
    );
}


export default StripePayment;