import React, {useEffect, useState} from "react";
import {Col, Divider, Row} from "antd";
import {Link, NavLink, Route, Routes, useNavigate} from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "../pages/profile/account/ForgotPassword";
import Chat from "./Chat";




const Account = (props) => {

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    const [redirectParams, setRedirectParams] = useState("");
    useEffect(() => {
        // 检查网址是否携带除了跟忘记密码和注册帐户以外其他的 path，得有的把他转换 redirect 参数，登录后自动转址
        if(window.location.pathname != "" && window.location.pathname != "/account/forgot-password" && window.location.pathname != "/account/register") {
            setRedirectParams(window.location.pathname + window.location.search);
        }
    },[]);

    useEffect(() => {
        console.log(redirectParams)
    },[redirectParams])

    return (
        <>
            {
                // 判断页面地址是否为 /chat/portal
                // 如果是，直接进入聊天室
                // 如果不是，进入登录页面
                window.location.pathname === "/chat/portal"
                    ?
                    <Routes>
                        {/*直接接受 token 的 chat portal*/}
                        <Route path="/chat/portal" element={<Chat/>}/>
                    </Routes>
                    :
                    <>
                        {/* ------------- BODY -------------- */}
                        <Row id="account-container" className="body" style={{paddingTop: "0"}}>
                            <Row
                                className={"safe-area header"}
                                style={{
                                    backgroundColor: "rgb(255 255 255 / 17%)",
                                    backdropFilter: "blur(7px)",
                                    maxWidth: "100%",
                                    padding: "15px"
                                }}
                            >
                                <Col span={24} id={"logo"}>
                                    <NavLink
                                        className={"safe-area"}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "left",
                                            height: "100%",
                                        }}
                                        to="/"
                                    >
                                        <img src={props.varBucketURL + "/assets/logo.svg"} alt="logo" width="160px"></img>
                                        <Divider
                                            type={"vertical"}
                                            style={{
                                                height: "30px",
                                                margin: "0 20px",
                                                backgroundColor: "#ffffff"
                                            }}
                                        />
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "500",
                                                color: "#ffffff"
                                            }}
                                        >
                                    登录/注册享买帐号
                                </span>
                                    </NavLink>
                                </Col>
                            </Row>

                            <Row className={"safe-area"} style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>

                                <Col span={12} id="box" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}>
                                    <img src={props.varBucketURL + "/assets/logo_orange.svg"} alt="logo" width="250px"></img>
                                    <Routes>
                                        {/*携带制作好的转址参数*/}
                                        <Route path="*" element={<Login redirect={redirectParams}/>}/>
                                        <Route path="/" element={<Login redirect={redirectParams}/>}/>
                                        <Route path="/account/forgot-password" element={<ForgotPassword renderType={"guest"}/>}/>
                                        <Route path="/account/register" element={<Register redirect={redirectParams}/>}/>

                                        {/*直接接受 token 的 chat portal*/}
                                        <Route path="/chat/portal" element={<Chat/>}/>

                                    </Routes>

                                </Col>

                                {/*<Col span={2}></Col>*/}
                            </Row>
                        </Row>
                        {/* ------------- BODY -------------- */}



                        <Row className="footer">
                            <Col span={24} style={{}}>
                                <img src={props.varBucketURL + "/assets/logo-large-with-context.svg"} width={"250px"}
                                     style={{marginBottom: "40px"}}></img>
                                <p>
                                    <Link to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e9%9a%90%e7%a7%81%e6%9d%a1%e6%ac%be/" target="_blank">隐私政策</Link>
                                    <Link to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e6%9c%8d%e5%8a%a1%e6%9d%a1%e6%ac%be/" target="_blank">使用条款</Link>
                                    <Link to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e7%89%a9%e6%b5%81%e6%9d%a1%e6%ac%be/" target="_blank">金物流服务条款</Link>
                                    <Link to="https://help.shopmy.com.au" target="_blank">帮助中心</Link>
                                </p>
                                <p>SHOPMY PTY LTD 2020-2023 © All Rights Reserved | ACN: 637 513 369</p>

                            </Col>
                        </Row>
                    </>
            }
        </>
    );
}


export default Account;