import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, message, List, Row, Steps, Image, Empty, Space, notification, Modal} from "antd";
import {EnvironmentFilled, LeftOutlined, PhoneFilled, RightOutlined} from "@ant-design/icons";
import {
    identifyOrderTipsDetail,
    orderStatusMapping,
    paymentMethodIconMapping,
    paymentMethodNameMapping,
    unixDateToHumanDate
} from "../../../data/tool";
import {bucketURL} from "../../../data/env";
import {useNavigate} from "react-router-dom";
import {
    doOmsOrderReceipt,
    doOmsOrderReceiptTime,
    findOmsOrder,
    saveOmsOrderCarByOrder
} from "../../../data/Order-Service-Repo";
import NotFound from "../../../components/NotFound";
import Loading from "../../../components/Loading";
import Countdown from "react-countdown";
import CancelDetail from "./component/CancelDetail";
import SubmitRequest from "./component/SubmitRequest";

const OrderDetail = (props) => {
    const navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // 是否展示订单，用于 notfound
    const [isOrderVisible, setIsOrderVisible] = useState(true);

    // 是否在加载
    const [isLoading, setIsLoading] = useState(true);

    // 订单数据
    const [order, setOrder] = useState([]);

    // 订单中的商铺对应商品合集
    const [orderProductStore, setOrderProductStore] = useState([]);

    // 订单物流信息
    const [orderLogistics, setOrderLogistics] = useState([]);
    const [orderLogisticsName, setOrderLogisticsName] = useState("");


    useEffect(() => {
        // when there are valid user set token and user object on state
        async function load() {
            // 显示上一次 render 放在 params 上的信息
            if (params.msg !== null) {
                notificationApi.success({
                    message: `提示信息`,
                    description: params.msg,
                    placement: "bottomRight",
                    duration: 5,
                });
            }


            if (params.id === null) {
                messageApi.error( '订单号不能为空');

                setIsOrderVisible(false);

            } else {
                fetchOrder();
            }
        }

        load()

    }, []);

    async function fetchOrder() {
        // fetch data
        var result = await findOmsOrder([], params.id, "");

        // set data
        if (result.code === 200) {
            // 完成加载
            setIsLoading(false);

            setOrder(result.data)
            setOrderProductStore(result.data.stores)

            // 生成 timeline
            generateTimeline(result.data);

            // 生成订单明细
            generateReceipt(result.data);

            // 取物流信息
            if (result.data.orderLogisticsData !== null || result.data.orderLogisticsData !== undefined) {
                setOrderLogistics(JSON.parse(result.data.orderLogisticsData))
                setOrderLogisticsName(result.data.orderLogisticsName)
            }

        } else {
            messageApi.error('无法取得该订单');
            setIsLoading(false)
            setIsOrderVisible(false);

        }
    }





    // 生成订单时间轴
    const [timeline, setTimeline] = useState([]);
    const [timelineStep, setTimelineStep] = useState(0);
    function generateTimeline(order){
        var item = [
            {
                title: '订单已成立',
                description: getTimelineDate(order.createDate),
            },
            {
                title: '订单已付款',
                description: getTimelineDate(order.orderPayDate),
            },
            {
                title: '订单已出货',
                description: getTimelineDate(order.orderShipDate),
            },
            {
                title: '订单已完成',
                description: getTimelineDate(order.orderReceiptDate),
            }
        ];

        // antd start from 0
        var step = 0;
        if(order.createDate !== null){
            step = 0;
        }

        if (order.orderPayDate !== null){
            step++;
        }

        if (order.orderShipDate !== null){
            step++;
        }

        if (order.orderReceiptDate !== null){
            step++;
        }

        // 后端没有评价时间，因此无法显示


        // 完成后放到 storage里
        setTimeline(item);
        setTimelineStep(step)
    }
    // 判断 timeline 要生成的时间
    function getTimelineDate (timestamp){
        if(timestamp === null){
            return "暂无";
        } else {
            return unixDateToHumanDate(timestamp)
        }
    }


    // 生成订单明细
    const [receipt, setReceipt] = useState([]);
    function generateReceipt(order){
        var item = [];

        // 商品总金额
        item.push({
            title: '商品总金额',
            price: order.orderProductPrice.toFixed(2),
        })

        // 优惠券折扣
        // 各项优惠券
        var subCoupon = [];
        if(order.orderDiscountPrice > 0){
            order.coupons.map((builderCoupons, index) => {
                subCoupon.push({
                    title: builderCoupons.title,
                    price: builderCoupons.price.toFixed(2),
                })
            })
        }
        // 优惠券总计
        item.push({
            title: '优惠券折扣',
            price: order.orderDiscountPrice.toFixed(2),
            sub: subCoupon
        })

        // 实付运费
        item.push({
            title: '实付运费',
            price: order.orderPostPrice.toFixed(2),
        })

        // 支付手续费
        var surcharge = "";
        if(order.orderPayDate === null){
            surcharge = "支付时计算";
        } else {
            switch (order.orderPayType) {
                case 1:
                    surcharge = "由支付商收取";
                    break;

                case 2:
                    surcharge = "由支付商收取";
                    break;

                case 3:
                    surcharge = "$ " + order.orderStripeServicePrice.toFixed(2);
                    break;

                case 4:
                    surcharge = "$ " + order.orderStripeServicePrice.toFixed(2);
                    break;

                case 5:
                    surcharge = "$ " + order.orderStripeServicePrice.toFixed(2);
                    break;
            }
        }
        item.push({
            title: '支付手续费',
            price: surcharge,
        })

        // 订单总金额
        item.push({
            title: '订单总金额',
            price: order.orderAllPrice.toFixed(2),
        })

        // 支付方式
        if(order.orderPayDate !== null){
            item.push({
                title: '支付方式',
                type: order.orderPayType,
            })
        }

        // 完成后放到 storage里
        setReceipt(item);
    }


    // 为待付款订单设计的：使用商铺id 查找该商铺订单的备注
    function getOrderRemarkByStoreId(storeId){
        var result = null;
        if(order.orderStoreRemarkList !== null){
            order.orderStoreRemarkList.map((remark) => {
                if(remark.storeId === storeId){
                    result = remark.buyerRemark;
                }
            })
        }


        return result;
    }


    // 发起请求控制件
    // 取消详情 modal
    const [cancelDetailModal, setCancelDetailModal] = useState(false);
    // 发起退货退款 modal
    const [submitRefundModal, setSubmitRefundModal] = useState(false);
    // 发起判定中心 modal
    const [submitAuditModal, setSubmitAuditModal] = useState(false);
    // 取消订单 modal
    const [cancelOrderModal, setCancelOrderModal] = useState(false);

    function identifyOrderButton(order) {
        switch (order.orderStatus) {

            case 10:
                return (
                    <Space>

                        <Button type="primary" onClick={() => navigate("/checkout/confirm?id=" + order.id)}>
                            去付款
                        </Button>

                        <Button
                            onClick={() => setCancelOrderModal(true)}
                        >
                            取消订单
                        </Button>

                        <SubmitRequest
                            order={order}
                            open={cancelOrderModal}
                            onCancel={() => setCancelOrderModal(false)}
                            type={3}
                        />


                    </Space>
                );

            case 20:
                return (
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                // 取第一个商铺的 customerId，因为待出货时已经拆分
                                window.open("/chat/portal?conversationUserId=" + order.stores[0].storeCustomerId, "_blank")
                            }}
                        >
                            联络商铺
                        </Button>

                        {
                            // 我们的 app 有个规则，当发退 3 次，第 4 次只能发判定中心
                            order.orderRefundCount < 3
                                ?
                                <>
                                    <Button
                                        onClick={() => setSubmitRefundModal(true)}
                                    >
                                        发起退货/退款
                                    </Button>

                                    <SubmitRequest
                                        order={order}
                                        open={submitRefundModal}
                                        onCancel={() => setSubmitRefundModal(false)}
                                        type={1}
                                    />

                                </>

                                :
                                <>
                                    <Button
                                        onClick={() => setSubmitAuditModal(true)}
                                    >
                                        转交判定中心
                                    </Button>

                                    <SubmitRequest
                                        order={order}
                                        open={submitAuditModal}
                                        onCancel={() => setSubmitAuditModal(false)}
                                        type={2}
                                    />
                                </>
                        }
                    </Space>

                );


            // 暂时没有配送中状态，不放链接
            case 30:
                return (<Button type="primary" onClick={() => navigate("/")}>追踪物流</Button>);

            case 40:
                return (
                    <Space>
                        <Button
                            type="primary"
                            onClick={(()=>{setConfirmReceivedModalOpen(true)})}
                        >
                            确认收货
                        </Button>

                        <Button
                            type="primary"
                            onClick={(()=>{setExtendReceivedModalOpen(true)})}
                        >
                            延长收货时间
                        </Button>

                        {
                            // 我们的 app 有个规则，当发退 3 次，第 4 次只能发判定中心
                            order.orderRefundCount < 3
                                ?
                                    <>
                                        <Button
                                            onClick={() => setSubmitRefundModal(true)}
                                        >
                                            发起退货/退款
                                        </Button>

                                        <SubmitRequest
                                            order={order}
                                            open={submitRefundModal}
                                            onCancel={() => setSubmitRefundModal(false)}
                                            type={1}
                                        />

                                    </>

                                :
                                    <>
                                        <Button
                                            onClick={() => setSubmitAuditModal(true)}
                                        >
                                            转交判定中心
                                        </Button>

                                        <SubmitRequest
                                            order={order}
                                            open={submitAuditModal}
                                            onCancel={() => setSubmitAuditModal(false)}
                                            type={2}
                                        />
                                    </>
                        }

                    </Space>

                );

            case 50:
                return (<Button type="primary" onClick={() => navigate("/profile/order/comment?id=" + order.id)}>去评价</Button>);

            case 60:
                return (<Button type="primary" onClick={() => navigate("/profile/order/refund-detail?id=" + order.id)}>退款详情</Button>);

            case 70:
                return (
                    <>
                        <CancelDetail
                            open={cancelDetailModal}
                            onCancel={()=>{setCancelDetailModal(!cancelDetailModal)}}
                            order={order}
                        />
                        <Button type="primary" onClick={() => setCancelDetailModal(true)}>取消详情</Button>
                    </>
                );

        }

        // 判断已完成 要不要显示修改评价
        if (order.orderStatus === 80 && (order.orderCommentUpdateCount !== 0 && order.orderCommentExpectedDate >= Date.now())) {
            return (<Button type="primary" onClick={() => navigate("/profile/order/comment?id=" + order.id)}>修改评价</Button>);
        } else {
            return (
                <Button
                    type="primary"
                    onClick={async () => {
                        var result = await saveOmsOrderCarByOrder(order.id);
                        if(result.code === 200){
                            notificationApi.success({
                                message: `提示信息`,
                                description: "加入购物车成功",
                                placement: "bottomRight",
                                duration: 5,
                            });
                        }
                    }}>
                    加入购物车
                </Button>
            );
        }
    }


    // Renderer timer callback with condition
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        var timer = days + " 天 " + hours + " 小时 " + minutes + " 分 " + seconds + " 秒";

        if(days === 0){
            timer = hours + " 小时 " + minutes + " 分 " + seconds + " 秒 ";
        }

        if(days === 0 && hours === 0){
            timer = minutes + " 分 " + seconds + " 秒 ";
        }

        return ("剩馀 " + timer + " 自动确认收货");
    };



    // 延长收货确认弹出框控制件
    const [extendReceivedModalOpen, setExtendReceivedModalOpen] = useState(false);
    // 确认收货弹出框控制件
    const [confirmReceivedModalOpen, setConfirmReceivedModalOpen] = useState(false);

    // 确认收货按钮加载
    const [confirmReceivedLoading, setConfirmReceivedLoading] = useState(false);
    // 延长收货加载
    const [extendReceivedLoading, setExtendReceivedLoading] = useState(false);


    return (
        <>
            { messageContextHolder }
            { notificationContextHolder }
            {
                isLoading
                ?
                    <>
                        <Loading/>
                    </>

                :
                    <Row id={"order-detail-page"}>
                        {/*延长收货弹出框*/}
                        <Modal title="您确认要延长收货时间吗？"
                               open={extendReceivedModalOpen}
                               onOk={async () =>
                               {
                                   // 让按钮 loading
                                   setExtendReceivedLoading(true);

                                   // 请求接口
                                   var result = await doOmsOrderReceiptTime(order.id)

                                   if (result.code === 200) {
                                       await fetchOrder();

                                       notificationApi.success({
                                           message: `提示信息`,
                                           description: `延长收货时间成功。订单将在 ${unixDateToHumanDate(order.orderGrantPriceExpectedDate)} 自动确认收货。`,
                                           placement: "bottomRight",
                                           duration: 5,
                                       });
                                       setExtendReceivedLoading(false)


                                   } else {
                                       notificationApi.error({
                                           message: `错误信息`,
                                           description: result.message,
                                           placement: "bottomRight",
                                           duration: 8,
                                       });
                                       setExtendReceivedLoading(false)
                                   }

                                   setExtendReceivedModalOpen(false)
                               }}
                               okButtonProps={{
                                   loading: extendReceivedLoading
                               }}
                               okText={"确认延长收货时间"}
                               cancelText={"取消"}
                               onCancel={()=>{setExtendReceivedModalOpen(false)}}
                        >
                            <p>收货时间可延长 {order.dealExtendLimit} 小时，但您可以延长 {order.dealExtendConfirmReceive} 次，当倒计时结束时订单将会自动确认，请问是否继续 ?</p>
                        </Modal>

                        {/*确认收货弹出框*/}
                        <Modal title="您要确认收货吗？"
                               open={confirmReceivedModalOpen}
                               onOk={async () =>
                               {
                                   // 让按钮 loading
                                   setConfirmReceivedLoading(true);

                                   // 请求接口
                                   var result = await doOmsOrderReceipt(order.id)

                                   if (result.code === 200) {
                                       await fetchOrder();
                                       notificationApi.success({
                                           message: `提示信息`,
                                           description: `确认收货成功。`,
                                           placement: "bottomRight",
                                           duration: 5,
                                       });

                                       setConfirmReceivedLoading(false)

                                   } else {
                                       messageApi.error(result.message);
                                       setConfirmReceivedLoading(false)
                                   }

                                   setConfirmReceivedModalOpen(false)
                               }}
                               okButtonProps={{
                                   loading: confirmReceivedLoading
                               }}
                               okText={"确认收货"}
                               cancelText={"取消"}
                               onCancel={()=>{setConfirmReceivedModalOpen(false)}}
                        >
                            <p>确认收货后，代表商品无误，且同意享买拨款给商铺。确认后不可取消或申请退款。</p>
                        </Modal>

                        {
                            isOrderVisible === true
                                ?
                                <>
                                    <Col span={24} className={"container"} id={"status"}>
                                        <div>
                                            <div className={"left"}>
                                                {/*我们会保存下最后一次进入 order list 的地址，在这边返回时可以用。如果没有该地址，就用 history.go*/}
                                                <Button type="dashed" icon={<LeftOutlined/>}
                                                        onClick={() => {
                                                            window.history.go(-1)
                                                        }}>
                                                    返回上一页
                                                </Button>
                                            </div>

                                            <div className={"right"}>
                                                <span>订单编号：{order.orderCode}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col span={24} className={"container"} id={"status"}>
                                        <div>
                                            <div className={"left"} style={{alignItems: "center"}}>
                                                <h3 className={"color-shopmy-orange"}>订单状态</h3>
                                                <strong className={"color-shopmy-orange"}>：{orderStatusMapping(order.orderStatus)} &nbsp;</strong>

                                                {/*确认收货倒计时并需在此处理，不可以呼叫其他方法*/}
                                                {
                                                    order.orderStatus === 40
                                                        ?
                                                        <Countdown
                                                            date={order.orderGrantPriceExpectedDate}
                                                            renderer={renderer}
                                                        />

                                                        :
                                                        <span>
                                                            {identifyOrderTipsDetail(order)}
                                                        </span>
                                                }


                                            </div>

                                            <div className={"right"}>
                                                {
                                                    identifyOrderButton(order)
                                                }
                                            </div>
                                        </div>
                                        <Divider/>
                                        <br/>
                                        <Steps
                                            current={timelineStep}
                                            size="small"
                                            labelPlacement="vertical"
                                            items={timeline}
                                            progressDot
                                        />


                                    </Col>

                                    <Col span={24} className={"container"} id={"shipping"}>
                                        <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>配送信息</h3>
                                        <br/>
                                        <Row>
                                            <Col
                                                span={11}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "13px",
                                                    paddingLeft: "6px"
                                                }}
                                            >
                                                <div className={"left"} id={"receiver"} style={{flexDirection: "column"}}>
                                    <span
                                        style={{
                                            fontSize: "17px"
                                        }}
                                    >
                                        {order.receiverName}
                                            </span>
                                                    <span
                                                        style={{
                                                            color: "#868686"
                                                        }}
                                                    >
                                                        <PhoneFilled/> &nbsp;
                                                        {order.receiverMobile}
                                                    </span>

                                                    <span
                                                        style={{
                                                            color: "#868686"
                                                        }}
                                                    >
                                                         <EnvironmentFilled/> &nbsp;
                                                        {order.receiverAddress}
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col span={2}>
                                                <Divider type="vertical" style={{height: "100px"}}/>
                                            </Col>

                                            <Col span={10}>

                                                {
                                                    order.orderLogisticsData === null
                                                        ?   <Empty
                                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                            description={"暂无追踪信息"}
                                                            style={{padding: "0px", width: "324px"}}
                                                        />
                                                        :
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "flex-start",
                                                            padding: "3px"
                                                        }}
                                                             id={"tracking"}
                                                        >

                                                            <img src={orderLogistics.logisticsPicture}
                                                                 width={"25px"}
                                                                 style={{marginTop: "4px"}}
                                                            ></img>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginLeft: "15px"
                                                            }}>
                                                                <span style={{marginBottom: "7px"}}>{orderLogistics.logisticsName}</span>
                                                                <span className={"color-subtitle-grey"}>物流单号：{orderLogistics.logisticsCode}
                                                                    <span
                                                                        className={"color-shopmy-orange"}
                                                                        style={{fontSize: "13px" , cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            // 复制物流单号
                                                                            navigator.clipboard.writeText({orderLogistics}.orderLogistics.logisticsCode);
                                                                            message.success("已拷贝分享链接到剪贴板");
                                                                        }}
                                                                    >
                                                                &nbsp;&nbsp;复制
                                                            </span>
                                                        </span>
                                                                {
                                                                    (new RegExp('其他物流商')).test(orderLogistics.logisticsName) !== true &&
                                                                    <span
                                                                        className={"color-shopmy-orange"}
                                                                        style={{fontSize: "13px", cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            window.open('https://tracking.shopmy.com.au/?carrierCode=' + orderLogistics.logisticsName + '&trackingNumber=' + orderLogistics.logisticsCode , '_blank');

                                                                        }}
                                                                    >
                                                                        物流追踪&nbsp;
                                                                        <span style={{fontSize: "10px"}}><RightOutlined/></span>
                                                                     </span>
                                                                }


                                                            </div>
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                        <br/>
                                    </Col>

                                    <Col span={24} className={"container"} id={"receipt"}>
                                        <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>购买明细</h3>
                                        {
                                            orderProductStore.map((storeBuilder, index) => {
                                                return (
                                                    <div className={"order-group"}>
                                                        <div className={"store-info"} onClick={() => {
                                                            navigate("/store?id=" + storeBuilder.storeId)
                                                        }}>
                                                            <img
                                                                src={storeBuilder.storeIco}
                                                                width={"25px"}
                                                                height={"25px"}
                                                                style={{borderRadius: "100px"}}
                                                            />
                                                            <span
                                                                style={{margin: "0px 0px 0px 12px"}}>{storeBuilder.storeName}&nbsp;&nbsp;
                                                                <span style={{fontSize: "10px"}}><RightOutlined/></span>
                                                            </span>
                                                        </div>
                                                        <div className={"order-item"}>
                                                            {
                                                                storeBuilder.products.map((productBuilder, index) => {
                                                                    return (
                                                                        <div className={"product-item"}>
                                                                            <img
                                                                                src={productBuilder.productImgurl}
                                                                                width={"80px"}
                                                                                height={"80px"}
                                                                                style={{boxShadow: "1px 1px 0 rgb(5 145 255 / 10%)"}}
                                                                            />
                                                                            <div className={"product-detail"}>
                                                                                <div
                                                                                    className={"product-title"}>{productBuilder.productName}</div>
                                                                                <div
                                                                                    className={"product-sku grey-sub-font"}>{productBuilder.productAttributes}</div>
                                                                                <div
                                                                                    className={"product-amount grey-sub-font"}>x{productBuilder.productNum}</div>
                                                                            </div>
                                                                            <div className={"product-item-price"}>
                                                                                ${(productBuilder.productSellerPrice).toFixed(2)}
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            // 待付款订单和已付款的备注有不同的处理方式
                                                            // 这边是处理待付款订单
                                                            (order.orderStatus === 10 && getOrderRemarkByStoreId(storeBuilder.storeId)) &&
                                                            <Alert
                                                                message={"订单备注：" + getOrderRemarkByStoreId(storeBuilder.storeId)}
                                                                type="warning"
                                                                showIcon
                                                                style={{
                                                                    margin: "0px 0px 13px 0px",
                                                                    width: "820px",
                                                                    backgroundColor: "rgba(245,245,245,0)"
                                                                }}
                                                            />
                                                        }

                                                        {
                                                            // 待付款订单和已付款的备注有不同的处理方式
                                                            // 这边是处理其他状态的订单
                                                            (order.orderStatus !== 10 && order.buyerRemark) &&
                                                            <Alert
                                                                message={"订单备注：" + order.buyerRemark}
                                                                type="warning"
                                                                showIcon
                                                                style={{
                                                                    margin: "0px 0px 13px 0px",
                                                                    width: "820px",
                                                                    backgroundColor: "rgba(245,245,245,0)"
                                                                }}
                                                            />
                                                        }

                                                        {
                                                            // 最后一个不显示
                                                            index !== orderProductStore.length - 1 &&
                                                            <Divider style={{
                                                                minWidth: "829px",
                                                                margin: "17px 0px",
                                                                marginright: "10px",
                                                                width: "829px"
                                                            }}/>
                                                        }

                                                    </div>
                                                );


                                            })

                                        }

                                    </Col>

                                    <Col span={24} className={"container"} id={"receipt"}>
                                        <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>支付明细</h3>
                                        <List
                                            id={"transaction-list"}
                                            bordered
                                            dataSource={receipt}
                                            renderItem={(item) => (
                                                <List.Item>


                                                    {
                                                        // 是支付方式
                                                        item.title === "支付方式" &&
                                                        <>
                                                            <strong
                                                                className={"title"}
                                                            >
                                                                {item.title}
                                                            </strong>

                                                            <span style={{display: "flex", alignItems: "center"}}>
                                                        <img src={paymentMethodIconMapping(varBucketURL, item.type)}
                                                             width={"35px"}></img>
                                                                &nbsp;&nbsp;
                                                                {paymentMethodNameMapping(item.type)}
                                                    </span>
                                                        </>

                                                    }

                                                    {
                                                        // 是优惠券明细
                                                        item.title === "优惠券折扣" &&
                                                        <>
                                                            <strong
                                                                className={"title"}
                                                            >
                                                                {item.title}
                                                            </strong>

                                                            <span className={"coupon-price"}>
                                                            - $ {item.price}
                                                                {item.sub.map((couponBuilder, index) => {
                                                                    return (
                                                                        <div className={"sub-coupon"}>
                                                                            <span className={"sub-coupon-title"}>({couponBuilder.title}</span>
                                                                            )&nbsp;&nbsp;&nbsp;
                                                                            {
                                                                                "$" + couponBuilder.price
                                                                            }
                                                                        </div>
                                                                    );
                                                                })}

                                                    </span>



                                                        </>
                                                    }

                                                    {
                                                        // 除了支付方式和优惠券的明细
                                                        (item.title !== "优惠券折扣" && item.title !== "支付方式") &&
                                                        <>
                                                            <strong
                                                                className={"title"}
                                                            >
                                                                {item.title}
                                                            </strong>

                                                            <span className={"price" + (item.title === "订单总金额" && ' total')}>
                                                        {
                                                            item.title === "支付手续费"
                                                                ? item.price
                                                                : "$ " + item.price
                                                        }
                                                    </span>
                                                        </>


                                                    }


                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                </>

                                :
                                <>
                                    <NotFound message={"找不到订单"}/>
                                </>
                        }


                    </Row>

            }
        </>

    ) ;
}


export default OrderDetail;