import React, {useEffect, useState} from "react";
import {Row} from "antd";
import {useNavigate} from "react-router-dom";
import $ from "jquery";

/**
 *
 * Doc comment
 * 在调用 proxy 时，请务必记得在最后目的的 navigate 加上  { replace: true } 选项
 * 这样才可以避免用户点击返回按钮时返回到此 proxy 页面
 */
const SearchAssistance = (props) => {
    const navigate = useNavigate();


    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // load product using URL param once page load
    useEffect(() => {
        async function load() {
            if (props.token !== null) {
                if(params.popularKeyword === "true"){
                    setTimeout(() => {
                        navigate("/search?key=" + params.key, { replace: true });
                    }, 100);
                }

            }
        }

        // 调整 #search-header #search input 的 padding，避免输入的内容跑到搜索选项下方
        $("#search-header #search input").css("padding-right", "0px")

        load();
    }, []);

    return (
        <Row style={{height: 'calc(100vh - 50px)'}}>

        </Row>);
}


export default SearchAssistance;