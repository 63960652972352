import React, {useEffect, useState} from "react";
import {Button, Popover, Row, Skeleton} from "antd";
import Loading from "./Loading";
import NoData from "./NoData";
import {findOmsOrderCarAppList} from "../data/Order-Service-Repo";
import {NavLink} from "react-router-dom";
import {bucketURL} from "../data/env";

const HeaderToolBox = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    }, []);

    // 购物车 popover
    const [cartList, setCartList] = useState([]);
    const [cartListIsLoading, setCartListIsLoading] = useState(true);


    return (
        <>
            <Popover
                // open={true}
                content={
                    <>
                        <span style={{fontWeight: "500"}}>近期加入商品：</span>
                        {
                            cartList.length === 0
                                ?
                                <>
                                    {
                                        cartListIsLoading
                                            ?
                                            <Loading/>

                                            :
                                            <NoData message={"购物车还是空的～"}/>
                                    }
                                </>
                                :
                                <div id={"cart-popover"}>
                                    {
                                        cartList.map((item, index) => {
                                            // 最多展示 4 个
                                            if(index < 4){
                                                return(
                                                    <div className={"item"}>
                                                        <img src={item.productImgurl}></img>
                                                        <Skeleton.Image active={"true"} />
                                                        <div className={"detail"}>
                                                            <span className={"product-name"}>{item.productName}</span>
                                                            <span className={"product-sku color-subtitle-grey"}>{item.productAttributes}</span>
                                                            <span className={"price"}>${item.productSellerPrice.toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                    }
                                    <br/>
                                    <Button type={"primary"} style={{width: "100%"}} onClick={()=>{window.location.href = "/cart"}}>查看购物车</Button>
                                </div>
                        }
                    </>
                }
                overlayStyle={{left: "1065px", top: "140.5px"}}
                overlayInnerStyle={{width: "400px", padding: "23px"}}
                title=""
                mouseEnterDelay="1"
                onOpenChange={async (open) => {
                    if (open) {
                        var result = await findOmsOrderCarAppList();
                        if(result.code === 200){
                            // 关闭加载
                            setCartListIsLoading(false);

                            // 用来存商品的 array
                            var cartArray = [];

                            // 只显示有效商品
                            result.data.stores.map((cartStore, index) => {
                                // 判断 cart 里有没有东西
                                if(cartStore.storeStatus === 1){
                                    // 把 store 里的商品放进 cartArray
                                    cartStore.products.map((cartProduct, index) => {
                                        cartArray.push(cartProduct);
                                    });
                                }
                            })

                            setCartList(cartArray)

                            console.log("gere")
                        }
                    }
                }}
            >
                <NavLink to="/cart" style={{marginRight: '16px'}}>
                    <div className={"item"}>
                        <img
                            src={varBucketURL + "/assets/icon-cart.svg"}
                            alt="cart"
                            width="28px"
                        ></img>
                    </div>
                </NavLink>
            </Popover>
            <NavLink to="/profile/notification" style={{marginRight: '22px'}}>
                <div className={"item"}>
                    <img
                        src={varBucketURL + "/assets/icon-notification.svg"}
                        alt="notification"
                        width="27px"
                    ></img>
                </div>
            </NavLink>
            <NavLink to="/chat" style={{marginRight: '10px'}}>
                <div className={"item"}>
                    <img src={varBucketURL + "/assets/icon-chat.svg"}
                         alt="chat"
                         width="22px"
                    ></img>
                </div>
            </NavLink>

        </>
    );
}


export default HeaderToolBox;