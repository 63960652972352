import React, {useState} from "react";
import {Button, Row} from "antd";
import AddressSelector from "../../../components/AddressSelector";

const Address = (props) => {


    return (
        <Row className={"profile-page-content-container"} id={"account-address-page"}>
            <h4 className={"color-shopmy-orange"} style={{margin: "0"}}>管理我的地址</h4>
            <br/>
            <br/>
            <div
                style={{
                    width: "80%",
                    margin: "auto"
                }}
            >
                <AddressSelector sessionToken={props.sessionToken} source={"setting"}/>
            </div>
            <br/>
            <br/>

        </Row>
    );
}


export default Address;