import {logout} from "./Customer-Service-Repo";

export default function beforeReturn(response){

    console.log(response)

    // 强制登出
    if(response.code === 402){
        logout();
    }
}