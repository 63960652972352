import React, {useEffect, useState} from "react";
import {Col, Divider, Menu, Row} from "antd";
import {getUserAvatar, getUserName} from "../data/tool";
import {Outlet, useNavigate} from "react-router-dom";
import {bucketURL} from "../data/env";
import {
    BellFilled,
    ContactsFilled,
    ShoppingFilled,
    TagsFilled,
} from "@ant-design/icons";


const Profile = (props) => {

    const navigate = useNavigate();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // ---------------------- Menu Config ----------------------
    function getMenuItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const items = [
        getMenuItem('我的帐户', '/profile/account/profile', <ContactsFilled />,[
            getMenuItem('个人信息', '/profile/account/profile'),
            getMenuItem('登录密码变更', '/profile/account/change-login-password'),
            getMenuItem('我的地址', '/profile/account/address'),
        ],null),
        getMenuItem('我的订单', '/profile/order/list', <ShoppingFilled />,null,null),
        getMenuItem('推送通知', '/profile/notification', <BellFilled />,null,null),
        getMenuItem('我的优惠券', '/profile/coupon', <TagsFilled />,null,null),
    ];

    const onClickSubMenu = (e) => {
        // change height of container
        changeHeight();

        // navigate to page
        navigate(e.key);
    };


    function changeHeight(){
        // set delay to ensure menu height is correct
        setTimeout(function(){
            // get height of menu
            const menuHeight = document.getElementById("profile-menu-content").offsetHeight;

            // set container height
            document.getElementById("profile-page-item").style.height = menuHeight + 50 +  "px";
        }, 250);
    }


    useEffect(() => {
        changeHeight();
    },[]);


    // ---------------------- Menu Config ----------------------

    return (
        <Row className={"safe-area full-height"} style={{marginBottom: "60px"}}>
            <Row id="profile-page">
                {/* 1/4 search and 3/4 product*/}
                <Col span={5} id={"profile-page-item"} style={{height:"342px"}}>
                    <div id={"profile-menu-content"}>
                        <Row>
                            <Col span={1}></Col>
                            <Col span={5}>
                                {/*<img src={varBucketURL + "/assets/icon-olly-placeholder.svg"} alt="profile" ></img>*/}
                                <img style={{
                                    borderRadius: "200px",
                                    boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.1)",
                                    objectFit: "cover",
                                }} src={getUserAvatar(varBucketURL, props.user.headUrl)} alt="profile" width={"50px"} height={"50px"}></img>

                            </Col>
                            <Col span={3}></Col>
                            <Col span={15} style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <p style={{
                                    marginTop: "2px",
                                    marginBottom: "0px",
                                    fontSize : "15px",
                                    lineHeight: "21px",
                                    letterSpacing: ".6px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "600",
                                    color: "#494949"
                                }}>
                                    享买用户 <br/>
                                    {getUserName(props.user.name, props.user.mobile)}
                                </p>
                            </Col>

                        </Row>
                        <Divider/>
                        <Menu
                            id={"profile-menu"}
                            onClick={onClickSubMenu}
                            onOpenChange={changeHeight}
                            style={{
                                width: 256,
                            }}
                            mode="inline"
                            items={items}
                            defaultSelectedKeys={[window.location.pathname]}
                            defaultOpenKeys={["/profile/account/profile"]}
                        />
                    </div>

                </Col>

                <Col span={18} id={"profile-page-content"}>
                    <Outlet />
                </Col>

            </Row>
        </Row>);
}


export default Profile;