import React, {useEffect, useState} from "react";
import {Button, Result} from "antd";
import {orderPayNotifyCard} from "../../data/Order-Service-Repo";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";


/**
 *
 * Doc comment
 * 支付完成页面，完成不代表成功，所以需要加以判断
 *
 * Stripe client 回调会传
 *  orderId
 *  payment_intent
 *  payment_intent_client_secret
 *  redirect_status
 */



const PaymentResult = (props) => {
    const navigate = useNavigate();

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // ------------------ 用来 rerender sub header ------------------
    useEffect(() => {
        // ----- 初始 -----
        props.setForceRender(props.forceRender+1);

        // ----- 离开页面 -----
        const handleBeforeUnload = () => {
            // 值不可以跟上面一开始的一样，不然系统识别不到 state change 请求
            props.setForceRender(props.forceRender-1);
        };

        // handles when page is unloaded
        window.addEventListener("beforeunload", handleBeforeUnload);

        // cleanup function handles when component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            handleBeforeUnload();
        };
    }, [])


    // 加载
    const [isLoading, setIsLoading] = useState(true);

    // 结果
    const [isSucceeded, setIsSucceeded] = useState(null);

    useEffect(() => {
        async function load() {
            // 先判断支付方式
            // 只有 stripe 回传 payment intent，所以如果有收到 payment intent 代表是 stripe 支付
            if (params.payment_intent !== undefined) {


                if (params.redirect_status === "succeeded") {
                    // 支付成功，回调给后端，提示用户成功
                    var result = await orderPayNotifyCard(1, params.orderId);

                    // 确认回调成功，显示给用户
                    if(result.code === 200){
                        setIsSucceeded(true)
                    } else {
                        setIsSucceeded(false);
                    }

                } else {
                    // 支付失败，显示给用户错误信息
                    setIsSucceeded(false);

                }

            }

        }
        load();

    },[])



    return(
        <>
            {
                isSucceeded === null
                    ?
                    <Loading/>
                    :
                    <div className={"safe-area full-height"} id={"payment-result-page"}>
                        <Result
                            className={"item-container"}
                            status={
                                isSucceeded === false
                                    ?
                                    "error"
                                    :
                                    "success"
                            }
                            title={
                                isSucceeded === false
                                    ?
                                    "订单支付失败"
                                    :
                                    "订单支付成功"
                            }
                            subTitle={
                                isSucceeded === false
                                    ?
                                    "订单支付失败，请前往订单详情重新尝试支付！"
                                    :
                                    "订单支付成功，商铺很快将会为您出货，您可以前往订单详情查看订单状态。"
                            }
                            extra={[
                                <Button type="primary" key="order" onClick={()=>{navigate("/profile/order/order-detail?id=" + params.orderId,{replace: true})}}>前往订单详情</Button>,
                                <Button key="home" onClick={()=>{navigate("/",{replace: true})}}>返回首页</Button>,
                            ]}
                        />
                    </div>
            }
        </>
    );

}


export default PaymentResult;