import React, {useEffect, useState} from "react";

import {bucketURL} from "../data/env";


const NoData = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "500px"
        }}>
            <img src={varBucketURL + "/assets/empty-v2.svg"} width={"270px"}></img>
            <br/>
            <p>{props.message}</p>
        </div>

    );
}


export default NoData;