import React, {useEffect, useState} from "react";
import {AutoComplete, Input, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {findSellStore, getAutoCompletedKeyword} from "../data/Product-Service-Repo";
import {useNavigate} from "react-router-dom";
import {Option} from "antd/es/mentions";



const Search_bar = (props) => {
    const navigate = useNavigate();

    // search auto-completed
    const [autoCompletedOption, setAutoCompletedOption] = useState([]);
    const [open, setOpen] = useState(true);

    // search field value
    const [searchInputValue, setSearchInputValue] = useState("");

    // search field prefix
    const [searchInputPrefixValue, setSearchInputPrefixValue] = useState("");

    const getSearchAutoCompleteKeyword = async (key) => {
        if (props.token !== null) {
            const data = await getAutoCompletedKeyword(key);
            let plainData = [];
            plainData = data.data;

            let result = [];

            plainData.forEach(function (item, index) {
                result.push({
                    value: item.associativeWord,
                })
            });

            return result;
        }
    }

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    useEffect(() => {
        setSearchInputValue(params.key);
    }, [params.key]);

    useEffect(() => {
        setSearchInputPrefixValue(params.prefix);
    }, [params.prefix]);


    // 保存搜索选项
    const [searchOption, setSearchOption] = useState("store");

    const onSearch = (data) => {
        // 提前保存 pathname 和 sellerStoreid，因为下一行代码回被跳转到 proxy 页面，会遗失这些信息
        var pathname = window.location.pathname;
        var sellerStoreid = "";


        if(params.id !== null && searchOption === "store"){
            sellerStoreid = params.id;

        } else if(params.sellerStoreid !== null){
            sellerStoreid = params.sellerStoreid;
        }


        /* The reason I am not navigate to "search" directly is because search bar and search result is
           belong to different component. Which means once entering search page the page cannot be re-rendering
           by search bar (on search submit). Content API might be the best solution of it.
           However, my rough solution is to  redirect router into a fake page and direct it back to force system re-render.

           Notes: I had test this method. It is the less harmful one. It won't crete extra api calls like "window.href" does.
        */
        navigate("/proxy");



        setTimeout(async () => {
            // 判断一下当前页面是否在商铺，如果要判断是否「搜索选项」为「商铺中搜索」，如果是要携带 storeId
            if ((pathname === "/store" && searchOption === "store") || sellerStoreid !== "") {
                // 查一下我们拿到的商铺 id
                var result = await findSellStore(sellerStoreid);

                // 过滤掉不成功/停权/删除的商铺
                if (result.code === 200 && result.data.isDel !== 1 && result.data.status !== 2) {

                    // 制作商铺名称
                    var storeName = result.data.storeName;
                    if(result.data.storeName.length > 12){
                        storeName = result.data.storeName.substring(0,12) + "...";
                    }

                    navigate("/search?key=" + data + "&sellerStoreid=" + sellerStoreid + "&prefix=在此商铺搜索：" + storeName, { replace: true });

                } else {
                    navigate("/search?key=" + data, { replace: true });

                }

            } else {
                navigate("/search?key=" + data, { replace: true });

            }

        }, "100");

        changeAutoCompletedDialogToClose();
    };


    const changeAutoCompletedDialogToClose = () => {
        setOpen(false);
    }

    const changeAutoCompletedDialogToOpen = (data) => {
        setOpen(true);
        setSearchInputValue(data)
    }


    return (
        <>
            <AutoComplete
                options={autoCompletedOption}
                open={open}
                onChange={changeAutoCompletedDialogToOpen}
                onBlur={changeAutoCompletedDialogToClose}
                onSelect={onSearch}
                onSearch={async (text) => setAutoCompletedOption(await getSearchAutoCompleteKeyword(text))}
                value={searchInputValue}
            >
                <Input.Search
                    addonBefore={searchInputPrefixValue}
                    size="large"
                    placeholder="开始搜索享买好物...."
                    enterButton={<SearchOutlined/>}
                    onSearch={onSearch}
                >
                </Input.Search>
            </AutoComplete>
            {
                // 只有网址是 /store 时显示此搜索选项
                window.location.pathname === "/store"
                    ?
                        <div id={"store-search-option"}>
                            <Select
                                defaultValue={searchOption}
                                onChange={(e)=>{
                                    setSearchOption(e)
                                }}
                            >
                                <Option value="store">在此商铺中搜索</Option>
                                <Option value="site">在享买全站搜索</Option>
                            </Select>
                        </div>
                    :
                        null
            }
        </>
    );
}


export default Search_bar;