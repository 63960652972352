import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Skeleton, Button, Carousel, Col, Row, Modal, Tooltip} from "antd";

import {bucketURL} from "../data/env.js";

// data processor
import RecommendProduct_loader from "../components/RecommendProduct_loader.js";
import {findAppIndexBanners, findAppStartBanner, getCategory} from "../data/Product-Service-Repo.js";
import {getRecommendStore} from "../data/Customer-Service-Repo.js";
import useScrollToTop from "../hooks/useScrollToTop";
import {doAdsClickPosition, getUserAvatar, getUserName} from "../data/tool";
import {
    ArrowUpOutlined,
    LeftOutlined,
    RightOutlined
} from "@ant-design/icons";
import BackToUp from "@uiw/react-back-to-top";
import ChatPopout from "./chat/ChatPopout";

const Home = (props) => {
    const navigate = useNavigate();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // variable
    // 所有类目
    const [category, setCategory] = useState([]);
    const [categoryLength, setCategoryLength] = useState(0);

    // 推荐商铺
    const [recommendStore, setRecommendStore] = useState([]); // 推荐商铺
    const [recommendStoreLength, setRecommendStoreLength] = useState(0); // 推荐商铺总数据长度
    const [recommendStoreCurrentPage, setRecommendStoreCurrentPage] = useState(1); // 当前页数
    const [moreRecommendStoreText, setMoreRecommendStoreText] = useState(0); // 推荐商铺更多按钮


    // 开屏广告
    const [isStartPageAdsOpen, setStartPageAdsOpen] = useState(false);
    const [startPageAds, setStartPageAds] = useState([]);

    // Banner
    const [banner, setBanner] = useState([]);


    // do once we get token
    useEffect(() => {
        if (props.token !== null) {
            async function loadCategory() {
                const data = await getCategory();
                category.push(data.data);
                setCategoryLength(category[0].length);
            }

            async function loadStartPagesAds(){
                // 判断一下该不该显示
                let lastShowStartPageAds = localStorage.getItem("lastShowStartPageAds")

                // lastShowStartPageAds 已经超过 60 分钟
                if(lastShowStartPageAds === null || new Date().getTime() - lastShowStartPageAds > 60 * 60 * 1000) {

                    const data = await findAppStartBanner();

                    if(data.code === 200 && data.data.length > 0){
                        setStartPageAds(data.data[0])
                        setStartPageAdsOpen(true)
                    }
                }
            }

            async function loadIndexBanner(){
                const data = await findAppIndexBanners();
                if(data.code === 200){
                    setBanner(data.data);
                }
            }


            loadCategory();
            loadRecommendStore(1);
            loadStartPagesAds();
            loadIndexBanner();
        }
    }, [props.token]);

    async function loadRecommendStore(index) {
        const data = await getRecommendStore(index);
        let plainData = [];
        plainData = data.data;

        // 归零
        setRecommendStore(plainData)

        // 设置数据总长度
        setRecommendStoreLength(data.page.pages);
    }


    // 更多商铺按钮提示
    useEffect(() => {
        if(recommendStoreCurrentPage === 1){
            setMoreRecommendStoreText("更多")
        } else if(recommendStoreCurrentPage === 5){
            setMoreRecommendStoreText("再更多")
        } else if(recommendStoreCurrentPage === 6){
            setMoreRecommendStoreText("再再更多")
        } else if(recommendStoreCurrentPage > 7){
            setMoreRecommendStoreText("再再再更多")
        }
    }, [recommendStoreCurrentPage]);


    // props.type 传的是左按钮 "left" 或 右按钮 "right"，请传 string 类型
    function MoreRecommendStoreButton(props){
        return(
            <Tooltip
                placement="top"
                title={moreRecommendStoreText}
            >
                <div
                    className={`next-button ${recommendStore.length === 0 ? 'disabled' : ''}`}
                    onClick={()=>{
                        setRecommendStore([])

                        // 检查是否禁用
                        if(props.disabled){
                            return;
                        }

                        // 计算页数
                        var num = 0;
                        if(props.type === "left" && recommendStoreCurrentPage === 1){
                            num = recommendStoreLength;
                            setRecommendStoreCurrentPage(num);

                        } else if(props.type === "right" && recommendStoreCurrentPage === recommendStoreLength){
                            num = 1;
                            setRecommendStoreCurrentPage(num);

                        } else if(props.type === "left"){
                            num =recommendStoreCurrentPage - 1
                            setRecommendStoreCurrentPage(num);

                        } else if (props.type === "right"){
                            num =recommendStoreCurrentPage + 1
                            setRecommendStoreCurrentPage(num);
                        }

                        // 执行的时候 delay 1.5s，比较顺畅
                        setTimeout(() => {
                            loadRecommendStore(num)
                        }, 1000);
                    }}
                >
                    {
                        props.type === "left"
                            ?
                            <LeftOutlined/>
                            :
                            <RightOutlined/>
                    }

                </div>
            </Tooltip>
        );
    }




    //-- get category --
    function CategoryContentBuilder() {

        // 不知道为什么有这个，但他会导致所有点击都被往上滑动
        // useScrollToTop();

        // fetch all the category from state storage
        let builderMainCategories = category[0];

        // declare a last sub item indicator to identify when to display divider
        let lastSubItemIndicator = 0;

        return (
            <div style={{overflowY: "scroll", height: "324px"}}>
                {builderMainCategories.map((builderMainCategory, index) => {
                    return (
                        <div>
                            {
                                /* identify the amount of sub category on a main category */
                                builderMainCategory.categoryList.length > 0
                                &&
                                <div>
                                    {/* print the name of main category */}
                                    <Link to={"/search?&firstStairId=" + builderMainCategory.id + "&prefix=类目搜索：" + builderMainCategory.name}><p
                                        key={builderMainCategory.id}
                                        id={builderMainCategory.id}>{builderMainCategory.name}</p></Link>
                                    <span>
                                    {builderMainCategory.categoryList.map((builderSubCategory, index) => {
                                        return (
                                            // print the name of sub category
                                            <Link to={"/search?firstStairId=" + builderSubCategory.id + "&prefix=类目搜索：" + builderSubCategory.name}>
                                                <span key={builderSubCategory.id} id={builderSubCategory.id}
                                                      className={"second-stair-category"}>
                                                    {builderSubCategory.name}

                                                    {
                                                        /* do not print divider when it is last one, and reset counter*/
                                                        builderMainCategory.categoryList.length > ++lastSubItemIndicator
                                                            ?
                                                            <span className={"divider"}>|</span>
                                                            :
                                                            lastSubItemIndicator = ""
                                                    }
                                                </span>
                                            </Link>
                                        );
                                    })}
                                </span>
                                </div>

                            }
                        </div>
                    );
                })}
            </div>
        );
    }




    return (
        <Row className={"safe-area"}>

        {/* ------------- BAR -------------- */}
        <Row id="bar" className={"inner-body"}>
            {
                // 开屏广告
                // 用 localstorage 纪录是否展示过，每 60 分钟展是一次
                // 因此 localstorage 会记录开屏的最后展示时间
                // 登录则重置此记录
            }
            {
                startPageAds !== [] &&
                <Modal
                    open={isStartPageAdsOpen}
                    wrapClassName={"start-page-ads-modal"}
                    footer={[]}
                    onCancel={()=>{
                        setStartPageAdsOpen(false)
                        localStorage.setItem("lastShowStartPageAds", new Date().getTime())
                    }}
                >
                    <img
                        src={startPageAds.imgUrl} width={"350px"}
                        onClick={()=>{
                            doAdsClickPosition("", startPageAds.hitType, startPageAds.hitPosition)
                        }}
                    ></img>
                </Modal>
            }

            <Col span={6} id={"category"}>
                <h3>精选分类</h3>
                {categoryLength !== 0 && <CategoryContentBuilder/>}
                <Link to={"/category"}>
                    <Button size={"large"}
                            style={{
                                textAlign: "center",
                                marginTop: "3px",
                                padding: "5px 10px 1px 10px",
                                width: "100%",
                                borderWidth: "0px",
                                borderRadius: "0px",
                                boxShadow: "none"
                            }}
                            className={"link"}>点击查看所有分类</Button>
                </Link>
            </Col>
            <Col span={17} id={"banner"}>
                <Carousel autoplay>
                    {
                        banner.map((builderBanner, index) => {
                            return(
                                <div
                                    onClick={()=>{
                                        doAdsClickPosition(navigate, builderBanner.hitType, builderBanner.hitPosition)
                                    }}
                                >
                                    <img
                                        src={builderBanner.imgUrl}
                                        width="100%"
                                        height="800px">
                                    </img>
                                    <Skeleton.Image active={true} className={"skeleton"}/>
                                </div>
                            );
                        })
                    }

                    {/*<div>*/}
                    {/*    <img*/}
                    {/*        src={varBucketURL + "/test/banner2.jpg"}*/}
                    {/*        width="100%" height="400px"></img>*/}
                    {/*    <Skeleton.Image active={true} className={"skeleton"}/>*/}
                    {/*</div>*/}
                </Carousel>
            </Col>
        </Row>
        {/* ------------- BAR -------------- */}


        {/* ------------- QUICK BTN -------------- */}
        <Row id="quick-link" className={"inner-body"}>
            <ChatPopout />
            <Col className={"item"} span={16} id={"tool-btn"}>
                <a onClick={()=>{navigate("/cart")}} className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-cart-orange.svg"} alt="cart"></img>
                    <p>购物车</p>
                </a>

                <a onClick={()=>{navigate("/officialDiscount")}} className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-coupon.svg"} alt="coupon"></img>
                    <p>享买优惠券</p>
                </a>

                <a onClick={()=>{navigate("/profile/order/list")}} className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-order-orange.svg"} alt="order" style={{width: "25px"}}></img>
                    <p>我的订单</p>
                </a>

                <a href={"/search?index=1&key=&productTags=%E4%BA%AB%E4%B9%B0%E4%BC%98%E9%80%89&saveStatus=null"} className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-star.svg"} alt="star"></img>
                    <p>享买优选</p>
                </a>

                <a href="https://help.shopmy.com.au/kb/%e7%ab%99%e5%86%85%e5%b9%bf%e5%91%8a%e4%b8%8e%e5%90%88%e4%bd%9c%e6%b4%bd%e8%b0%88/" target="_blank" className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-agree.svg"} alt="agree"></img>
                    <p>广告合作</p>
                </a>

                <Link to={"/seller"} className={"tool-btn-item"}>
                    <img src={varBucketURL + "/assets/icon-store.svg"} alt="store"></img>
                    <p>商铺入驻</p>
                </Link>
            </Col>
            <div className={"item"} span={7} id={"account-btn"} style={{width: "339px"}}>
                <Row>
                    <Col span={1}></Col>
                    <Col span={5}>
                        {/*<img src={varBucketURL + "/assets/icon-olly-placeholder.svg"} alt="profile" ></img>*/}
                        <img
                            style={{
                                borderRadius: "200px",
                                boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.1)",
                                objectFit: "cover"
                            }}
                            src={getUserAvatar(varBucketURL, props.user.headUrl)}
                            alt="profile"
                            width={"80px"}
                            height={"80px"}
                        >

                        </img>

                    </Col>
                    <Col span={3}></Col>
                    <Col span={15}>
                        <p style={{marginTop: "8px", marginBottom: "10px", fontSize : "15px", fontWeight: "500"}}>您好，{getUserName(props.user.name, props.user.mobile)}</p>
                        <div >
                            <Button type="primary" style={{width: "201px"}} onClick={()=>{navigate("/profile/account/profile")}}>我的享买</Button>
                        </div >

                        {/*<div>*/}
                        {/*    <Button type="primary">登录/注册享买帐号</Button>*/}
                        {/*</div>*/}

                    </Col>

                </Row>
            </div>
        </Row>
        {/* ------------- QUICK BTN -------------- */}

        {/* ------------- SUB BANNER -------------- */}
        {/* 先注解掉因为没有办法单独上架 */}
        {/*<Row id="sub-banner" className={"inner-body"}>*/}
        {/*    <Col span={11} className={"item"} id={"sub-banner-left"}>*/}

        {/*    </Col>*/}
        {/*    <Col span={11} className={"item"} id={"sub-banner-right"}>*/}

        {/*    </Col>*/}
        {/*</Row>*/}
        {/* ------------- SUB BANNER -------------- */}


        {/* ------------- RECOMMEND STORE -------------- */}
        <Row id="recommend-store" className={"inner-body"}>
            <div className={"title"}>
                <h3>精选商铺</h3>
                <span style={{marginLeft: "988px"}} className={"color-subtitle-grey"}>精选页数：{recommendStoreCurrentPage+ "/" + recommendStoreLength}</span>
            </div>
            <Col span={24}>
                <>
                    <MoreRecommendStoreButton type={"left"}/>
                    {
                        recommendStore.length === 0
                            ?
                                <div className={"store-container"}>
                                    {
                                        // loop 6 times
                                        Array.from(Array(6).keys()).map((index) => {
                                            return (
                                                <div className={"rec-store-item loader"}>
                                                    <Skeleton.Image
                                                        active={true}
                                                        className={"skeleton"}
                                                    />
                                                    <br/>
                                                    <Skeleton.Input
                                                        active={true}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                            :
                                <div className={"store-container"}>
                                    {recommendStore.map((builderStore, index) => {
                                        return (
                                            <div className={"rec-store-item item"}>
                                                <Link to={"/store?id=" + builderStore.id}>
                                                    {
                                                        builderStore.storeIco &&
                                                        <img src={builderStore.storeIco}></img>
                                                    }

                                                </Link>
                                                <Skeleton.Image
                                                    active={!!builderStore.storeIco}
                                                    className={"skeleton"}
                                                />
                                                <p
                                                    style={{
                                                        width: "152px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        textAlign: "center"
                                                    }}
                                                >{builderStore.storeName}</p>
                                            </div>
                                        );
                                    })}
                                </div>

                    }

                    <MoreRecommendStoreButton type={"right"}/>
                </>
            </Col>
        </Row>

        {/* ------------- RECOMMEND STORE -------------- */}


        {/* ------------- RECOMMEND PRODUCT -------------- */}
        <RecommendProduct_loader token={props.token} />
        {/* ------------- RECOMMEND PRODUCT -------------- */}

            <BackToUp className={"back-to-top"}>
                <Button type="primary" size={"large"} style={{width: "150px"}}>
                    <ArrowUpOutlined/>&nbsp;回到最上方
                </Button>

            </BackToUp>

    </Row>);
}


export default Home;