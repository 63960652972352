import React, {useEffect, useState} from "react";
import {Button, Row} from "antd";
import {bucketURL} from "../../data/env";

import ConfettiExplosion from 'react-confetti-explosion';


const StoreApproved = (props) => {

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // 用户没有登录过商铺系统，显示庆祝彩带
    const [isExploding, setIsExploding] = React.useState(false);
    useEffect(() => {
        if(props.store!= "" && props.store.data.lastloginDate === null){
            setIsExploding(true);
        }
    },[props.store])


    return (
        <Row className={"safe-area open-store-page open-store-page-approved"}>
            {isExploding && <ConfettiExplosion />}
            <div className={"mobile-container"}>
                <img src={varBucketURL + "/assets/store-approved.svg"} height="250px"></img>
                <br/>
                <span style={{textAlign: "center", lineHeight: 2}}>
                    <span>开店成功！点击以下按钮前往网页版商铺中心。</span>
                    <br/>
                    <span>快来开始您的第一笔订单吧～</span>
                </span>
                <br/>

                <Button type={"primary"} onClick={()=>window.open('https://seller.shopmy.com.au', '_blank')}>登录商铺后台</Button>
            </div>

        </Row>
    );
}


export default StoreApproved;