import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    List,
    message,
    Modal,
    notification,
    Rate,
    Row,
    Steps,
    Upload
} from "antd";
import {
    LeftOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {uploadFilesSync} from "../../../data/User-Service-Repo";
import {findOmsOrder} from "../../../data/Order-Service-Repo";
import Loading from "../../../components/Loading";
import {unixDateToHumanDate} from "../../../data/tool";
import NotFound from "../../../components/NotFound";
import {useNavigate} from "react-router-dom";
import {doOmsProductComment, findOmsProductCommentOrderList} from "../../../data/Product-Service-Repo";


/*

 *  ||==========================================================================||
 *  ||                                                                          ||
 *  ||     请注意，此页面大量运用 index 来定位评价或图片内容，因此顺序「非常重要」!!!       ||
 *  ||                                                                          ||
 *  ||==========================================================================||
 *
 */



// check selected file type
const checkFileType = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg';
    const isHEIC = file.type === 'image/heic';

    if (!isPNG && !isHEIC && !isJPEG) {
        message.error('您只可以上传图片（PNG, JPEG, JPG, HEIC）');
        return Upload.LIST_IGNORE;
    }   else {
        return true;
    }
}

// upload btn
const uploadButton = (
    <div>
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);



const Comment = (props) => {

    const navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [form] = Form.useForm();


    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // 是否展示订单，用于 notfound
    const [isOrderVisible, setIsOrderVisible] = useState(true);
    // 是否在加载
    const [isLoading, setIsLoading] = useState(true);

    // 订单 storage
    const [order, setOrder] = useState([]);

    // 备注 storage
    const [comment, setComment] = useState([]);
    // [{
    //     productName: "",
    //     productImgurl: "",
    //     productSpuId: "",
    //     comment: {
    //         content: "",
    //         files: [], // for backend
    //         star: 0
    // }// }]


    useEffect(() => {
        // 先取得订单信息
        async function load() {
            if (params.id == null) {
                setIsLoading(false); // 取得信息后关闭 loading
                setIsOrderVisible(false);
            } else {
                await fetchData();
            }
        }
        load();
    },[]);


    async function fetchData() {
        var result = await findOmsOrder([], params.id, "");

        if (result.code === 200) {
            setIsLoading(false); // 取得信息后关闭 loading

            // 保存订单信息
            setOrder(result.data);

            // 确认一下订单是否可以评价
            if (result.data.orderCommentUpdateCount === 0 || result.data.orderCommentExpectedDate <= Date.now() || result.data.orderCommentUpdateCount === null) {
                setIsOrderVisible(false);
            }


            // 判断这个用户是新评价，还是修改评价
            // 如果是在待评价，代表是第一次评价
            // 如果是在已完成，代表是修改评价
            if(result.data.orderStatus === 50){

                var commentList = [];

                // 查询哪一些商品可以评价
                for (const element of result.data.products) {
                    // 将商品添加到评价列表中（因为 productVo 是区分规格的，但是我们评论不区分规格，所以要排除掉重复的规格）
                    if(isProductExistOnCommentList(commentList, element.productSpuId) === false){
                        // 添加商品
                        commentList.push({
                            productName: element.productName,
                            productImgurl: element.productImgurl,
                            productSpuId: element.productSpuId,
                            comment: {
                                content: "",
                                files: [], // for backend
                                star: 0,
                                productSpuId: element.productSpuId
                            }
                        })

                        // 添加商品的图片存储
                        uploadImage.push({
                            file: []
                        })

                        // 添加商品的图片存储
                        isUploadLoading.push({
                            display: "none"
                        })

                    }
                }
                
                // 保存评价列表
                setComment(commentList);


            } else if (result.data.orderStatus === 80) {

                // 先取得先前的订单评价列表
                var orderCommentList = await findOmsProductCommentOrderList(result.data.id);
                setIsLoading(false); // 取得信息后关闭 loading

                if(orderCommentList.code !== 200){
                    setIsOrderVisible(false); // 不是 200 代表有错误
                    setIsLoading(false); // 取得信息后关闭 loading

                }

                // 开始写入评价内容到前端
                orderCommentList.data.map((element, index) => {

                    // 添加商品
                    comment.push({
                        productName: element.productName,
                        productImgurl: element.productImgurl,
                        productSpuId: element.productSpuId,
                        comment: {
                            content: element.content,
                            files: element.files === null ? [] : element.files, // for backend, 因为后端的 files 如果是空的会给 null，会导致前端错误。因此如果后端没有 files 把他至换成空合集
                            star: element.star,
                            productSpuId: element.productSpuId
                        }
                    })

                    // form 要用 setFieldsValue 的方式处理
                    form.setFieldsValue({
                        [`comment-content-${index}`]: element.content,
                        [`comment-star-${index}`]: element.star,
                    })



                    // 添加图片，因为图片有很多张，所以要再做一个 loop
                    var imageList = [];

                    // 如果是空的 files，就不要做 loop 了
                    if(element.files){
                        element.files.map((image, index) => {
                            imageList.push({
                                uid: index,
                                name: image,
                                status: 'done',
                                url: image,
                            })
                        })
                    }

                    uploadImage.push({
                        file: imageList
                    })



                    // 添加商品的图片存储
                    isUploadLoading.push({
                        display: "none"
                    })



                    // 强制 rerender
                    setRerenderImage(rerenderImage + 1)
                })

            }

        } else {
            setIsOrderVisible(false);
        }
    }


    // 寻找商品是否已经添加到评价列表中
    // 如果已经添加，返回 true
    // 如果没有添加，返回 false
    function isProductExistOnCommentList(commentList, productSpuId){
        for (const element of commentList) {
            if(element.productSpuId === productSpuId){
                return true;
            }
        }
        return false;
    }






    // --- preview box ---
    const [isPreviewBoxOpen, setIsPreviewBoxOpen] = useState(false);
    const [previewImageURL, setPreviewImageURL] = useState("");

    function PreviewBox(){
        return (
            <Modal title="图片预览" width={600} open={isPreviewBoxOpen} onCancel={handlePreviewBoxCancel} footer={null}>
                <img width={"100%"} src={previewImageURL}></img>
            </Modal>
        );
    }
    const handlePreviewBoxCancel = () => {
        setIsPreviewBoxOpen(false);
    };

    const handlePreviewBoxOpen = (data) => {
        setIsPreviewBoxOpen(true);
        setPreviewImageURL(data.url);
    };



    // -------- 图片 --------
    const [uploadImage, setUploadImage] = useState([]);
    // const [uploadImage, setUploadImage] = useState([
    //     {
    //         file: [
    //             {
    //                 uid: '-1',
    //                 name: 'image.png',
    //                 status: 'done',
    //                 url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //             },
    //             {
    //                 uid: '-2',
    //                 name: 'image.png',
    //                 status: 'done',
    //                 url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //             }
    //         ]
    //
    //     },
    //     {
    //         file: [
    //             {
    //                 uid: '-1',
    //                 name: 'image.png',
    //                 status: 'done',
    //                 url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //             },
    //             {
    //                 uid: '-2',
    //                 name: 'image.png',
    //                 status: 'done',
    //                 url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //             }
    //         ]
    //
    //     }
    // ]);

    // used to force rerender
    const [rerenderImage, setRerenderImage] = useState(0);
    const [rerenderLoader, setRerenderLoader] = useState(0);

    // loading indicator
    const [isUploadLoading, setIsUploadLoading] = useState([]);

    // [
    //     {
    //         display: "flex"
    //     },
    //     {
    //         display: "flex"
    //     }
    // ]

    const handleImageRemove = async (data, index) => {
        var currentImgArray = uploadImage
        var array = [{
            file: []
        }]
        // don't know why splice comes up with wired result, so I am using the other way to remove element from array
        currentImgArray[index].file.forEach(element => {
            if(element.uid != data.uid){
                array[0].file.push(element)
            }
        });

        var newImageArray = []
        // 重组原使 array
        currentImgArray.map((element, i) => {
            if(i !== index){
                newImageArray.push(currentImgArray[i])
            } else {
                newImageArray.push(array[0])
            }
        })

        setUploadImage(newImageArray)


        // 把上传的图片塞到对应的商品中
        // 这边直接重置一个 array 塞进去，不要再去删除商品的 array
        var newBackendArray = []

        // 提取最终的图片列表
        newImageArray[index].file.map((element, i) => {
            newBackendArray.push(element.url)
        })

        // 最后放到商品的 comment 中
        comment[index].comment.files = newBackendArray
    }

    const handleImageChange = async (data, index) => {
        // 开始加载
        isUploadLoading[index].display = "flex"
        setRerenderLoader(rerenderLoader + 1)

        var result = await uploadFilesSync(data);;

        // 获取当前的图片列表
        var currentImgArray = uploadImage

        // 将原来的图片添加到新的图片列表中
        currentImgArray[index].file.push(result[0])
        setUploadImage(currentImgArray)


        // 加载完成
        setRerenderImage(rerenderImage + 1)
        isUploadLoading[index].display = "none"
        setRerenderLoader(rerenderLoader + 1)

        // 把上传的图片塞到对应的商品中
        comment[index].comment.files.push(result[0].url)

    }


    // 处理输入数据
    // 内容
    function handleContentChange(data, index){
        comment[index].comment.content = data
    }

    // 星等
    function handleStarChange(data, index){
        comment[index].comment.star = data
    }

    // 送出
    // 按钮加载
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
    async function onFinish() {
        setSubmitButtonLoading(true); // 开始加载

        var data = [];

        // 先处理数据
        comment.map((element, index) => {
            data.push(element.comment)
        })

        var result = await doOmsProductComment(data, order.id)


        if (result.code === 200) {
            message.success("评论成功");
            // 导向到订单详情页面
            window.location.href = "/profile/order/order-detail?id=" + order.id + "&msg=评论发布成功"

        } else {
            notificationApi.error({
                message: `错误信息`,
                description: result.message,
                placement: "bottomRight",
                duration: 8,
            });

        }

        setSubmitButtonLoading(false); // 结束加载
    }


    // 因为订单需要一些时间加载，因此在没加载完之前，显示 loading
    if(isLoading){
        return (
            <>
                { messageContextHolder }
                { notificationContextHolder }
                <Loading/>
            </>
        );
    } else {
        return (
            <Form
                id={"order-comment-page"}
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <PreviewBox/>
                { messageContextHolder }
                { notificationContextHolder }
                {
                    isOrderVisible === true
                        ?
                        <>
                            <div
                                className={"container"}
                                style={{
                                    display: "flex",
                                    padding: "20px 30px"
                                }}
                            >

                                <Button
                                    type="dashed"
                                    icon={<LeftOutlined/>}
                                    onClick={() => window.history.go(-1)}
                                >
                                    返回上一页
                                </Button>


                                <Button
                                    type={"primary"}
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "0px",
                                    }}
                                    htmlType="submit"
                                    loading={submitButtonLoading}
                                >
                                    {
                                        order.orderStatus === 50
                                            ? "提交评价"
                                            : "修改评价"
                                    }
                                </Button>
                            </div>

                            <div
                                className={"container"}
                            >
                                <Alert
                                    message={"请在 " + unixDateToHumanDate(order.orderCommentExpectedDate) +" 前评价商品，在这些时间内您可以修改评价内容 " + order.orderCommentUpdateCount + " 次"}
                                    type="warning"
                                    showIcon
                                />
                                <br/>
                                <br/>
                                {
                                    comment.map((item, index) => {
                                        return(
                                            <>
                                                <Row>
                                                    <Col span={2}>
                                                        <img
                                                            className={"comment-img"}
                                                            src={item.productImgurl}
                                                            width={"80px"}
                                                            height={"80px"}
                                                            style={{boxShadow: "1px 1px 0 rgb(5 145 255 / 10%)"}}
                                                        />
                                                    </Col>
                                                    <Col span={1}>

                                                    </Col>
                                                    <Col span={21} className={"comment-content"}>
                                                        <span className={"item title"} style={{marginBottom: "7px"}}>
                                                            {item.productName}
                                                        </span>

                                                        <Form.Item
                                                            className={"item"}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: '请选择星等..',
                                                                },
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        // start detect once two input field are fill
                                                                        if (value > 0  && value <6) {
                                                                            return Promise.resolve();
                                                                        } else {
                                                                            return Promise.reject("请选择星等.."); // The validator should always return a promise on both success and error
                                                                        }
                                                                    }
                                                                })
                                                            ]}
                                                            value={comment[index].comment.star}
                                                            name={"comment-star-" + index}
                                                        >
                                                            <Rate onChange={(data) => handleStarChange(data, index)}/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            className={"item"}
                                                            onChange={(data) => handleContentChange(data.target.value, index)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: '请填写评价内容..',
                                                                },
                                                            ]}
                                                            value={comment[index].comment.content}
                                                            name={"comment-content-" + index}
                                                        >
                                                            <Input.TextArea
                                                                rows={3}
                                                                placeholder={"请输入评论内容..."}
                                                            >
                                                            </Input.TextArea>
                                                        </Form.Item>

                                                        <Form.Item
                                                            className={"item"}
                                                        >
                                                            <Upload
                                                                rerender={rerenderImage}
                                                                action=""
                                                                listType="picture-card"
                                                                fileList={uploadImage[index].file}
                                                                onPreview={handlePreviewBoxOpen}
                                                                customRequest={async (data ) => {
                                                                    await handleImageChange(data, index)
                                                                }}
                                                                onRemove={async (data) => {
                                                                    await handleImageRemove(data, index)
                                                                }}
                                                                beforeUpload={checkFileType}
                                                                style={{width: "200px"}}
                                                            >
                                                                {
                                                                    uploadImage[index].file.length >= 5 ? null : uploadButton
                                                                }
                                                            </Upload>
                                                            <div
                                                                style={{display: isUploadLoading[index].display}}
                                                                className={"upload-loading"}
                                                                rerender={rerenderLoader}
                                                            >
                                                                <LoadingOutlined />
                                                                <span>上传中请稍后</span>
                                                            </div>
                                                        </Form.Item>







                                                    </Col>
                                                </Row>
                                                {
                                                    comment[index].comment.files.length === index-1 &&
                                                    <Divider/>
                                                }

                                                <br/>
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </>

                        :
                        <>
                            <NotFound message={"找不到订单或此订单无法评价"}/>
                        </>
                }
            </Form>
        );
    }

}


export default Comment;