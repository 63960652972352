import React, {useEffect, useState} from "react";
import {Button, Col, Space, Form, Input, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {
    checkSmsCode,
    sendRegisterSmsCode,
    register,
    login, getUserInfo
} from "../data/Customer-Service-Repo";
import Countdown from "react-countdown";


const Register = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();


    const [currentStep, setCurrentStep] = useState(1);

    const [mobileNumber, setMobileNumber] = useState("");
    const [code, setCode] = useState("");

    const [resendRepeatCount, setResendRepeatCount] = useState(0);


    useEffect(() => {
        console.log(props.redirect != "");
    },[props.redirect])


    async function getVerificationCode() {

        // get mobile number
        var mobile = form.getFieldValue("mobile");
        await setMobileNumber(mobile);

        // post to api
        var result = await sendRegisterSmsCode(mobile);

        if (result.code === 200) {
            setCurrentStep(2)
        } else {
            alert(result.message)
        }

    }

    async function resendVerificationCode() {
        // post to api
        var result = await sendRegisterSmsCode(mobileNumber);

        if (result.code === 200) {
            var count = resendRepeatCount;
            setResendRepeatCount(count + 1);

        } else {
            alert(result.message)
        }

    }


    async function checkVerificationCode() {
        // get code
        var codeInput = form.getFieldValue("codeInput");

        // post to api
        var result = await checkSmsCode(mobileNumber, codeInput);

        if (result.code === 200) {
            setCurrentStep(3)
            setCode(codeInput)
        } else {
            alert(result.message)
        }
    }


    async function submitRegistration() {
        // get password
        var password1 = form.getFieldValue("password1");
        var password2 = form.getFieldValue("password2");

        // verify password
        if (password1 !== password2) {
            alert("请检查两次输入的密码必须相同")
            return;
        }

        // 检查密码条件
        const regex = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$');
        if(regex.test(password1) !== true){
            alert("请检查密码是否满足条件")
            return;
        }


        // post to api
        // eslint-disable-next-line no-undef
        var result = await register(mobileNumber, password1);

        if (result.code === 200) {
            alert("注册成功，将自动登录")

            // do login
            const result = await login(mobileNumber, password1)
            doLogin(result.data);

        } else {
            alert(result.message)
        }
    }

    async function doLogin(token) {
        window.localStorage.removeItem('Access-Token');
        window.localStorage.setItem('Access-Token', token);

        const user = await getUserInfo(token, 'app');
        window.localStorage.removeItem('User');

        // convert to string in order to store in localstorage
        window.localStorage.setItem('User', JSON.stringify(user.data));

        // 如果在 Account 主部件有判断出转址需求就转到该地址，要不然就转到首页
        window.location.href = props.redirect;


    }


    function PageStep1() {
        return (
            <Form
                name="step1"
                autoComplete="off"
                onFinish={getVerificationCode}
                style={{width: "100%"}}
                form={form}
            >
                <Form.Item
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && !value.match(/^4[0-9]{8}$/)){
                                    return Promise.reject("手机号不正确（4 开头，共 9 码数字）"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                        size="large"
                    >
                        <Input addonBefore="+61" placeholder="请输入手机号" size="large"/>
                    </Space.Compact>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        发送验证码
                    </Button>
                </Form.Item>
                <FeatureBtn/>
            </Form>
        );
    }


    function PageStep2() {
        return (
            <Form
                name="step2"
                onFinish={checkVerificationCode}
                autoComplete="off"
                style={{width: "100%"}}
                form={form}
            >
                <Form.Item
                    name="codeInput"
                    rules={[
                        {
                            required: true,
                            message: '请输入验证码',
                        },
                    ]}
                >
                    <Input placeholder="请输入验证码" size="large"/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        下一步
                    </Button>

                    <Countdown
                        date={Date.now() + 120000}
                        renderer={renderer}
                        key={resendRepeatCount}
                    />
                </Form.Item>
                <FeatureBtn/>
            </Form>
        );
    }

    // Renderer timer callback with condition
    const renderer = ({minutes, seconds, completed}) => {

        var isNotCompleted = true;
        var timer = "";

        if (completed) {
            isNotCompleted = false;

        } else {

            // display the common format 00:00:00 instead of 0:0:0
            if (minutes < 10) {
                minutes = minutes.toString().padStart(2, '0')
            }
            if (seconds < 10) {
                seconds = seconds.toString().padStart(2, '0')
            }

            // Render a countdown
            timer = minutes + ":" + seconds
        }

        return (
            <Button size="large" style={{width: "100%", marginTop: "10px"}} disabled={isNotCompleted}
                    onClick={resendVerificationCode}>

                {
                    timer === ""
                        ? "再次发送验证码"
                        : "再次发送验证码（请等待 " + timer + " 秒）"
                }
            </Button>
        );
    };


    function PageStep3() {
        return (
            <Form
                name="step3"
                onFinish={submitRegistration}
                autoComplete="off"
                style={{width: "100%"}}
                form={form}

            >
                <Form.Item style={{marginBottom: "0px", color: "#868686"}}>
                    <p style={{margin: "0px"}}>请确认密码满足以下条件：</p>
                    <ul style={{paddingLeft: "28px", marginTop: "5px"}}>
                        <li>至少 8 个字，且包含大小写、数字</li>
                    </ul>
                </Form.Item>


                <Form.Item
                    name="password1"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                const regex = new RegExp('^[a-zA-Z0-9!@#$%^&*(){}_+-=]{8,50}$');
                                if(value == ""){
                                    return null;
                                }

                                if (regex.test(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject("请确认您输入的密码符合规则！"); // The validator should always return a promise on both success and error
                                }
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="请输入密码" size="large"/>
                </Form.Item>


                <Form.Item
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: '请再次输入密码',
                        },

                    ]}
                >
                    <Input.Password placeholder="请再次输入密码" size="large"/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        送出注册
                    </Button>
                </Form.Item>

                <FeatureBtn/>

            </Form>
        );
    }


    function FeatureBtn() {
        return (
            <Row id={"feature-btn"} style={{marginTop: "40px"}}>
                <Col span={12}>
                    <Button type="link" onClick={() => navigate('/')}>
                        登录享买帐号
                    </Button>
                </Col>
                <Col span={12}>

                </Col>
            </Row>
        );
    }


    return (
        <>
            <h2 style={{
                fontWeight: "400"
            }}>注册享买帐号</h2><br/>

            {
                currentStep === 1 &&
                <PageStep1/>
            }

            {
                currentStep === 2 &&
                <PageStep2/>
            }

            {
                currentStep === 3 &&
                <PageStep3/>
            }
        </>
    );
}


export default Register;