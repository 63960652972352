import React, {useEffect, useState} from "react";
import {Avatar, List, Row, Skeleton, Tabs} from "antd";
import {getAppPushMsgRecordList} from "../../data/Product-Service-Repo";
import {bucketURL} from "../../data/env";
import {doAdsClickPosition, unixDateToHumanDate} from "../../data/tool";
import {useNavigate} from "react-router-dom";

const Notification = (props) => {

    var navigate = useNavigate();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });
    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // storage
    // notification storage
    const [notification, setNotification] = useState([{
        "id": "",
        "msgType": 3,
        "msgTitle": "",
        "msgContent": "",
        "msgImg": null,
        "hitType": null,
        "hitPosition": null,
        "note": null,
        "status": 1,
        "createDate": 1692710500000
    }])

    // notification page storage
    const [notificationIndex, setNotificationIndex] = useState(1)
    const [notificationMsgType, setNotificationMsgType] = useState(3) // default 3

    // disabled tab selection
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        console.log(isLoading)
    }, [isLoading])

    // get notification
    useEffect(() => {
        async function load() {
            // loading
            setIsLoading(true)

            const result = await getAppPushMsgRecordList(notificationIndex, notificationMsgType, JSON.parse(localStorage.getItem("User")).id);

            if(result.code === 200){
                setNotification(result.data)
            }

            // finish loading
            setIsLoading(false)
        }

        load();
    }, [notificationIndex, notificationMsgType])


    // msgType (integer, optional): 1消息2折扣3物流 ,
    function getMsgTypeIcon(msgType){
        switch (msgType){
            case 1:
                return varBucketURL + "/assets/icon-notification-alert.svg";

            case 2:
                return varBucketURL + "/assets/icon-notification-coupon.svg";

            case 3:
                return varBucketURL + "/assets/icon-notification-logistic.svg";
        }
    }

    function Item(){
        return(
            <List
                itemLayout="horizontal"
                dataSource={notification}
                renderItem={(item, index) => (
                    <List.Item
                        onClick={()=>{doAdsClickPosition(navigate, item.hitType, item.hitPosition)}}
                        className={`${notificationMsgType === "1" ? 'notification-clickable' : ''} ${item.status === 0 ? 'notification-unread-bg' : ''}`}
                    >
                        <Skeleton avatar title={false} loading={isLoading} active>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        src={getMsgTypeIcon(item.msgType)}
                                        size={40}
                                    />
                                }
                                title={<span>{item.msgTitle}</span>}
                                description={
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <span style={{color: "#494949", marginRight: "18px"}}>{item.msgContent}</span>
                                        <span>{unixDateToHumanDate(item.createDate)}</span>
                                    </div>
                                }
                            />
                            {
                                item.msgImg
                                ?
                                    <img className={"noti-img"} width={70} height={70} src={item.msgImg}></img>
                                :
                                    <div></div>
                        }
                        </Skeleton>
                    </List.Item>
                )}
                pagination={{
                    position: "bottom",
                    align: "center",
                }}
                split={false}
            />
        );
    }


    return (
        <Row
            className={"profile-page-content-container"}
            id={"notification-page"}
            style={{
                padding: "20px"
            }}
        >
            <Tabs
                defaultActiveKey="3"
                items={[
                    {
                        key: '3',
                        label: '交易物流',
                        disabled: isLoading,
                        children: <Item/>,
                    },
                    {
                        key: '1',
                        label: '推送通知',
                        disabled: isLoading,
                        children: <Item/>,
                    }
                ]}
                onChange={(e)=>{
                    // indicate loading
                    setIsLoading(true);

                    // do not clear, cuz it will cause loading not dosplay

                    setNotificationMsgType(e)
                    setNotificationIndex(1)
                }}
                tabBarStyle={{
                    width: "850px"
                }}
                size={"middle"}
            />
        </Row>
    );
}


export default Notification;