import axios from "axios";

// --- Constants ----------------
const API_HOST = "https://api.shopmy.com.au/api/v1";
const SERVICE_NAME = "/us";

axios.defaults.headers.common['X-Auth-Lang'] = `zh`;
axios.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('Access-Token');
axios.defaults.headers.common['X-Auth-Type'] = `app`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = `localhost`;

// upload
async function uploadFilesSync(data) {
    const formData = new FormData();
    formData.append("files", data.file);


    return axios({
        method: "post",
        url: API_HOST + SERVICE_NAME + "/sysfile/uploadFilesSync",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
    })
        .then(function (response) {
            //handle success
            var result = response.data.data[0];

            const request = [{
                uid: result.id,
                name: result.id,
                status: 'done',
                url: result.path,
                original: result
            }]

            return request;

        })
        .catch(function (response) {
            //handle error
            // console.log(response);
        });
}


export {
   uploadFilesSync
};