import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';



// 这是正式买家的位置
import App from './App';

// 这是早期只提供开店的位置
import AppEarly from "./App_early";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <App/>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
