import React, {useEffect, useState} from "react";
import {Button, Input, message, Row, Space} from "antd";
import {bucketURL} from "../../data/env";
import Search from "../Search";
import {CopyOutlined} from "@ant-design/icons";

const StoreSubmitted = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    const [auditNo, setAuditNo] = useState(null);
    useEffect(() => {
        if(props.store != ""){
            setAuditNo(props.store.data.auditNo)
            message.success("🎉 提交成功，工作人员将在 1-3 天回复您的商铺审核结果");

        }
    },[props.store])


    return (
        <Row className={"safe-area open-store-page open-store-page-submitted"}>
            <div className={"mobile-container"}>
                <img src={varBucketURL + "/assets/store-submitted.svg"} height="250px"></img>
                <br/>
                <span style={{textAlign: "center", lineHeight: 2}}>
                    <span>提交成功，喝杯咖啡放松一下吧 ! </span>
                    <br/>
                    <span>我们会尽快通知您审核结果 : )</span>
                </span>
                <br/>
                <br/>
                <br/>

                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                    >
                        <Input addonBefore="审核编号" value={auditNo}/>
                    </Space.Compact>
                    <Button
                        style={{
                            marginTop: "10px"
                        }}
                        type={"text"}
                        onClick={() => {
                            // Copy the text inside the text field
                            navigator.clipboard.writeText(auditNo);
                            message.success("已复制")
                        }}
                    >
                        <CopyOutlined />复制编号
                    </Button>

                </div>

            </div>

        </Row>
    );
}


export default StoreSubmitted;