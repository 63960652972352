import React, {useEffect, useState} from "react";

import {bucketURL} from "../data/env";


const NotReady = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "700px"

        }}>
            <br/>
            <br/>
            {/*<img src={varBucketURL + "/assets/not_ready_comp1.jpg"} width={"300px"}></img>*/}
            <div
                style={{
                    background: "#fff",
                    padding: "52px",
                    border: "1px solid rgb(208, 208, 208)",
                    borderRadius: "19px",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "600px",
                }}
            >
                <span>网页版暂时不支持此功能，请下载手机 APP 查看</span>
                <br/><br/>
                <img src={varBucketURL + "/assets/not_ready_comp2.svg"} width={"500px"}></img>
            </div>

            <p>{props.message}</p>
            <br/>
            <br/>
        </div>

    );
}


export default NotReady;