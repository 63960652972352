import React, {useEffect, useState} from "react";

import NotReady from "../components/NotReady";


const OfficialCoupon = (props) => {
    return (
        <NotReady/>
    );
}


export default OfficialCoupon;