import React, { useEffect, useState } from 'react';
import { TUIKit } from '../../tencent-im/dist/cjs/index';
import './TencentChatUI.css';
import TIM, { ChatSDK } from 'tim-js-sdk/tim-js-friendship';
import TIMUploadPlugin from 'tim-upload-plugin';
import {Alert} from "antd";
import {WarningFilled} from "@ant-design/icons";
import ChatLoadingComponent from "./ChatLoadingComponent";
import {getUserInfo, getUserSig} from "../../data/Customer-Service-Repo";


// icon image
// src/components/Icon/images


/**
 * 私信用的是外部 SDK，所以需要自己写一个 portal 来包裹
 * TUIChat.tsx 为所有外部 SDK 的入口（总控制 provider 放在这）
 * TUIManage.tsx 用来处理商品链接弹出窗
 *
 */



const ChatPortal =  (props) => {
    // 获取 params
    let params = new URLSearchParams(document.location.search);

    // 腾讯 im 方法
    const [tim, setTim] = useState();

    // 加载中
    const [loading, setLoading] = useState(true);

    // ------------------ 用来 rerender sub header ------------------
    useEffect(() => {
        // ----- 初始 -----
        props.setForceRender(props.forceRender + 1);

        // ----- 离开页面 -----
        const handleBeforeUnload = () => {
            // 值不可以跟上面一开始的一样，不然系统识别不到 state change 请求
            props.setForceRender(props.forceRender - 1);
        };

        // handles when page is unloaded
        window.addEventListener("beforeunload", handleBeforeUnload);

        // cleanup function handles when component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            handleBeforeUnload();
        };
    }, [])



    // ------------------ 检查要是否传递 seller token ------------------
    // 这边会判断是否有传递 seller token，如果有的话就用 seller token，没有的话就用 app token
    // 有传 seller token 的话，代表是使用 standalone 的方式，所以需要自己去获取 sig 和 user info
    // 没有传 seller token 的话，代表是使用 buyer embed 的方式，所以直接用登录时获取的 app token, sig, user info 就可以了

    const [im, setIm] = useState({
        authToken: '',
        authType: '',
        sig: '',
        userId: '',
    });

    useEffect(() => {
        async function load(){
            // 如果有商铺 token，以商家身分优先登录
            if (params.get("authToken") && params.get("authType")) {

                // get sig
                const sigResult = await getUserSig(params.get("authToken"), params.get("authType"));

                // get user info
                const userInfoResult = await getUserInfo(params.get("authToken"), params.get("authType"));

                if(sigResult.code === 200 && userInfoResult.code === 200){
                    setIm({
                        authToken: params.get("authToken"),
                        authType: params.get("authType"),
                        sig: sigResult.data,
                        userId: userInfoResult.data.id,
                    })
                } else {
                    // 代表 token 过期，用户从 history 进入私信
                    window.location.href = '/';
                    alert("授权已过期，请从商铺系统登录私信。")
                }

            } else {
                // get sig
                const sigResult = await getUserSig(localStorage.getItem('Access-Token'), "app");

                if(sigResult.code === 200){
                    setIm({
                        authToken: window.localStorage.getItem('Access-Token'),
                        authType: 'app',
                        sig: sigResult.data,
                        userId: JSON.parse(window.localStorage.getItem('User')).id,
                    })
                } else {
                    // 如果什么都没有，代表用户再未登录的情况下尝试访问私信
                    // 这边需要跳转到登录页面
                    window.location.href = '/';
                    alert("请先登录再继续")

                }

            }
        }

        load();

    }, [])



    // ------------------ 用来初始化 tim ------------------


    useEffect(() => {
        if(im.sig !== ''){
            const init = async ():Promise<ChatSDK> => {
                return new Promise((resolve, reject) => {
                    const tim = TIM.create({ SDKAppID: 1400696089 });
                    tim?.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
                    const onReady = () => { resolve(tim); };
                    tim.on(TIM.EVENT.SDK_READY, onReady);
                    tim.login({
                        userID: im.userId,
                        userSig: im.sig
                    });
                });
            }

            // loading
            // 这边需要一些延迟来让 params 先就位，不然会发生错误，最少要 1500ms 不然容易报错
            setTimeout(() => {
                setLoading(false);
            }, 1500);

            (async () => {
                const tim = await init()
                console.log(tim)
                setTim(tim)
            })()


        }
    }, [im.sig])



    return (
        <div style={{width: "100%"}}>
            {
                loading
                    ?
                    <ChatLoadingComponent/>
                    :
                    <>
                        <Alert
                            message={<span><WarningFilled style={{color: "#faad14"}}/> 为了维护您的隐私，私信内容将会在 7 天自动删除，如有需要请自行备份。</span>}
                            type="warning"
                            style={{
                                textAlign: "center",
                            }}
                            closable={false}
                        />
                        <div
                            style={{
                                height: 'calc(100vh - 110px)',
                                backgroundColor: '#fff',
                            }}
                        >
                            {
                                <TUIKit tim={tim} im={im}></TUIKit>
                            }
                        </div>
                    </>
            }
        </div>

    );

}

export default ChatPortal;
