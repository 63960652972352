import {findCrmCustomerAddressTagList, getUserInfo, logout} from "./Customer-Service-Repo";
import React from "react";
import {bucketURL} from "./env";

// 把后端豪秒为单为的 unix timestamp 转换成 YYYY-MM-DD HH:MM 格式
function unixDateToHumanDate(unix_timestamp){
    // 正常来说把 unix timestamp 转成 utc timestamp 要 *1000
    // 但是这里的 unix timestamp 是以毫秒为单位的，所以不用 *1000
    const dateObject = new Date(unix_timestamp);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    const hour = dateObject.getHours();
    const minute = dateObject.getMinutes();

    return year + "-" + singleDigitAddZero(month) + "-" + singleDigitAddZero(date) + " " + singleDigitAddZero(hour) + ":" + singleDigitAddZero(minute);
}


// 针对个位数字前面加上 0
function singleDigitAddZero(num){
    if (num < 10) {
        return "0" + num;
    }
    return num;
}


// 隐藏手机号码中间位数
function hideCredentialString(string){
    return string.substring(0, 3) + "*****";
}


// 取得用户名称（手机号 vs 用户设置的名称）
function getUserName(name, mobile){
    if(name === null){
        return mobile;
    }

    return name;
}


// 取得用户头像（如果没有头像，就用默认头像）
function getUserAvatar(varBucketURL, headUrl){
    if(headUrl === null){
        return varBucketURL + "/shopmy_buyer_desktop/assets/default_profile.svg";
    }

    return headUrl;
}


// 取得订单状态
// refundStatus (integer, optional): 退款状态（10：待审核、20：商铺拒绝、30：商铺同意、40：待判定、50：判定买家胜、60：判定商铺胜、70：退款完成、80：申请撤销、301：商铺同意收到退款/不需退货） ,
function orderStatusMapping(orderStatusId){
    switch (orderStatusId) {
        case 10:
            return "待付款";
        case 20:
            return "待出货";
        case 30:
            return "配送中";
        case 40:
            return "待完成";
        case 50:
            return "待评价";
        case 60:
            return "退货/退款";
        case 70:
            return "已取消";
        case 80:
            return "已完成";
        case 0:
            return "预订单";
        default:
            return "查无状态";
    }

}


// 取得退款状态
function refundStatusMapping(orderStatusRefund){
    switch (orderStatusRefund) {
        case 10:
            return "买家发起退款，待商铺回复";
        case 20:
            return "商铺拒绝退款，请重新发起";
        case 30:
            return "同意退款，如需退货，请协商退货方式";
        case 40:
            return "转交判定中心，待判定";
        case 50:
            return "判定结果，买家胜，请买家寄回商品";
        case 60:
            return "判定结果，商铺胜";
        case 70:
            return "商铺收到商品，退款完成";
        case 80:
            return "申请撤销 DEV_TEST_ONLY";
        case 301:
            return "内部状态：商铺同意收到退款/不需退货 DEV_TEST_ONLY";
        default:
            return "查无状态";
    }
}


// -------------------------- 支付方式 --------------------------
// 取得支付方式图标
// orderPayType (integer, optional): 支付方式（0：未实际支付、1：微信、2：支付宝、3：信用卡、4：Google支付、5：Apple支付） ,
function paymentMethodIconMapping(bucket, method){
    switch (method) {
        case 1:
            return bucket + "/assets/icon-wechat.svg";
        case 2:
            return bucket + "/assets/icon-alipay.svg";
        case 3:
            return bucket + "/assets/icon-visacard.svg";
        case 4:
            return bucket + "/assets/icon-googlepay.svg";
        case 5:
            return bucket + "/assets/icon-applepay.svg";

    }
}


// 取得支付方式名称
function paymentMethodNameMapping(method){
    switch (method) {
        case 0:
            return "未支付";
        case 1:
            return "微信支付";
        case 2:
            return "支付宝";
        case 3:
            return "银行卡支付";
        case 4:
            return "Google Pay";
        case 5:
            return "Apple Pay";
    }
}
// -------------------------- 支付方式 --------------------------


// 条列订单用的交易提示信息
function identifyOrderTipsList(order) {
    switch (order.orderStatus) {
        case 10:
            return (order.cancelDateStr);

        case 20:
            return ("商铺预计于 " + unixDateToHumanDate(order.orderShipExpectedDate) + " 出货");

        case 30:
            return ("喝杯奶茶，快递就到了!");

        case 50:
            return ("请在 " + unixDateToHumanDate(order.orderCommentExpectedDate) + " 前评价");

        case 60:
            return (order.orderRefundAuditInfo);

        case 70:
            return ("订单于 " + unixDateToHumanDate(order.orderCancelDate) + " 取消");

        case 80:
            return ("订单于 " + unixDateToHumanDate(order.orderShipDate) + " 完成");


    }
}


// 详情页用的交易提示信息
function identifyOrderTipsDetail(order) {
    switch (order.orderStatus) {
        case 10:
            return ("订单在" + unixDateToHumanDate(order.orderInvalidDate) + " 后失效");

        case 20:
            return ("商铺预计于 " + unixDateToHumanDate(order.orderShipExpectedDate) + " 出货");

        case 30:
            return ("喝杯奶茶，快递就到了!");

        case 50:
            return ("请在 " + unixDateToHumanDate(order.orderCommentExpectedDate) + " 前评价");

        case 60:
            return (order.orderRefundAuditInfo);

        case 70:
            return ("订单于 " + unixDateToHumanDate(order.orderCancelDate) + " 取消");

        case 80:
            return ("订单于 " + unixDateToHumanDate(order.orderShipDate) + " 完成");

    }
}


// 检查 token 是否到期
async function checkIsTokenExpire() {
    const result = await getUserInfo(window.localStorage.getItem('Access-Token'), 'app');

    // kill session if the backend already expire OR no access token OR no user data
    if (result.code === 402 || window.localStorage.getItem('Access-Token') == null || window.localStorage.getItem('User') == null) {
        logout();
        window.localStorage.removeItem('User');
        window.localStorage.removeItem('Access-Token');
        window.localStorage.removeItem('Tencent-IM-Sig');
        window.localStorage.removeItem('storeFormData');
        window.localStorage.removeItem('storeFormStatus');
        window.localStorage.removeItem('lastShowStartPageAds');
        window.location.href = window.location.pathname + window.location.search; // 不要清空 path，因为 Account 部件会辨别转址
        alert("登录超时,请重新登录");
    }
}


// 生成 uuid
function generateUuidV4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


// 从 url 取得图片的 base64
async function getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);

        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    })
}


// 处理地址合并
function addressFormatterWithUnit(address){
    // 因为如果没有 unit 和 有 unit 的地址格式不一样，所以需要判断
    var unit;
    address.unit === null
        ? unit = ""
        : unit = address.unit + " "


    return unit + address.address + ", " + address.suburb + " " + address.state + " " + address.postcode;

}


// 处理地址合并包含 Unit
function addressFormatterWithOutUnit(address){
    return address.address + ", " + address.suburb + " " + address.state + " " + address.postcode;
}


// 取得地址 icon
async function getAddressIcon(tagName) {
    var result = await findCrmCustomerAddressTagList();

    if(result.code === 200){
        // loop thought data
        for(var i = 0; i < result.data.length; i++){
            // console.log(tagName)
            // console.log(result.data[i].tag)

            if(result.data[i].tag === tagName){
                return result.data[i].tagIcon;
            }
        }

        return "https://app-apsoutheast2-static.shopmy.com.au/shopmy_buyer_desktop/assets/icon-default-address-tag.svg";
    }
}


// 执行广告点击位置
// navigate (function, optional): 跳转函数
// hitType (integer, optional): 点击类型（1，商品，2商铺 3url）
// hitPosition (string, optional): 点击内容
function doAdsClickPosition(navigate, hitType, hitPosition){
    if(hitType === 1) {
        // navigate("/product?id=" + hitPosition)
        window.open("/product?id=" + hitPosition, "_blank")

    } else if(hitType === 2) {
        // navigate("/store?id=" + hitPosition)
        window.open("/store?id=" + hitPosition, "_blank")

    } else if(hitType === 3) {
        window.open(hitPosition, "_blank")
    }
}



// 取得绑定社交帐号图标和名称
function getSocialLoginNameAndIcon(user){ // 传的是 getUserInfo 的结果

    var logo = "";
    var name = "";

    if(user.unionid !== null){
        logo = bucketURL() + "/assets/wechat-icon.svg";
        name = "已绑定微信登录"

    } else if(user.appleid !== null){
        logo = bucketURL() + "/assets/apple-icon.svg";
        name = "已绑定Apple ID 登录"

    } else {
        name = "无"

    }

    return (
        <div className={"social-login-indicator"}>
            {
                logo !== "" && <><img src={logo}></img>&nbsp;</>
            }
            <span>{name}</span>
        </div>
    );

}




export {
    unixDateToHumanDate,
    hideCredentialString,
    getUserName,
    getUserAvatar,
    checkIsTokenExpire,
    orderStatusMapping,
    refundStatusMapping,
    paymentMethodIconMapping,
    paymentMethodNameMapping,
    identifyOrderTipsList,
    generateUuidV4,
    identifyOrderTipsDetail,
    getBase64ImageFromUrl,
    addressFormatterWithUnit,
    addressFormatterWithOutUnit,
    getAddressIcon,
    doAdsClickPosition,
    getSocialLoginNameAndIcon
}