import React, { useEffect, useState } from 'react';
import {LoadingOutlined} from "@ant-design/icons";

const ChatLoadingComponent = (props) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <LoadingOutlined
                style={{
                    fontSize: 60,
                }}
                spin
                className={"color-shopmy-orange"}
            />
            <h4 style={{fontWeight: "500"}}>私信加载中，请稍后...</h4>
        </div>
    );

}

export default ChatLoadingComponent;
