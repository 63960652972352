import React, {useEffect, useState} from "react";
import {
    Affix,
    Avatar,
    Button,
    Checkbox,
    Divider,
    Drawer,
    Form,
    InputNumber,
    notification,
    Popover,
    Row,
    Skeleton
} from "antd";
import {Col} from "antd/es/grid";
import {
    CaretDownFilled,
    HomeOutlined,
    MessageFilled,
    MinusOutlined,
    PlusOutlined,
    RightOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {bucketURL} from "../../data/env";
import {
    deleteOmsOrderCar,
    doOmsOrderCarAllSelected,
    doOmsOrderCarStoreSelected, editOmsOrderCar,
    findOmsOrderCarAppList, moveOmsOrderCar, preOrder
} from "../../data/Order-Service-Repo";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import NoData from "../../components/NoData";
import Product from "../Product";

const Cart = (props) => {

    const navigate = useNavigate();
    const [notificationApi, notificationContextHolder] = notification.useNotification();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // cart storage
    const [cart, setCart] = useState([]);


    // 全选按钮当前的状态
    const [allSelected, setAllSelected] = useState(0);


    // 加载项
    const [isLoading, setIsLoading] = useState(true); // loading status
    const [isVisible, setIsVisible] = useState(true); // visible status
    const [visableMessage, setVisableMessage] = useState(""); // visible message



    useEffect(() => {
        fetchData();
    }, [])


    // 后端取得信息
    async function fetchData() {
        // get cart
        const result = await findOmsOrderCarAppList();

        // 判断是否成功
        if (result.code === 200) {
            setCart(result.data);


            var state = [];

            result.data.stores.map((store, index) => {
                var array = [];

                store.products.map((product, index) => {
                    array.push({
                        visible: false
                    })
                })

                state.push(array);
            })
            setOpenSkuPopover(state)
            console.log(state)

        } else {
            setIsVisible(false)
            setVisableMessage("找不到相关信息！")
        }

        // 加载成功，开启内容，关闭加载status
        setIsLoading(false);
    }


    // 执行勾选操作
    const checkboxOnChange = async (selection, id, currentStatue) => {
        // 结果
        var result = null;

        // 执行
        if (selection === "all") { // 点购物车的全选
            result = await doOmsOrderCarAllSelected(allSelected === 0 ? 1 : 0);
            setAllSelected(allSelected === 0 ? 1 : 0);

        } else if (selection === "store") { // 选取/取消选取「商铺的」商品
            // 如果 currentStatue 是 0，就传 1
            // 如果 currentStatue 是 1，就传 0
            result = await doOmsOrderCarStoreSelected(id, currentStatue === 0 ? 1 : 0);
        }
        // 商品的勾选、规格、数量修改让 editOmsOrderCar 一起处理


        // 反馈结果
        if (result.code === 200) {
            notificationApi.success({
                message: `提示信息`,
                description: "成功",
                placement: "bottomRight",
                duration: 2,
            });

            // 刷新当前列表
            await fetchData();

        } else {
            notificationApi.error({
                message: `提示信息`,
                description: result.message,
                placement: "bottomRight",
                duration: 2,
            });
        }
    };


    // 执行删除或移入收藏夹
    const deleteOrMoveToFavorite = async (selection, id) => {
        // 结果
        var result = null;

        // 因为后端支持批量删除，因此要求 string 合集，但是我们在 pc web 不需要提供批量删除，因此要制造一个假 array
        var ids = [
            id
        ];

        // 执行
        if (selection === "delete") { // 点购物车的全选
            result = await deleteOmsOrderCar(ids);

        } else if (selection === "favorite") { // 选取/取消选取「商铺的」商品
            result = await moveOmsOrderCar(ids);

        }

        // 反馈结果
        if (result.code === 200) {
            notificationApi.success({
                message: `提示信息`,
                description: "成功",
                placement: "bottomRight",
                duration: 2,
            });

            // 刷新当前列表
            await fetchData();

        } else {
            notificationApi.error({
                message: `提示信息`,
                description: result.message,
                placement: "bottomRight",
                duration: 5,
            });
        }
    };


    // 修改购物车的商品
    // 如果传了 doNotify，就会在成功后弹出提示
    async function cartItemOnChange(id, isSelected, productNum, productSkuId, doNotify) {
        // 结果
        var result = [];

        // 做一些基本校验
        if (productNum <= 0) {
            result.code = -4001;
            result.message = "商品数量不能小于 1";

        } else {
            // 执行
            result = await editOmsOrderCar(id, isSelected, productNum, productSkuId);
        }



        // 反馈结果
        if (result.code === 200) {
            if(doNotify){
                notificationApi.success({
                    message: `提示信息`,
                    description: "成功",
                    placement: "bottomRight",
                    duration: 3,
                });
            }


            console.log("成功");

            // 刷新当前列表
            await fetchData();

        } else {
            notificationApi.error({
                message: `提示信息`,
                description: result.message,
                placement: "bottomRight",
                duration: 2,
            });

        }


    }


    // 下方的 footer 是否变成 fixed
    const [footerFixed, setFooterFixed] = useState(false);

    // -------------------------------------- 优惠券页面的 drawer --------------------------------------
    const [openCouponDrawer, setOpenCouponDrawer] = useState(false);
    const [couponDrawerContent, setCouponDrawerContent] = useState([]); // 内容


    const showCouponDrawer = (builder) => {
        setOpenCouponDrawer(true);
        setCouponDrawerContent(builder);
    };

    const onCloseCouponDrawer = () => {
        setOpenCouponDrawer(false);
    };

    // -------------------------------------- 商品规格的 popover --------------------------------------
    const [openSkuPopover, setOpenSkuPopover] = useState([]);
    // 强制 re-render
    const [forceRender, setForceRender] = useState(0);


    // -------------------------------------- 结算 --------------------------------------
    async function handleCheckoutOnClick() {
        // 建立暂存 cartId
        var carIds = [];

        // loop 过 cart
        // 先检查 store，都以 store 中的商品为单位判断，所有直接前往商品 loop 中
        cart.stores.map((store, index) => {
            store.products.map((product, index) => {
                // 如果是选中的，就加入 cartId
                if (product.isSelected === 1) {
                    carIds.push(product.id);
                }
            })
        })
        console.log("carIds", carIds)

        // 如果 cartId 为空，就不执行
        if (carIds.length === 0) {
            notificationApi.error({
                message: `提示信息`,
                description: "请先勾选商品",
                placement: "bottomRight",
                duration: 5,
            });

            return;
        } else {
            // 建立预售订单
            var result = await preOrder(carIds,[]);

            if(result.code === 200){
                // 跳转到确认订单页面
                navigate("/checkout/confirm?id=" + result.data.id);

            } else {
                // 显示错误信息
                notificationApi.error({
                    message: `提示信息`,
                    description: result.message,
                    placement: "bottomRight",
                    duration: 5,
                });

            }
        }

    }


    return (
        <>
            {notificationContextHolder}
            {
                isLoading
                    ?
                    <>
                        <Loading/>
                    </>

                    :
                    <>
                        {
                            isVisible
                                ?
                                <div className={"full-height"}>
                                    <Drawer classname={"cart-coupon-drawer"} title="领取商铺优惠券" placement="right" onClose={onCloseCouponDrawer} open={openCouponDrawer} bodyStyle={{overflowX: "hidden"}} width={"417px"}>
                                        {
                                            couponDrawerContent.length !== 0 &&
                                            couponDrawerContent.products.map((item, index) => {
                                                // 随便选一个商品来查找，所以只做第一个
                                                if(index === 0){
                                                    return(
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <Avatar src={couponDrawerContent.storeIco} size={80}/>
                                                                <span style={{fontWeight: "500", fontSize: "15px"}}>商铺优惠券</span>
                                                                <span>适用于 {couponDrawerContent.storeName}</span>
                                                            </div>
                                                            <br/>
                                                            <br/>
                                                            <Product token={props.accessToken} user={props.user} renderType={"coupon"} id={couponDrawerContent.products[0].productSpuId}/>
                                                        </>
                                                    );
                                                }

                                            })
                                        }
                                    </Drawer>


                                    <Row className={"safe-area"} id={"cart-page"} rerender={forceRender}>
                                        {/*table header*/}
                                        <Row className={"table store-table"}
                                             style={{marginBottom: "5px", border: "0"}}>
                                            <Col span={1} className={"table-item ckeckbox"}>
                                                <Checkbox checked={allSelected} onChange={() => {
                                                    checkboxOnChange("all", "", 0)
                                                }}></Checkbox>
                                            </Col>

                                            <Col span={8} className={"table-item product-detail"}>
                                                <span>商品</span>

                                            </Col>

                                            <Col span={4} className={"table-item product-sku color-subtitle-grey"}>
                                                <span>&nbsp;</span>
                                            </Col>

                                            <Col span={3} className={"table-item product-single-price"}>
                                                <span>单价</span>
                                            </Col>

                                            <Col span={3} className={"table-item product-amount"}>
                                                <span>数量</span>
                                            </Col>

                                            <Col span={2} className={"table-item product-total-price"}>
                                                <span>总价</span>
                                            </Col>

                                            <Col span={2} className={"table-item action"}>
                                                <span>&nbsp;&nbsp;&nbsp;  操作</span>
                                            </Col>
                                        </Row>

                                        {
                                            cart.productAllNum !== 0
                                                ?
                                                cart.stores.map((cartStoreBuilder, storeIndex) => {
                                                    return (
                                                        <div style={{marginTop: "20px", width: "100%"}}
                                                             className={`${cartStoreBuilder.storeStatus !== 1 ? "isDisabled" : ""}`}>
                                                            {/*商家列*/}
                                                            <Row className={"table store-table"}>
                                                                <Col span={1} className={"table-item ckeckbox"}>
                                                                    <Checkbox
                                                                        checked={cartStoreBuilder.isSelected}
                                                                        onChange={() => {
                                                                            checkboxOnChange("store", cartStoreBuilder.storeId, cartStoreBuilder.isSelected)
                                                                        }}
                                                                        disabled={
                                                                            // 如果 storeStatus 不是 1，代表商铺失效，不提供点击
                                                                            cartStoreBuilder.storeStatus !== 1
                                                                        }
                                                                    ></Checkbox>
                                                                </Col>

                                                                <Col span={23}
                                                                     className={"table-item store-name expect-from-disabled"}>
                                                                        <span
                                                                            onClick={() => {
                                                                                navigate("/store?id=" + cartStoreBuilder.storeId)
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <HomeOutlined/>
                                                                            &nbsp;
                                                                            {cartStoreBuilder.storeName}
                                                                            &nbsp;
                                                                            <RightOutlined
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                }}
                                                                            />
                                                                        </span>

                                                                    &nbsp;&nbsp;&nbsp;&nbsp;

                                                                    {/*TODO: 私信商铺*/}
                                                                    {/*没有办法提供，因为没传用户id*/}
                                                                    {/*<Button*/}
                                                                    {/*    size={"small"}*/}
                                                                    {/*    style={{*/}
                                                                    {/*        display: "flex",*/}
                                                                    {/*        alignItems: "center",*/}
                                                                    {/*        color: "#f46e12",*/}
                                                                    {/*        marginTop: "3px"*/}
                                                                    {/*    }}*/}
                                                                    {/*    onClick={()=>{*/}
                                                                    {/*        window.open("/chat/portal?conversationUserId=" + cartStoreBuilder.products[0].customerId, "_blank")*/}
                                                                    {/*    }}*/}
                                                                    {/*>*/}
                                                                    {/*    <MessageFilled/>*/}
                                                                    {/*    联络商铺*/}
                                                                    {/*</Button>*/}

                                                                    <div
                                                                        style={{
                                                                            marginLeft: "auto",
                                                                            marginRight: "0",
                                                                            fontSize: "14px"
                                                                        }}
                                                                    >
                                                                        {/*storeStatus 的后端文档不是很清晰，1: 代表正常 2: 商品失效 3: 商铺休假中*/}
                                                                        {
                                                                            // 如果正常，同时 isCoupon = 1 代表有优惠券
                                                                            (cartStoreBuilder.storeStatus === 1 && cartStoreBuilder.isCoupon === 1) &&

                                                                            <Button
                                                                                size={"small"}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    color: "#f46e12",
                                                                                    marginTop: "3px"
                                                                                }}
                                                                                onClick={() => {
                                                                                    // 传 store 物件
                                                                                    showCouponDrawer(cartStoreBuilder)
                                                                                }}
                                                                            >
                                                                                领取优惠券
                                                                            </Button>
                                                                        }


                                                                        {
                                                                            cartStoreBuilder.storeStatus === 2 &&
                                                                            <span
                                                                                className={"color-subtitle-grey"}>商品已失效</span>
                                                                        }


                                                                        {
                                                                            cartStoreBuilder.storeStatus === 3 &&
                                                                            <span className={"color-shopmy-orange"}
                                                                                  style={{
                                                                                      fontWeight: "500",
                                                                                      display: "flex"
                                                                                  }}>
                                                                                        <img
                                                                                            src={varBucketURL + "/assets/icon-vacation-mode.svg"}></img>
                                                                                &nbsp;&nbsp;商铺休假中
                                                                                    </span>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            {
                                                                cartStoreBuilder.products.map((cartProductBuilder, productIndex) => {
                                                                    return (
                                                                        <Row className={"table product-table"}>
                                                                            <Col span={1}
                                                                                 className={"table-item ckeckbox"}>
                                                                                <Checkbox
                                                                                    checked={cartProductBuilder.isSelected}
                                                                                    onClick={() => {
                                                                                        // 这边只改 isSelected 所以其他东西维持不变
                                                                                        cartItemOnChange(cartProductBuilder.id, cartProductBuilder.isSelected === 0 ? 1 : 0, cartProductBuilder.productNum, cartProductBuilder.productSkuId, false)
                                                                                    }}
                                                                                    disabled={
                                                                                        // 如果 storeStatus 不是 1，代表商铺失效，不提供点击
                                                                                        cartStoreBuilder.storeStatus !== 1
                                                                                    }
                                                                                ></Checkbox>
                                                                            </Col>

                                                                            <Col
                                                                                span={8}
                                                                                className={"table-item product-detail"}
                                                                                onClick={() => {
                                                                                    navigate("/product?id=" + cartProductBuilder.productSpuId)
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={cartProductBuilder.productImgurl}
                                                                                    width={"80px"}></img>
                                                                                <Skeleton.Image active={"true"}
                                                                                                width={"80px"}/>
                                                                                <span
                                                                                    style={{marginLeft: "96px"}}>{cartProductBuilder.productName}</span>
                                                                            </Col>

                                                                            <Col
                                                                                span={4}
                                                                                className={"table-item product-sku color-subtitle-grey"}
                                                                            >
                                                                                <Popover
                                                                                    render={forceRender}
                                                                                    content={
                                                                                        <>
                                                                                            <Product token={props.accessToken} user={props.user} renderType={"sku"} id={cartProductBuilder.productSpuId} cartProduct={cartProductBuilder} cartItemOnChange={cartItemOnChange}/>
                                                                                            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                                                                                                <span style={{color: "#adadad", fontSize: "13px", marginRight: "42px"}}>修改规格后，您的购买数量将会被重置为1，请在下单前核对购买数量</span>
                                                                                                <Button
                                                                                                    onClick={()=>{
                                                                                                        openSkuPopover[storeIndex][productIndex].visible = false
                                                                                                        setForceRender(forceRender+1);
                                                                                                    }}
                                                                                                    style={{width: "152px"}}
                                                                                                >
                                                                                                    取消
                                                                                                </Button>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    title="更改规格"
                                                                                    trigger="hover"
                                                                                    overlayInnerStyle={{
                                                                                        padding: "20px",
                                                                                        width: "524px"
                                                                                    }}
                                                                                    open={openSkuPopover[storeIndex][productIndex].visible}
                                                                                    placement={"bottom"}
                                                                                >
                                                                                    <div
                                                                                        onClick={()=> {
                                                                                            //把所有的 state 都先归零
                                                                                            openSkuPopover.forEach((store, index) => {
                                                                                                store.forEach((product, index) => {
                                                                                                    product.visible = false;
                                                                                                })
                                                                                            })
                                                                                            openSkuPopover[storeIndex][productIndex].visible = true;
                                                                                            setForceRender(forceRender+1);
                                                                                        }}
                                                                                    >
                                                                                        <span>规格：<span ><CaretDownFilled style={{fontSize: "12px"}}/></span></span>
                                                                                        <span>{cartProductBuilder.productAttributes}</span>
                                                                                    </div>

                                                                                </Popover>
                                                                            </Col>

                                                                            <Col span={2}
                                                                                 className={"table-item product-single-price"}>
                                                                                <span>$ {cartProductBuilder.productSellerPrice.toFixed(2)}</span>
                                                                            </Col>

                                                                            <Col span={4}
                                                                                 className={"table-item product-amount"}>
                                                                                <Button
                                                                                    size={"small"} type="primary"
                                                                                    shape="circle"
                                                                                    icon={<MinusOutlined/>}
                                                                                    style={{marginRight: "7px"}}
                                                                                    onClick={() => {
                                                                                        // 这边只改 productNum 所以其他东西维持不变
                                                                                        cartItemOnChange(cartProductBuilder.id, cartProductBuilder.isSelected, cartProductBuilder.productNum - 1, cartProductBuilder.productSkuId, false)
                                                                                    }}
                                                                                    disabled={
                                                                                        // 如果 storeStatus 不是 1，代表商铺失效，不提供点击
                                                                                        cartStoreBuilder.storeStatus !== 1
                                                                                    }
                                                                                />
                                                                                <InputNumber
                                                                                    value={cartProductBuilder.productNum}
                                                                                    controls={false}
                                                                                    onChange={(value) => {
                                                                                        // 这边只改 productNum 所以其他东西维持不变
                                                                                        cartItemOnChange(cartProductBuilder.id, cartProductBuilder.isSelected, value, cartProductBuilder.productSkuId, false)
                                                                                    }}
                                                                                    style={{
                                                                                        width: "60px",
                                                                                        textAlign: "center"
                                                                                    }}
                                                                                    disabled={
                                                                                        // 如果 storeStatus 不是 1，代表商铺失效，不提供点击
                                                                                        cartStoreBuilder.storeStatus !== 1
                                                                                    }
                                                                                />
                                                                                <Button
                                                                                    size={"small"}
                                                                                    type="primary"
                                                                                    shape="circle"
                                                                                    icon={<PlusOutlined/>}
                                                                                    style={{
                                                                                        marginLeft: "7px"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        // 这边只改 productNum 所以其他东西维持不变
                                                                                        cartItemOnChange(cartProductBuilder.id, cartProductBuilder.isSelected, cartProductBuilder.productNum + 1, cartProductBuilder.productSkuId, false)
                                                                                    }}
                                                                                    disabled={
                                                                                        // 如果 storeStatus 不是 1，代表商铺失效，不提供点击
                                                                                        cartStoreBuilder.storeStatus !== 1
                                                                                    }
                                                                                />
                                                                            </Col>

                                                                            <Col span={2}
                                                                                 className={"table-item product-total-price"}>
                                                                                <span>$ {(cartProductBuilder.productSellerPrice * cartProductBuilder.productNum).toFixed(2)}</span>
                                                                            </Col>

                                                                            <Col span={2}
                                                                                 className={"table-item action expect-from-disabled"}>
                                                                                <Button
                                                                                    type="text"
                                                                                    className={"color-shopmy-orange"}
                                                                                    onClick={() => {
                                                                                        deleteOrMoveToFavorite("delete", cartProductBuilder.id)
                                                                                    }}
                                                                                >
                                                                                    删除
                                                                                </Button>

                                                                                {
                                                                                    // 只要不是失效的商品都可以加入收藏
                                                                                    cartStoreBuilder.storeStatus !== 2 &&
                                                                                    <Button
                                                                                        type="text"
                                                                                        className={"color-subtitle-grey"}
                                                                                        onClick={() => {
                                                                                            deleteOrMoveToFavorite("favorite", cartProductBuilder.id)
                                                                                        }}
                                                                                    >
                                                                                        移入收藏夹
                                                                                    </Button>
                                                                                }
                                                                            </Col>
                                                                            {
                                                                                cartStoreBuilder.products.length - 1 !== productIndex &&
                                                                                <Divider style={{
                                                                                    width: "1164px",
                                                                                    margin: "0px 0px 0px 16px",
                                                                                    minWidth: "1164px"
                                                                                }}/>
                                                                            }

                                                                        </Row>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    );
                                                })
                                                :
                                                <Row className={"table empty-table"} style={{marginTop: "10px"}}>
                                                    <NoData message={"购物车还是空的"}/>
                                                </Row>

                                        }


                                        {/*table header*/}
                                        <Affix offsetBottom={0}
                                               style={{
                                                   width: "100%",
                                                   height: "98px"
                                               }}
                                               onChange={(affixed) => setFooterFixed(affixed)}
                                        >
                                            {
                                                footerFixed
                                                    ?
                                                    <div
                                                        style={{background: "linear-gradient(transparent,rgba(0,0,0,.06))"}}>
                                                        &nbsp;
                                                    </div>
                                                    :
                                                    <div style={{marginTop: "5px"}}>
                                                        &nbsp;
                                                    </div>
                                            }

                                            <Row className={"table total-table"}
                                                 style={{margin: "0", border: "0"}}>
                                                <Col span={12} className={"table-item ckeckbox"}>
                                                    <Checkbox checked={allSelected} onChange={() => {
                                                        checkboxOnChange("all", "", 0)
                                                    }}></Checkbox>
                                                    &nbsp;&nbsp;&nbsp;
                                                    全选
                                                </Col>

                                                <Col span={12} className={"table-item detail"}>
                                                    <span>總金額 ({cart.productAllNum} 個商品)：</span>
                                                    <span className={"price"}>${cart.productAllPrice.toFixed(2)}</span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {/*TODO: 结算*/}
                                                    <Button
                                                        type={"primary"}
                                                        size={"large"}
                                                        style={{width: "200px"}}
                                                        disabled={
                                                            cart.productAllPrice === 0 ? true : false
                                                        }
                                                        onClick={
                                                            handleCheckoutOnClick
                                                        }
                                                    >
                                                        去结算
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Affix>
                                    </Row>
                                    <br/>
                                </div>

                                :
                                <>
                                    <NotFound message={"购物车还是空的～"}/>
                                </>
                        }
                    </>
            }
        </>

    );

}


export default Cart;