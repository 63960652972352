import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Divider,
    Row,
    Radio,
    InputNumber,
    Form,
    Image,
    Avatar,
    Rate,
    Pagination,
    Popover, message, Modal, QRCode, Spin
} from "antd";
import {Comment} from '@ant-design/compatible';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

import $ from 'jquery';
import {
    ArrowUpOutlined,
    HeartOutlined,
    LikeFilled,
    MessageFilled,
    MinusOutlined,
    PlusOutlined,
    ShareAltOutlined,
    StarFilled,
    HeartFilled, LoadingOutlined, HomeFilled
} from "@ant-design/icons";
import {bucketURL} from "../data/env";

import {
    deleteLogicOmsProductFavorite,
    doOmsProductCommentUpvote,
    getOmsProduct,
    getOmsProductCommentList,
    getOmsProductProductCommentStatistic, saveOmsProductFavorite, saveOmsProductFootprint
} from "../data/Product-Service-Repo";

import {doReceiveOmsCoupon, getOmsCouponList, preOrder, saveOmsOrderCar} from "../data/Order-Service-Repo";
import {unixDateToHumanDate, hideCredentialString, getBase64ImageFromUrl} from "../data/tool";
import NoData from "../components/NoData";
import BackToUp from "@uiw/react-back-to-top";
import html2canvas from "html2canvas";
import {useNavigate} from "react-router-dom";
import NotFound from "../components/NotFound";


/**
 *
 * Doc comment
 * Product.js 不仅仅是一个商品页面，同时他也用来提供「店铺优惠券」和「商品规格选择」的功能
 * 因为这两个功能都需要用到商品的数据，所以就放在这里了
 *
 * 在调用 Product Component 时，可以用 props "renderType" 来告诉组件你希望显示什么样式
 * renderType = "product" 时，显示商品页面
 * renderType = "coupon" 时，显示店铺优惠券
 * renderType = "sku" 时，显示商品规格选择 （如果使用 sku 选择器时必须携带 cartProduct[Array] 和 cartItemOnChange[method]）
 *      cartProduct: 商品在购物车的合集 (findOmsOrderCarAppList 的 store.products)
 *      cartItemOnChange: 购物车商品数量改变时的回调方法
 */

const Product = (props) => {
    var navigate = useNavigate();

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });
    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // product
    const [product, setProduct] = useState([]); // product
    const [productRotationImg, setProductRotationImg] = useState([]); // product rotation img
    const [productDetailImg, setProductDetailImg] = useState([]);// product detail img
    const [sellerStore, setSellerStore] = useState([]);// seller store

    // comment
    const [commentStatistic, setCommentStatistic] = useState([]);
    const [commentList, setCommentList] = useState([]);

    // coupon
    let [coupon, setCoupon] = useState([]);
    const [couponHeight, setCouponHeight] = useState(0);
    const [storeId, setStoreId] = useState("");
    const [hasCoupon, setHasCoupon] = useState(false);

    // sku list
    const [skuOption1, setSkuOption1] = useState([]); // 第一层规格选项纯文字 array
    const [skuOptionName1, setSkuOptionName1] = useState(""); // 第一层名称
    const [skuOption2, setSkuOption2] = useState([]); // 第二层规格选项纯文字 array
    const [skuOptionName2, setSkuOptionName2] = useState(""); // 第二层名稱
    const [completeSkuList, setCompleteSkuList] = useState([]); //所有离散库存表

    // 商品收藏状态
    const [isProductFavorite, setIsProductFavorite] = useState(false);

    // 商品图片 base64 for 分享图卡
    const [productImgBase64, setProductImgBase64] = useState(null);
    // logo base64 for 分享图卡
    const [logoBase64, setLogoBase64] = useState(null);

    // 是否显示商品
    const [isProductVisible, setIsProductVisible] = useState(true);

    // product id
    const [productId, setProductId] = useState(null);


    useEffect(() => {
        async function load() {
            if (params.id === null && props.id !== undefined) {
                setProductId(props.id)
            } else {
                setProductId(params.id)
            }
        }

        load();
    }, [props.token]);


    useEffect(() => {
        async function load() {
            if (productId !== null) {
                // default stock amount
                setAmountInputValue(1);

                // get product data
                const productData = await getOmsProduct(productId);
                setProduct(productData.data);

                // 判断如果商品失效不加载任何内容
                // productStatus (integer, optional): 商品状态（1已上架 2已下架 3已售完 4已禁卖 5已删除） ,
                if (productData.data.productStatus === 4 || productData.data.productStatus === 5 || productData.code !== 200) {
                    setIsProductVisible(false);

                } else {
                    setProductRotationImg(productData.data.rotationList);
                    setProductDetailImg(productData.data.detailList);
                    setStoreId(productData.data.sellStoreVo.id)
                    setSellerStore(productData.data.sellStoreVo);
                    setCompleteSkuList(productData.data.skuList);

                    // set sku and default
                    setSkuOption1(productData.data.ruleList[0].natureName.split(","));
                    setSkuOptionName1(productData.data.ruleList[0].ruleName);

                    // if there are second rule list, set second option
                    if (productData.data.ruleList.length === 2) {
                        setSkuOption2(productData.data.ruleList[1].natureName.split(","));
                        setSkuOptionName2(productData.data.ruleList[1].ruleName);
                    }

                    // get comment statistic
                    const commentStatData = await getOmsProductProductCommentStatistic(productId)
                    setCommentStatistic(commentStatData.data)


                    // get comment
                    await fetchComment(1);

                    // 增加足迹
                    await saveOmsProductFootprint(productId);

                    // 检查是否已经收藏
                    setIsProductFavorite(productData.data.isProductFavorite);


                    // 商品图片 base64 for 分享图卡
                    getBase64ImageFromUrl(productData.data.productImg)
                        .then(result => setProductImgBase64(result))
                        .catch(err => console.error(err));

                    // logo base64 for 分享图卡
                    getBase64ImageFromUrl(varBucketURL + "/assets/logo-classic.svg")
                        .then(result => setLogoBase64(result))
                        .catch(err => console.error(err));
                }
            }
        }

        load();

    }, [productId])





    // -------------------------------- Coupon controller ----------------------------------
    // indicator to render coupon
    const [couponRender, setCouponRender] = useState(0);

    // get coupon require storeId been set, so wait until store id to start fetch coupon
    useEffect(() => {
        async function load() {
            if (props.token !== null && storeId !== "") {
                await fetchCoupon();
            }
        }

        load();
    }, [storeId]);


    async function fetchCoupon() {

        // reset coupon
        coupon = [];

        // get coupon data, try page 1 first
        let couponData = await getOmsCouponList(storeId, 1);

        let couponTotalPage = couponData.page.pages;
        let couponCurrentPage = 1; // current page start from 1
        couponData = couponData.data; // get plain data for forEach

        while (couponTotalPage > 0) {

            let tempCoupon = coupon;
            // push data from original array to the useState array
            couponData.forEach(function (item, index) {
                tempCoupon.push(item)
            });

            setCoupon(tempCoupon);

            // if there are still a page to fetch
            if (couponTotalPage > 1) {
                // move current page to next one
                couponCurrentPage++

                // re-fetch next page
                couponData = await getOmsCouponList(storeId, couponCurrentPage);
            }

            // minus one page after one-page finish
            couponTotalPage--;

            setHasCoupon(true);
        }
    }


    // set coupon height when coupon is loaded
    useEffect(() => {
        try {
            setCouponHeight(document.getElementById('coupon-height').offsetHeight + 52);
        } catch (error) {
            setCouponHeight(0);
        }

    }, [hasCoupon]);


    // coupon badge component
    function CouponBadge() {
        return (
            <Popover
                placement="bottom"
                content={
                    <Coupon/>
                }
                title={
                    <h3 className={"color-shopmy-orange"} style={{textAlign: "center"}}>商铺优惠</h3>
                }
                id={"product-float-coupon"}
            >
                {coupon.map((couponBuilder, index) => {
                    return (
                        <div>
                            {
                                (couponBuilder.discountPrice === 0 && index < 6) &&
                                <span id={"coupon"}>{couponBuilder.discountRate}%OFF</span>
                            }

                            {
                                (couponBuilder.discountPrice !== 0 && index < 6) &&
                                <span id={"coupon"}>折 ${couponBuilder.discountPrice}</span>
                            }
                        </div>
                    );
                })}
            </Popover>
        );
    }

    // 判断优惠券折数
    function couponDiscountRate(discountRate) {
        var discount = 100 - discountRate;

        if (discount > 9 && discount % 10 === 0) {
            return discount.toString().slice(0, 1) + " 折";

        } else if (discount < 10) {
            return discountRate + "% OFF";
        }

        return discount + " 折"
    }


    // coupon component
    function Coupon() {
        return (
            coupon.map((couponBuilder, index) => {
                return (
                    <>
                        {
                            <Row className={"coupon-container"}>
                                <Col span={9} className={"coupon-price"}>
                                    {
                                        couponBuilder.discountRate !== 0
                                            ? couponDiscountRate(couponBuilder.discountRate)
                                            : "$" + couponBuilder.discountPrice
                                    }
                                </Col>
                                <Col span={15} className={"coupon-detail"}>
                                    <strong className={"coupon-title"}
                                            style={{fontSize: "14px", color: "#494949"}}>{couponBuilder.title}</strong>
                                    <p style={{fontSize: "12px", color: "#494949"}}>
                                        {
                                            couponBuilder.minSpendingPrice === 0
                                                ? "无门槛使用"
                                                : "满 $" + couponBuilder.minSpendingPrice + " 使用"
                                        }
                                        {
                                            couponBuilder.maxDiscountPrice !== 0 &&
                                            "，最高折 $" + couponBuilder.maxDiscountPrice
                                        }
                                    </p>
                                    <p style={{
                                        color: "#979797",
                                        fontSize: "12px",
                                        marginBottom: "6px"
                                    }}>有效期至 {unixDateToHumanDate(couponBuilder.endDate)}</p>

                                    {
                                        // isReceive (integer, optional): 是否已经领取（0：否、1：是） ,
                                        couponBuilder.isReceive === 0
                                            ? <Button
                                                type="primary"
                                                size={"small"}
                                                style={{width: "50%"}}
                                                value={couponRender}
                                                onClick={async () => {
                                                    var result = await doReceiveOmsCoupon(couponBuilder.id);
                                                    if (result.code === 200) {
                                                        message.success("领取成功");

                                                        // 重新加载优惠券
                                                        await fetchCoupon();
                                                        setCouponRender(couponRender + 1);

                                                    } else {
                                                        message.error(result.message);
                                                    }
                                                }}
                                            >
                                                领取优惠券
                                            </Button>

                                            : <Button
                                                size={"small"}
                                                style={{
                                                    width: "50%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                                                }}
                                                disabled={true}
                                            >
                                                已领取
                                            </Button>
                                    }
                                </Col>
                            </Row>
                        }
                    </>
                );
            })
        );
    }


    // -------------------------------- SKU controller ----------------------------------
    // 取规格的反灰情况
    // 这个是整理好的第一层和第二层规格数据，里面包含了是否要反灰
    const [displaySkuList1, setDisplaySkuList1] = useState([]);
    const [displaySkuList2, setDisplaySkuList2] = useState([]);
    // 当规格选项被配置完后，自动查找规格状态，同时请求预设值查找
    useEffect(() => {
        checkSkuStackStatus(skuOption1[0] + ",", true, false);
    }, [skuOption1]);

    useEffect(() => {
        checkSkuStackStatus(skuOption1[0] + "," + skuOption2[0], true, false);
    }, [skuOption2]);


    // 设置页面价格和库存
    const [defaultSkuList1, setDefaultSkuList1] = useState("");
    const [defaultSkuList2, setDefaultSkuList2] = useState("");
    const [productSkuStock, setProductSkuStock] = useState(0); // SKU 的库存
    const [productSkuPrice, setProductSkuPrice] = useState(0);// SKU 的金额
    useEffect(() => {


        if (defaultSkuList2 === "") {
            checkSkuOptionStatus(defaultSkuList1)
        } else {
            checkSkuOptionStatus(defaultSkuList1 + "," + defaultSkuList2)
            checkSkuStackStatus(defaultSkuList1 + "," + defaultSkuList2, true, false);
        }

    }, [defaultSkuList1, defaultSkuList2]);


    // 当前选择的库存表已经失效，需要更换成另一个
    const [requestChangeDefault, setRequestChangeDefault] = useState(false);
    useEffect(() => {

        if (requestChangeDefault) {

            // 先 polling 看当前主库存里的第二层有哪一个还有货，选第一个当作 default
            displaySkuList2.map((item, index) => {

                if (item.isSold !== true) {

                    form.setFieldsValue({
                        sku1: form.getFieldValue("sku1"),
                        sku2: item.name
                    });
                    checkSkuOptionStatus(form.getFieldValue("sku1") + "," + form.getFieldValue("sku2"));
                    setRequestChangeDefault(false);
                    return;
                }
            })

        }

    }, [requestChangeDefault]);

    // 切换规格时
    const onClickSkuRadio = async (value) => {
        var combineSkuName = null

        if (form.getFieldValue("sku2") === undefined) {
            combineSkuName = form.getFieldValue("sku1");
            form.setFieldsValue({
                sku1: form.getFieldValue("sku1")
            });


        } else {
            combineSkuName = form.getFieldValue("sku1") + "," + form.getFieldValue("sku2");
            form.setFieldsValue({
                sku1: form.getFieldValue("sku1"),
                sku2: form.getFieldValue("sku2")
            });
            checkSkuStackStatus(form.getFieldValue("sku1") + "," + form.getFieldValue("sku2"), false, true);
        }
        checkSkuOptionStatus(combineSkuName);

        // 最大购买库存变化时，自动重置购买数量输入框
        setAmountInputValue(1);
    };

    // 这边我们取得的是 SKU 层级的状态：
    // 由此可知，用户选择的层和名称的库存状态。库存不足可以反灰选择
    // 请传
    // natureName 选择的名称：string
    // doSetDefault 是否查找并赋予 default 选择：boolean
    // isClick 是否通过点击（更改sku radio）触发此方法：boolean（这个是为了避免第一次就开始检查规格是否失效）
    function checkSkuStackStatus(natureName, doSetDefault, isByClick) {

        var stack1Result = [];
        var stack2Result = [];

        // 检查所有 stack1，有没有所属的 stack2 全部售完：这边是用来反灰 stack1
        var doDefault1 = false;

        // 先进入第一层
        skuOption1.map((itemStack1, index) => {

            var tempResult = []; // 纪录数据
            var isAllSold = true;

            // 去找该一层对应的二层
            completeSkuList.map((itemSkuList, index) => {

                // 如果只有一层规格，就在第一层判断
                // 如果有第二层规格，我们就进入第二层判断
                if (skuOption2 == "") {

                    // 如果 rule list 的名字和 sku list match 后不是 null 代表找到对象
                    if (covertToASCII(itemSkuList.natureVal).match(covertToASCII(itemStack1)) !== null) {

                        // 检查售出数据，如果还有库存就把 isAllSold 改成 false，要不就代表都卖光了
                        if (itemSkuList.stockNums > 0) {
                            isAllSold = false;
                        }
                    }


                } else {

                    // 如果 rule list 的名字和 sku list match 后不是 null 代表找到对象
                    if (covertToASCII(itemSkuList.natureVal).match(covertToASCII(itemStack1 + ",")) !== null) {

                        // 检查售出数据，如果还有库存就把 isAllSold 改成 false，要不就代表都卖光了
                        if (itemSkuList.stockNums > 0) {
                            isAllSold = false;
                        }
                    }
                }
            })


            // 回圈结束，统计结果
            tempResult['name'] = itemStack1; // 第一层名称
            tempResult['isSold'] = isAllSold; // 第一层是否全部售完
            // 如果有库存，且没有配置过预设值，且呼叫的时候有要求请求预设值

            if (doDefault1 === false && doSetDefault === true && isAllSold === false) {
                form.setFieldsValue({
                    sku1: itemStack1
                });
                doDefault1 = true;
                setDefaultSkuList1(itemStack1)

            }

            stack1Result.push(tempResult);

        })


        // 分析用户传入的数据
        var natureNameToArray = natureName.split(",");
        // 拆成出第一层名称
        var stack1Name = natureNameToArray[0]

        // 检查该 stack1 的 stack2，有没有 stack 2 售完：这边是用来反灰 stack2
        // 先确认是否有第二层，没有的话不执行
        if (natureNameToArray.length === 2) {

            var doDefault2 = false;// 纪录数据

            // 去找该一层对应的二层
            completeSkuList.map((itemSkuList, index) => {
                var isSold = true; // 纪录数据
                var tempResult = []; // 纪录数据

                // 去大清单里找对应的第一层，如果匹配就不会是 null
                if (covertToASCII(itemSkuList.natureVal).match(covertToASCII(stack1Name + ",")) !== null) {

                    var splitNatureVal = itemSkuList.natureVal.split(",");


                    // 取对「第二层名称」
                    tempResult['name'] = splitNatureVal[1];


                    // 取对库存状态
                    if (itemSkuList.stockNums > 0) {
                        isSold = false;


                        // 如果有库存，且没有配置过预设值，且呼叫的时候有要求请求预设值
                        if (doDefault2 === false && doSetDefault === true) {
                            form.setFieldsValue({
                                sku2: splitNatureVal[1]
                            });
                            doDefault2 = true;
                            setDefaultSkuList2(splitNatureVal[1]);
                        }

                    } else {
                        // 如果第二层库存没货的话
                        // 预防性的检查当前的 natureName 是不是相符
                        // 如我是的话，要把当前的 natureName 换成有库存的
                        // 这边先标记起来，最后在 polling 来换 stack2 default
                        if (covertToASCII(natureName).match(covertToASCII(itemSkuList.natureVal)) !== null && isByClick === true) {
                            setRequestChangeDefault(true);
                        }

                    }

                    tempResult['isSold'] = isSold;
                    stack2Result.push(tempResult);
                }
            })


            // 如果有要求取预设值
            // 到了最后都没有 default，代表这个主库存里的子库存都没货了
            // 这时候先校验商品是否「已售完」，如果没有售完，默认以下一个主库存的第一子库存为 default
            if (product.productStatus !== 3 && doDefault2 === false && doSetDefault === true) {
                setDefaultSkuList2(skuOption2[0]);
            }

        }

        setDisplaySkuList1(stack1Result);
        setDisplaySkuList2(stack2Result);
    }

    // 这边我们取得的是 SKU 「选项」的状态：
    // 由此可知，用户选择的组合的「库存数」和「价钱」。
    function checkSkuOptionStatus(natureName) {
        // 去大清单，找对应 natureName 的数据
        // 并保存 price 和 amount
        completeSkuList.map((itemSkuList, index) => {

            // 如果匹配就不会是 null
            if (covertToASCII(natureName).match(covertToASCII(itemSkuList.natureVal)) !== null) {
                setProductSkuStock(itemSkuList.stockNums);
                setProductSkuPrice(itemSkuList.sellPrice);
            }
        })
    }


    // BUG FIXED: 20230422: 修复了「毛毡包+骰子」这种 SKU 名称无法匹配的问题
    // Convert string to ASCII
    // This is use for match "natureName" to "natureVal"
    // the reason we use ASCII is because .match() will convert parse in value to a "Regex"
    // that make we can't match symbol directly.
    //
    // For example, if "毛毡包+骰子" contains "+", there will be a miss match.
    // So we convert sku name to ASCII, and match it to ensure unexpect sku name.
    function covertToASCII(s) {
        var charCodeArr = [];
        for (let i = 0; i < s.length; i++) {
            let code = s.charCodeAt(i);
            charCodeArr.push(code);
        }
        return charCodeArr.toString();
    }


    // -------------------------------- Amount controller ----------------------------------
    // set controller
    const [amountInputValue, setAmountInputValue] = useState(0); //选择的库存数
    // onclick amount add/minus button
    const onClickAmountStepper = async (action) => {

        // 不可高过最高库存，不可低于 1
        if (action === "add" && amountInputValue < productSkuStock) {
            setAmountInputValue(amountInputValue + 1);

        } else if (action === "minus" && amountInputValue !== 1) {
            setAmountInputValue(amountInputValue - 1);

        }
    };


    // onchange amount on input directly
    const onChangeAmountInput = async (value) => {
        // 不可高过最高库存，不可低于 1
        if (amountInputValue !== 1 && amountInputValue <= productSkuStock) {
            setAmountInputValue(value);
        } else {
            setAmountInputValue(productSkuStock);
        }
    };


    // when amount increase or decrease refresh input
    useEffect(() => {
        form.setFieldsValue({
            amount: amountInputValue
        });

    }, [amountInputValue]);


    // sku 选择器
    function SkuController() {
        return (
            <Row id={"stock-bar"}>
                <Col span={4} className={"sub-heading"}>选择{skuOptionName1}</Col>
                <Col span={20}>
                    <Form.Item
                        name="sku1"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        style={{margin: "0px"}}
                    >

                        <Radio.Group onChange={onClickSkuRadio}>
                            {
                                displaySkuList1.map((item, index) => {
                                    return (
                                        <Radio.Button value={item.name}
                                                      disabled={item.isSold}>
                                            {item.name}
                                        </Radio.Button>
                                    );
                                })
                            }
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {
                    skuOption2 != "" &&
                    <>
                        <Col span={4} className={"sub-heading"}
                             style={{marginTop: "10px"}}>选择{skuOptionName2}</Col>
                        <Col span={20} style={{marginTop: "10px"}}>
                            <Form.Item
                                name="sku2"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{margin: "0px"}}
                            >
                                <Radio.Group onChange={onClickSkuRadio}>
                                    {
                                        displaySkuList2.map((item, index) => {
                                            return (
                                                <Radio.Button value={item.name}
                                                              disabled={item.isSold}>
                                                    {item.name}
                                                </Radio.Button>
                                            );
                                        })
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </>
                }

            </Row>
        );
    }



    // -------------------------------- Comment controller ----------------------------------
    // paging control
    const [commentTotalPage, setCommentTotalPage] = useState(0); // total data amount (not page amount)
    const [commentCurrentPage, setCommentCurrentPage] = useState(0);  // current page

    // filter control
    // 这个参数的 target.value 会存在以下几种情况
    // all
    // star5
    // star4
    // star3
    // star2
    // star1
    // img
    const [commentFilter, setCommentFilter] = useState("");


    async function fetchComment(index) {
        var showType = null; //展示类型（1：纯文本、2：图文）
        var star = null; //评论星级


        // 先判断筛选状况
        switch (commentFilter) {
            case "star5":
                star = 5;
                break;
            case "star4":
                star = 4;
                break;
            case "star3":
                star = 3;
                break;
            case "star2":
                star = 2;
                break;
            case "star1":
                star = 1;
                break;
            case "img":
                showType = 2;
                break;
            default:
        }


        const commentData = await getOmsProductCommentList(productId, index, star, showType)

        // get new fetched comment data
        const newComment = commentData.data

        const tempComment = [];

        // push new data into old data
        newComment.forEach(function (item, index) {
            tempComment.push(item)
        });

        setCommentList(tempComment);
        setCommentTotalPage(commentData.page.total);
        setCommentCurrentPage(commentData.page.index);
    }


    // 当筛选被改变
    useEffect(() => {
        setCommentList([]);
        fetchComment(1);
    }, [commentFilter]);


    // 当翻页按钮被点击
    function onPageChange(page) {
        fetchComment(page);
        // scroll to top of comment
        document.getElementById('product-comment').scrollIntoView();
    }

    // 点击查看评价
    function onClickViewComment() {
        // scroll to top of comme
        document.getElementById('product-comment').scrollIntoView();
    }


    // -------------------------------- Submit order controller ----------------------------------
    // 暂存用户的购买信息
    const [userOrderInfo, setUserOrderInfo] = useState({
        skuId: null,
        amount: null,
        action: null
    })

    // nature name 查 sku id
    function getSkuIdByNatureName(natureName) {
        // 拿 natureName 去查 skuId
        var skuId = null;
        completeSkuList.forEach(function (item, index) {
            if (item.natureVal === natureName) {
                skuId = item.id;
            }
        });
        return skuId;
    }

    // 从表单提交
    const onSubmitOrder = async (action) => {
        var natureName = form.getFieldValue("sku1");
        var amount = form.getFieldValue("amount");

        // 处理单规格的 nature name
        if (form.getFieldValue("sku2") !== undefined) {
            natureName = natureName + "," + form.getFieldValue("sku2");
        }

        // 拿 natureName 去查 skuId
        var skuId = getSkuIdByNatureName(natureName);

        // 暂存用户的购买信息
        setUserOrderInfo({
            skuId: skuId,
            amount: amount,
            action: action
        });


        // 判断是否提示用户商铺多天未上线
        if (product.isOffLineSellerTip === 1) {
            // 开启提示 modal，并让 modal 的 response (isOffLineSellerTipModalResponse) 去呼叫 submitOrder
            handleOffLineSellerTipModalOpen();

        } else {
            // 直接呼叫 submitOrder
            submitOrder(action, skuId, amount);
        }

    };


    // 商品的下单控制器
    async function submitOrder(action, productSkuId, productNum) {
        // 判断 加入购物车 还是 直接购买
        if (action === "cart") {
            // 处理报文
            const request = {
                "isSelected": 0,
                "productNum": productNum,
                "productSkuId": productSkuId,
                "productSpuId": productId
            }

            // 呼叫
            var result = await saveOmsOrderCar(request);

            // 处理结果
            if (result.code === 200) {
                messageApi.success("加入购物车成功");
            } else {
                messageApi.error(result.message);
            }


        } else if (action === "buy") {
            // 处理报文
            const products = [{
                "productSpuId": productId,
                "productSkuId": productSkuId,
                "productNum": productNum
            }]

            // 呼叫
            var result = await preOrder([], products);

            // 处理结果
            if (result.code === 200) {
                // 跳转到订单确认页面
                navigate("/checkout/confirm?id=" + result.data.id)
            } else {
                messageApi.error(result.message);
            }

        }
    }


    // 调用 sku 组件时的提交
    const onEditSkuList = async (action) => {
        var natureName = form.getFieldValue("sku1");

        // 处理单规格的 nature name
        if (form.getFieldValue("sku2") !== undefined) {
            natureName = natureName + "," + form.getFieldValue("sku2");
        }

        // 拿 natureName 去查 skuId
        var skuId = getSkuIdByNatureName(natureName);

        // 呼叫 props 传来的 cartItemOnChange
        props.cartItemOnChange(props.cartProduct.id, props.cartProduct.isSelected, 1, skuId, true);
    };


    // ------------------------ 提示多天未上线的 modal ---------------------------
    // modal controller
    const [isOffLineSellerTipModalOpen, setIsOffLineSellerTipModalOpen] = useState(false);

    // modal open
    const handleOffLineSellerTipModalOpen = () => {
        setIsOffLineSellerTipModalOpen(true);
    }

    function OffLineSellerTipModal(pros) {
        return (
            <Modal
                title="该商铺长时间未上线"
                open={isOffLineSellerTipModalOpen}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            setIsOffLineSellerTipModalOpen(false)
                        }}
                    >
                        取消
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setIsOffLineSellerTipModalOpen(false)
                            submitOrder(userOrderInfo.action, userOrderInfo.skuId, userOrderInfo.amount);
                        }}
                    >
                        继续结算
                    </Button>
                ]}

            >
                <p>此商铺超过 {product.offLineSellerDays} 天没有上线，建议通过私信功能与商铺取得联系再下单.</p>
            </Modal>

        );
    }


    // -------------------------------- Share ----------------------------------
    // share modal control
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    // share modal
    function ShareModal() {
        const handleCancel = () => {
            setIsShareModalOpen(false);
        };

        const shareLink = "https://share.shopmy.com.au?type=product&id=" + product.id;


        // 商品图卡的图片因为 CORS 问题，需要在 shopmy.com.au 或 shopmy.top 域才可以显示
        return (
            <Modal title="分享商品"
                   open={isShareModalOpen}
                   onCancel={handleCancel}
                   footer={[]}
                   width={600}
            >
                <div id={"share-card"}>
                    <div
                        style={{
                            backgroundImage: "url(" + productImgBase64 + ")",
                        }}
                        id={"product-img"}
                    >
                    </div>
                    <span id={"product-name"}>{product.productName}</span>
                    <span id={"product-price"}>{product.priceArea}</span>
                    <br/>
                    <QRCode value={shareLink} size={120}/>
                    <div
                        style={{
                            backgroundImage: "url(" + logoBase64 + ")",
                        }}
                        id={"logo"}
                    >
                    </div>
                </div>
                <div id={"share-card-btn"}>
                    <div
                        onClick={() => {
                            navigator.clipboard.writeText(shareLink);
                            message.success("已拷贝分享链接到剪贴板");
                        }}
                    >
                        <img src={varBucketURL + "/assets/icon-share-card-copy.svg"} width={"60px"}></img>
                        <span>拷贝分享链接</span>
                    </div>
                    <div
                        onClick={() => {
                            // 加载图卡用的加载动画
                            messageApi.open({
                                type: 'loading',
                                content: '正在生成图卡，请稍后...',
                                duration: 0,
                            });
                            // 这边用不正规的方式处理加载 spinner
                            $("#share-card-btn .ant-spin.ant-spin-spinning").attr("style", "display: block");

                            // 五秒后自动销毁加载动画
                            setTimeout(messageApi.destroy, 3000);


                            // 将图卡从 canvas 上转换成图片
                            html2canvas(
                                document.querySelector("#share-card"),
                                {
                                    allowTaint: true,
                                }
                            )
                                .then(canvas => {
                                    // create canvas in canvas container
                                    var canvasContainer = document.getElementById("canvas-container");
                                    canvasContainer.appendChild(canvas)

                                    /// create an "off-screen" anchor tag
                                    var lnk = document.createElement('a'), e;

                                    /// the key here is to set the download attribute of the a tag
                                    lnk.download = "share";

                                    /// convert canvas content to data-uri for link. When download
                                    /// attribute is set the content pointed to by link will be
                                    /// pushed as "download" in HTML5 capable browsers
                                    lnk.href = canvas.toDataURL("image/png;base64");

                                    /// create a "fake" click-event to trigger the download
                                    if (document.createEvent) {
                                        e = document.createEvent("MouseEvents");
                                        e.initMouseEvent("click", true, true, window,
                                            0, 0, 0, 0, 0, false, false, false,
                                            false, 0, null);

                                        lnk.dispatchEvent(e);
                                    } else if (lnk.fireEvent) {
                                        lnk.fireEvent("onclick");
                                    }

                                    // 这边用不正规的方式处理加载 spinner，关闭加载
                                    $("#share-card-btn .ant-spin.ant-spin-spinning").attr("style", "display: none !important;");


                                    // 销毁 canvas
                                    canvasContainer.removeChild(canvas);
                                });
                        }}
                    >
                        <img src={varBucketURL + "/assets/icon-share-card-download.svg"} width={"60px"}></img>
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 28,
                                    }}
                                    spin
                                />
                            }
                        />
                        <span>下载分享图卡</span>
                    </div>

                </div>

            </Modal>
        )
    }




    if (props.renderType === "product") {
        return (
            <Row className={"safe-area"} id={"product"}>
                {
                    isProductVisible === true
                        ?
                        <>
                            {contextHolder}
                            <ShareModal/>
                            <OffLineSellerTipModal/>
                            <Row className={"body container"} id={"info"}>
                                <Col span={9} id={"product-img"}>
                                    {
                                        // print product status, 1已上架 2已下架 3已售完 4已禁卖 5已删除
                                        product.productStatus === 3 &&
                                        <div className={"sold-out-tag"}>已售完</div>
                                    }
                                    <Carousel
                                        showArrows={true}
                                        showIndicators={false}
                                        showThumbs={true}
                                        useKeyboardArrows={true}
                                        stopOnHover={false}
                                        swipeable={true}
                                        dynamicHeight={false}
                                        emulateTouch={false}
                                        autoFocus={false}
                                        autoPlay={false}
                                        showStatus={false}
                                        infiniteLoop={true}
                                    >
                                        {
                                            productRotationImg.map((img, index) => {
                                                return (
                                                    <div>


                                                        {/* this is a temp img container to let for thumb */}
                                                        <img src={img.sourceUrl} className={"tempImg"}/>


                                                        {/* this is the real img provides preview functions */}
                                                        <Image src={img.sourceUrl} style={
                                                            product.productStatus === 3
                                                                ? {
                                                                    filter: "contrast(0.3)",
                                                                    WebkitFilter: "contrast(0.4)"
                                                                }
                                                                : {filter: "contrast(1)", WebkitFilter: "contrast(1)"}
                                                        }/>
                                                    </div>


                                                );
                                            })
                                        }
                                    </Carousel>
                                </Col>
                                <Col span={14} id={"product-info"}>
                                    <title>{product.productName}</title>
                                    <div id={"status-bar"}>
                    <span className={"tag"} id={"save-status-tag"}>
                {
                    product.saveStatus === 1
                        ? "全新"
                        : "二手"
                }
                    </span>
                                        <span className={"tag"} id={"sold-tag"}>已售出 {product.saleNum}</span>
                                        <span id={"avg-comment"} onClick={onClickViewComment}>商品评分</span><span
                                        id={"avg-point"}>
                {
                    commentStatistic.averageNumber >= 0 &&
                    commentStatistic.averageNumber.toFixed(1)
                }
                    </span>
                                    </div>
                                    <p id={"price"}>$ {productSkuPrice !== 0 && productSkuPrice.toFixed(2)}</p>
                                    <Divider/>
                                    <div id={"button"}>
                                        <Button
                                            type="text"
                                            value={product.id}
                                            onClick={async () => {
                                                if (isProductFavorite === 0) {
                                                    // 如果原本没有收藏，执行收藏指令
                                                    var result = await saveOmsProductFavorite(product.id)
                                                    if (result.code === 200) {
                                                        setIsProductFavorite(1)
                                                        message.success("收藏成功")
                                                    } else {
                                                        message.error(result.message)
                                                    }
                                                } else {
                                                    // 如果原本已经收藏，执行删除收藏指令
                                                    var result = await deleteLogicOmsProductFavorite([product.id])
                                                    if (result.code === 200) {
                                                        setIsProductFavorite(0)
                                                        message.success("取消收藏成功")
                                                    } else {
                                                        message.error(result.message)
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                isProductFavorite === 0
                                                    ? <><HeartOutlined/> 收藏</>
                                                    : <><HeartFilled/> 已收藏</>
                                            }

                                        </Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                            type="text"
                                            value={product.id}
                                            onClick={() => {
                                                setIsShareModalOpen(true);
                                            }}
                                        >
                                            <ShareAltOutlined/>
                                            分享
                                        </Button>
                                    </div>
                                    <Row id={"payment-method-bar"}>
                                        <Col span={4} className={"sub-heading"}>支付方式</Col>
                                        <Col span={20}>
                                            <img src={varBucketURL + "/assets/icon-mastercard.svg"}/>
                                            <img src={varBucketURL + "/assets/icon-visacard.svg"}/>
                                            <img src={varBucketURL + "/assets/icon-applepay.svg"}/>
                                            <img src={varBucketURL + "/assets/icon-googlepay.svg"}/>
                                            <img src={varBucketURL + "/assets/icon-wechat.svg"}/>
                                            <img src={varBucketURL + "/assets/icon-alipay.svg"}/>
                                        </Col>
                                    </Row>
                                    <Row id={"coupon-bar"}>
                                        <Col span={4} className={"sub-heading"}>领券</Col>
                                        <Col span={20} className={"sub-heading"}>
                                            {
                                                hasCoupon === true
                                                    ? <CouponBadge/>
                                                    : "唉呀！商铺没有发优惠～"
                                            }
                                        </Col>

                                    </Row>
                                    <Form
                                        name="buy"
                                        autoComplete="off"
                                        form={form}
                                    >
                                        <SkuController/>
                                        <Row id={"amount-bar"}>
                                            <Col span={4} className={"sub-heading"}>数量</Col>
                                            <Col span={20} className={"sub-heading"}>
                                                <Button size={"small"} type="primary" shape="circle"
                                                        icon={<MinusOutlined/>}
                                                        style={{marginRight: "7px"}}
                                                        onClick={() => onClickAmountStepper('minus')}/>
                                                <Form.Item
                                                    name={"amount"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    style={{margin: "0px"}}
                                                >
                                                    <InputNumber min={1} max={productSkuStock} controls={false}
                                                                 onChange={onChangeAmountInput}/>
                                                </Form.Item>
                                                <Button size={"small"} type="primary" shape="circle"
                                                        icon={<PlusOutlined/>}
                                                        style={{marginLeft: "7px"}}
                                                        onClick={() => onClickAmountStepper('add')}/>
                                                &nbsp;&nbsp;&nbsp;
                                                <span>还剩 {productSkuStock} 件库存</span>
                                            </Col>
                                        </Row>
                                        <Row id={"button-bar"}>
                                            <Form.Item>
                                                <Button size={"large"} type="primary" htmlType="cart"
                                                        onClick={() => onSubmitOrder('cart')}>加入购物车</Button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button size={"large"} type="primary" htmlType="buy"
                                                        onClick={() => onSubmitOrder('buy')}>立即购买</Button>
                                            </Form.Item>
                                        </Row>
                                    </Form>

                                </Col>
                            </Row>

                            <Row className={"body container"} id={"store"}
                                 style={{marginTop: "20px"}}>
                                <Col span={2} id={"store-image"}>
                                    <img src={sellerStore.storeIco}></img>
                                </Col>
                                <Col span={4} id={"store-name"}>
                                    <strong>{sellerStore.storeName}</strong>
                                </Col>
                                <Divider type="vertical" style={{height: "60px"}}/>
                                <Col span={5} id={"store-detail"}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <img src={varBucketURL + "/assets/icon-location.svg"}
                                             style={{width: "16px"}}></img>&nbsp;
                                        <span className={"color-shopmy-orange"}>商铺地区</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>{sellerStore.liaisonSuburb + " " + sellerStore.liaisonPostcode}</span>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                        <img src={varBucketURL + "/assets/icon-product-star.svg"}></img>&nbsp;
                                        <span className={"color-shopmy-orange"}>商铺评分</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>{
                                            sellerStore.score >= 0 &&
                                            sellerStore.score.toFixed(2)
                                        }</span>
                                    </div>
                                </Col>
                                <Col span={11} id={"store-button"}>
                                    <Button
                                        size={"large"}
                                        onClick={()=>{
                                            window.open("/chat/portal?conversationUserId=" + product.sellStoreVo.userId + "&conversationProductId=" + product.id, "_blank")
                                        }}
                                    >
                                        <span style={{fontSize: "17px"}}>
                                            <MessageFilled />
                                        </span>&nbsp;&nbsp;联络商铺
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button size={"large"} onClick={() => navigate("/store?id=" + product.sellerStoreid)}><span style={{fontSize: "17px"}}><HomeFilled /></span>&nbsp;&nbsp;进入商铺</Button>
                                </Col>
                            </Row>

                            <Row className={"body"} id={"detail"}>
                                <Col span={16}>
                                    <Row>
                                        <Col span={24} className="container" id={"product-detail"}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <img src={varBucketURL + "/assets/icon-order-orange.svg"}
                                                     width={"15px"}></img>&nbsp;&nbsp;
                                                <h3 className={"color-shopmy-orange"}>商品详情</h3>&nbsp;&nbsp;&nbsp;
                                                <span
                                                    style={{color: "#C4C4C4"}}>更新时间 {unixDateToHumanDate(product.updateDate)}</span>
                                            </div>
                                            <div id={"product-detail-context"}>
                                                <p>{product.productRemark}</p>
                                            </div>
                                            <div id={"product-detail-img"}>
                                                {
                                                    productDetailImg !== [] &&
                                                    productDetailImg.map((img, index) => {
                                                        return (
                                                            <>
                                                                <img src={img.sourceUrl}></img>
                                                            </>
                                                        );
                                                    })}

                                            </div>
                                            <br/><br/>

                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col span={24} className="container" id={"product-comment"}>
                                            <h3 className={"color-shopmy-orange"}><MessageFilled/>&nbsp;&nbsp;买家说
                                            </h3>
                                            <br></br>
                                            <div style={{
                                                borderRadius: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)"
                                            }}>
                                                <h2 style={{
                                                    textAlign: "center",
                                                    color: "#F46E12"
                                                }}>
                                                    <StarFilled/>&nbsp;&nbsp;{commentStatistic != 0 && commentStatistic.averageNumber.toFixed(1)}/5.0
                                                </h2>
                                                <Radio.Group defaultValue="all" size="large"
                                                             onChange={(event) => setCommentFilter(event.target.value)}>
                                                    <Radio.Button value="all">
                                                        <div className={"comment-radio"}>
                                                            <span>全部评价</span>
                                                            <span
                                                                className={"count"}>({commentStatistic.totalNumber})</span>
                                                        </div>
                                                    </Radio.Button>
                                                    <Radio.Button value="star5">
                                                        <div className={"comment-radio"}>
                                                            <div>
                                                                <StarFilled/><StarFilled/><StarFilled/><StarFilled/><StarFilled/>
                                                            </div>
                                                            <span
                                                                className={"count"}>({commentStatistic.fiveNumber})</span>
                                                        </div>

                                                    </Radio.Button>
                                                    <Radio.Button value="star4">
                                                        <div className={"comment-radio"}>
                                                            <div><StarFilled/><StarFilled/><StarFilled/><StarFilled/>
                                                            </div>
                                                            <span
                                                                className={"count"}>({commentStatistic.fourNumber})</span>
                                                        </div>

                                                    </Radio.Button>
                                                    <Radio.Button value="star3">
                                                        <div className={"comment-radio"}>
                                                            <div><StarFilled/><StarFilled/><StarFilled/></div>
                                                            <span
                                                                className={"count"}>({commentStatistic.threeNumber})</span>
                                                        </div>

                                                    </Radio.Button>
                                                    <Radio.Button value="star2">
                                                        <div className={"comment-radio"}>
                                                            <div><StarFilled/><StarFilled/></div>
                                                            <span
                                                                className={"count"}>({commentStatistic.twoNumber})</span>
                                                        </div>

                                                    </Radio.Button>
                                                    <Radio.Button value="star1">
                                                        <div className={"comment-radio"}>
                                                            <div><StarFilled/></div>
                                                            <span
                                                                className={"count"}>({commentStatistic.oneNumber})</span>
                                                        </div>

                                                    </Radio.Button>
                                                    <Radio.Button value="img">
                                                        <div className={"comment-radio"}>
                                                            <span>附上图片</span>
                                                            <span
                                                                className={"count"}>({commentStatistic.pictureNumber})</span>
                                                        </div>
                                                    </Radio.Button>
                                                </Radio.Group>
                                                <br></br>
                                            </div>
                                            <br></br><br></br>
                                            {
                                                commentList == ""
                                                    // : <NoDataPlaceholder/>
                                                    ? <NoData message={"暂时没有评价！"}/>
                                                    : commentList.map((builderComment, index) => {
                                                        return (
                                                            <Comment
                                                                actions={[
                                                                    <Button type="text"
                                                                            className={`upvote-btn ${builderComment.isUpvote === 1 ? "is-upvote" : "is-not-upvote"}`}
                                                                            onClick={async () => {

                                                                                var result = await doOmsProductCommentUpvote(builderComment.id)

                                                                                // 让用户按完赞后，立即看到效果
                                                                                // 排序问题 notion 有提交给后端，让他处理
                                                                                if (result.code === 200) {
                                                                                    fetchComment(commentCurrentPage);
                                                                                    message.success("成功")
                                                                                } else {
                                                                                    message.error(result.message)
                                                                                }
                                                                            }}>
                                                                        <LikeFilled/>
                                                                        {
                                                                            builderComment.upvoteNumber === 0
                                                                                ? "成为第一个点赞的人"
                                                                                : builderComment.upvoteNumber + "人赞过"
                                                                        }

                                                                    </Button>
                                                                ]}
                                                                author={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        <a style={{
                                                                            lineHeight: "1.5"
                                                                        }}>{hideCredentialString(builderComment.customerName)}</a>
                                                                        <Rate disabled
                                                                              defaultValue={builderComment.star}
                                                                              style={{
                                                                                  fontSize: "15px",
                                                                                  color: "#F46E12",
                                                                                  lineHeight: "1.3"
                                                                              }}/>
                                                                    </div>
                                                                }
                                                                avatar={<Avatar src={builderComment.customerHeadUrl}
                                                                                size={"large"}/>}
                                                                content={
                                                                    <div className={"comment-container"}>
                                                                        <p className={"detail"}>{unixDateToHumanDate(builderComment.updateDate)} /
                                                                            規格: {builderComment.productAttributes}</p>
                                                                        <p className={"context"}>{builderComment.content}</p>
                                                                        <div className={"image"}>
                                                                            {
                                                                                builderComment.files !== null &&
                                                                                builderComment.files.map((builderCommentImg, index) => {
                                                                                    return (
                                                                                        <Image
                                                                                            width={60}
                                                                                            src={builderCommentImg}
                                                                                            style={{marginRight: "10px"}}
                                                                                        />
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                        {
                                                                            builderComment.reply !== null &&
                                                                            <div
                                                                                className={"comment-container-store-reply"}>
                    <span className={"title"}>
                    商铺回复：
                    </span>
                                                                                <span className={"content"}>
                {builderComment.reply}
                    </span>
                                                                                <span className={"date"}>
                {unixDateToHumanDate(builderComment.replyDate)}
                    </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            >
                                                                <Divider/>
                                                            </Comment>

                                                        );
                                                    })

                                            }
                                            <br></br><br></br>
                                            <Pagination defaultCurrent={1} total={commentTotalPage}
                                                        current={commentCurrentPage} pageSize={5}
                                                        onChange={onPageChange}/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={8} className="container" id={"store-coupon"} style={{height: couponHeight}}>
                                    <div id={"coupon-height"}>
                                        <h3 className={"color-shopmy-orange"}
                                            style={{textAlign: "center"}}>商铺优惠</h3>
                                        <br></br>
                                        {
                                            hasCoupon === true
                                                ? <Coupon/>
                                                : <NoData message={"唉呀！商铺没有发优惠～"}/>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <div id={"canvas-container"}></div>

                            <BackToUp className={"back-to-top"}>
                                <Button type="primary" size={"large"}>
                                    <ArrowUpOutlined/>&nbsp;回到最上方
                                </Button>

                            </BackToUp>
                        </>
                        :
                        <>
                            <NotFound message={"找不到商品，或商品已失效或删除"}/>
                        </>
                }
            </Row>
        );

    } else if (props.renderType === "coupon") {
        return (
            <div
                id={"product-float-coupon"}
                style={{
                    padding: 0,
                    height: "calc(100% - 174px)",
                    marginLeft: "24px"
                }}>
                <Coupon/>
            </div>

        );

    } else if (props.renderType === "sku") {
        return (
            <div
                id={"product"}
            >
                <Form
                    id={"product-info"}
                    style={{
                        marginLeft: 0,
                        gap: "5px"
                    }}
                    autoComplete="off"
                    form={form}
                >
                    <SkuController/>
                    <br/>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button onClick={onEditSkuList} type={"primary"} style={{width: "126px"}}>确认修改</Button>
                    </div>

                </Form>
            </div>
        );
    }


}


export default Product;