import React, {useEffect} from "react";
import {Button, Col, Divider, Form, Input, Row,} from "antd";
import {getAppleUserInfo, getUserInfo, getUserSig, login} from "../data/Customer-Service-Repo";

import AppleLogin from 'react-apple-login'
import WechatLogin from "react-wechat-login";
import {useNavigate} from "react-router-dom";


const Login = (props) => {
    const navigate = useNavigate();

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.hash), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // click login on form
    const onClickLogin = async (values) => {
        const result = await login(values.username, values.password);

        // process login when it was success, otherwise pop error msg
        if(result.code === 200){
            doLogin(result.data);
        } else {
            alert(result.message)
        }
    };


    // detect is apple id_token got when reload
    useEffect(() => {
        async function load(){

            if(params.id_token != null){
                const result = await getAppleUserInfo(params.id_token);

                // process login when it was success, otherwise pop error msg
                if(result.code === 200){
                    doLogin(result.data.token)
                } else {
                    alert(result.message)
                }
            }
        }
        load();
    }, []);


    async function doLogin(token) {
        window.localStorage.setItem('Access-Token', token);

        // 设置用户
        const user = await getUserInfo(token, 'app');

        // convert to string in order to store in localstorage
        window.localStorage.setItem('User', JSON.stringify(user.data));

        // 设置腾讯 im sig
        const tencentImSig = await getUserSig(token, 'app');
        if(tencentImSig.code === 200){
            window.localStorage.setItem('Tencent-IM-Sig', tencentImSig.data);
        }

        // 如果在 Account 主部件有判断出转址需求就转到该地址，要不然就转到首页
        window.location.href = props.redirect;

    }




    return (
        <>
            <h2 style={{
                fontWeight: "400"
            }}>登录享买帐户</h2><br/>
            <Form
                name="basic"
                onFinish={onClickLogin}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号',
                        },
                    ]}
                >
                    <Input addonBefore="+61" placeholder="请输入手机号..." size="large"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码',
                        },
                    ]}
                >
                    <Input.Password placeholder="请输入密码..." size="large"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width:"100%"}}>
                        登录享买帐户
                    </Button>
                </Form.Item>
                <Row id={"feature-btn"}>
                    <Col span={12}>
                        <Button type="link" onClick={()=> navigate('/account/forgot-password')}>
                            忘记登录密码
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="link"
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                alignItems: "center"
                            }}
                            onClick={()=> navigate('/account/register')}
                        >
                            加入享买
                        </Button>
                    </Col>
                </Row>
                {/* 这边有一个问题，移动端的接口都不承认我新申请的 identifier，因此需要修改接口 */}
                {/*<Divider plain style={{color: "#b0b0b0"}}>或</Divider>*/}
                {/*<Row id={"social-login-btn"}>*/}
                {/*    <Col span={24}*/}
                {/*         style={{*/}
                {/*             display: "flex",*/}
                {/*             justifyContent: "center"*/}
                {/*         }}>*/}
                {/*        /!* TODO: Change to Prod once release*!/*/}
                {/*        <AppleLogin*/}
                {/*            clientId="com.nate.shopmy.desktop.buyer"*/}
                {/*            redirectURI="https://sandbox-buyer.shopmy.top"*/}
                {/*            responseType={"code id_token"}*/}
                {/*            responseMode={"fragment"}*/}
                {/*            usePopup={false}*/}
                {/*        />*/}

                {/*        /!* TODO: Change to Prod once release*!/*/}
                {/*        <WechatLogin*/}
                {/*            appid={YOUR_APP_ID}*/}
                {/*            redirectUri="http://localhost:4000/api/wechatlogin"*/}
                {/*            onSuccess={loginWechatRes}*/}
                {/*        />*/}
                {/*        */}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Divider dashed></Divider>
                <p style={{
                    color: "#b0b0b0",
                    textAlign: "center"
                }}>电脑版尚不支持社交帐号登录，请使用手机号和密码登录</p>
            </Form>
        </>);
}


export default Login;