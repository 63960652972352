import React, {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Button,
    Col,
    DatePicker,
    Divider,
    Form, Input,
    message, Modal,
    notification,
    Radio,
    Row, Tooltip,
    Upload,
} from "antd";
import {
    changeMobile,
    editUserInfo,
    getUserInfo,
    sendchangeMobileSmsCode,
    verifyWalletPassword
} from "../../../data/Customer-Service-Repo";
import Loading from "../../../components/Loading";
import dayjs from "dayjs";
import {getSocialLoginNameAndIcon} from "../../../data/tool";
import {uploadFilesSync} from "../../../data/User-Service-Repo";
import {CheckCircleFilled, EditOutlined, LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import Countdown from "react-countdown";

const UserProfile = (props) => {
    const [form] = Form.useForm();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // loading indicator
    const [loading, setLoading] = useState(false);


    // user storage
    const [user, setUser] = useState([]);

    // edit storage
    const [editedUser, setEditedUser] = useState({});
    const [requestSubmission, setRequestSubmission] = useState(0);

    // 取用户信息
    useEffect(() => {
        async function load() {
            // start loading
            setLoading(true);

            // get user data
            var result = await getUserInfo(localStorage.getItem("Access-Token"))

            if (result.code === 200) {
                setUser(result.data)

                // set user data
                form.setFieldValue("sex", result.data.sex)
                form.setFieldValue("birthday", dayjs(result.data.birthday))

                // set edited user data
                setEditedUser({
                    "birthday": result.data.birthday,
                    "headUrl": result.data.headUrl,
                    "name": result.data.name,
                    "sex": result.data.sex
                })

                setRequestSubmission(0)

                // close loading
                setLoading(false);

            } else {
                notificationApi.error({
                    message: '获取用户信息失败',
                    description: result.message,
                });
            }
        }

        load()
    }, []);


    // ---------------- 頭像 ----------------
    // upload loading
    const [uploadLoading, setUploadLoading] = useState(false);

    const handleAvatarChange = async (data) => {
        setUploadLoading(true);

        var result = await uploadFilesSync(data);

        // 成功
        if (result) {
            // 发报文更新后端
            editedUser.headUrl = result[0].url

            // manually submission, 因为这边需要回调重新加载页面
            var result = await editUserInfo(editedUser)

            // 显示成功
            notificationApi.success({
                message: '上传成功',
                description: '您的头像已经成功上传',
                placement: "bottomRight"
            });

            if (result.code === 200) {
                // 重新加载此页面
                window.location.reload();
            }

        }
        setUploadLoading(false);
    }


    // check selected file type
    const checkFileType = (file) => {
        const isPNG = file.type === 'image/png';
        const isJPEG = file.type === 'image/jpeg';

        if (!isPNG && !isJPEG) {
            message.error('您只可以上传图片（PNG, JPEG, JPG）');
            return Upload.LIST_IGNORE;
        } else {
            return true;
        }
    }


    // ---------------- 自动保存 ----------------
    // 当 editedUser 更动，且 requestSubmission > 0 时允许自动保存，避免保存到 initialization
    useEffect(() => {
        async function load() {
            if (requestSubmission > 0 && editUserInfo !== {}) {
                setSaveStatus(1)
                var result = await editUserInfo(editedUser)

                if (result.code === 200) {
                    setSaveStatus(2);
                }
            }
        }

        load();
    }, [requestSubmission]);

    // save status
    // 0:不动作、1: 保存中、2:已保存
    const [saveStatus, setSaveStatus] = useState(0);

    // UI component
    function SaveStatus() {
        switch (saveStatus) {
            case 0:
                return <span></span>;
            case 1:
                return <span className={"color-shopmy-orange"}><LoadingOutlined/> 正在保存变更...</span>;
            case 2:
                return <span className={"color-shopmy-orange"}><CheckCircleFilled/> 您的变更已被自动保存</span>;
        }
    }


    // ---------------- 操作弹出框（变更手机号/变更用户名称） ----------------
    const [modal, setModal] = useState({
        title: "",
        body: <></>,
        open: false
    });


    // ------ 变更用户名称 ------
    const [changeNameLoading, setChangeNameLoading] = useState(false);

    function onClickChangeName() {
        setModal({
            title: "变更用户名称",
            body:
                <>
                    <Form
                        autoComplete="off"
                        layout={"vertical"}
                        onFinish={async (values: any) => {
                            // start loading
                            setChangeNameLoading(true);

                            // change name
                            editedUser.name = values.name

                            // manually submission, 因为这边需要回调重新加载页面
                            var result = await editUserInfo(editedUser)


                            if (result.code === 200) {
                                // 显示成功
                                notificationApi.success({
                                    message: '变更成功',
                                    description: '您的用户名称已经成功变更',
                                    placement: "bottomRight"
                                });

                                // delay 2s
                                setTimeout(() => {
                                    // 重新加载此页面
                                    window.location.reload();
                                }, 2000);

                            } else {
                                notificationApi.error({
                                    message: '变更失败',
                                    description: result.message,
                                });
                            }

                            // stop loading
                            setChangeNameLoading(false);


                        }}
                    >
                        <Form.Item style={{marginBottom: "0px", color: "#868686"}}>
                            <p style={{margin: "0px"}}>用户名称规范:</p>
                            <ul style={{paddingLeft: "28px", marginTop: "5px"}}>
                                <li>6-12 个英文、数字、符号字元</li>
                                <li>名称不可包含「享买」、「SHOPMY」、「SHOP」、「MY」字词</li>
                                <li>每位用户不得重复</li>
                                <li>只可修改两次</li>
                            </ul>
                        </Form.Item>

                        <br/>
                        <Form.Item
                            name="name"
                            label="用户名称"
                            rules={[
                                {
                                    required: true
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        const regex = new RegExp('享买|SHOPMY|SHOP|MY', 'i');

                                        // 6-12 个英文、数字、符号字元, 名称不可包含「享买」、「SHOPMY」、「SHOP」、「MY」字词
                                        if (value.length < 6 || value.length > 12 || value.match(/^[a-zA-Z0-9]+$/) === null || value.match(regex) !== null) {
                                            return Promise.reject('请输入 6-12 个英文、数字、符号字元，名称不可包含「享买」、「SHOPMY」、「SHOP」、「MY」字词');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}
                        >
                            <Input
                                size={"large"}
                                placeholder={"请输入您的用户名称..."}
                            />
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{width: "100%"}} size={"large"}
                                    loading={changeNameLoading}>
                                确认送出
                            </Button>
                        </Form.Item>


                    </Form>
                </>,
            open: true
        })
    }


    // ------ 变更手机号 ------
    const [changePhoneLoading, setChangePhoneLoading] = useState(false);

    // 存储
    const [changePhoneRequest, setChangePhoneRequest] = useState({
        code: "",
        mobile: "",
        walletPassword: ""
    });

    // 验证码发送时间
    const [resendRepeatCount, setResendRepeatCount] = useState(0);
    const [resendRepeatTime, setResendRepeatTime] = useState();

    // 当前步骤
    const [currentStep, setCurrentStep] = useState(0);

    // 发送验证码
    async function sendChangePhoneSms(mobile) {
        var result = await sendchangeMobileSmsCode(mobile);

        if (result.code === 200) {
            notificationApi.success({
                message: '发送成功',
                description: '验证码已经发送到您的手机，请注意查收',
                placement: "bottomRight"
            });


            // 开始倒数
            setResendRepeatCount(resendRepeatCount + 1);

            // 设置重发时间
            // 如果用户重发三次，就让他等 15 分钟
            if (resendRepeatCount >= 3) {
                setResendRepeatTime(Date.now() + 900000);
            } else {
                setResendRepeatTime(Date.now() + 120000);
            }


            // 转到下一步
            changePhoneRequest.mobile = mobile;


            // 要强制 render
            setCurrentStep(4);
            onClickChangePhone(4);

            // delay 2s
            setTimeout(() => {
                setCurrentStep(3);
                onClickChangePhone(3);
            }, 100);


        } else {
            notificationApi.error({
                message: '发送失败',
                description: result.message,
                placement: "bottomRight"
            });
        }
    }


    // 呼叫 render 手机号更改 modal
    function onClickChangePhone(overrideStep) {
        // 如果有商铺要校验钱包密码，从 step1 开始。
        // 如果没有商铺则不用，从 step2 开始
        if (overrideStep !== 0) {
            setCurrentStep(overrideStep)
        } else if (user.storeId) {
            setCurrentStep(1)
        } else {
            setCurrentStep(2)
        }
    }

    useEffect(() => {
        // 避免一 render 就被执行
        if (currentStep > 0) {
            setModal({
                title: "变更用户名称",
                body:
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "100px",
                        }}
                        className={"color-shopmy-orange"}
                    >
                        <LoadingOutlined/>
                        <br/>
                        <span>&nbsp;图片上传中...</span>
                    </div>,
                open: true
            })

            setTimeout(() => {
                setModal({
                    title: "变更用户名称",
                    body:
                        <>
                            <br/>
                            {
                                currentStep === 1 &&
                                <>
                                    <Form
                                        autoComplete={"off"}
                                        layout={"vertical"}
                                        onFinish={async (values: any) => {
                                            // 验证钱包密码，正确才转到下一步
                                            var result = await verifyWalletPassword(values.password);

                                            if (result.code === 200) {
                                                changePhoneRequest.walletPassword = values.password;
                                                setCurrentStep(2);

                                                // recall to update modal
                                                onClickChangePhone(2)


                                                notificationApi.success({
                                                    message: '密码验证成功',
                                                    description: '您可以开始更改手机号，请输入新手机号',
                                                    placement: "bottomRight"
                                                });


                                            } else {
                                                notificationApi.error({
                                                    message: '钱包密码错误',
                                                    description: result.message,
                                                    placement: "bottomRight"
                                                });

                                            }
                                        }}
                                    >
                                        <Form.Item
                                            name="password"
                                            label="钱包密码"
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}
                                        >
                                            <Input.Password
                                                size={"large"}
                                                placeholder={"请输入您的钱包密码..."}
                                            />
                                        </Form.Item>

                                        <Alert
                                            message={
                                                <span>如果您已更改手机号且忘记钱包密码，请参考 <a className={"color-shopmy-orange"} href={"https://help.shopmy.com.au/kb/%ef%bc%88%e5%95%86%e9%93%ba%ef%bc%89%e5%bf%98%e8%ae%b0%e9%92%b1%e5%8c%85%e5%af%86%e7%a0%81%e4%b8%94%e5%b7%b2%e6%9b%b4%e6%8d%a2%e6%89%8b%e6%9c%ba%e5%8f%b7%e8%af%a5%e6%80%8e%e4%b9%88%e5%8a%9e%ef%bc%9f/"} target="_blank">帮助中心文章</a>，进行人工验证。</span>
                                            }
                                            type="warning"
                                            showIcon
                                        />
                                        <br/>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{width: "100%"}}
                                                    size={"large"}
                                                    loading={changePhoneLoading}>
                                                下一步
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            }

                            {
                                currentStep === 2 &&
                                <>
                                    <Form
                                        autoComplete={"off"}
                                        layout={"vertical"}
                                        onFinish={async (values: any) => {
                                            await sendChangePhoneSms(values.mobile);
                                        }}
                                    >
                                        <Form.Item
                                            name="mobile"
                                            label="请输入新手机号"
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}
                                        >
                                            <Input
                                                addonBefore="+61"
                                                size={"large"}
                                                placeholder={"请输入新手机号..."}
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{width: "100%"}}
                                                    size={"large"}
                                                    loading={changePhoneLoading}>
                                                下一步
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            }


                            {
                                currentStep === 3 &&
                                <>
                                    <Form
                                        autoComplete={"off"}
                                        layout={"vertical"}
                                        onFinish={async (values: any) => {
                                            var result = await changeMobile(changePhoneRequest.mobile, changePhoneRequest.code, changePhoneRequest.walletPassword);

                                            if (result.code) {
                                                // 显示成功
                                                notificationApi.success({
                                                    message: '手机号更改成功',
                                                    description: '您的手机号已经更改成功',
                                                    placement: "bottomRight"
                                                });

                                                // delay 200ms
                                                await new Promise(r => setTimeout(r, 200));

                                                // 关闭 modal
                                                setModal({
                                                    title: "",
                                                    body: <></>,
                                                    open: false
                                                })

                                                setChangePhoneRequest({
                                                    code: "",
                                                    mobile: "",
                                                    walletPassword: ""
                                                })

                                                //刷新页面
                                                window.location.reload();

                                            } else {
                                                notificationApi.error({
                                                    message: '手机号更改失败',
                                                    description: result.message,
                                                    placement: "bottomRight"
                                                });

                                            }
                                        }}
                                    >
                                        <Form.Item
                                            name="mobile"
                                            label="请输入验证码"
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}
                                        >
                                            <Input
                                                size={"large"}
                                                placeholder={"请输入验证码..."}
                                            />
                                        </Form.Item>

                                        <Countdown
                                            date={resendRepeatTime}
                                            renderer={({minutes, seconds, completed}) => {
                                                var isNotCompleted = true;
                                                var timer = "";

                                                if (completed) {
                                                    isNotCompleted = false;

                                                } else {

                                                    // display the common format 00:00:00 instead of 0:0:0
                                                    if (minutes < 10) {
                                                        minutes = minutes.toString().padStart(2, '0')
                                                    }
                                                    if (seconds < 10) {
                                                        seconds = seconds.toString().padStart(2, '0')
                                                    }

                                                    // Render a countdown
                                                    timer = minutes + ":" + seconds
                                                }

                                                return (
                                                    <Button
                                                        size="large"
                                                        style={{width: "100%", marginBottom: "10px"}}
                                                        disabled={isNotCompleted}
                                                        onClick={async () => {
                                                            await sendChangePhoneSms(changePhoneRequest.mobile) // 这时候就不用用即数据
                                                        }}
                                                    >

                                                        {
                                                            timer === ""
                                                                ? "再次发送验证码"
                                                                : "再次发送验证码（请等待 " + timer + " 秒）"
                                                        }
                                                    </Button>
                                                );
                                            }}
                                            key={resendRepeatCount}
                                        />
                                        <br/>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{width: "100%"}}
                                                    size={"large"}
                                                    loading={changePhoneLoading}>
                                                确认更改手机号
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            }
                        </>,
                    open: true
                })
            }, 100)
        }
    }, [currentStep])


    return (
        <>
            <Modal
                title={modal.title}
                footer={[]}
                open={modal.open}
                className={"user-profile-page-functional-popout"}
                onCancel={() => {
                    setModal({
                        title: "",
                        body: <></>,
                        open: false
                    })

                    setChangePhoneRequest({
                        code: "",
                        mobile: "",
                        walletPassword: ""
                    })
                }}
            >
                {modal.body}
            </Modal>


            {
                (loading || user === [])
                    ?
                    <Loading/>
                    :
                    <Row className={"profile-page-content-container"} id={"user-profile"}>
                        {notificationContextHolder}
                        <Col span={24}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <span className={"color-shopmy-orange"} style={{
                                    fontSize: "18px",
                                    color: "#494949",
                                    fontWeight: "500",
                                    marginBottom: "8px"
                                }}>个人信息管理
                                </span>
                                <span style={{
                                    color: "#9c9c9c",
                                    marginTop: "7px"
                                }}>为了保护你帐号的安全，请不要分享你的密码给其他人</span>
                                <Divider/>
                            </div>
                        </Col>

                        <Col span={11}>
                            <div style={{marginLeft: "20px"}}>
                                <Form
                                    autoComplete="off"
                                    form={form}
                                    layout="vertical"
                                >
                                    <Form.Item label="用户名称">
                                        <span>
                                            {
                                                user.name === null ? "您还没有设置用户名称" : user.name
                                            }
                                        </span>

                                        <Tooltip
                                            title={user.modifynameTimes > 0 && "已达到修改上限"}
                                            placement={"right"}
                                        >
                                            <Button
                                                type="link"
                                                className={"color-shopmy-orange"}
                                                onClick={onClickChangeName}
                                                disabled={user.modifynameTimes > 0 && true}
                                            >
                                                <EditOutlined/>变更
                                            </Button>
                                        </Tooltip>
                                    </Form.Item>


                                    <Form.Item
                                        label="性别"
                                        name="sex"
                                        defaultValue={user.sex}
                                    >
                                        <Radio.Group
                                            onChange={(e) => {
                                                editedUser.sex = e.target.value
                                                setRequestSubmission(requestSubmission + 1)
                                            }}
                                        >
                                            <Radio value={1}>男</Radio>
                                            <Radio value={2}>女</Radio>
                                            <Radio value={0}>其他</Radio>
                                            <Radio value={3}>不公开</Radio>
                                        </Radio.Group>
                                    </Form.Item>


                                    <Form.Item
                                        label="生日"
                                        name="birthday"
                                    >
                                        <div style={{marginBottom: "5px"}}></div>
                                        <DatePicker
                                            defaultValue={dayjs(user.birthday)}
                                            style={{width: "300px"}}
                                            size={"middle"}
                                            format={"YYYY-MM-DD"}
                                            allowClear={false}
                                            onChange={(date) => {
                                                editedUser.birthday = dayjs(date).valueOf()
                                                setRequestSubmission(requestSubmission + 1)
                                            }}
                                        />
                                    </Form.Item>


                                    <Form.Item label="手机号">
                                        <span>{user.areaCode + " " + user.mobile}</span>
                                        <Button
                                            type="link"
                                            className={"color-shopmy-orange"}
                                            onClick={() => onClickChangePhone(0)}
                                        >
                                            <EditOutlined/>变更
                                        </Button>
                                    </Form.Item>


                                    <Form.Item label="绑定的社交帐号" style={{marginBottom: "20px"}}>
                                        <div style={{marginBottom: "5px"}}></div>
                                        {getSocialLoginNameAndIcon(user)}
                                    </Form.Item>
                                </Form>
                            </div>
                            <br/>
                        </Col>

                        <Col span={1} style={{display: "flex", justifyContent: "center"}}>
                            {/*<Divider type={"vertical"} style={{height: "400px"}}/>*/}
                        </Col>


                        <Col span={11}>
                            <div className={"avatar-upload-container"}>
                                {
                                    uploadLoading
                                        ?
                                        <div className={"avatar-upload-loading"}>
                                            <LoadingOutlined/>
                                            <br/>
                                            <span>&nbsp;图片上传中...</span>
                                        </div>
                                        :
                                        <>
                                            <Upload
                                                customRequest={handleAvatarChange}
                                                beforeUpload={checkFileType}
                                            >
                                                <div className={"avatar-upload-btn"}>
                                                    <Avatar src={user.headUrl} size={100}
                                                            style={{marginBottom: "20px"}}/>
                                                    <Button icon={<UploadOutlined/>}>更换头像</Button>
                                                </div>
                                            </Upload>
                                            <br/><br/>
                                            <span className={"color-subtitle-grey"}>图片必须为 JPG/JPEG/PNG</span>
                                            <span className={"color-subtitle-grey"}>大小不得超过 10MB</span>
                                        </>
                                }
                            </div>
                        </Col>

                        {
                            saveStatus !== 0 &&
                            <Col span={24}>

                                <Divider/>
                                <SaveStatus/>
                            </Col>
                        }
                    </Row>
            }
        </>
    );
}


export default UserProfile;