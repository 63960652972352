import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Radio,
    Col,
    Pagination,
    Row,
    Skeleton,
    Input,
    Button,
    InputNumber,
    Form,
    Divider,
    Spin,
    Alert
} from "antd";

// data processor
import {Link, useNavigate} from "react-router-dom";
import {findSellStore, getProductTag, getSearchResult} from "../data/Product-Service-Repo";
import {LoadingOutlined, SortAscendingOutlined} from "@ant-design/icons";
import NoDataPlaceholder from "../components/NoDataPlaceholder.js";
import NoData from "../components/NoData";
import $ from "jquery";


/**
 *
 * Doc comment
 * Search.js 不仅仅是一个商品页面，同时他也用来提供「商品结果列表」的功能
 * 因为这个功能都需要用到搜索的数据，所以就放在这里了
 *
 * 在调用 Product Component 时，可以用 props "renderType" 来告诉组件你希望显示什么样式
 * renderType = "search" 时，显示「搜索页面」的搜索结果
 * renderType = "store" 时，显示「商铺页面」的商品结果
 *      storeId 为必须参数，用于指定商铺
 */
const Search_pg = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // product
    const [product, setProduct] = useState([]);
    const [productLength, setProductLength] = useState(0);
    const [productCurrentPage, setProductCurrentPage] = useState(0);

    // search query
    const [paramsData, setParamsData] = useState([]); // request json
    const [uri, setURI] = useState("");// uri of url
    const [ready, setReady] = useState(false); // set to true when request body ready
    const [run, setRun] = useState(false); // set to true when fetch data finished
    // 这个参数这叫「欠了几次更新」
    // 用于检测是否呼叫 navigate 方法刷新网页，刷新网页时扣掉，平时应该维持 0
    const [oweRerender, setOweRerender] = useState(0); // owe to fresh the data

    // sort
    const [sortValue, setSortValue] = useState("");

    // filter
    const [saveStatusCheckedList, setSaveStatusCheckedList] = useState([]); // current select of save status
    const [productTagCheckedList, setProductTagCheckedList] = useState([]); // current select of product select
    const [productTagOption, setProductTagOption] = useState([]); // product tag option
    const [maxPriceValue, setMaxPriceValue] = useState(""); // this value used to hold value on minPrice field
    const [minPriceValue, setMinPriceValue] = useState(""); // this value used to hold value on maxPrice field


    // load product using URL param once page load
    useEffect(() => {
        async function load() {
            if (props.token !== null) {
                // load result
                await setSearchRequest(null, null, null, null, null, null, null, null, null, null)
            }
        }

        load();
    }, [props.token]);

    // load once params change
    useEffect(() => {
        async function load(){
            if (props.token !== null) {
                // load search result
                fetchData(paramsData);
            }
        }

        load();
    }, [paramsData]);


    // load once rerender request
    useEffect(() => {
        if (oweRerender > 0) {
            navigate("/proxy");

            setTimeout(() => {
                navigate(uri, { replace: true });
            }, 100);
            setOweRerender(oweRerender-1)
        }
    },[oweRerender]);



    // 制作报文
    // DO NOT REMOVE "tempSecondStairId" cuz it is a part of constructor. The system will CRASH if you remove it
    async function setSearchRequest(tempIndex, tempKey, tempProductTags, tempSaveStatus, tempMinPrice, tempMaxPrice, tempFirstStairId, tempSecondStairId, tempSellerStoreid, tempOrderBy) {

        // 基础 uri
        var uri = "";

        // 判断一下是属于 store 还是 search
        if(props.renderType === "search"){
            uri = '/search?';

        } else if (props.renderType === "store"){
            uri = '/store?id=' + props.storeId + '&';
        }


        // make url
        // check state first
        if(tempIndex !== null){
            uri += ('index=' + tempIndex);
            setProductCurrentPage(tempIndex);
        } else {
            // if there are no state get param
            if(params.index === null){
                uri += ('index=' + 1);
                tempIndex = 1;
                setProductCurrentPage(1);

            // if there are no param use default
            } else {
                uri += ('index=' + params.index);
                tempIndex = params.index;
                setProductCurrentPage(params.index);
            }
        }

        if (params.key !== null)
            uri += ("&key=" + params.key)
            tempKey = params.key


        if (tempProductTags !== null){
            uri += ("&productTags=" + tempProductTags)
            setProductTagCheckedList(tempProductTags.split(","));

        } else {
            // if there are param data
            if(params.productTags === "" || params.productTags === "null"){
                uri += ("&productTags=null")
                tempProductTags = null

            } else if(params.productTags !== null){
                uri += ("&productTags=" + params.productTags)
                tempProductTags = params.productTags
                setProductTagCheckedList(tempProductTags.split(","));

            }
        }



        if (tempSaveStatus!== null){
            uri += ("&saveStatus=" + tempSaveStatus)
        } else {
            // this means user unchecked the checkbox, so disable filter on saveStatus
            if(params.saveStatus !== "0"){
                // otherwise give take the params from uri
                uri += ("&saveStatus=" + params.saveStatus)
                tempSaveStatus = params.saveStatus
            }
        }

        // set for display check status on checkbox
        if (tempSaveStatus === "1") {
            setSaveStatusCheckedList(['全新']);
        } else if (tempSaveStatus === "2") {
            setSaveStatusCheckedList(['二手']);
        } else {
            setSaveStatusCheckedList([]);
        }

        // both zero means been clear
        if(tempMinPrice !== 0 && tempMaxPrice !== 0){
            if(tempMinPrice !== null){
                uri += ("&minPrice=" + tempMinPrice)
            } else {
                if (params.minPrice !== null) {
                    uri += ("&minPrice=" + params.minPrice)
                    tempMinPrice = params.minPrice
                }
            }
            // once initial value, set value to input
            if(tempMinPrice !== null){
                setMinPriceValue(tempMinPrice);
            }


            if(tempMaxPrice !== null){
                uri += ("&maxPrice=" + tempMaxPrice)
            } else {
                if (params.maxPrice !== null){
                    uri += ("&maxPrice=" + params.maxPrice)
                    tempMaxPrice = params.maxPrice
                }
            }
            // once initial value, set value to input
            if(tempMaxPrice !== null){
                setMaxPriceValue(tempMaxPrice);
            }

        } else if(tempMinPrice >= 0 && tempMaxPrice > 0){
            // 如果 max > 0，但是 min 是 0，代表用户实际想输入的范围是 0~max，因此不将 min 视为 null
            // 这边我分开写，避免混乱
            uri += ("&minPrice=" + tempMinPrice)
            setMinPriceValue(tempMinPrice);

            uri += ("&maxPrice=" + tempMaxPrice)
            setMaxPriceValue(tempMaxPrice);
        }



        // 不管是主类目还是子类目，都统一传 firstStairId
        if (params.firstStairId !== null || tempFirstStairId !== null){
            uri += ("&firstStairId=" + params.firstStairId)
            tempFirstStairId = params.firstStairId
        }


        if (params.sellerStoreId !== null){ // 先查 params 是否有 sellerStoreid
            uri += ("&sellerStoreId=" + params.sellerStoreId)
            tempSellerStoreid = params.sellerStoreId

        } else if (props.storeId){ // 再查商铺页面的 props 是否有 id
            uri += ("&sellerStoreId=" + props.storeId)
            tempSellerStoreid = props.storeId

        }


        if(tempOrderBy !== null){
            // give a default value by state
            uri += ('&orderBy=' + tempOrderBy);
            setSortValue(tempOrderBy);
        } else {
            if(params.orderBy === null){
                // give a default value if there are no sort
                setSortValue("is_top-desc");
            } else {
                // otherwise give take the params from uri
                uri += ("&orderBy=" + params.orderBy)
                tempOrderBy = params.orderBy
                setSortValue(params.orderBy);
            }
        }

        // make request json
        const request = {
            "page": {
                "index": tempIndex,
                "paging": true,
                "size": 60
            },
            "productName": tempKey,
            "productTags": tempProductTags,
            "saveStatus": tempSaveStatus,
            "minPrice": tempMinPrice,
            "maxPrice": tempMaxPrice,
            "sellerStoreid": tempSellerStoreid,
            "productStairCategoryId": tempFirstStairId,
            "orderBy": tempOrderBy
        }

        //set request json and uri
        setParamsData(request);
        setURI(uri);
        setReady(true);
    }


    // This is use for force re-rendering on product tag
    const [rerender, setRerender] = useState(0);

    async function fetchData(request) {
        if (ready === true && run === false) {
            // load result
            let data = await getSearchResult(request);

            // set page
            setProductLength(data.page.total);

            // if there are data, put into array
            if (data.page.total !== 0) {
                let plainData = []
                plainData = data.data

                plainData.forEach(function (item, index) {
                    product.push(item)
                });
            }
            setProductCurrentPage(data.page.index);
            setRun(false);



            // load product tag for filter
            let data1 = await getProductTag();
            // if there are data, put into array
            let plainData = []
            plainData = data1.data


            // 暂存
            var tempProductTagOption = [];
            plainData.forEach(function (item, index) {
                tempProductTagOption.push(item["tag"])
            });
            setProductTagOption(tempProductTagOption);


            setRerender(1)
        }
    }


    // when move to next/previous page
    const onChangeProductPage = async (pageNumber) => {
        await setSearchRequest(pageNumber, params.key, null, null, null, null, null, null, null, null)
        setOweRerender(oweRerender+1)
    };

    // when sort click
    const onChangeSortOption = async (e) => {
        // reset page avoid out of range
        await setSearchRequest(1, params.key, null, null, null, null, null, null, null, e.target.value);
        setOweRerender(oweRerender + 1);
        setSortValue(e.target.value);
    };


    // when click or change save status
    const onChangeSaveStatus = async (e) => {
        // convert to number
        var status = 0;
        var list = "";

        if(saveStatusCheckedList !== []){
            list = saveStatusCheckedList.join("")
        }

        // if re-click then remove current clicked state
        if (list === e.target.value) {
            setSaveStatusCheckedList([]);

        } else {
            //identify which is click and put it to group
            if (e.target.value === "全新") {
                setSaveStatusCheckedList(['全新'])
                saveStatusCheckedList.push(['全新'])
                status = 1;

            } else if (e.target.value === "二手") {
                setSaveStatusCheckedList(['二手'])
                saveStatusCheckedList.push(['二手'])
                status = 2;
            }
        }

        // reset page avoid out of range
        await setSearchRequest(1, params.key, null, status, null, null, null, null, null, null);
        setOweRerender(oweRerender + 1);
    };


    // when click or change product tag
    const onChangeProductTag = async (list) => {
        // set checked value
        setProductTagCheckedList(list);

        var listString = null;

        // avoid system empty error

        if (list !== []) {
            listString = list.join(",");
        }

        await setSearchRequest(1, params.key, listString, null, null, null, null, null, null, null);
        setOweRerender(oweRerender + 1);
    };


    // when submit price filter
    const onFinishPrice = async (values) => {
        await setSearchRequest(1, params.key, null, null, values.minPrice, values.maxPrice, null, null, null, null);
        setOweRerender(oweRerender + 1);
    };
    // when reset price filter
    const onResetPrice = async () => {
        form.resetFields();
        await setSearchRequest(1, params.key, null, null, 0, 0, null, null, null, null);
        setOweRerender(oweRerender + 1);
    };


    // insert value to price input after refresh
    React.useEffect(() => {
        // both zero means been clear
        if(minPriceValue !== 0 && maxPriceValue !== 0){
            form.setFieldsValue({
                minPrice: minPriceValue,
                maxPrice: maxPriceValue
            });
        }

    }, [minPriceValue, maxPriceValue]);


    // set when to display
    const [display, setDisplay] = useState({
        loading: "flex",
        noData: "none"
    });
    useEffect(() => {
        setTimeout(function () {
            setDisplay({
                loading: "none",
                noData: "block"
            })
        }, 2000)
    }, []);


    async function clearAllFilter() {
        form.resetFields();

        await setSearchRequest(1, params.key, null, null, 0, 0, null, null, null, null);
        setOweRerender(oweRerender + 1);
    }

    function SearchResult(){
        return(
            <>
                <Row className={"container body"} style={{display: "flex"}}>
                    {/* rendering product */}
                    {
                        productLength !== 0
                            ?
                            <>
                                {product.map((builderProduct, index) => {
                                    return (
                                        <Col className={"item"} span={5}>
                                            <Link to={"/product?id=" + builderProduct.id}>
                                                <div className={"tag"}>

                                                    {
                                                        // print product tag if there are one
                                                        builderProduct.productTag != null &&
                                                        <p className={"product-tag"}>{builderProduct.productTag}</p>
                                                    }

                                                    <p className={"status-tag"}>
                                                        {
                                                            // print save status, 1: 全新，2: 二手
                                                            builderProduct.saveStatus === 1
                                                                ? "全新"
                                                                : "二手"
                                                        }
                                                    </p>

                                                </div>
                                                <div>
                                                    <img src={builderProduct.productImg} style={
                                                        builderProduct.productStatus === 3
                                                            ? {filter: "contrast(0.3)" , WebkitFilter : "contrast(0.4)"}
                                                            : {filter: "contrast(1)", WebkitFilter : "contrast(1)"}
                                                    }></img>
                                                    <Skeleton.Image active={true} className={"skeleton"}/>
                                                    {
                                                        // print product status, 1已上架 2已下架 3已售完 4已禁卖 5已删除
                                                        builderProduct.productStatus === 3 &&
                                                        <div className={"sold-out-tag"}>已售完</div>
                                                    }
                                                </div>
                                                <title>{builderProduct.productName}</title>
                                                <Row className={"detail"}>
                                                    <Col className={"price"} span={12}>${builderProduct.priceArea.substring(0, builderProduct.priceArea.indexOf('--'))}</Col>
                                                    <Col className={"sold"} span={12}>已售出 {builderProduct.saleNum}</Col>
                                                </Row>
                                            </Link>
                                        </Col>
                                    );
                                })}
                            </>
                            :
                            <>
                                <div
                                    id={"loading"}
                                    style={{
                                        width: "1255px",
                                        display: display.loading,
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "500px"
                                    }}
                                >
                                    {<Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin/>}/>}
                                    <h6>加载中...</h6>
                                </div>

                                <div
                                    style={{
                                        display: display.noData,
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <NoData message={"找不到该关键词，换一个尝试吧！"}/>
                                </div>

                            </>
                    }

                </Row>
                {
                    productLength !== 0 &&
                    <Pagination showQuickJumper current={productCurrentPage} defaultCurrent={1} defaultPageSize={60} total={productLength} showSizeChanger={false} onChange={onChangeProductPage}/>
                }
            </>
        );
    }

    function SearchSort(){
        return(
            <Row className={"sort"} >
                <Col span={3}>
                    <strong style={{display:"flex", alignItems: "center", fontSize: "16px", color: "#464646", fontWeight:"600"}}>&nbsp;&nbsp;商品排序&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </Col>
                <Col span={15}>
                    <Radio.Group onChange={onChangeSortOption} currentValue="create_date-desc"  value={sortValue} buttonStyle="solid">
                        <Radio.Button value="is_top-desc">综合排名</Radio.Button>
                        <Radio.Button value="sale_Num-desc">最热销</Radio.Button>
                        <Radio.Button value="create_date-desc">最新上架</Radio.Button>
                        <Radio.Button value="minprice-asc">价格低到高</Radio.Button>
                        <Radio.Button value="minprice-desc">价格高到低</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col span={6} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    {
                        productLength !== 0 &&
                        <Pagination className={"top"} simple current={productCurrentPage} defaultCurrent={2} defaultPageSize={60} total={productLength} onChange={onChangeProductPage} />
                    }
                </Col>
            </Row>
        );
    }

    // 依照不同的 renderType 返回
    if(props.renderType === "search") {
        return (
            <Row className={"safe-area"} style={{marginBottom: "60px"}}>
                <Row id="search-product">
                    {/* 1/4 search and 3/4 product*/}
                    <Col span={4} id={"filter"}>
                        <div>
                            <h3 style={{display:"flex", alignItems: "center", fontSize: "16px", color: "#464646", fontWeight: "600"}}>商品筛选&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
                            <br></br>
                        </div>
                        {
                            (params.sellerStoreId !== null || params.firstStairId !== null) &&
                                <>
                                    <div>
                                        <strong>
                                            {
                                                // 商铺类目搜索
                                                params.sellerStoreId !== null && params.firstStairId !== null &&
                                                "当前的商铺类目筛选"
                                            }
                                            {
                                                // 商铺搜索
                                                params.sellerStoreId !== null && params.firstStairId === null &&
                                                "当前的商铺筛选"
                                            }
                                            {
                                                // 商铺类目搜索
                                                params.sellerStoreId === null && params.firstStairId !== null &&
                                                "当前的类目筛选"
                                            }
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </strong>
                                        <Alert message="搜索结果将会被影响" type="warning" style={{marginBottom: "10px"}} />
                                        <Checkbox checked={true} onChange={clearAllFilter}>{params.prefix}</Checkbox>
                                        <Button htmlType="button" onClick={clearAllFilter} style={{marginTop: "10px", width: "100%"}}>清除</Button>
                                    </div>
                                    <Divider />
                                </>
                        }


                        <div>
                            <strong>价格区间设置</strong>
                            <Form
                                name="basic"
                                onFinish={onFinishPrice}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item>
                                    <Row gutter={24} style={{display: "flex"}}>

                                        <Col span={9}>
                                            <Form.Item
                                                name="minPrice"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入..',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            // start detect once two input field are fill
                                                            if (value >= getFieldValue("maxPrice") && getFieldValue("maxPrice") != 0) {
                                                                return Promise.reject("必须较低"); // The validator should always return a promise on both success and error
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        }
                                                    })
                                                ]}
                                            >
                                                <InputNumber prefix="$" min={0} max={999999999} placeholder="最低" id={"minPriceInput"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1} id={"divider"}>
                                            &nbsp;&nbsp;-
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                name="maxPrice"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入..',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            // start detect once two input field are fill
                                                            if (value <= getFieldValue("minPrice") && getFieldValue("minPrice") != 0) {
                                                                console.log(getFieldValue("minPrice"));
                                                                return Promise.reject("必须较高"); // The validator should always return a promise on both success and error
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        }
                                                    })
                                                ]}
                                            >
                                                <InputNumber prefix="$" min={0} max={999999999} placeholder="最高" id={"maxPriceInput"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Button type="primary" htmlType="submit" style={{marginTop: "10px", width: "100%"}}>套用</Button>
                                <Button htmlType="button" onClick={onResetPrice} style={{marginTop: "10px", width: "100%"}}>清除</Button>
                            </Form>
                        </div>
                        <Divider />

                        <div>
                            <strong>商品保存状况</strong>
                            <Checkbox.Group value={saveStatusCheckedList}>
                                <Checkbox value="全新" onChange={onChangeSaveStatus}>全新</Checkbox>
                                <Checkbox value="二手" onChange={onChangeSaveStatus}>二手</Checkbox>
                            </Checkbox.Group>
                        </div>
                        <Divider />

                        <div>
                            <strong>商品标签（可多选）</strong>
                            <Checkbox.Group id="product-tag" options={productTagOption} value={productTagCheckedList} onChange={onChangeProductTag} indeterminate/>
                        </div>

                    </Col>

                    <Col span={19} className={"list-product"}>
                        <SearchSort/>
                        <SearchResult/>
                    </Col>

                </Row>
            </Row>
        );

    } else if (props.renderType === "store"){
        return (
            <>
                <SearchSort/>
                <SearchResult/>
            </>
        )
    }


}


export default Search_pg;