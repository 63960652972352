import React, {useEffect, useState} from "react";
import {Col, Pagination, Row, Skeleton, Tabs} from "antd";
import {findMyOmsCouponList} from "../../data/Order-Service-Repo";
import {bucketURL} from "../../data/env";
import NotFound from "../../components/NotFound";
import GlobalCouponUIBuilder from "../../components/GlobalCouponUIBuilder";
import NoData from "../../components/NoData";

const Coupon = (props) => {

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // coupon data
    const [coupon, setCoupon] = useState([]);

    // current page
    const [page, setPage] = useState(1);

    // current tab
    const [tab, setTab] = useState(1);

    // total date
    const [total, setTotal] = useState(0);


    // isLoading
    const [isLoading, setIsLoading] = useState(true);



    // trigger when tab or page change
    useEffect(() => {
        fetchCoupon(tab);
        console.log("tab: " + tab);
    }, [tab, page])

    async function fetchCoupon(queryType) {
        setIsLoading(true)

        var result = await findMyOmsCouponList(queryType, page);

        if(result.code === 200){
            setCoupon(result.data);
            setTotal(result.page.total);
        }

        // 设置一下延迟，避免页面闪来闪去
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }




    function CouponBuilder(props){
        return(
            <>
                {
                    isLoading
                        ?
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {
                                // loop twice
                                [1,2,3,4].map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundImage: `url(${varBucketURL}/assets/background_checkout_store_2.svg)`,
                                                height: "118px",
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                                backgroundSize: "100%",
                                            }}
                                            className={"coupon-container"}
                                        >
                                            <div
                                                style={{
                                                    margin: "0px 46px 0px 46px"
                                                }}
                                            >
                                                <Skeleton.Avatar active size={55} style={{marginBottom: "4px"}}/>
                                                <Skeleton active paragraph={false}/>
                                            </div>
                                            <Skeleton.Input active size={60}/>
                                            &nbsp;&nbsp;&nbsp;
                                            <Skeleton.Input active size={60}/>
                                            &nbsp;&nbsp;
                                            <div
                                                style={{
                                                    marginRight: "30px",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <Skeleton.Button active size={30}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        :
                        <>
                            {
                                coupon.length > 0
                                    ?
                                    <div>
                                        <br/>

                                        {
                                            coupon.map((item, index) => {
                                                return (
                                                    <GlobalCouponUIBuilder
                                                        source={"coupon-list"}
                                                        coupon={item}
                                                        status={props.status}
                                                    />
                                                )
                                            })
                                        }
                                        <br/>
                                        <Pagination
                                            defaultCurrent={1}
                                            total={total}
                                            onChange={(page) => {
                                                setPage(page);
                                            }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}
                                            pageSize={10}
                                            current={page}
                                        />
                                    </div>

                                    :
                                    <NoData message={"暂时没有优惠券"}/>
                            }
                        </>
                }
            </>
        );
    }


    useEffect(() => {
        console.log("coupon: ", coupon);
    }, [coupon]);


    return (
        <Row className={"profile-page-content-container"} id={"my-coupon-page"}>
            <Col span={24}>
                <h4 className={"color-shopmy-orange"} style={{margin: "0"}}>我的优惠券</h4>
                <Tabs
                    style={{
                        width: "80%",
                        margin: "auto",
                    }}
                    size={"middle"}
                    defaultActiveKey="1"
                    centered
                    destroyInactiveTabPane
                    items={[
                        {
                            key: '1',
                            label: `未使用的优惠券`,
                            children:
                                <CouponBuilder status={"available"}/>
                            ,
                        },
                        {
                            key: '2',
                            label: `已使用的优惠券`,
                            children:
                                <CouponBuilder status={"unavailable"}/>
                            ,
                        },
                        {
                            key: '3',
                            label: `已过期的优惠券`,
                            children:
                                <CouponBuilder status={"available"}/>
                            ,
                        },
                    ]}
                    onTabClick={(key) => {
                        setTab(key);
                        setPage(1);
                    }}
                />
            </Col>
        </Row>
    );
}


export default Coupon;