import React, {useEffect, useState} from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { CSSObject } from '@emotion/serialize';
import { ClearIndicatorProps } from 'react-select';


/**
 *
 * Doc comment
 * 地址自动填写，请用 props 传以下参数
 * addressValue: 地址值
 * onAutoCompletedSelected: 地址选中后的回调函数
 */
const AddressAutocomplete = (props) => {

    const ClearIndicatorStyles = (
        base: CSSObject,
        state: ClearIndicatorProps<ColourOption>
    ): CSSObject => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused ? 'blue' : 'black',
    });


    const [addressValue, setAddressValue] = useState(null);
    useEffect(() => {
        setAddressValue(props.addressValue)
    }, [props.addressValue])


    return (
        <GooglePlacesAutocomplete
            apiKey={"AIzaSyA6coDKHH7RZH37HlkilcM896yq11R1Dhs"}
            apiOptions={{
                language: 'en'
            }}
            autocompletionRequest={{
                // location: [-38.050667, 144.865566],
                bounds: [
                    { lat: -38.050667, lng: 144.865566 }
                ],
                componentRestrictions: {
                    country: ['au'],
                },
                radius: 66000,
                types: ['address'],
            }}
            selectProps={{
                placeholder: '请开始输入地址 (不包含 Unit/Flat/Apt)...',
                addressValue,
                onChange: props.onAutoCompletedSelected,
                escapeClearsValue: true,
                isClearable: true
            }}
            styles={{ clearIndicator: ClearIndicatorStyles }}
            withSessionToken={true}
        />
    );
}


export default AddressAutocomplete;