import axios from "axios";
import beforeReturn from "./apiControllers";

// --- Constants ----------------
const API_HOST = "https://api.shopmy.com.au/api/v1";
const SERVICE_NAME = "/ps";

axios.defaults.headers.common['X-Auth-Lang'] = `zh`;
axios.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('Access-Token');
axios.defaults.headers.common['X-Auth-Type'] = `app`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = `localhost`;

// 取得首頁類目
async function getCategory() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscategory/getAppCategoryList");

    beforeReturn(response.data);
    return response.data;
}


// 取得首頁商品推薦
async function getRecommendProduct(index) {
    const request = {
        "page": {
            "index" : index
        }
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/homeproduct/findHomeProductList", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得搜索结果
async function getSearchResult(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproduct/searchProductList", request);

    beforeReturn(response.data);
    return response.data;

}

//  取得搜索关键词
async function getPopularSearchKeywords() {
    const request = {
        "page": {
            "index" : 1
        }
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/apphotsearchword/findAppHotsearchwordList", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得搜索联想
async function getAutoCompletedKeyword(key) {
    const request = {
        "page": {
            "index" : 1,
        },
        "key": key,
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/appassociativeword/findAppAssociativewordList", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得商品标签
async function getProductTag() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/omsproduct/getOmsProductTagList");

    beforeReturn(response.data);
    return response.data;

}

// 取得商品信息
async function getOmsProduct(id){
    const request = {
        "id": id,
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproduct/findOmsProduct", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得商品评分总揽
async function getOmsProductProductCommentStatistic(id){
    const request = {
        "productSpuId": id,
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductcomment/findOmsProductCommentStatistics", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得商品评分条列
async function getOmsProductCommentList(id, index, star, showType){
    const request = {
        "page": {
            "index": index,
            "paging": true,
            "size": 5
        },
        "productSpuId": id,
        "star": star,
        "showType": showType
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductcomment/findOmsProductCommentList", request);

    beforeReturn(response.data);
    return response.data;

}

// 点赞
async function doOmsProductCommentUpvote(id){
    const request = {
        "id": id
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductcomment/doOmsProductCommentUpvote", request);

    beforeReturn(response.data);
    return response.data;

}

// 加入商品足迹
async function saveOmsProductFootprint(id){
    const request = {
        "productId": id
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductfootprint/saveOmsProductFootprint", request);

    beforeReturn(response.data);
    return response.data;

}

// 加入商品收藏
async function saveOmsProductFavorite(id){
    const request = {
        "productId": id
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductfavorite/saveOmsProductFavorite", request);

    beforeReturn(response.data);
    return response.data;

}

// 移除商品收藏
async function deleteLogicOmsProductFavorite(id){
    const request = {
        "ids": id
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductfavorite/deleteLogicOmsProductFavorite", request);

    beforeReturn(response.data);
    return response.data;

}

// 评价商品
async function doOmsProductComment(items, orderId){
    const request = {
        "items": items,
        "orderId": orderId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductcomment/doOmsProductComment", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得订单评价列表
async function findOmsProductCommentOrderList(orderId){
    const request = {
        "orderId": orderId
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproductcomment/findOmsProductCommentOrderList", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得卖场商铺信息
async function findSellStore(oneparm){
    const request = {
        "oneparm": oneparm
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsproduct/findSellStore", request);

    beforeReturn(response.data);
    return response.data;

}

// 取得启动页广告
async function findAppStartBanner(){
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/appbanner/findAppStartBanner");

    beforeReturn(response.data);
    return response.data;

}

// 取得首页 Banner
async function findAppIndexBanners(){
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/appbanner/findAppIndexBanners");

    beforeReturn(response.data);
    return response.data;

}

// Get Notification
async function getAppPushMsgRecordList(index, msgType, userId){
    const request = {
        "index": index,
        "size": 15,
        "msgType": msgType,
        "paging": true,
        "userId": userId
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/apppushmsgrecord/getAppPushMsgRecordList" , request);

    beforeReturn(response.data);
    return response.data;

}

export {
    getCategory,
    getRecommendProduct,
    getPopularSearchKeywords,
    getAutoCompletedKeyword,
    getSearchResult,
    getProductTag,
    getOmsProduct,
    getOmsProductProductCommentStatistic,
    getOmsProductCommentList,
    doOmsProductCommentUpvote,
    saveOmsProductFootprint,
    saveOmsProductFavorite,
    deleteLogicOmsProductFavorite,
    doOmsProductComment,
    findOmsProductCommentOrderList,
    findSellStore,
    findAppStartBanner,
    findAppIndexBanners,
    getAppPushMsgRecordList
};