import React, {useEffect, useState} from "react";

import {bucketURL} from "../data/env";


const NotFound = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "700px"
        }}>
            <br/>
            <br/>

            <img src={varBucketURL + "/assets/not-found.svg"} width={"300px"}></img>
            <p>{props.message}</p>
            <br/>
            <br/>
        </div>

    );
}


export default NotFound;