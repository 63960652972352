import React, {useEffect, useState} from "react";
import {Button, Modal, Row} from "antd";
import {unixDateToHumanDate} from "../../../../data/tool";


// props: order ([array])
// props: open (true or false)
// props: onCancel (method)

const CancelDetail = (props) => {

    return (
        <Modal
            open={props.open}
            title="取消详情"
            onCancel={props.onCancel}
            maskClosable={false}
            footer={[
                <Button type="primary" key="close" onClick={props.onCancel}>
                    关闭
                </Button>
            ]}
        >
            <p>
                退款金额：
                {
                    // 这边要判断一下是否有支付过，因为没支付过不该产生退款费用
                    props.order.orderStatusPay === 0
                    ? "尚未支付"
                    : props.order.estimateRefundPrice
                }
            </p>
            <p>
                取消人：
                {
                    props.order.orderCancelType === 0 &&
                    "买家"
                }
                {
                    props.order.orderCancelType === 1 &&
                    "商铺"
                }
                {
                    props.order.orderCancelType === 2 &&
                    "平台"
                }
            </p>
            <p>取消时间：{unixDateToHumanDate(props.order.orderCancelDate)}</p>
            <p>取消原因：{props.order.orderCancelRemark}</p>
        </Modal>
    );
}


export default CancelDetail;