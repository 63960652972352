import React, {useEffect, useState} from "react";
import {Col, Row, Skeleton} from "antd";
import {Link} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {getRecommendProduct} from "../data/Product-Service-Repo";

const RecommendProduct_loader = (props) => {

    // do once we get token
    useEffect(() => {
        if (props.token !== null) {
            async function loadRecommendProduct() {
                fetchRecommendProductNextPage()
            }

            loadRecommendProduct();
        }
    }, [props.token]);


    // load product
    const [recommendProduct, setRecommendProduct] = useState([]);
    const [recommendProductLength, setRecommendProductLength] = useState(0);
    const [recommendProductHasMore, setRecommendProductHasMore] = useState(true)
    const [recommendProductCurrentPage, setRecommendProductCurrentPage] = useState(0)
    const [recommendProductTotalPage, setRecommendProductTotalPage] = useState(1)

    // when initial load: the system will call the function once token ready via use-effect
    // when scroll down: the plugin will call the function

    // this method won't load data directly. It adds a page to system
    // and  the use-effect will trigger "load" when page is increase
    async function fetchRecommendProductNextPage() {
        if (props.token !== null && firstRun !== false) {
            if (recommendProductCurrentPage >= recommendProductTotalPage) {
                // exit when there are no data
                setRecommendProductHasMore(false);
                return;

            } else {
                // move to next page
                setRecommendProductCurrentPage(recommendProductCurrentPage + 1)

            }
        }
    }


    // avoid run after load, false= haven't run, true= run once after load
    const [firstRun, setFirstRun] = useState(false)

    // use effect of page
    useEffect(() => {
        if (props.token !== null && firstRun !== false) {
            async function load() {
                const data = await getRecommendProduct(recommendProductCurrentPage);
                let plainData = [];
                plainData = data.data;

                plainData.forEach(function (item, index) {
                    recommendProduct.push(item)
                });

                // update total length
                setRecommendProductLength(recommendProduct.length);
                // update total page everytime avoid new data entry
                setRecommendProductTotalPage(data.page.pages);
            }


            load()
        }

        setFirstRun(true);

    }, [recommendProductCurrentPage]);


    return (
        <InfiniteScroll
            dataLength={recommendProductLength}
            next={fetchRecommendProductNextPage}
            hasMore={recommendProductHasMore}
            loader={<h4 style={{textAlign: "center"}}>努力加载中...</h4>}
            endMessage={
                <p style={{textAlign: "center"}}>
                    <b>没有更多了～</b>
                </p>
            }
            style={{marginBottom: "60px"}}
        >
            <div className={"list-product"} id="recommend-product">
                <h2>猜你享买</h2>
                <Row className={"container body"}>
                    <>
                        {/* rendering product */}
                        {recommendProduct.map((builderProduct, index) => {
                            return (
                                <Col className={"item"} span={4}>
                                    <Link to={"/product?id=" + builderProduct.id}>
                                        <div className={"tag"}>

                                            {
                                                // print product tag if there are one
                                                builderProduct.productTag != null &&
                                                <p className={"product-tag"}>{builderProduct.productTag}</p>
                                            }

                                            <p className={"status-tag"}>
                                                {
                                                    // print save status, 1: 全新，2: 二手
                                                    builderProduct.saveStatus === 1
                                                        ? "全新"
                                                        : "二手"
                                                }
                                            </p>

                                        </div>
                                        <div>

                                            <img src={builderProduct.productImg} style={
                                                builderProduct.productStatus === 3
                                                    ? {filter: "contrast(0.3)" , webkitFilter : "contrast(0.4)"}
                                                    : {filter: "contrast(1)", webkitFilter : "contrast(1)"}
                                            }></img>
                                            <Skeleton.Image active={true} className={"skeleton"}/>
                                            {
                                                // print product status, 1已上架 2已下架 3已售完 4已禁卖 5已删除
                                                builderProduct.productStatus === 3 &&
                                                <div className={"sold-out-tag"}>已售完</div>
                                            }

                                        </div>
                                        <title>{builderProduct.productName}</title>
                                        <Row className={"detail"}>
                                            <Col className={"price"} span={12}>${builderProduct.sellPrice.toFixed(2)}</Col>
                                            <Col className={"sold"} span={12}>已售出 {builderProduct.saleNum}</Col>
                                        </Row>
                                    </Link>
                                </Col>
                            );
                        })}
                    </>

                </Row>
            </div>
        </InfiniteScroll>);
}


export default RecommendProduct_loader;