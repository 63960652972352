import React, {useEffect, useState} from "react";
import {
    Button,
    Radio,
    Drawer,
    Form,
    Input,
    Modal,
    notification,
    Switch,
    Tag,
    Divider,
    Skeleton,
    Checkbox,
    Tooltip
} from "antd";
import {
    deleteLogicCrmCustomerAddress, editCrmCustomerAddress,
    findCrmCustomerAddressList,
    saveCrmCustomerAddress
} from "../data/Customer-Service-Repo";
import {bucketURL} from "../data/env";
import {addressFormatterWithOutUnit, addressFormatterWithUnit, getAddressIcon} from "../data/tool";
import AddressAutocomplete from "./AddressAutocomplete";
import {geocodeByPlaceId} from "react-google-places-autocomplete";
import {PlusCircleOutlined} from "@ant-design/icons";


/**
 *
 * Doc comment
 *
 * Props 需求参数:
 * - sessionToken
 * - source: [string] setting | checkout：地址选择器的调用位置
 *   -- selectedAddressId: [string] 已选择的地址 -> 此函数只有 source 为 checkout 时才会被调用
 *   -- onAddressSelected: [function] 选择地址后的回调函数 -> 此函数只有 source 为 checkout 时才会被调用
 * - disabled: [boolean] 不允许点击
 *
 */
const AddressSelector = (props) => {
    const [form] = Form.useForm();

    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // 加载中
    const [addressLoading, setAddressLoading] = useState(false);

    // 用户所有地址存储
    const [addressList, setAddressList] = useState([]);

    // once on load
    useEffect(() => {
        getUserAddressList();
    }, [])

    // 取得用户的地址
    async function getUserAddressList() {
        setAddressLoading(true);
        var result = await findCrmCustomerAddressList();

        if (result.code === 200) {
            setAddressList(result.data)

            // wait 2 second to close loading
            setTimeout(() => {
                setAddressLoading(false);
            }, 1000);
        }
    }


    // --------------------- 删除地址 ---------------------
    // 欲删除的地址id
    const [targetDeleteAddressId, setTargetDeleteAddressId] = useState("null");

    // 删除请求
    async function handleAddressOnDelete() {
        const request = {
            ids: [targetDeleteAddressId]
        }
        var result = await deleteLogicCrmCustomerAddress(request);

        if (result.code === 200) {
            // 刷新地址列表
            await getUserAddressList();

            notificationApi.success({
                message: `提示信息`,
                description: "地址删除成功",
                placement: "bottomRight",
                duration: 2,
            });

        } else {
            notificationApi.error({
                message: `地址删除失败`,
                description: result.message,
                placement: "bottomRight",
                duration: 5,
            });
        }


    }

    // 地址删除 modal
    const [addressDeleteModalVisible, setAddressDeleteModalVisible] = useState(false);

    function AddressDeleteModal() {
        return (
            <Modal
                title="系统提示"
                open={addressDeleteModalVisible}
                onOk={() => {
                    handleAddressOnDelete();
                    setAddressDeleteModalVisible(false);
                }}
                onCancel={() => {
                    setAddressDeleteModalVisible(false);
                }}
                okText={"确认"}
                cancelText={"取消"}
            >
                <p>您确定要删除此地址吗？</p>
            </Modal>
        );
    }


    // --------------------- 新增/编辑地址 ---------------------
    // 编辑对象 object
    const [addressEditObject, setAddressEditObject] = useState([]);

    // 设置功能模式
    // create: 新增地址
    // edit: 编辑地址
    const [addressEditDrawerMode, setAddressEditDrawerMode] = useState("");
    const [isDefault, setIsDefault] = useState(false); // 是否默认地址

    // 事先准备
    useEffect(() => {
        // clear form
        form.resetFields();
        setIsDefault(false);

        // 编辑地址前预先填写地址
        if (addressEditDrawerMode === "edit") {
            form.setFieldsValue({
                receiverName: addressEditObject.name,
                receiverMobile: addressEditObject.mobile,
                addressLine: addressEditObject.address,
                suburb: addressEditObject.suburb,
                state: addressEditObject.state,
                postcode: addressEditObject.postcode,
                unit: addressEditObject.unit,
                tag: addressEditObject.tag,
            });

            setIsDefault(addressEditObject.isDefault === 1);
        }
    }, [addressEditDrawerMode]);


    // --- REQUEST ---
    // 编辑请求
    async function handleAddressOnEdit() {


        // 判断 isDefault 是否为 true
        /* 用这个方法是因为，如果用 form.getFieldValue("isDefault")，当开关没被动过时，form field 会是 undefined
         * 所以需要使用 isDefault state 追踪
         * 但是 setIsDefault 会让 drawer 一直重新 rerender（重新开关），因此使用这种方式判断
         */
        var isDefaultSwitchValue;

        if(form.getFieldValue("isDefault") === undefined){
            // form field undefined 代表开关没也被动过，所以维持原始值
            isDefaultSwitchValue = isDefault;

        } else if (form.getFieldValue("isDefault") === true || form.getFieldValue("isDefault") === false){
            // form field 有值代表有动过开关，以 form field 为主
            isDefaultSwitchValue = form.getFieldValue("isDefault");
        }


        // 把修改对象 object 存到暂存
        var tempAddressEditObject = addressEditObject;

        // 制作新报文
        tempAddressEditObject.address = form.getFieldValue("addressLine");
        tempAddressEditObject.isDefault = isDefaultSwitchValue ? 1 : 0;
        tempAddressEditObject.mobile = form.getFieldValue("receiverMobile");
        tempAddressEditObject.name = form.getFieldValue("receiverName");
        tempAddressEditObject.postcode = form.getFieldValue("postcode");
        tempAddressEditObject.state = form.getFieldValue("state");
        tempAddressEditObject.suburb = form.getFieldValue("suburb");
        tempAddressEditObject.tag = form.getFieldValue("tag") === undefined ? "" : form.getFieldValue("tag");
        tempAddressEditObject.tagIcon = await getAddressIcon(form.getFieldValue("tag"));
        tempAddressEditObject.unit = form.getFieldValue("unit");

        console.log(tempAddressEditObject)

        var result = await editCrmCustomerAddress(tempAddressEditObject);

        if (result.code === 200) {
            handleAddressEditDrawerOnClose()

            getUserAddressList(); // 刷新地址列表

            notificationApi.success({
                message: `提示信息`,
                description: "地址保存成功",
                placement: "bottomRight",
                duration: 2,
            });

        } else {
            notificationApi.error({
                message: `地址保存失败`,
                description: result.message,
                placement: "bottomLeft",
                duration: 5,
            });
        }
    }

    // 新增请求
    async function handleAddressOnCreate() {
        // 制作报文
        var request = {
            address: form.getFieldValue("addressLine"),
            isDefault: form.getFieldValue("isDefault") ? 1 : 0,
            mobile: form.getFieldValue("receiverMobile"),
            name: form.getFieldValue("receiverName"),
            postcode: form.getFieldValue("postcode"),
            state: form.getFieldValue("state"),
            suburb: form.getFieldValue("suburb"),
            tag: form.getFieldValue("tag") === undefined ? "" : form.getFieldValue("tag"),
            tagIcon: await getAddressIcon(form.getFieldValue("tag")),
            unit: form.getFieldValue("unit"),
        };


        var result = await saveCrmCustomerAddress(request);

        if (result.code === 200) {
            handleAddressEditDrawerOnClose()

            getUserAddressList(); // 刷新地址列表

            notificationApi.success({
                message: `提示信息`,
                description: "地址保存成功",
                placement: "bottomRight",
                duration: 2,
            });

        } else {
            notificationApi.error({
                message: `地址保存失败`,
                description: result.message,
                placement: "bottomLeft",
                duration: 5,
            });
        }
    }


    // --- DRAWER ---
    // 关闭 drawer
    function handleAddressEditDrawerOnClose() {
        setAddressEditObject([]); // 清空对象 object
        setAddressEditDrawerMode(""); // 清空功能模式
        setAddressEditDrawerVisible(false);
    }

    // 新增/编辑地址 drawer
    const [addressEditDrawerVisible, setAddressEditDrawerVisible] = useState(false);


    // --- AUTO-COMPLETED ---
    // 地址 AutoCompleted 选择后的回调函数
    async function onAutoCompletedSelected(data) {
        // var temp address
        var address = {};

        // set address line
        address.address = data.value.structured_formatting.main_text;

        // fetch detail
        var placeDetail = await geocodeByPlaceId(data.value.place_id)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });


        // 针对有些地址没有号码的情况
        // 没有 street no. 的话 address_components.length 会是 6
        // 有 street no. 的话 address_components.length 会是 7
        if (placeDetail[0].address_components.length === 6) {
            address.suburb = placeDetail[0].address_components[1].long_name;
            address.state = placeDetail[0].address_components[3].short_name;
            address.postcode = placeDetail[0].address_components[5].long_name;

        } else if (placeDetail[0].address_components.length === 7) {
            // set address detail
            address.suburb = placeDetail[0].address_components[2].long_name;
            address.state = placeDetail[0].address_components[4].short_name;
            address.postcode = placeDetail[0].address_components[6].long_name;
        }

        form.setFieldsValue({
            addressLine: address.address,
            suburb: address.suburb,
            state: address.state,
            postcode: address.postcode,
        });
    }


    /*
     * ---- 追踪用户是否重复点击一个 radio，如果是的话把选项反选 ----
     * 我使用追踪 tag radio button 的 onChange 事件来实现
     * 利用 onFocus 会比 onChange 早触发的特性、以及 onChange 只有在值改变时才会呼叫
     *
     * 我在 onFocus 里面归零了这个 state，并预约 200ms 后执行检查
     * 然后在 onChange 里面修改这个 state 这个值
     * 因此如果超时没有接收掉值改变，代表用户是重复点击同一个 radio，onChange 没有呼叫
     *
     */
    // const [tagRadioOnChangeCallback, setTagRadioOnChangeCallback] = useState({
    //     state: false
    // });

    function AddressEditDrawer() {
        return (
            <Drawer
                title={addressEditDrawerMode === "create" ? "新增地址" : "编辑地址"}
                placement="right"
                onClose={handleAddressEditDrawerOnClose}
                open={addressEditDrawerVisible}
                maskClosable={false}
                contentWrapperStyle={{
                    width: "500px",
                }}
                bodyStyle={{
                    height: "calc (100% - 120px)",
                    flex: "0 1 calc(100% - 120px)"
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={() => {
                        addressEditDrawerMode === "create" ? handleAddressOnCreate() : handleAddressOnEdit();
                    }}
                    style={{
                        width: "100%"
                    }}
                >
                    <Form.Item
                        name="receiverName"
                        label="收件人名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入收件人名称',
                            },
                        ]}
                    >
                        <Input placeholder={"请输入收件人名称"}/>
                    </Form.Item>
                    <Form.Item
                        name="receiverMobile"
                        label="收件人手机号"
                        rules={[
                            {
                                required: true,
                                message: '请输入收件人手机号',
                            },
                            // 自定义校验规则
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (value != "" && !value.match(/^4[0-9]{8}$/)) {
                                        return Promise.reject("手机号不正确（4 开头，共 9 码数字）"); // The validator should always return a promise on both success and error
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })
                        ]}
                    >
                        <Input prefix={"+61"} placeholder={"请输入手机号"}/>
                    </Form.Item>

                    <Divider dashed style={{margin: "40px 0px"}}/>

                    <Form.Item
                        name="address"
                        label="请输入您的地址"
                    >
                        <AddressAutocomplete onAutoCompletedSelected={onAutoCompletedSelected}
                                             addressValue={form.getFieldValue("address")}/>
                    </Form.Item>

                    <Form.Item
                        name="unit"
                        label="单元号 Unit/Flat/Apartment"
                    >
                        <Input placeholder={"请输入单元号"}/>
                    </Form.Item>
                    <Form.Item
                        label="Address Line:"
                        name={"addressLine"}
                        rules={[
                            {
                                required: true,
                                message: '请输入于上方 Address 输入地址...',
                            },
                        ]}
                    >
                        <Input placeholder="填写地址后会自动带入" disabled/>
                    </Form.Item>

                    <Form.Item
                        label="Suburb:"
                        name={"suburb"}
                        rules={[
                            {
                                required: true,
                                message: '请输入于上方 Address 输入地址...',
                            },
                        ]}
                    >
                        <Input placeholder="填写地址后会自动带入" disabled/>
                    </Form.Item>

                    <Form.Item
                        name={"state"}
                        label="State:"
                        rules={[
                            {
                                required: true,
                                message: '请输入于上方 Address 输入地址...',
                            },
                        ]}
                    >
                        <Input placeholder="填写地址后会自动带入" disabled/>
                    </Form.Item>

                    <Form.Item
                        name={"postcode"}
                        label="Postcode:"
                        rules={[
                            {
                                required: true,
                                message: '请输入于上方 Address 输入地址...',
                            },
                        ]}
                    >
                        <Input placeholder="填写地址后会自动带入" disabled/>
                    </Form.Item>

                    <Divider dashed style={{margin: "40px 0px"}}/>


                    <Form.Item
                        name="tag"
                        label="地址标签"
                    >
                        <Radio.Group
                            options={[
                                {
                                    label: '住家',
                                    value: '住家',
                                },
                                {
                                    label: '学校',
                                    value: '学校',
                                },
                                {
                                    label: '公司',
                                    value: '公司',
                                },
                            ]}
                            optionType="button"
                            // onFocus={() => {
                            //     // 说明文档请阅读上面 tagRadioOnChangeCallback state 的说明
                            //     // 开始重选，把之前的选项清空
                            //     tagRadioOnChangeCallback.state = false;
                            //
                            //
                            //     // 等待一下执行比对，如果没有拿到 onchage 的回调，就代表是重点
                            //     setTimeout(()=>{
                            //         console.log(tagRadioOnChangeCallback)
                            //         // 如果没有间听到回调，就代表反选，清空选项
                            //         if(tagRadioOnChangeCallback.state === false){
                            //             form.setFieldsValue({
                            //                 tag: undefined
                            //             });
                            //         }
                            //     }, 200);
                            // }}
                            //
                            // onChange={(e)=>{
                            //     // 说明文档请阅读上面 tagRadioOnChangeCallback state 的说明
                            //     tagRadioOnChangeCallback.state = true;
                            // }}
                        />

                    </Form.Item>
                    <Form.Item
                        name="isDefault"
                        label="设为默认地址"
                        value={isDefault}
                        valuePropName="checked"
                    >
                        <Switch
                            defaultChecked={isDefault}
                            value={isDefault}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{
                            background: "#fff",
                            padding: "20px",
                            margin: "0px 0px 0px -24px",
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                            zIndex: "100",
                            borderTop: "1px solid #F0F0F0"
                        }}
                    >
                        <Button
                            type={"primary"}
                            htmlType={"submit"}
                            style={{width: "440px"}}
                            size={"large"}
                        >
                            保存
                        </Button>
                    </Form.Item>
                </Form>

            </Drawer>
        );
    }


    return (
        <>
            <AddressDeleteModal/>
            <AddressEditDrawer/>
            {notificationContextHolder}
            {
                addressList.length > 0 &&
                <>
                    {
                        addressLoading
                            ?
                            <>
                                <div className={"address-container"}>
                                    <div>
                                        <Skeleton.Avatar active size={40}/>
                                    </div>
                                    <Skeleton active paragraph={false}/>
                                    &nbsp;
                                    <Skeleton.Button active/>
                                    <Skeleton.Button active/>

                                </div>
                                <br/>
                                <div className={"address-container"}>
                                    <div>
                                        <Skeleton.Avatar active size={40}/>
                                    </div>
                                    <Skeleton active paragraph={false}/>
                                    &nbsp;
                                    <Skeleton.Button active/>
                                    <Skeleton.Button active/>

                                </div>
                                <br/>
                                <div className={"address-container"}>
                                    <div>
                                        <Skeleton.Avatar active size={40}/>
                                    </div>
                                    <Skeleton active paragraph={false}/>
                                    &nbsp;
                                    <Skeleton.Button active/>
                                    <Skeleton.Button active/>

                                </div>
                                <br/>
                            </>
                            :
                            <>
                                {
                                    addressList.map((item, index) => {
                                        return (
                                            <>

                                                <div className={`address-container ${item.id === props.selectedAddressId && "address-container-selected"} ${props.disabled && "address-container-disabled"}`}>
                                                    <div
                                                        style={{display: "flex", flexDirection: "row", gap: "20px"}}
                                                        className={`${props.source === "checkout" ? "clickable" : ""}`}
                                                        onClick={() => {
                                                            if (props.source === "checkout" && !props.disabled) {
                                                                props.onAddressSelected(item);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            props.source === "checkout" &&
                                                            <Radio checked={`${item.id === props.selectedAddressId ? "true" : ""}`}/>
                                                        }
                                                        <div>
                                                            <img style={{zIndex: "100", position: "relative"}} className={"icon"}
                                                                 src={item.tagIcon !== null ? item.tagIcon : varBucketURL + "/assets/icon-default-address-tag.svg"}
                                                                 width={"40px"} height={"40px"}></img>
                                                            <Skeleton.Avatar
                                                                active size={40}
                                                                style={{
                                                                    marginLeft: "-40px",
                                                                    marginTop: "-26px"
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className={"detail"}>
                                                                <span className={"receiver-name"}>{item.name}</span>
                                                                <span className={"receiver-mobile"}>{item.mobile}</span>
                                                                {
                                                                    item.isDefault === 1 &&
                                                                    <Tag color="volcano">默认</Tag>
                                                                }
                                                            </div>
                                                            <div className={"receiver-address"}>
                                                                <span>{addressFormatterWithUnit(item)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{marginRight: "0", marginLeft: "auto", gap: "3px", display: "flex"}}>
                                                        <Button
                                                            type={"text"}
                                                            className={"color-shopmy-orange"}
                                                            onClick={() => {
                                                                setAddressEditObject(item);
                                                                setAddressEditDrawerMode("edit");
                                                                setAddressEditDrawerVisible(true);
                                                            }}
                                                            disabled={props.disabled}
                                                        >
                                                            编辑
                                                        </Button>
                                                        <Tooltip placement="top" title={(props.selectedAddressId === item.id && props.source === "checkout") && "不可以删除已选中的地址，请选择其他地址后再尝试"}>
                                                            <Button
                                                                type={"text"}
                                                                danger
                                                                onClick={() => {
                                                                    setTargetDeleteAddressId(item.id);
                                                                    setAddressDeleteModalVisible(true);
                                                                }}
                                                                disabled={
                                                                    props.disabled || (props.selectedAddressId === item.id && props.source === "checkout")
                                                                }
                                                            >
                                                                删除
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <br/>

                                            </>

                                        )
                                    })
                                }
                            </>
                    }

                </>
            }
            <Button
                type="dashed"
                style={{
                    width: "100%"
                }}
                onClick={() => {
                    setAddressEditDrawerMode("create");
                    setAddressEditDrawerVisible(true);
                }}
                disabled={props.disabled}
            >
                <PlusCircleOutlined /> 添加新地址
            </Button>
        </>

    );
}


export default AddressSelector;