import axios from "axios";
import beforeReturn from "./apiControllers";


// --- Constants ----------------
const API_HOST = "https://api.shopmy.com.au/api/v1";
const SERVICE_NAME = "/cs";

axios.defaults.headers.common['X-Auth-Lang'] = `zh`;
axios.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('Access-Token');
axios.defaults.headers.common['X-Auth-Type'] = `app`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ 退出登录 ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
async function logout(){
    // 远端登出
    await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/logout");

    // 本地登出
    window.localStorage.removeItem('User');
    window.localStorage.removeItem('Access-Token');
    window.localStorage.removeItem('Tencent-IM-Sig');
    window.localStorage.removeItem('storeFormData');
    window.localStorage.removeItem('storeFormStatus');
    window.localStorage.removeItem('lastShowStartPageAds');

    // 跳转
    window.location.href = "/";
}
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ 退出登录 ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝







// 取得首頁商鋪推薦
async function getRecommendStore(index) {
    const request = {
        "page": {
            "index" : index,
            "size": 6
        }
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellstore/recommendSellStores", request);

    beforeReturn(response.data);
    return response.data;
}


// 登录
async function login(mobile, password){

    // 转换 md5
    var CryptoJS = require("crypto-js");

    // 检查手机号要不要去 0
    if(Array.from(mobile)[0] === "0"){
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile" : mobile,
        "password" : CryptoJS.MD5(password).toString().toUpperCase() // 转换 md5
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/login", request);

    if(response.data.code === 200){
        window.localStorage.removeItem('User');
        window.localStorage.removeItem('Access-Token');
        window.localStorage.removeItem('Tencent-IM-Sig');
        window.localStorage.removeItem('storeFormData');
        window.localStorage.removeItem('storeFormStatus');
        window.localStorage.removeItem('lastShowStartPageAds');
    }

    beforeReturn(response.data);
    return response.data;
}


// 取腾讯 IM Sig
// 兼容未登录，所以要手动传 token 和 type
async function getUserSig(token, type){
    axios.defaults.headers.common['X-Auth-Type'] = type;
    axios.defaults.headers.common['X-Auth-Token'] = token;

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/getUserSig");

    beforeReturn(response.data);
    return response.data;
}

// 取用户信息
async function getUserInfo(token, type){
    // 避免 token 没有即时传道 localstorage，所以主动给 token
    axios.defaults.headers.common['X-Auth-Token'] = token;

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/getUserInfo");

    beforeReturn(response.data);
    return response.data;
}


// 修改用户信息
async function editUserInfo(request){
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/editUserInfo", request);

    // 如果成功，把 localStorage 的东西也刷新
    if(response.data.code === 200){
        var newProfileData = await getUserInfo(localStorage.getItem("Access-Token"), "")

        if(newProfileData.code === 200){
            localStorage.setItem('User', JSON.stringify(newProfileData.data));
        }
    }

    beforeReturn(response.data);
    return response.data;
}


// 苹果登录
async function getAppleUserInfo(identityToken){
    const request = {
        "identityToken" : identityToken,
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/getAppleUserInfo", request);

    beforeReturn(response.data);
    return response.data;
}


// 验证短信验证码
async function checkSmsCode (mobile, code){

    // 检查手机号要不要去 0
    if(Array.from(mobile)[0] === "0"){
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile" : mobile,
        "code": code
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/checkSmsCode", request);

    beforeReturn(response.data);
    return response.data;
}

// ----------- 更改手机号 -----------
// 传短信验证码
async function sendchangeMobileSmsCode(mobile){
    // 检查手机号要不要去 0
    if(Array.from(mobile)[0] === "0"){
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile" : mobile,
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/sendchangeMobileSmsCode", request);

    beforeReturn(response.data);
    return response.data;
}


// 修改手机号
async function changeMobile(mobile, code, walletPassword){
    const request = {
        "mobile" : mobile,
        "code": code,
        "walletPassword": walletPassword
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/changeMobile", request);

    beforeReturn(response.data);
    return response.data;
}



// ----------- 钱包密码 -----------
async function verifyWalletPassword(oneparm){
    // 转换 md5
    var CryptoJS = require("crypto-js");

    const request = {
        "oneparm": CryptoJS.MD5(oneparm).toString().toUpperCase()
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/verifyWalletPassword", request);

    beforeReturn(response.data);
    return response.data;
}



// ----------- 忘记登录密码 -----------
// 传短信验证码
async function sendForgetPasswordSmsCode (mobile){

    // 检查手机号要不要去 0
    if(Array.from(mobile)[0] === "0"){
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile" : mobile
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/sendForgetPasswordSmsCode", request);

    beforeReturn(response.data);
    return response.data;
}

// 修改密码
async function forgetPassword (mobile, code, password){

    // 转换 md5
    var CryptoJS = require("crypto-js");

    // 检查手机号要不要去 0
    if(Array.from(mobile)[0] === "0"){
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile" : mobile,
        "smsCode": code,
        "newPassowrd" : CryptoJS.MD5(password).toString().toUpperCase() // 转换 md5
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/forgetPassword", request);

    beforeReturn(response.data);
    return response.data;
}

// 修改密码
async function changePassWord (oldPassword, newPassword){
    // 转换 md5
    var CryptoJS = require("crypto-js");


    const request = {
        "oldPassowrd": CryptoJS.MD5(oldPassword).toString().toUpperCase(), // 转换 md5
        "newPassowrd" : CryptoJS.MD5(newPassword).toString().toUpperCase() // 转换 md5
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/changePassWord", request);

    beforeReturn(response.data);
    return response.data;
}



// ----------- 注册享买帐号 -----------
// 发送短信验证码
async function sendRegisterSmsCode(mobile) {
    // 检查手机号要不要去 0
    if (Array.from(mobile)[0] === "0") {
        mobile = mobile.slice(1);
    }

    const request = {
        "mobile": mobile
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/sendLoginSmsCode", request);

    beforeReturn(response.data);
    return response.data;
}

// 注册帐号
async function register(mobile, password) {
    // 转换 md5
    var CryptoJS = require("crypto-js");

    // 检查手机号要不要去 0
    if (Array.from(mobile)[0] === "0") {
        mobile = mobile.slice(1);
    }

    const request = {
        "areaCode": "+61",
        "mobile": mobile,
        "password": CryptoJS.MD5(password).toString().toUpperCase() // 转换 md5
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomer/register", request);

    beforeReturn(response.data);
    return response.data;}


// --- 开店/店铺相关 ----------------
// 取得个人商铺认证文件提示
async function getPersonalApplyTips() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/baseconfig/getPersonalApplyTips");

    beforeReturn(response.data);
    return response.data;
}

// 取得企业商铺认证文件提示
async function getEnterpriseApplyTips() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/baseconfig/getEnterpriseApplyTips");

    beforeReturn(response.data);
    return response.data;
}

// 送出开店申请
async function submitSellStore(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellstore/saveSellStore", request);

    beforeReturn(response.data);
    return response.data;
}

// 修改开店申请（退件重发）
async function editSellStore(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellstore/editSellStore", request);

    beforeReturn(response.data);
    return response.data;
}

// 取得个人商铺信息
async function findSellStore() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellstore/findSellStore");

    beforeReturn(response.data);
    return response.data;
}


// --- 关注/取消关注商铺 ----------------
// 关注商铺
async function saveSellerStoreFavorite(storeId) {
    const request = {
        "storeId": storeId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellerstorefavorite/saveSellerStoreFavorite", request);

    beforeReturn(response.data);
    return response.data;
}

// 取消关注商铺
async function deleteLogicSellerStoreFavorite(storeId) {
    const request = {
        "storeId": storeId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/sellerstorefavorite/deleteLogicSellerStoreFavorite", request);

    beforeReturn(response.data);
    return response.data;
}



// --- 用户地址 ----------------
// 取得用户默认地址
async function findCrmCustomerDefaultAddress() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/findCrmCustomerDefaultAddress");

    beforeReturn(response.data);
    return response.data;
}

// 取得用户所有地址（不分页）
async function findCrmCustomerAddressList() {
    const request = {
        "page": {
            "index": 1,
            "paging": false,
            "size": 999
        }
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/findCrmCustomerAddressList", request);

    beforeReturn(response.data);
    return response.data;
}

// 编辑地址
// 全部拿到的数据，都要传回去
async function editCrmCustomerAddress(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/editCrmCustomerAddress", request);

    beforeReturn(response.data);
    return response.data;
}

// 删除地址
//POST /api/v1/cs/app/crmcustomeraddress/deleteLogicCrmCustomerAddress
async function deleteLogicCrmCustomerAddress(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/deleteLogicCrmCustomerAddress", request);

    beforeReturn(response.data);
    return response.data;
}


// 新增地址
/*
    address (string): 地址【必填】 ,
    isDefault (integer, optional): 是否默认（0：否、1：是） ,
    mobile (string): 手机号【必填】 ,
    name (string): 联系人【必填】 ,
    postcode (string): 邮编【必填】 ,
    state (string): 州【必填】 ,
    suburb (string): 城市【必填】 ,
    tag (string, optional): 标签 ,
    tagIcon (string, optional): 标签图像 ,
    unit (string, optional): 单元【必填】
 */
async function saveCrmCustomerAddress(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/saveCrmCustomerAddress", request);

    beforeReturn(response.data);
    return response.data;
}



// 地址标签
async function findCrmCustomerAddressTagList() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/crmcustomeraddress/findCrmCustomerAddressTagList");

    beforeReturn(response.data);
    return response.data;
}




export {
    getRecommendStore,
    login,
    getUserSig,
    logout,
    getUserInfo,
    editUserInfo,
    getAppleUserInfo,
    checkSmsCode,
    sendchangeMobileSmsCode,
    changeMobile,
    sendForgetPasswordSmsCode,
    forgetPassword,
    changePassWord,
    verifyWalletPassword,
    sendRegisterSmsCode,
    register,
    getPersonalApplyTips,
    getEnterpriseApplyTips,
    submitSellStore,
    findSellStore,
    editSellStore,
    saveSellerStoreFavorite,
    deleteLogicSellerStoreFavorite,
    findCrmCustomerDefaultAddress,
    findCrmCustomerAddressList,
    editCrmCustomerAddress,
    deleteLogicCrmCustomerAddress,
    saveCrmCustomerAddress,
    findCrmCustomerAddressTagList
};