import React, {useEffect, useState} from "react";
import {Button, Col, Row, Skeleton} from "antd";
import {getCategory} from "../data/Product-Service-Repo";
import {useNavigate} from "react-router-dom";
import {ArrowUpOutlined, RightOutlined} from "@ant-design/icons";
import { HashLink as Link } from 'react-router-hash-link';
import BackToUp from "@uiw/react-back-to-top";

const Category = (props) => {
    const navigate = useNavigate();

    // 类目表存储
    const [categoryList, setCategoryList] = useState([]);

    useEffect( () => {
        async function load() {
            const category = await getCategory();
            if (category.code === 200) {
                setCategoryList(category.data);
            }
        }

        load();

    }, []);

    return (
        <div className={"safe-area"} id={"category-page"}>
            <div style={{padding: "40px"}}>
                <h3 className={"color-shopmy-orange"} style={{marginBottom: "10px"}}>主类目</h3>
                <div className={"main-category-bubble-container"}>
                    {
                        categoryList.map((mainCategory, index) => {
                            return (
                                <Link to={`/category#${mainCategory.id}`} className={"category-bubble"}>{mainCategory.name}</Link>
                            );
                        })
                    }
                </div>
                <br/>
                <br/>
                <br/>
                <h3 className={"color-shopmy-orange"}>所有类目</h3>
                <br/>
                {
                    categoryList.map((mainCategory, index) => {
                        return (
                            <div className={"main-category-container"} id={mainCategory.id}>
                                <div onClick={()=>{navigate(`/search?firstStairId=${mainCategory.id}&prefix=类目搜索：${mainCategory.name}`)}} className={"main-title"}>{mainCategory.name} &nbsp;<span className={"more-icon"}><RightOutlined /></span></div>

                                <Row className={"sub-category-collection"}>
                                    {
                                        mainCategory.categoryList.map((subCategory, index) => {
                                            return (
                                                <Col span={3} onClick={()=>{navigate(`/search?firstStairId=${subCategory.id}&prefix=类目搜索：${subCategory.name}`)}} className={"sub-category-container"}>
                                                    <img className={"sub-img"} src={subCategory.imgUrl}></img>
                                                    <Skeleton.Avatar active={true} size={70} shape={"circle"} />
                                                    <span className={"sub-name"}>{subCategory.name} &nbsp;<span className={"more-icon"}><RightOutlined /></span></span>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>


                            </div>
                        )
                    })
                }
                <BackToUp className={"back-to-top"}>
                    <Button type="primary" size={"large"}>
                        <ArrowUpOutlined/>&nbsp;回到最上方
                    </Button>

                </BackToUp>
            </div>

        </div>);
}


export default Category;