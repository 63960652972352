import React, {useEffect, useState} from "react";
import {Button, Col, Space, Form, Input, message, Row, Steps, theme} from "antd";
import {useNavigate} from "react-router-dom";
import {checkSmsCode, forgetPassword, sendForgetPasswordSmsCode} from "../../../data/Customer-Service-Repo";
import Countdown from "react-countdown";


/**
 *
 * Doc comment
 * ForgotPassword.js 同时提供两种模式，分别是「未登录的忘记密码」以及「已登录的忘记密码」
 *
 * 在调用 ForgotPassword Component 时，可以用"renderType" 来告诉组件你希望显示什么样式
 * renderType="guest"，为「未登录的忘记密码」，会要求用户输入手机号，并提供工具栏
 * renderType="logged"，为「已登录的忘记密码」，会自动带入手机号，并不提供工具栏（推荐使用 modal 显示此组件）
 *      mobile: 当已登录时，请传送手机号
 *
 * 以上参数请用 props 传送
 */



const ForgotPassword = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // 用于判断模式
    useEffect(()=>{
        if(props.renderType === "logged"){
            // 自动赋予手机号
            form.setFieldsValue({
                mobile: props.mobile
            })

            // 发验证码
            getVerificationCode();

            // 移动到第二步骤
            setCurrentStep(2)
        }
    }, [])

    //当前步骤
    const [currentStep, setCurrentStep] = useState(1);

    // 存储
    const [mobileNumber, setMobileNumber] = useState("");
    const [code, setCode] = useState("");

    // 纪录发送的几次验证码
    const [resendRepeatCount, setResendRepeatCount] = useState(0);


    async function getVerificationCode() {

        // get mobile number
        var mobile = form.getFieldValue("mobile");
        await setMobileNumber(mobile);

        // post to api
        var result = await sendForgetPasswordSmsCode(mobile);

        if (result.code === 200) {
            setCurrentStep(2)
        } else {
            alert(result.message)
        }

    }

    async function resendVerificationCode() {
        // post to api
        var result = await sendForgetPasswordSmsCode(mobileNumber);

        if (result.code === 200) {
            var count = resendRepeatCount;
            setResendRepeatCount(count + 1);

        } else {
            alert(result.message)
        }

    }


    async function checkVerificationCode() {
        // get code
        var codeInput = form.getFieldValue("codeInput");

        // post to api
        var result = await checkSmsCode(mobileNumber, codeInput);

        if (result.code === 200) {
            setCurrentStep(3)
            setCode(codeInput)
        } else {
            alert(result.message)
        }
    }


    async function submitForgotPassword() {
        // get password
        var password1 = form.getFieldValue("password1");
        var password2 = form.getFieldValue("password2");

        // verify password
        if (password1 !== password2) {
            alert("请检查两次输入的密码必须相同")
            return;
        }

        // 检查密码条件
        const regex = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$');
        if(regex.test(password1) !== true){
            alert("请检查密码是否满足条件")
            return;
        }


        // post to api
        var result = await forgetPassword(mobileNumber, code, password1);

        if (result.code === 200) {

            if(props.renderType=== "logged"){
                // reload page
                window.location.reload();

            } else if (props.renderType === "guest"){
                // redirect to home page
                navigate("/")

            }

            alert("密码修改成功")

        } else {
            alert(result.message)
        }
    }


    function PageStep1() {
        return (
            <Form
                name="step1"
                autoComplete="off"
                onFinish={getVerificationCode}
                style={{width: "100%"}}
                form={form}
            >
                <Form.Item
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: '请输入注册时的手机号',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && !value.match(/^4[0-9]{8}$/)){
                                    return Promise.reject("手机号不正确（4 开头，共 9 码数字）"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                        size="large"
                    >
                        <Input addonBefore="+61" placeholder="请输入注册时的手机号" size="large"/>
                    </Space.Compact>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        发送验证码
                    </Button>
                </Form.Item>
                {
                    // 非登录用户才显示工具栏
                    props.renderType !== "logged"  && <FeatureBtn/>
                }
            </Form>
        );
    }


    function PageStep2() {
        return (
            <Form
                name="step2"
                onFinish={checkVerificationCode}
                autoComplete="off"
                style={{width: "100%"}}
                form={form}
            >
                <Form.Item
                    name="codeInput"
                    rules={[
                        {
                            required: true,
                            message: '请输入验证码',
                        },
                    ]}
                >
                    <Input placeholder="请输入验证码" size="large"/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        下一步
                    </Button>

                    <Countdown
                        date={Date.now() + 120000}
                        renderer={renderer}
                        key={resendRepeatCount}
                    />
                </Form.Item>
                {
                    // 非登录用户才显示工具栏
                    props.renderType !== "logged"  && <FeatureBtn/>
                }
            </Form>
        );
    }

    // Renderer timer callback with condition
    const renderer = ({minutes, seconds, completed}) => {

        var isNotCompleted = true;
        var timer = "";

        if (completed) {
            isNotCompleted = false;

        } else {

            // display the common format 00:00:00 instead of 0:0:0
            if (minutes < 10) {
                minutes = minutes.toString().padStart(2, '0')
            }
            if (seconds < 10) {
                seconds = seconds.toString().padStart(2, '0')
            }

            // Render a countdown
            timer = minutes + ":" + seconds
        }

        return (
            <Button size="large" style={{width: "100%", marginTop: "10px"}} disabled={isNotCompleted}
                    onClick={resendVerificationCode}>

                {
                    timer === ""
                        ? "再次发送验证码"
                        : "再次发送验证码（请等待 " + timer + " 秒）"
                }
            </Button>
        );
    };


    function PageStep3() {
        return (
            <Form
                name="step3"
                onFinish={submitForgotPassword}
                autoComplete="off"
                style={{width: "100%"}}
                form={form}

            >
                <Form.Item style={{marginBottom: "0px", color: "#868686"}}>
                    <p style={{margin: "0px"}}>请确认密码满足以下条件：</p>
                    <ul style={{paddingLeft: "28px", marginTop: "5px"}}>
                        <li>至少 8 个字</li>
                        <li>包含大小写、数字</li>
                    </ul>
                </Form.Item>


                <Form.Item
                    name="password1"
                    rules={[
                        {
                            required: true,
                            message: '请输入新密码',
                        },
                    ]}
                >
                    <Input.Password placeholder="请输入新密码" size="large"/>
                </Form.Item>


                <Form.Item
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: '请再次输入新密码',
                        },
                    ]}
                >
                    <Input.Password placeholder="请再次输入新密码" size="large"/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>
                        确认更改密码
                    </Button>
                </Form.Item>

                {
                    // 非登录用户才显示工具栏
                    props.renderType !== "logged"  && <FeatureBtn/>
                }
            </Form>
        );
    }


    function FeatureBtn() {
        return (
            <Row id={"feature-btn"} style={{marginTop: "50px"}}>
                <Col span={12}>
                    <Button type="link" onClick={() => navigate('/')}>
                        登录享买帐号
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        type="link"
                        style={{
                            display: "flex",
                            marginLeft: "auto"
                        }}
                        onClick={() => navigate('/account/register')}
                    >
                        加入享买
                    </Button>
                </Col>
            </Row>
        );
    }


    return (
        <>


            {
                props.renderType !== "logged" &&
                <>
                    <h2 style={{
                        fontWeight: "400"
                    }}>忘记登录密码</h2>
                </>
            }

            <br/>

            {
                currentStep === 1 &&
                <PageStep1/>
            }

            {
                currentStep === 2 &&
                <PageStep2/>
            }

            {
                currentStep === 3 &&
                <PageStep3/>
            }
        </>
    );
}


export default ForgotPassword;