import React, {useEffect, useState} from "react";
import {Col, Row, Radio, Button, Form, Input, Upload, message, Alert, Modal, Divider, notification} from "antd";
import {bucketURL} from "../../data/env";
import {InfoCircleOutlined, LeftOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Password from "antd/es/input/Password";
import {uploadFilesSync} from "../../data/User-Service-Repo";
import { CSSObject } from '@emotion/serialize';
import { ClearIndicatorProps } from 'react-select';

import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';

import {
    editSellStore,
    getEnterpriseApplyTips,
    getPersonalApplyTips,
    submitSellStore
} from "../../data/Customer-Service-Repo";

import AddressAutocomplete from "../../components/AddressAutocomplete";


//部属到 early stage buyer
const StoreList = (props) => {

    const [notificationApi, notificationContextHolder] = notification.useNotification();


    // upload btn
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    // check selected file type
    const checkFileType = (file) => {
        const isPNG = file.type === 'image/png';
        const isJPEG = file.type === 'image/jpeg';
        // const isHEIC = file.type === 'image/heic';

        if (!isPNG && !isJPEG) {
            message.error('您只可以上传图片（PNG, JPEG, JPG）');
            return Upload.LIST_IGNORE;
        }   else {
            return true;
        }
    }

    // convert multiple img dataset to up-loadable dataset
    function convertMultipleImgDataset(dataset, type, storeId){
        var uploadableDataset = [];

        dataset.forEach((item, index) => {
            uploadableDataset.push({
                "id": item.id,
                "storeId": storeId,
                "fileUrl" : item.url,
                "updateId": item.id,
                "createId": item.id,
                "fileType": type,
                "isDel": 0,
                "updateDate": Date.now(),
                "createDate": Date.now()
            })
        })

        return uploadableDataset;
    }

    // convert single img dataset to up-loadable dataset
    function convertSingleImgDataset(dataset){
        if(dataset.length > 0){
            return dataset[0].url;
        } else {
            return "";
        }
    }

    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    // 取得预先填写过的数据（或是被退件的数据）
    // 退件原因
    const [refusedReason, setRefusedReason] = useState("");
    // 用户为新开店
    const [isNewStore, setIsNewStore] = useState(true);

    useEffect(() => {

        // 只有没送出过 和 被退件的数据才会有请求 findSellerStore
        if(props.store != ""){
            // store data 不是 null 代表是被退件的
            setIsNewStore(false);

            // 配置拒绝原因
            setRefusedReason(props.store.data.outRemark);


            // 配置填写状态
            // 说明：后端 checkList 会返回没有通过的内容（以 1, 2, 3, 4, 5 表示）
            // 1. 商铺资料
            // 2. 联络人基本资料
            // 3. 提现帐户验证
            // 4. 身份验证
            // 5. 钱包密码验证

            // 有在 checkList 里面的内容 配置为 alert
            // 没有在 checkList 里面的内容 配置为 success
            // 钱包密码（5），统一配置成 undone
            formStatus.storeInfo = props.store.data.checkList.includes(1) ? "alert" : "success";
            formStatus.contactInfo = props.store.data.checkList.includes(2) ? "alert" : "success";
            formStatus.bankInfo = props.store.data.checkList.includes(3) ? "alert" : "success";
            formStatus.idInfo = props.store.data.checkList.includes(4) ? "alert" : "success";
            formStatus.walletInfo = "undone";

            // process data
            processData(props.store.data)

        } else {
            // 没送出过我们才去 localstorage 查有没有缓存数据，在填上去前端
            if (localStorage.getItem("storeFormData")) {
                // process data
                processData(JSON.parse(localStorage.getItem("storeFormData")))

                // set status
                setFormStatus(JSON.parse(localStorage.getItem("storeFormStatus")));
            }
        }


        function processData(data){
            // ------ 显示前端输入框、选择 和 上传组件内容 ------
            // set form fields (这是用来显示前端的输入框用的)
            form.setFieldsValue({
                storeName : data.storeName,
                storeIntro : data.storeRemark,
                storeContactName : data.liaisonName,
                storeContactPhone : data.liaisonMobile,
                storeContactEmail : data.liaisonEmail,
                storeContactAddressLine : data.liaisonAddressline,
                storeContactAddressSuburb : data.liaisonSuburb,
                storeContactAddressState : data.liaisonState,
                storeContactAddressPostcode : data.liaisonPostcode,
                accountNickname : data.withdrawalNickname,
                accountBSB : data.withdrawalBsb,
                accountNumber : data.withdrawalAccountnumber,
                accountName : data.withdrawalAccountname,
                storeABN : data.identityAbn,
            })


            // set file list storage on state (这是用来显示前端文档上传用的)
            setAvatarFileList(makeSingleAntdUploadDataFromBackendData(data.storeIco)); //单个文件
            setBackgroundFileList(makeSingleAntdUploadDataFromBackendData(data.storeImg)); //单个文件
            setBannerFileList(makeMultipleAntdUploadDataFromBackendData(data.bannerList)); //多个文件
            setVerifyDocsFileList(makeMultipleAntdUploadDataFromBackendData(data.checkfileList)); //多个文件


            // set a backup frontend file list on state （这是用来备用的前端文档上传用的）
            formDataPlainImg.storeIco = (makeSingleAntdUploadDataFromBackendData(data.storeIco)); //单个文件
            formDataPlainImg.storeImg = (makeSingleAntdUploadDataFromBackendData(data.storeImg)); //单个文件
            formDataPlainImg.bannerList = (makeMultipleAntdUploadDataFromBackendData(data.bannerList)); //多个文件
            formDataPlainImg.checkfileList = (makeMultipleAntdUploadDataFromBackendData(data.checkfileList)); //多个


            // set store type on state （这是用来显示商铺验证类型的）
            setStoreType(data.identityStoretype);


            // ------ 处理给后端的数据 ------
            // set from storage（这是用来备用发送给后端用的）
            formData.storeName = data.storeName;
            formData.storeIco = data.storeIco;
            formData.storeImg = data.storeImg;
            formData.bannerList = data.bannerList;
            formData.storeRemark = data.storeRemark;

            formData.liaisonName = data.liaisonName;
            formData.liaisonMobile = data.liaisonMobile;
            formData.liaisonEmail = data.liaisonEmail;
            formData.liaisonAddressline = data.liaisonAddressline;
            formData.liaisonUnit = data.liaisonUnit;
            formData.liaisonSuburb = data.liaisonSuburb;
            formData.liaisonState = data.liaisonState;
            formData.liaisonPostcode = data.liaisonPostcode;

            formData.withdrawalNickname = data.withdrawalNickname;
            formData.withdrawalBsb = data.withdrawalBsb;
            formData.withdrawalAccountnumber = data.withdrawalAccountnumber;
            formData.withdrawalAccountname = data.withdrawalAccountname;

            formData.identityStoretype = data.identityStoretype;
            formData.checkfileList = data.checkfileList;
            formData.identityAbn = data.identityAbn;


            // ------ 因为是被退件的申请（或是已经填写过的申请），所以直接跳过引导页面 ------
            setCurrentStep(3)

        }



        // (后端提供图片 array 时)这个是用来把后端的图片/文档数据转换成前端的数据用的
        function makeMultipleAntdUploadDataFromBackendData(Dataset){
            if(Dataset.length != 0){
                var antdUploadDataset = [];

                Dataset.forEach(function (item, index) {
                    antdUploadDataset.push({
                        uid: item.id,
                        name: item.fileUrl,
                        status: 'done',
                        url: item.fileUrl,
                    })
                });

                return antdUploadDataset;
            }
            return [];
        }

        // （后端提供单图片网址时）这个是用来把后端的图片/文档数据转换成前端的数据用的
        function makeSingleAntdUploadDataFromBackendData(Dataset){
            if(Dataset != ""){
                var antdUploadDataset = [{
                    uid: Dataset,
                    name: Dataset,
                    status: 'done',
                    url: Dataset,
                }];

                return antdUploadDataset;
            }
            return [];
        }

    },[props.store])


    // ---- Form ----
    // step indicator
    const [currentStep, setCurrentStep] = useState(1);

    // run when step change
    useEffect(() => {
        // back to top when step change
        window.scrollTo(0, 0);

        // save to local storage when step change
        if(currentStep === 3){
            // 保存 form data 到 localstorage
            localStorage.setItem("storeFormData", JSON.stringify(formData));

            // 保存 form status 到 localstorage
            localStorage.setItem("storeFormStatus", JSON.stringify(formStatus));
        }

    },[currentStep])


    // form status
    // status:
    //  - success
    //  - alert
    //  - undone
    const [formStatus, setFormStatus] = useState({
        storeInfo: "undone",
        contactInfo: "undone",
        bankInfo: "undone",
        idInfo: "undone",
        walletInfo: "undone"
    })


    // ---- Storage ----
    // total form data
    const [formData, setFormData] = useState({
        storeName: "",
        storeIco: "",
        storeImg: "",
        bannerList: [],
        storeRemark: "",

        liaisonName: "",
        liaisonMobile: "",
        liaisonEmail: "",
        liaisonAddressline: "",
        liaisonUnit: "",
        liaisonSuburb: "",
        liaisonState: "",
        liaisonPostcode: "",

        withdrawalNickname: "",
        withdrawalBsb: "",
        withdrawalAccountnumber: "",
        withdrawalAccountname: "",

        identityStoretype: 0,
        checkfileList: [],
        identityAbn: "",

        walletPassword: "",
    })


    // 这个主要用来存放上传的图片的原始 array
    // 因为后端需要的图片 array 和 antd 的图片 array 格式不一样
    const [formDataPlainImg, setFormDataPlainImg] = useState({
        storeIco: [],
        storeImg: [],
        bannerList: [],
        checkfileList: [],
    })

    // form
    const [form] = Form.useForm();

    // store info
    const [avatarFileList, setAvatarFileList] = useState([]); // 头像
    const [backgroundFileList, setBackgroundFileList] = useState([]); // 底图
    const [bannerFileList, setBannerFileList] = useState([]); // 横幅

    // store id verification
    const [verifyDocsFileList, setVerifyDocsFileList] = useState([]); // 审核文件
    const [storeType, setStoreType] = useState(0); // 商铺验证类型（1个人2企业）



    // when move to next/previous page
    const backToList = () => {
        setIsCloseConfirmationModalOpen(true);

        // scroll to top
        window.scrollTo(0, 0);
    };

    function Intro(){
        return(
            <div className={"intro"}>
                <h1 style={{textAlign: "center"}}>欢迎来到享买商铺</h1>
                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-1.svg"} height="40px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>一站式商铺管理</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-2.svg"} height="40px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>专属钱包保障款项</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-3.svg"} height="40px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>高效率门道门物流服务</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-4.svg"} height="40px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>即时信息不漏接</h3>
                    </Col>
                </Row>
            </div>
        )

    }

    function Step(){
        return(
            <div className={"step"}>
                <h1 style={{textAlign: "center"}}>申请步骤及文件</h1>
                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num1.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>商铺资料</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num2.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>联络人基本资料</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num3.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>提现帐户验证</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num4.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>身分验证</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num5.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>钱包密码验证</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={5}>
                        <img src={varBucketURL + "/assets/icon-store-num6.svg"} height="20px"></img>
                    </Col>

                    <Col span={17}>
                        <h3>开店完成</h3>
                    </Col>
                </Row>
            </div>
        )
    }

    function List(){
        return(
            <div className={"list"}>
                <h1 style={{textAlign: "center"}}>请填妥以下资料</h1>
                <br/>
                {
                    refusedReason !== "" &&
                    <>
                        <Alert
                            message={
                                <>
                                    <strong>您的申请已被拒绝，请依照说明修正后重新提交:</strong><br/>
                                    <span>{refusedReason}</span>
                                </>
                            }
                            type="error" showIcon />
                        <br/>
                    </>
                }
                <Row className={"item"} onClick={()=> setCurrentStep(10)}>
                    <Col span={13} style={{marginLeft: "20px"}} className={"name"}>
                        <strong>商铺资料</strong>
                    </Col>

                    <Col span={7} className={"status"}>
                        {
                            formStatus.storeInfo === "success" &&
                            <img src={varBucketURL + "/assets/icon-check.svg"}></img>
                        }

                        {
                            formStatus.storeInfo === "alert" &&
                            <img src={varBucketURL + "/assets/icon-alert.svg"}></img>
                        }

                        {
                            formStatus.storeInfo === "undone" &&
                            <p>尚未完成</p>
                        }
                    </Col>

                    <Col span={2}>
                        <img src={varBucketURL + "/assets/icon-next.svg"} height="10px"></img>
                    </Col>
                </Row>
                <Row className={"item"} onClick={()=> setCurrentStep(20)}>
                    <Col span={13} style={{marginLeft: "20px"}} className={"name"}>
                        <strong>联络人基本资料</strong>
                    </Col>

                    <Col span={7} className={"status"}>
                        {
                            formStatus.contactInfo === "success" &&
                            <img src={varBucketURL + "/assets/icon-check.svg"}></img>
                        }

                        {
                            formStatus.contactInfo === "alert" &&
                            <img src={varBucketURL + "/assets/icon-alert.svg"}></img>
                        }

                        {
                            formStatus.contactInfo === "undone" &&
                            <p>尚未完成</p>
                        }
                    </Col>

                    <Col span={2}>
                        <img src={varBucketURL + "/assets/icon-next.svg"} height="10px"></img>
                    </Col>
                </Row>
                <Row className={"item"} onClick={()=> setCurrentStep(30)}>
                    <Col span={13} style={{marginLeft: "20px"}} className={"name"}>
                        <strong>提现帐户验证</strong>
                    </Col>

                    <Col span={7} className={"status"}>
                        {
                            formStatus.bankInfo === "success" &&
                            <img src={varBucketURL + "/assets/icon-check.svg"}></img>
                        }

                        {
                            formStatus.bankInfo === "alert" &&
                            <img src={varBucketURL + "/assets/icon-alert.svg"}></img>
                        }

                        {
                            formStatus.bankInfo === "undone" &&
                            <p>尚未完成</p>
                        }
                    </Col>

                    <Col span={2}>
                        <img src={varBucketURL + "/assets/icon-next.svg"} height="10px"></img>
                    </Col>
                </Row>
                <Row className={"item"} onClick={()=> setCurrentStep(40)}>
                    <Col span={13} style={{marginLeft: "20px"}} className={"name"}>
                        <strong>身份验证</strong>
                    </Col>

                    <Col span={7} className={"status"}>
                        {
                            formStatus.idInfo === "success" &&
                            <img src={varBucketURL + "/assets/icon-check.svg"}></img>
                        }

                        {
                            formStatus.idInfo === "alert" &&
                            <img src={varBucketURL + "/assets/icon-alert.svg"}></img>
                        }

                        {
                            formStatus.idInfo === "undone" &&
                            <p>尚未完成</p>
                        }
                    </Col>

                    <Col span={2}>
                        <img src={varBucketURL + "/assets/icon-next.svg"} height="10px"></img>
                    </Col>
                </Row>
                <Row className={"item"} onClick={()=> setCurrentStep(50)}>
                    <Col span={13} style={{marginLeft: "20px"}} className={"name"}>
                        <strong>钱包密码验证</strong>
                    </Col>

                    <Col span={7} className={"status"}>
                        {
                            formStatus.walletInfo === "success" &&
                            <img src={varBucketURL + "/assets/icon-check.svg"}></img>
                        }

                        {
                            formStatus.walletInfo === "alert" &&
                            <img src={varBucketURL + "/assets/icon-alert.svg"}></img>
                        }

                        {
                            formStatus.walletInfo === "undone" &&
                            <p>尚未完成</p>
                        }
                    </Col>

                    <Col span={2}>
                        <img src={varBucketURL + "/assets/icon-next.svg"} height="10px"></img>
                    </Col>
                </Row>
            </div>
        );
    }

    // step = 10
    function ListForm1(){
        // --- preview box ---
        const [isPreviewBoxOpen, setIsPreviewBoxOpen] = useState(false);
        const [previewImageURL, setPreviewImageURL] = useState("");

        function PreviewBox(){
            return (
                <Modal title="图片预览" width={600} open={isPreviewBoxOpen} onCancel={handlePreviewBoxCancel} footer={null}>
                    <img width={"100%"} src={previewImageURL}></img>
                </Modal>
            );
        }
        const handlePreviewBoxCancel = () => {
            setIsPreviewBoxOpen(false);
        };

        const handlePreviewBoxOpen = (data) => {
            setIsPreviewBoxOpen(true);
            setPreviewImageURL(data.url);
        };


        // --- 商铺资料的的「Banner/头像/底图」位置提示弹窗 ---
        const [isStoreInfoTipsDialogModalOpen, setIsStoreInfoTipsDialogModalOpen] = useState(false);
        const [modalTitle, setModalTitle] = useState("");
        const [modalContext, setModalContext] = useState([]);
        function StoreInfoTipsDialog(){

            return(
                <Modal
                    title={modalTitle}
                    open={isStoreInfoTipsDialogModalOpen}
                    onCancel={() => {
                        setIsStoreInfoTipsDialogModalOpen(false)
                        setModalContext([]); // empty context when close
                    }}
                    footer={[]}
                    width={"25%"}
                    style={{
                        minWidth: "320px"
                    }}
                >
                    {modalContext}
                </Modal>
            );
        }



        // -------- 商鋪頭像 --------
        const handleAvatarRemove = async () => {
            setAvatarFileList([]);
        }

        const handleAvatarChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setAvatarFileList(request);


            var result = await uploadFilesSync(data);
            setAvatarFileList(result);
        }



        // -------- 商鋪底圖 --------
        const handleBackgroundRemove = async () => {
            setBackgroundFileList([]);
        }

        const handleBackgroundChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setBackgroundFileList(request);


            var result = await uploadFilesSync(data);
            setBackgroundFileList(result);
        }



        // -------- 商鋪 Banner --------
        const handleBannerRemove = async (data) => {
            var array = []

            // don't know why splice comes up with wired result, so I am using the other way to remove element from array
            bannerFileList.forEach(element => {
                if(element.uid != data.uid){
                    array.push(element)
                }
            });

            setBannerFileList(array);
        }

        const handleBannerChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setBannerFileList(bannerFileList.concat(request));

            var result = await uploadFilesSync(data);

            if(bannerFileList.length === 0){
                setBannerFileList(result);
            } else {
                setBannerFileList(bannerFileList.concat(result));
            }
        }



        const onSubmit = async () => {
            // 在 state 中保存后端需要的数据格式
            formData.storeName = form.getFieldValue("storeName")
            formData.storeIco = convertSingleImgDataset(avatarFileList)
            formData.storeImg = convertSingleImgDataset(backgroundFileList)

            // BUG FIXED: 20230530: 修复了商铺介绍没有填写时，会造成移动端错误的问题
            // getFieldValue 如果 Field 没填写时，会变成 undefined
            // 因此如果没有填写商铺介绍，就传空值，而不是 undefined。不然会被后端认为是 null，并造成移动端错误
            if(form.getFieldValue("storeIntro") === undefined){
                formData.storeRemark = ""
            } else {
                formData.storeRemark = form.getFieldValue("storeIntro")
            }

            formData.bannerList = convertMultipleImgDataset(bannerFileList, "1", "")


            // 在 state 中保存前端 antd 需要的图片数据格式
            formDataPlainImg.storeIco = avatarFileList
            formDataPlainImg.storeImg = backgroundFileList
            formDataPlainImg.bannerList = bannerFileList

            // 确认所有必填项当填写完
            if(form.getFieldValue("storeName")!==null){
                formStatus.storeInfo = "success"
            }

            // back to list
            setCurrentStep(3)
            window.scrollTo(0, 0);
        }


        // 间听 closeSelection 变化
        useEffect(() => {
            // 只有在用户确认关闭选择框时才会触发
            if(closeSelection === "close"){

                // clean up 表单内容（恢复成总存储的内容）
                // form
                form.setFieldsValue({
                    storeName: formData.storeName,
                    storeIntro: formData.storeRemark,
                })
                // state
                setAvatarFileList(formDataPlainImg.storeIco)
                setBackgroundFileList(formDataPlainImg.storeImg)
                setBannerFileList(formDataPlainImg.bannerList)


                // 重置 closeSelection
                setCloseSelection("");

                // 返回列表
                setCurrentStep(3);
                window.scrollTo(0, 0);

            }

        },[closeSelection])



        return(
            <Form
                name="form"
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <StoreInfoTipsDialog/>
                <PreviewBox/>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                    <span onClick={backToList}><LeftOutlined /> &nbsp;&nbsp;</span>
                    <h2>商铺基本资料</h2>
                </div>
                <Alert message="审核成功后，您可以在「商铺设置」中修改此设置。" type="warning" showIcon />
                <br/>
                <Form.Item
                    label="您的商铺名称"
                    name={"storeName"}
                    rules={[
                        {
                            required: true,
                            message: '请输入商铺名称',
                        },
                    ]}
                >
                    <Input placeholder="请输入商铺名称..." />
                </Form.Item>

                <Form.Item
                    name={"storeAvatar"}
                >
                    <div>
                        <span style={{marginBottom: "10px", marginRight: "5px", display: "inline-block"}}>您的商铺头像</span>
                        <span
                            onClick={() => {
                                setIsStoreInfoTipsDialogModalOpen(true);
                                setModalTitle("商铺头像");
                                modalContext.push(
                                    <img src={varBucketURL + "/assets/store-tips-avatar.jpg"} width={"100%"}></img>
                                )
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                            role="img"
                            aria-label="info-circle"
                            title=""
                            className="anticon anticon-info-circle ant-form-item-tooltip"
                        >
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
                                d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>
                        </span>
                    </div>

                    <Upload
                        listType="picture-circle"
                        fileList={avatarFileList}
                        onRemove={handleAvatarRemove}
                        customRequest={handleAvatarChange}
                        onPreview={handlePreviewBoxOpen}
                        beforeUpload={checkFileType}
                    >
                        {avatarFileList.length >= 1 ? null : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item
                    name={"storeBackground"}
                >
                    <div>
                        <span style={{marginBottom: "10px", marginRight: "5px", display: "inline-block"}}>您的商铺底图</span>
                        <span
                            onClick={() => {
                                setIsStoreInfoTipsDialogModalOpen(true);
                                setModalTitle("您的商铺底图");
                                modalContext.push(
                                    <img src={varBucketURL + "/assets/store-tips-bg.jpg"} width={"100%"}></img>
                                )
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                            role="img"
                            aria-label="info-circle"
                            title=""
                            className="anticon anticon-info-circle ant-form-item-tooltip"
                        >
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
                                    d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>
                        </span>
                    </div>
                    <Upload
                        action=""
                        listType="picture-card"
                        fileList={backgroundFileList}
                        onRemove={handleBackgroundRemove}
                        customRequest={handleBackgroundChange}
                        onPreview={handlePreviewBoxOpen}
                        beforeUpload={checkFileType}
                    >
                        {backgroundFileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    <br/><br/>
                </Form.Item>

                <Form.Item
                    label="您的商铺介绍"
                    name={"storeIntro"}
                >
                    <TextArea rows={4} placeholder={"請輸入商鋪介紹..."}/>
                </Form.Item>

                <Form.Item
                    name={"storeBanner"}
                >
                    <div>
                        <span style={{marginBottom: "10px", marginRight: "5px", display: "inline-block"}}>您的商铺Banner</span>
                        <span
                            onClick={() => {
                                setIsStoreInfoTipsDialogModalOpen(true);
                                setModalTitle("您的商铺介绍 Banner");
                                modalContext.push(
                                    <img src={varBucketURL + "/assets/store-tips-banner.jpg"} width={"100%"}></img>
                                )
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                            role="img"
                            aria-label="info-circle"
                            title=""
                            className="anticon anticon-info-circle ant-form-item-tooltip"
                        >
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
                                    d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>
                        </span>
                    </div>
                    <Upload
                        action=""
                        listType="picture-card"
                        fileList={bannerFileList}
                        onRemove={handleBannerRemove}
                        customRequest={handleBannerChange}
                        onPreview={handlePreviewBoxOpen}
                        beforeUpload={checkFileType}
                        style={{width: "200px"}}
                    >
                        {bannerFileList.length >= 6 ? null : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <br/>
                    <Button htmlType="submit" type={"primary"} size={"large"} style={{width: "100%"}}>
                        保存
                    </Button>
                    <Button
                        type={"text"}
                        onClick={backToList}
                        style={{width: "100%", color: "#f46e12", marginTop: "20px"}}>
                        返回主列表
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    // step = 20
    function ListForm2(){

        // Google address autocompleted
        const [addressValue, setAddressValue] = useState('');
        const [suburbValue, setSuburbValue] = useState('');
        const [stateValue, setStateValue] = useState('');
        const [postcodeValue, setPostCodeValue] = useState('');

        const onAutoCompletedSelected = async (data) => {
            // set address line
            setAddressValue(data.value.structured_formatting.main_text)

            // fetch detail
            var placeDetail = await geocodeByPlaceId(data.value.place_id)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return error;
                });


            var suburb;
            var state;
            var postcode;

            // 针对有些地址没有号码的情况
            // 没有 street no. 的话 address_components.length 会是 6
            // 有 street no. 的话 address_components.length 会是 7
            if(placeDetail[0].address_components.length === 6){
                suburb = placeDetail[0].address_components[1].long_name;
                state = placeDetail[0].address_components[3].short_name;
                postcode = placeDetail[0].address_components[5].long_name;

            } else if(placeDetail[0].address_components.length === 7){
                // set address detail
                suburb = placeDetail[0].address_components[2].long_name;
                state = placeDetail[0].address_components[4].short_name;
                postcode = placeDetail[0].address_components[6].long_name;
            }

            // set address detail
            setSuburbValue(suburb);
            setStateValue(state);
            setPostCodeValue(postcode);



            form.setFieldsValue({
                storeContactAddressLine: data.value.structured_formatting.main_text,
                storeContactAddressSuburb: suburb,
                storeContactAddressState: state,
                storeContactAddressPostcode: postcode,
            });


            notificationApi.success({
                message: `地址填写成功`,
                description: '以为您自动填写 Suburb, State, Postcode',
                placement: "bottomRight",
            });
        }


        const onSubmit = async () => {
            // 在 state 中保存后端需要的数据格式
            formData.liaisonName = form.getFieldValue("storeContactName")
            formData.liaisonMobile = form.getFieldValue("storeContactPhone")
            formData.liaisonEmail = form.getFieldValue("storeContactEmail")
            formData.liaisonAddressline = form.getFieldValue("storeContactAddressLine")

            // getFieldValue 如果 Field 没填写时，会变成 undefined
            // 因此如果没有填写商铺介绍，就传空值，而不是 undefined。不然会被后端认为是 null，并造成移动端错误
            if(form.getFieldValue("storeContactAddressUnit") === undefined){
                formData.liaisonUnit = ""
            } else {
                formData.liaisonUnit = form.getFieldValue("storeContactAddressUnit")
            }

            formData.liaisonSuburb = form.getFieldValue("storeContactAddressSuburb")
            formData.liaisonState = form.getFieldValue("storeContactAddressState")
            formData.liaisonPostcode = form.getFieldValue("storeContactAddressPostcode")

            // 确认所有必填项当填写完
            if(
                form.getFieldValue("storeContactName") &&
                form.getFieldValue("storeContactPhone") &&
                form.getFieldValue("storeContactEmail") &&
                form.getFieldValue("storeContactAddressLine") &&
                form.getFieldValue("storeContactAddressSuburb") &&
                form.getFieldValue("storeContactAddressState") &&
                form.getFieldValue("storeContactAddressPostcode")
            ){
                formStatus.contactInfo = "success"
            }

            // back to list
            setCurrentStep(3)
            window.scrollTo(0, 0);

        }



        // 间听 closeSelection 变化
        useEffect(() => {
            // 只有在用户确认关闭选择框时才会触发
            if(closeSelection === "close"){

                // clean up 表单内容（恢复成总存储的内容）
                // form
                form.setFieldsValue({
                    storeContactName: formData.liaisonName,
                    storeContactPhone: formData.liaisonMobile,
                    storeContactEmail: formData.liaisonEmail,
                    storeContactAddressLine: formData.liaisonAddressline,
                    storeContactAddressUnit: formData.liaisonUnit,
                    storeContactAddressSuburb: formData.liaisonSuburb,
                    storeContactAddressState: formData.liaisonState,
                    storeContactAddressPostcode: formData.liaisonPostcode
                })

                // 重置 closeSelection
                setCloseSelection("");

                // 返回列表
                setCurrentStep(3);
                window.scrollTo(0, 0);

            }

        },[closeSelection])


        return(
            <Form
                name="form"
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                    <span onClick={backToList}><LeftOutlined /> &nbsp;&nbsp;</span>
                    <h2>联络人基本资料</h2>
                </div>
                <Alert message="此资料会用于身份核实及运费计算，请确保使用英文填写。买家下单后您就不可更改地址，请确认地址无误。" type="warning" showIcon />
                <br/>


                <Form.Item
                    label="您的姓名"
                    name={"storeContactName"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的姓名',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的姓名..." />
                </Form.Item>

                <Form.Item
                    label="您的手机号（4 开头）"
                    name={"storeContactPhone"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的手机号',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && !value.match(/^4[0-9]{8}$/)){
                                    return Promise.reject("手机号不正确（4 开头，共 9 码数字）"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Input placeholder="请输入您的手机号..." addonBefore="+61"/>
                </Form.Item>

                <Form.Item
                    label="您的电子邮箱"
                    name={"storeContactEmail"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的电子邮箱',
                        },
                        {
                            type: 'email',
                            message: '请输入正确的电子邮箱',
                        }
                    ]}
                >
                    <Input placeholder="请输入您的电子邮箱..." />
                </Form.Item>

                <Form.Item
                    label="您的联络地址"
                    name={"storeContactAddress"}
                    value={addressValue}
                >
                    <AddressAutocomplete
                        onAutoCompletedSelected={onAutoCompletedSelected}
                        addressValue={addressValue}
                    />
                </Form.Item>

                <Form.Item
                    label="Unit:"
                    name={"storeContactAddressUnit"}
                >
                    <Input placeholder="例：U2001" />
                </Form.Item>

                <Divider />

                <Form.Item
                    label="Address Line"
                    name={"storeContactAddressLine"}
                    rules={[
                        {
                            required: true,
                            message: '请输入于上方 Address 输入地址...',
                        },
                    ]}
                >
                    <Input placeholder="请于 Address 选填" disabled/>
                </Form.Item>

                <Form.Item
                    label="Suburb:"
                    name={"storeContactAddressSuburb"}
                    rules={[
                        {
                            required: true,
                            message: '请输入于上方 Address 输入地址...',
                        },
                    ]}
                >
                    <Input placeholder="请于 Address 选填" disabled/>
                </Form.Item>

                <Form.Item
                    name={"storeContactAddressState"}
                    label="State:"
                    rules={[
                        {
                            required: true,
                            message: '请输入于上方 Address 输入地址...',
                        },
                    ]}
                >
                    <Input placeholder="请于 Address 选填" disabled/>
                </Form.Item>

                <Form.Item
                    name={"storeContactAddressPostcode"}
                    label="Postcode:"
                    rules={[
                        {
                            required: true,
                            message: '请输入于上方 Address 输入地址...',
                        },
                    ]}
                >
                    <Input placeholder="请于 Address 选填" disabled/>
                </Form.Item>

                <Form.Item>
                    <br/>
                    <Button htmlType="submit" type={"primary"} size={"large"} style={{width: "100%"}}>
                        保存
                    </Button>
                    <Button
                        type={"text"}
                        onClick={backToList}
                        style={{width: "100%", color: "#f46e12", marginTop: "20px"}}>
                        返回主列表
                    </Button>
                </Form.Item>

            </Form>
        );
    }

    // step = 30
    function ListForm3(){

        const onSubmit = async () => {
            // 在 state 中保存后端需要的数据格式
            formData.withdrawalNickname = form.getFieldValue("accountNickname")
            formData.withdrawalBsb = form.getFieldValue("accountBSB")
            formData.withdrawalAccountnumber = form.getFieldValue("accountNumber")
            formData.withdrawalAccountname = form.getFieldValue("accountName")

            // 确认所有必填项当填写完
            if(
                form.getFieldValue("accountNickname") &&
                form.getFieldValue("accountBSB") &&
                form.getFieldValue("accountNumber") &&
                form.getFieldValue("accountName")
            ){
                formStatus.bankInfo = "success"
            }

            // back to list
            setCurrentStep(3)
            window.scrollTo(0, 0);

        }



        // 间听 closeSelection 变化
        useEffect(() => {
            // 只有在用户确认关闭选择框时才会触发
            if(closeSelection === "close"){

                // clean up 表单内容（恢复成总存储的内容）
                // form
                form.setFieldsValue({
                    accountNickname: formData.withdrawalNickname,
                    accountBSB: formData.withdrawalBsb,
                    accountNumber: formData.withdrawalAccountnumber,
                    accountName: formData.withdrawalAccountname
                })

                // 重置 closeSelection
                setCloseSelection("");

                // 返回列表
                setCurrentStep(3);
                window.scrollTo(0, 0);

            }

        },[closeSelection])

        return(
            <Form
                name="form"
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                    <span onClick={backToList}><LeftOutlined /> &nbsp;&nbsp;</span>
                    <h2>提现帐户验证</h2>
                </div>

                <Alert message="此资料会用于核实身份，同时也会设为订单完成后的主要提现帐户，审核成功后，您可以在「钱包设置」中修改或新增帐户。" type="warning" showIcon />
                <br/>
                <Form.Item
                    label="您的帐户昵称"
                    name={"accountNickname"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的帐户昵称',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的帐户昵称..." />
                </Form.Item>

                <Form.Item
                    label="您的 BSB (请输入数字，不包含符号)"
                    name={"accountBSB"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的 BSB',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && (value.length !== 6 || !/^\d+$/.test(value))){
                                    return Promise.reject("BSB 应该为 6 位数字(不包含杠 -)"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Input placeholder="请输入您的 BSB..." />
                </Form.Item>

                <Form.Item
                    label="您的 Account Number"
                    name={"accountNumber"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的 Account Number',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && !/^\d+$/.test(value)){
                                    return Promise.reject("Account Number 数字"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Input placeholder="请输入您的 Account Number..." />
                </Form.Item>

                <Form.Item
                    label="您的 Account Name"
                    name={"accountName"}
                    rules={[
                        {
                            required: true,
                            message: '请输入您的 Account Name',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的 Account Name..." />
                </Form.Item>

                <Form.Item>
                    <br/>
                    <Button htmlType="submit" type={"primary"} size={"large"} style={{width: "380px"}}>
                        保存
                    </Button>
                    <Button
                        type={"text"}
                        onClick={backToList}
                        style={{width: "380px", color: "#f46e12", marginTop: "20px"}}>
                        返回主列表
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    // step = 40
    // 验证文件提示信息
    const [verifyDocsAlert, setVerifyDocsAlert] = useState("");
    function ListForm4(){
        // --- preview box ---
        const [isPreviewBoxOpen, setIsPreviewBoxOpen] = useState(false);
        const [previewImageURL, setPreviewImageURL] = useState("");

        function PreviewBox(){
            return (
                <Modal title="图片预览" width={600} open={isPreviewBoxOpen} onCancel={handlePreviewBoxCancel} footer={null}>
                    <img width={"100%"} src={previewImageURL}></img>
                </Modal>
            );
        }
        const handlePreviewBoxCancel = () => {
            setIsPreviewBoxOpen(false);
        };

        const handlePreviewBoxOpen = (data) => {
            setIsPreviewBoxOpen(true);
            setPreviewImageURL(data.url);
        };



        // -------- 验证文件 --------
        const handleVerifyDocsRemove = async (data) => {
            var array = []

            // don't know why splice comes up with wired result, so I am using the other way to remove element from array
            verifyDocsFileList.forEach(element => {
                if(element.uid != data.uid){
                    array.push(element)
                }
            });

            setVerifyDocsFileList(array);
        }

        const handleVerifyDocsChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setVerifyDocsFileList(verifyDocsFileList.concat(request));

            var result = await uploadFilesSync(data);

            if(verifyDocsFileList.length === 0){
                setVerifyDocsFileList(result);
            } else {
                setVerifyDocsFileList(verifyDocsFileList.concat(result));
            }
        }

        // trigger 验证文件提示信息 when 用户选择商铺身分（storeType）
        useEffect(() => {
            async function fetchData() {
                var result;

                if (storeType === 1) { // 个人
                    result = await getPersonalApplyTips();
                    setVerifyDocsAlert(result.data)
                    console.log(result)

                } else if (storeType === 2) { // 企业
                    result = await getEnterpriseApplyTips();
                    setVerifyDocsAlert(result.data)
                    console.log(result)

                }

            }
            fetchData();
        }, [storeType]);




        const onSubmit = async () => {
            // 在 state 中保存后端需要的数据格式
            formData.identityStoretype = storeType
            formData.identityAbn = form.getFieldValue("storeABN")
            formData.checkfileList = convertMultipleImgDataset(verifyDocsFileList, "2", "")

            // 在 state 中保存前端 antd 需要的图片数据格式
            formDataPlainImg.checkfileList = verifyDocsFileList

            // 确认所有必填项当填写完
            if(
                storeType !== 0 &&
                form.getFieldValue("storeABN") &&
                verifyDocsFileList.length !== 0
            ){
                formStatus.idInfo = "success"
            }

            // back to list
            setCurrentStep(3)
            window.scrollTo(0, 0);

        }



        // 间听 closeSelection 变化
        useEffect(() => {
            // 只有在用户确认关闭选择框时才会触发
            if(closeSelection === "close"){

                // clean up 表单内容（恢复成总存储的内容）
                // form
                form.setFieldsValue({
                    storeABN: formData.identityAbn,
                })

                // state
                setStoreType(formData.identityStoretype)
                setVerifyDocsFileList(formDataPlainImg.checkfileList)

                // 重置 closeSelection
                setCloseSelection("");

                // 返回列表
                setCurrentStep(3);
                window.scrollTo(0, 0);

            }

        },[closeSelection])



        return(
            <Form
                name="form"
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <PreviewBox/>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                    <span onClick={backToList}><LeftOutlined /> &nbsp;&nbsp;</span>
                    <h2>身份验证</h2>
                </div>

                <Alert message={<span>享买需要验证您的身份来保障交易安全，资料将会受 Victoria Privacy Laws 保障，您也有与个人资料相关的行使权，请参阅 <span style={{color:"#f46e12", textDecoration:"underline", cursor:"pointer"}} onClick={()=>window.open('https://redirect-app.shopmy.top/privacy/', '_blank')}>帮助中心</span>。</span>} type="warning" showIcon />
                <br/>
                <br/>
                <Form.Item
                    label={
                        <>
                            <div className={"input-required"}>*</div>
                            请选择商铺验证类型
                        </>
                    }
                    name={"storeType"}
                    tooltip={{ title: '「商铺验证类型」不会影响您使用享买商铺的权益', icon: <InfoCircleOutlined /> }}
                    rules={[
                        // 自定义校验规则
                        () => ({
                            validator(rule, value) {
                                if(storeType === 0){
                                    return Promise.reject("请选择商铺验证类型"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                    value={storeType}
                >
                    <Radio.Group style={{display: "none"}}>
                        <Radio value="1">个人商铺</Radio>
                        <Radio value="2">企业商铺</Radio>
                    </Radio.Group>

                    <div onClick={()=>setStoreType(1)} style={{marginTop: "15px"}}>
                        <Row className={`store-type-selector ${storeType === 1 &&"store-type-selector-selected"}`}>
                            <Col span={6} className={"img-container"}>
                                <img src={varBucketURL + `/assets/icon-store-individual-${storeType === 1 ? "orange" : "grey"}.svg`} height="35px"></img>
                            </Col>
                            <Col span={18} className={"container"}>
                                <strong className={"type"}>个人商铺</strong>
                                <span className={"description"}>「个人商铺」为法人自己 (Individual) 所开设的商铺，只需实名制负责人个人的身份。</span>
                            </Col>
                        </Row>
                    </div>
                    <br/>

                    <div onClick={()=>setStoreType(2)} style={{marginBottom: "10px"}}>
                        <Row className={`store-type-selector ${storeType === 2 &&"store-type-selector-selected"}`}>
                            <Col span={6} className={"img-container"}>
                                <img src={varBucketURL + `/assets/icon-store-business-${storeType === 2 ? "orange" : "grey"}.svg`} height="35px"></img>
                            </Col>
                            <Col span={18} className={"container"}>
                                <strong className={"type"}>企业商铺</strong>
                                <span className={"description"}>「企业商铺」为公司组织所开设的商铺，需验证公司主体与负责人的身份关系。</span>
                            </Col>
                        </Row>
                    </div>

                </Form.Item>
                <br/>
                <br/>

                {
                    storeType !== 0 &&
                    <>
                        <Form.Item
                            label={
                                <>
                                    <div className={"input-required"}>*</div>
                                    审核文件上传
                                </>
                            }
                            name={"storeVerifyDocs"}
                            rules={[
                                // 自定义校验规则
                                () => ({
                                    validator(rule, value) {

                                        if(verifyDocsFileList.length === 0){
                                            return Promise.reject("请上传审核文件..."); // The validator should always return a promise on both success and error
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}
                            extra={"请上传 JPG, JPEG, PNG 格式的图片"}
                        >
                            <div className={"store-verify-docs"}>
                                <Alert
                                    message={
                                        <>
                                            <strong>请依照下述说明上传文件:</strong>
                                            <br/>
                                            {verifyDocsAlert}
                                        </>
                                    }
                                    type="info"
                                    style={{
                                        marginBottom: "15px",
                                        whiteSpace: "pre-line"
                                    }}
                                />
                            </div>
                            <Upload
                                action=""
                                listType="picture-card"
                                fileList={verifyDocsFileList}
                                onPreview={handlePreviewBoxOpen}
                                customRequest={handleVerifyDocsChange}
                                onRemove={handleVerifyDocsRemove}
                                beforeUpload={checkFileType}
                                style={{width: "200px"}}
                            >
                                {verifyDocsFileList.length >= 5 ? null : uploadButton}
                            </Upload>

                        </Form.Item>
                        <br/>
                        <br/>

                        <Form.Item
                            label="ABN"
                            name={"storeABN"}
                            tooltip={{
                                title: <span>依据法令，商业行为需拥有 ABN，请提供个人/企业 ABN。如果没有，您可以暂时离开此页面在政府网站免费申请，以上填写的资料将会自动保存，查看申请教程请点击「<span style={{color:"#f46e12", textDecoration:"underline", cursor:"pointer"}} onClick={()=>window.open('https://redirect-app.shopmy.top/abn-register/', '_blank')}>幫助中心</span>」</span>,
                                icon: <InfoCircleOutlined />
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入您的 ABN',
                                },
                            ]}
                        >
                            <Input placeholder="请输入您的 ABN..." />
                        </Form.Item>
                    </>
                }

                <Form.Item>
                    <br/>
                    <Button htmlType="submit" type={"primary"} size={"large"} style={{width: "100%"}}>
                        保存
                    </Button>
                    <Button
                        type={"text"}
                        onClick={backToList}
                        style={{width: "100%", color: "#f46e12", marginTop: "20px"}}>
                        返回主列表
                    </Button>
                </Form.Item>
            </Form>
        )

    }

    // step = 50
    function ListForm5(){
        const onSubmit = async () => {
            // 转换成 md5
            var CryptoJS = require("crypto-js");

            // 在 state 中保存后端需要的数据格式
            formData.walletPassword = CryptoJS.MD5(form.getFieldValue("storePassword1")).toString().toUpperCase()


            // 确认所有必填项当填写完
            if(
                form.getFieldValue("storePassword1")
            ){
                formStatus.walletInfo = "success"
            }

            // 清理表格中的密码，防止用户窥视先前输入过的密码
            form.setFieldsValue({
                storePassword1: "",
                storePassword2: ""
            })

            // back to list
            setCurrentStep(3)
            window.scrollTo(0, 0);

        }



        // 间听 closeSelection 变化
        // 钱包密码和其他表单不同，不要把钱包密码的数据放回表单中，防止用户窥视先前输入过的密码
        useEffect(() => {
            // 只有在用户确认关闭选择框时才会触发
            if(closeSelection === "close"){
                // 清理表格中的密码，防止用户窥视先前输入过的密码
                form.setFieldsValue({
                    storePassword1: "",
                    storePassword2: ""
                })

                // 重置 closeSelection
                setCloseSelection("");

                // 返回列表
                setCurrentStep(3);
                window.scrollTo(0, 0);

            }

        },[closeSelection])

        return(
            <Form
                name="form"
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                    <span onClick={backToList}><LeftOutlined /> &nbsp;&nbsp;</span>
                    <h2>钱包密码设置</h2>
                </div>
                <br/>
                <Form.Item
                    label="请设置钱包密码（6位数字）"
                    name={"storePassword1"}
                    rules={[
                        {
                            required: true,
                            message: '请输入钱包密码',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && (value.length !== 6 || !/^\d+$/.test(value))){
                                    return Promise.reject("密码应该为 6 位数字"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Password placeholder="请输入钱包密码..." />
                </Form.Item>

                <Form.Item
                    label="请再次输入钱包密码（6位数字）"
                    name={"storePassword2"}
                    rules={[
                        {
                            required: true,
                            message: '请再次输入钱包密码',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && (getFieldValue("storePassword1") !== value)){
                                    return Promise.reject("两次输入的密码不匹配");

                                } else if(value != "" && (value.length !== 6 || !/^\d+$/.test(value))){
                                    return Promise.reject("密码应该为 6 位数字");

                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Password
                        placeholder="请再次输入钱包密码..."
                    />
                </Form.Item>

                <Form.Item
                    className={"btn"}>
                    <br/>
                    <Button htmlType="submit" type={"primary"} size={"large"} style={{width: "100%"}}>
                        保存
                    </Button>
                    <Button
                        type={"text"}
                        onClick={backToList}
                        style={{width: "100%", color: "#f46e12", marginTop: "20px"}}>
                        返回主列表
                    </Button>
                </Form.Item>
            </Form>
        );
    }



    // ---- 这个是用来询问用户是否要关闭当前表单的弹窗 ----
    // close dialog
    const [isCloseConfirmationModalOpen, setIsCloseConfirmationModalOpen] = useState(false);

    // user selection
    // initial: ""
    // remain open: false
    // close: true
    const [closeSelection, setCloseSelection] = useState("");


    // 这个控制件，会在用户选择「关闭」或「保持开启时」更改 closeSelection 的值
    // 在每一表单 component 要用 useEffect 监听 closeSelection 的变化，如果是 true，执行 clean up 并触发关闭表单的动作

    // 完成后要记得在 useEffect 里面把 closeSelection 的值恢复位（to initial）
    function CloseConfirmationDialog(){

        const handleClose = () => {
            setCloseSelection("close");
            setIsCloseConfirmationModalOpen(false);
        };
        const handleOpen = () => {
            setCloseSelection("open");
            setIsCloseConfirmationModalOpen(false);
        };



        return(
            <Modal
                title="请问是否返回？"
                open={isCloseConfirmationModalOpen}
                onCancel={() => setIsCloseConfirmationModalOpen(false)}
                footer={[
                    <Button key="close" onClick={handleClose}>
                        是，舍弃编辑
                    </Button>,
                    <Button key="open" type={"primary"} onClick={handleOpen}>
                        否，保持开启
                    </Button>,
                ]}
            >
                <p>返回后当前所填写的内容将会遗失。如果您想要保存当前内容，请先选择「否，保持开启」，再至表单最下方选择橘色「保存按钮」</p>
            </Modal>
        );
    }


    // 送出表单时的确认弹窗
    const [isSubmitConfirmationModalOpen, setIsSubmitConfirmationModalOpen] = useState(false);
    function SubmitConfirmationDialog(){
        const handleCancel = () => {
            setIsSubmitConfirmationModalOpen(false);
        };

        const handleConfirm = async () => {
            setIsSubmitConfirmationModalOpen(false);

            // check is all the step finished
            if (
                formStatus.storeInfo !== "success" ||
                formStatus.contactInfo !== "success" ||
                formStatus.bankInfo !== "success" ||
                formStatus.idInfo !== "success" ||
                formStatus.walletInfo !== "success"
            ) {
                message.error("请完成所有步骤");
                return false;
            }


            var result
            // 这边需要判断用户是「新开店」还是「重送退件」
            // 因为接口不同
            if(isNewStore){
                result = await submitSellStore(formData);
            } else {
                // 因为 editSellStore 需要追加这些额外参数
                formData.userId = props.store.data.userId;
                formData.id = props.store.data.id;
                formData.createId = props.store.data.createId;
                formData.updateId = props.store.data.updateId;
                formData.createDate = props.store.data.createDate;
                formData.updateDate = Date.now();

                result = await editSellStore(formData);
            }




            if (result.code === 200) {
                // when success, redirect to store page
                window.location.href = "/seller";

            } else {
                // when fail, show error message and redirect back to step 3
                message.error(result.message);
                return false;
            }
        };

        return(
            <Modal
                title={"提示"}
                open={isSubmitConfirmationModalOpen}
                onCancel={() => setIsSubmitConfirmationModalOpen(false)}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        返回编辑
                    </Button>,
                    <Button key="confirm" type={"primary"} onClick={handleConfirm}>
                        资料无误确认提交
                    </Button>,
                ]}
            >
                <p style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}>
                    <img src={varBucketURL + "/assets/store-confirm-dialog.svg"} height="200px"></img>
                    <br/><br/>
                    <span>提交审核后就不可以变更以上资料，请如实填写，享买需要 1-3 天审查，如不通过将会通知您前来此页面修改资料。</span>
                    <br/>
                    <span>享买可能会透过手机号或邮箱与联络人进行身份确认，请保持联络方式畅通，以加速审查。</span>
                    <br/>
                    <span>提交前请先阅读我们所有的条约及政策，谢谢。</span>
                </p>
            </Modal>
        );
    }





    return (
        <Row className={"safe-area open-store-page"}>
            {notificationContextHolder}
            <div className={"mobile-container"}>

                <CloseConfirmationDialog />
                <SubmitConfirmationDialog/>
                {
                    currentStep === 1 &&
                    <Intro/>
                }

                {
                    currentStep === 2 &&
                    <Step/>
                }

                {
                    currentStep === 3 &&
                    <>
                        <List/>
                        {/*<Button type={"primary"} size={"large"} onClick={()=> console.log(formData)} style={{width: "280px"}}>DEBUG</Button>*/}
                    </>
                }

                {
                    currentStep === 10 &&
                    <ListForm1/>
                }
                {
                    currentStep === 20 &&
                    <ListForm2/>
                }
                {
                    currentStep === 30 &&
                    <ListForm3/>
                }
                {
                    currentStep === 40 &&
                    <ListForm4/>
                }
                {
                    currentStep === 50 &&
                    <ListForm5/>
                }
            </div>
            <br/>
            {
                (currentStep/10 < 1 && currentStep !== 3) &&
                <>
                    <Button type={"primary"} size={"large"} onClick={()=> setCurrentStep(currentStep+1)} style={{width: "280px", marginBottom: "20px"}}>下一步</Button>
                </>

            }
            {
                currentStep === 3 &&
                <Button type={"primary"} size={"large"} onClick={() => setIsSubmitConfirmationModalOpen(true)} style={{width: "280px", marginBottom: "20px"}}>送出审核</Button>
            }

            {
                (currentStep !== 1 && currentStep/10 < 1) &&
                <Button
                    type={"text"}
                    onClick={()=> setCurrentStep(currentStep - 1)}
                    style={{width: "70%", color: "#f46e12"}}>
                    返回上一页
                </Button>
            }

            {
                currentStep === 1 &&
                <span style={{
                    color: "rgb(196 196 196)",
                    fontSize: "10px"
                }}>
                        点击「下一步」即同意
                        <span
                            style={{
                                color: "#f46e12",
                                fontSize: "10px",
                                cursor: "pointer"
                            }}
                            onClick={
                                ()=>window.open('https://help.shopmy.com.au/kb/settlement-agreement', '_blank')
                            }
                        >
                            &nbsp;商铺入驻条款 Settlement Agreement
                        </span>
                    </span>

            }


        </Row>);
}


export default StoreList;