import React, {useEffect, useState} from "react";
import {Button, Form, Row, Input, Divider, notification, Modal} from "antd";
import {changePassWord} from "../../../data/Customer-Service-Repo";
import Loading from "../../../components/Loading";
import ForgotPassword from "../account/ForgotPassword";

const ChangeLoginPassword = (props) => {
    const [form] = Form.useForm();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    // loading indicator
    const [loading, setLoading] = useState(false);


    async function onFinish(values: any) {
        // start loading
        setLoading(true);


        // post to api
        var result = await changePassWord(values.oldPassword, values.newPassword);

        if (result.code === 200) {
            notificationApi.success({
                message: '密码变更成功',
                description: '密码变更成功',
                placement: "bottomRight",
                duration: 0,
            });

            // delay for 2s
            setTimeout(() => {
                window.location.href = "/profile/account/profile";
            }, 2000);


        } else {
            notificationApi.error({
                message: "密码变更失败",
                description: result.message,
                placement: "bottomRight",
                duration: 3,
            });

            // stop loading
            setLoading(true);

        }
    }


    // forgot password modal
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <Row className={"profile-page-content-container"} id={"change-password"}>
            {notificationContextHolder}
            <div style={{display: "flex", flexDirection: "column"}}>
                <span className={"color-shopmy-orange"} style={{fontSize: "18px", color: "#494949", fontWeight: "500"}}>变更享买登录密码</span>
                <span style={{color: "#9c9c9c", marginTop : "7px"}}>为了保护你帐号的安全，请不要分享你的密码给其他人</span>
                <span style={{color: "#9c9c9c", marginTop : "5px"}}>请确认密码满足以下条件：至少 8 个字，包含大小写、数字</span>

            </div>
            <Divider />
            <div style={{marginLeft: "20px", width: "100%", display: "flex", justifyContent: "center"}}>
                {
                    loading
                        ?
                            <Loading/>


                        :
                            <Form
                                autoComplete="off"
                                form={form}
                                onFinish={onFinish}
                                layout="vertical"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch"
                                }}
                            >
                                <Form.Item
                                    label="当前密码"
                                    name="oldPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                // required
                                                if(!value){
                                                    return Promise.reject("此为必填项"); // The validator should always return a promise on both success and error
                                                }

                                                // all pass
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password placeholder={"请输入当前密码..."}/>
                                </Form.Item>

                                <Form.Item
                                    label="新的密码"
                                    name="newPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                // required
                                                if(!value){
                                                    return Promise.reject("此为必填项"); // The validator should always return a promise on both success and error
                                                }

                                                // format
                                                const regex = new RegExp('^[a-zA-Z0-9!@#$%^&*(){}_+-=]{8,50}$');
                                                if(!regex.test(value)){
                                                    return Promise.reject("请确认您输入的密码符合规则"); // The validator should always return a promise on both success and error

                                                } else {
                                                    // all pass
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password placeholder={"请输入新的密码..."}/>
                                </Form.Item>

                                <Form.Item
                                    label="确认密码"
                                    name="newPassword2"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                // required
                                                if(!value){
                                                    return Promise.reject("此为必填项"); // The validator should always return a promise on both success and error
                                                }

                                                // password match
                                                if (value !== getFieldValue("newPassword")) {
                                                    return Promise.reject("密码不匹配"); // The validator should always return a promise on both success and error
                                                }

                                                // format
                                                const regex = new RegExp('^[a-zA-Z0-9!@#$%^&*(){}_+-=]{8,50}$');
                                                if(!regex.test(value)){
                                                    return Promise.reject("请确认您输入的密码符合规则"); // The validator should always return a promise on both success and error

                                                } else {
                                                    // all pass
                                                    return Promise.resolve();
                                                }


                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password placeholder={"请再次输入新的密码..."}/>
                                </Form.Item>

                                <Form.Item style={{marginBottom: "10px"}}>
                                    <Button type="primary" htmlType="submit" style={{width: "500px"}}>
                                        确认
                                    </Button>
                                </Form.Item>

                                <>
                                    <Button type={"link"} className={"color-shopmy-orange"} style={{padding: "0"}} onClick={()=>{setIsModalOpen(true)}}>
                                        忘记登录密码
                                    </Button>
                                    <Modal
                                        title="忘记登录密码"
                                        open={isModalOpen}
                                        footer={[]}
                                        onCancel={()=>{setIsModalOpen(false)}}
                                        destroyOnClose={true}
                                    >
                                        <ForgotPassword renderType={"logged"} mobile={JSON.parse(localStorage.getItem("User")).mobile}/>
                                    </Modal>
                                </>

                            </Form>
                }

            </div>

        </Row>
    );
}


export default ChangeLoginPassword;