import React, {useEffect, useState} from "react";
import {Avatar, Button, Checkbox, Form, Popover, Row} from "antd";
import {bucketURL} from "../data/env";
import {InfoCircleFilled, LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {unixDateToHumanDate} from "../data/tool";
import {useNavigate} from "react-router-dom";


/**
 * Doc Comment
 * 这个 component 是用来绘制「结算页面」以及「我的优惠券」页面的优惠券 UI
 *
 * @param props
 * - props.source: 有两种类型，
 *      1. 一种是「结算页面」的优惠券: checkout
 *      2. 一种是「我的优惠券」的优惠券: coupon-list
 *
 * - props.coupon: 单张优惠券的数据
 *
 * - props.status: 有两种状态，
 *      1. 一种是「可用」: available
 *      2. 一种是「不可用」: unavailable

 *
 * - props.checked: 是否勾选 (boolean)
 *   备注，此参数只有在 checkout 时生效，用于提示用户该优惠券是否可以使用
 *
 * - props.disabled: 是否禁用 (boolean)
 *   备注，此参数只有在 checkout 时生效，用于提示用户该优惠券是否可以使用
 *
 * - props.handleCouponClick: 点击优惠券的回调函数
 * - props.coupon: 优惠券的数据
 *
 *
 * @returns {JSX.Element}
 *
 */

const GlobalCouponUIBuilder = (props) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });

    function couponDetailFormatter(){
        var detail = [];

        // 先处理折扣信息
        // 优惠方式（1：比例折扣、2：费用折抵）
        if(props.coupon.preferentialType === 1){
            detail.push(<span>{props.coupon.discountRate + "% OFF"}</span>)

        } else if(props.coupon.preferentialType === 2){
            detail.push(<span>折扣 ${props.coupon.discountPrice}</span>)
        }

        return detail;
    }


    function couponSpendingDetailFormatter(){
        var detail = [];

        // 再处理指定商铺
        if(props.coupon.platformStores){
            detail.push(
                <span>
                    于
                    <Popover
                        content={
                            props.coupon.platformStores.map((store) => {
                                return (
                                    <>
                                        <Row
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "0px 0px 5px 5px"
                                            }}
                                        >
                                            <Avatar src={store.storeIco}/>
                                            &nbsp;&nbsp;
                                            <span>{store.storeName}</span>
                                            <Button
                                                type="link"
                                                className={"color-shopmy-orange"}
                                                onClick={() => {
                                                    navigate("/store?id=" + props.coupon.id);
                                                }}
                                                style={{fontSize: "12px"}}
                                            >
                                                去使用 <RightOutlined style={{fontSize: "10px"}}/>
                                            </Button>
                                        </Row>
                                    </>

                                )
                            })
                        }
                        title="以下商铺可以使用"
                        placement="right"
                        overlayInnerStyle={{
                            padding: "18px"
                        }}
                    >
                        <span className='color-shopmy-orange' style={{cursor: "pointer"}}>指定商铺</span>
                    </Popover>
                </span>
            )
        }

        // 低消
        if(props.coupon.minSpendingPrice !== 0){
            detail.push(<span>订单满 ${props.coupon.minSpendingPrice}</span>)
        }

        detail.push(<span> 使用</span>)

        return detail;

    }

    // checkbox loading
    const [checkboxLoading, setCheckboxLoading] = useState(false);

    return (
        <>
            {
                <div
                    style={{
                        backgroundImage: `url(${varBucketURL}${props.coupon.type === 1 ? "/assets/background_checkout_store_3.svg" : "/assets/background_checkout_global_3.svg"})`,
                        height: "122px",
                        backgroundSize: `${props.coupon.type === 1 ? "99.3" : "100%"}`,
                        display: "flex",
                        marginBottom: "15px",
                        width: "570px"
                    }}
                    className={"global-coupon-container"}
                >
                    {
                        props.coupon.type === 1
                        ?
                            <div style={{width: "165px"}} className={"store-container"}>
                                <img className={`store-avatar ${props.status === "unavailable" &&"disabled"}`} src={props.coupon.storeIco}></img>
                                <span className={`store-name truncate ${props.status === "unavailable" &&"disabled"}`} style={{width: "100px"}}>{props.coupon.storeName}</span>
                            </div>
                        :
                            <div style={{width: "165px"}}>&nbsp;</div>
                    }

                    <div
                        className={`coupon-content ${props.status === "unavailable" &&"disabled"}`}
                    >
                        <span className={"title truncate"}>{props.coupon.title}</span>
                        <span className={"detail"}>{couponDetailFormatter()}</span>
                        <span className={"detail"}>{couponSpendingDetailFormatter()}</span>
                        <span className={"date"}>有效期至{unixDateToHumanDate(props.coupon.endDate)}</span>
                    </div>
                    <div className={`alert-container ${props.status === "unavailable" &&"disabled"}`}>
                        {
                            (props.source === "checkout" && props.status === "unavailable") &&
                            <span className={"alert"}><InfoCircleFilled /> &nbsp;優惠券不適用於所選商品</span>
                        }

                        {
                            (props.source === "checkout" && props.status === "available") &&
                            <Form form={form}>
                                <Form.Item name={`${props.coupon.receiveId}-checkbox`}>
                                    <Checkbox
                                        onClick={async (e) => {
                                            setCheckboxLoading(true)
                                            props.handleCouponClick(e.target.checked, props.coupon.receiveId)

                                            // 超时就关闭加载中（这种情况可能是优惠券不可用，所以没呼叫刷新订单单，物件没有rerender，因此需要手动超时关闭加载）
                                            setTimeout(() => {
                                                setCheckboxLoading(false)
                                            }, 2000)
                                        }}
                                        checked={props.checked}
                                        disabled={props.disabled}
                                        className={`color-shopmy-orange ${checkboxLoading ? "d-none" : ""}`}
                                    />
                                    &nbsp;&nbsp;
                                    <LoadingOutlined
                                        className={`color-shopmy-orange ${!checkboxLoading ? "d-none" : ""}`}
                                        style={{ fontSize: 20 }}
                                        spin
                                    />
                                </Form.Item>
                            </Form>
                        }

                        {
                            (props.source === "coupon-list" && props.status === "available" && props.coupon.type === 1) &&
                            <Button
                                type="link"
                                className={"color-shopmy-orange"}
                                onClick={() => {
                                    navigate("/store?id=" + props.coupon.storeId);
                                }}
                            >
                                去使用 <RightOutlined style={{fontSize: "10px"}}/>
                            </Button>
                        }
                    </div>

                </div>
            }
        </>
    );
}


export default GlobalCouponUIBuilder;