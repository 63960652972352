import React, {useEffect, useState} from "react";

import {bucketURL} from "../data/env";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


const Loading = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });


    return (
        <div
            id={"loading"}
            style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "600px"
            }}
        >
            {<Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin/>}/>}
            <h4 style={{color: "#494949"}}>努力加载中...</h4>
        </div>

    );
}


export default Loading;