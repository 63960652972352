import React, {useEffect, useState} from "react";
import {
    Alert,
    Badge,
    Button,
    Col,
    Divider,
    Drawer,
    Image,
    List,
    message,
    notification,
    Row,
    Steps, Tag,
    Timeline
} from "antd";
import {useNavigate} from "react-router-dom";
import {bucketURL} from "../../../data/env";
import {
    LeftOutlined,
    MessageOutlined,
    RightOutlined
} from "@ant-design/icons";
import {findOmsOrder, findOmsOrderRefundInfo} from "../../../data/Order-Service-Repo";
import ShowMoreText from "react-show-more-text";
import {refundStatusMapping, unixDateToHumanDate} from "../../../data/tool";
import Loading from "../../../components/Loading";
import NotFound from "../../../components/NotFound";
import SubmitRequest from "./component/SubmitRequest";


const RefundDetail = (props) => {

    const navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    });

    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // 是否展示订单，用于 notfound
    const [isOrderVisible, setIsOrderVisible] = useState(true);

    // 是否在加载
    const [isLoading, setIsLoading] = useState(true);

    // 订单信息
    const [order, setOrder] = useState([]);
    const [orderOms, setOrderOms] = useState([]);

    useEffect(() => {
        // when there are valid user set token and user object on state
        async function load() {
            // 显示上一次 render 放在 params 上的信息
            if (params.msg !== null) {
                notificationApi.success({
                    message: `提示信息`,
                    description: params.msg,
                    placement: "bottomRight",
                    duration: 7,
                });
            }


            if (params.id === null) {
                messageApi.open({
                    type: 'error',
                    content: '订单号不能为空',
                });

                // 完成加载
                setIsLoading(false);

                // 订单页面无法加载
                setIsOrderVisible(false);

            } else {
                // fetch data
                var result1 = await findOmsOrderRefundInfo(params.id);

                // set data
                if (result1.code === 200) {
                    var result2 = await findOmsOrder([], params.id, "");

                    // 完成加载
                    setIsLoading(false);

                    // 订单页面已经加载
                    setIsOrderVisible(true);

                    // 配置订单数据
                    setOrder(result1.data)

                    // 配置 findOmsOrder 数据
                    setOrderOms(result2.data)

                } else {
                    messageApi.open({
                        type: 'error',
                        content: '无法取得该订单',
                    });

                    // 完成加载
                    setIsLoading(false);

                    // 订单页面无法加载
                    setIsOrderVisible(false);

                }
            }
        }
        load()
    }, []);



    function generateDescription(order){
        // 这边除了后端返回的提示信息
        // 在退款被商铺拒绝时，前端会自动显示出超时的提示信息
        // 但是这个提示信息不会被后端返回，所以需要前端自己判断
        var string = order.auditDescriptionApp;

        // 从待出货发起的退货退款，被商铺拒绝
        if(order.auditStatus === 20 && order.orderStatusRefundBefore === 20)
        {
            // TODO: 这个现在实现不完美：https://www.notion.so/shopmy/012ed178786544ed93e7315dc81eab02?pvs=4
            string+= ("如果您想让商铺继续出货您的订单，请先发起退货退款请求再撤销，订单就能回到「待出货」当中")

        }

        // 从待完成发起的退货退款，被商铺拒绝
        else if(order.auditStatus === 20 && order.orderStatusRefundBefore === 60)
        {
            string+= ("请在" + unixDateToHumanDate(order.orderGrantPriceExpectedDate) + "前回复，超时您的订单将会自动确认收货。")

        }

        return string;
    }


    function generateRefundHistory(negotiatesApp){
        var items = [];

        // 这里先 render 交涉组合
        negotiatesApp.map((negotiate, index) => {
            var children = {
                children :
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <span className={"heading"}>{negotiate.type}</span>
                            &nbsp;&nbsp;&nbsp;
                            {
                                negotiate.type === "判定中心" &&
                                <Tag color="volcano" style={{height: "23px", marginTop: "2px"}}>由享买判定中心处理</Tag>
                            }

                        </div>
                        {
                            // 这里先 render 交涉里面的内容
                            negotiate.items.map((item, index) => {
                                return(
                                    <div className={"history-item"}>
                                        <span className={"title"}>{item.title}</span>
                                        <span className={"date"}>{unixDateToHumanDate(item.createDate)}</span>
                                        <span className={"context"}>{item.content}</span>


                                        {
                                            // 有图片再 render 图片 div，避免无意义的空白
                                            item.files !== null &&
                                            <div className={"image-group"}>
                                                {
                                                    item.files.map((file, index) => {
                                                        return(
                                                            <Image
                                                                width={70}
                                                                src={file.url}
                                                            />
                                                        );

                                                    })
                                                }
                                            </div>

                                        }
                                    </div>
                                );
                            })
                        }
                    </>
            }

            items.push(children);
        })

        console.log(items);

        return items;

    }



    // 协商历史右弹出框控制件
    const [openRefundHistoryDrawer, setOpenRefundHistoryDrawer] = useState(false);
    const showDrawer = () => {
        setOpenRefundHistoryDrawer(true);
    };
    const onClose = () => {
        setOpenRefundHistoryDrawer(false);
    };



    // 发起请求控制件
    // 取消详情 modal
    const [revokeRefundModal, setRevokeRefundModal] = useState(false);

    // 发起退货退款 modal
    const [submitRefundModal, setSubmitRefundModal] = useState(false);

    // 发起判定中心 modal
    const [submitAuditModal, setSubmitAuditModal] = useState(false);


    return (

        <>
            {messageContextHolder}
            {notificationContextHolder}
            {
                isLoading
                    ?
                        <>
                            <Loading/>
                        </>
                    :
                        <Row id={"order-detail-page"}>
                            {
                                isOrderVisible
                                ?
                                    <>
                                        <Col span={24} className={"container"} id={"status"}>
                                            <div>
                                                <div className={"left"}>
                                                    <Button type="dashed" icon={<LeftOutlined/>}
                                                            onClick={() => window.history.go(-1)}>返回上一页</Button>
                                                </div>

                                                <div className={"right"}>
                                                    <span>订单编号：{order.orderCode}</span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Badge
                                                        count={"整张订单退款"}
                                                        style={{
                                                            background: "rgb(255 255 255)",
                                                            border: "1px solid #f46e12",
                                                            color: "#f46e12",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={24} className={"container"} id={"status"}>
                                            <br/>
                                            <Steps
                                                current={order.auditStatusApp}
                                                size="small"
                                                labelPlacement="vertical"
                                                items={[
                                                    {
                                                        title: "提交申請"
                                                    },
                                                    {
                                                        title: "處理中"
                                                    },
                                                    {
                                                        title: "退款成功"
                                                    }
                                                ]}
                                                progressDot
                                            />
                                        </Col>

                                        <Col span={24}
                                             className={"container"}
                                             id={"tips"}
                                        >
                                            <div className={"left"}
                                                 style={{
                                                     flexDirection: "column",
                                                     width: "100%",
                                                     display: "flex",
                                                     alignItems: "flex-start",
                                                }}
                                            >
                                                <div>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <h3 className={"color-shopmy-orange"}>订单状态</h3>
                                                        <strong className={"color-shopmy-orange"}>：{refundStatusMapping(order.auditStatus)} &nbsp;</strong>
                                                    </div>
                                                    <div style={{marginTop:"10px"}}>
                                                        <span>{generateDescription(order)}</span>
                                                    </div>
                                                    <div style={{width:"450px", marginTop:"10px"}}>
                                                        <span className={"color-subtitle-grey"}>状态更新时间：{unixDateToHumanDate(order.statusDateStr)}</span>
                                                    </div>
                                                </div>

                                                <Divider/>
                                                <div style={{width:"550px"}}>
                                                    <span className={"color-subtitle-grey"}>退款原因：{order.refundCause}</span>
                                                </div>
                                                <div style={{
                                                    width:"550px",
                                                    marginTop:"5px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                            <span className={"color-subtitle-grey"}>退款金額：
                                                                <span className={"color-shopmy-orange"} style={{
                                                                    fontSize: "19px",
                                                                    fontWeight: "600"
                                                                }}>
                                                                    ${order.refundPrice.toFixed(2)}
                                                                </span>
                                                            </span>
                                                </div>
                                            </div>

                                        </Col>

                                        <Col span={24}
                                             className={"container"}
                                             id={"tips"}
                                        >
                                            <div >
                                                <div className={"left"} style={{flexDirection: "column"}}>
                                                    <Button onClick={()=>setOpenRefundHistoryDrawer(true)}>查看协商历史</Button>
                                                </div>

                                                <div className={"right"}>
                                                    {
                                                        // 等待商铺审核时才可以撤销退款请求
                                                        order.auditStatus === 10 &&
                                                        <Button type="primary" onClick={()=>setRevokeRefundModal(true)}>撤销退货退款</Button>
                                                    }

                                                    {
                                                        // 被商铺拒绝才可以发判定
                                                        order.auditStatus === 20 &&
                                                        <>
                                                            <Button type="primary" onClick={()=>setSubmitAuditModal(true)}>轉交判定中心</Button>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <Button type="primary" onClick={()=>setSubmitRefundModal(true)}>再次發起申請</Button>
                                                        </>
                                                    }

                                                    {
                                                        //if auditStatus isn't 10 or 20
                                                        (order.auditStatus !== 10 && order.auditStatus !== 20) &&
                                                        <span className={"color-subtitle-grey"}>无可用操作</span>
                                                    }

                                                    {   /*
                                                        *     ||＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
                                                        *     ||                                                                                                              ||
                                                        *     ||        请注意：因为 SubmitRequest 组件的 order props 只接受 findOmsOrder（订单） 的订单数据                        ||
                                                        *     ||        这里的 order 是 findOmsOrderRefundInfo（退款单），不能直接传给 SubmitRequest 组件                          ||
                                                        *     ||        但是在 findOmsOrderRefundInfo（退款单）有一个 order 合集，正好是 findOmsOrder（订单） 的订单数据              ||
                                                        *     ||        所以这里用 orderOms                                                                                    ||
                                                        *     ||                                                                                                              ||
                                                        *     ||＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
                                                        */
                                                    }
                                                    {/*退货退款谈窗*/}
                                                    <SubmitRequest
                                                        order={orderOms}
                                                        open={submitRefundModal}
                                                        onCancel={() => setSubmitRefundModal(false)}
                                                        type={1}
                                                    />

                                                    {/*判定中心请求谈窗*/}
                                                    <SubmitRequest
                                                        order={orderOms}
                                                        open={submitAuditModal}
                                                        onCancel={() => setSubmitAuditModal(false)}
                                                        type={2}
                                                    />

                                                    {/*==撤销退货退款可以传退款单信息==*/}
                                                    {/*撤销退货退款谈窗*/}
                                                    <SubmitRequest
                                                        order={order}
                                                        open={revokeRefundModal}
                                                        onCancel={() => setRevokeRefundModal(false)}
                                                        type={4}
                                                    />


                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={24} className={"container"} id={"receipt"}>
                                            <h3 className={"color-shopmy-orange"} style={{marginBottom: "5px"}}>购买明细</h3>

                                            <div className={"order-group"}>
                                                <div className={"store-info"}>
                                                    <img
                                                        src={order.order.storeHead}
                                                        width={"25px"}
                                                        height={"25px"}
                                                        style={{borderRadius: "100px"}}
                                                    />
                                                    <span
                                                        style={{
                                                            margin: "0px 0px 0px 12px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {order.order.storeName}&nbsp;&nbsp;
                                                        <span style={{fontSize: "10px"}}><RightOutlined/></span>
                                                    </span>
                                                    <Button
                                                        size={"small"}
                                                        style={{margin: "0px 0px 0px 15px"}}
                                                        onClick={() => {
                                                            window.open("/chat/portal?conversationUserId=" + order.store.userId, "_blank")
                                                        }}
                                                    >
                                                        <MessageOutlined /> 联络商铺
                                                    </Button>
                                                </div>
                                                <div className={"order-item"}>
                                                    {
                                                        order.products.map((item, index) => {
                                                            return(
                                                                <div className={"product-item"}>
                                                                    <img
                                                                        src={item.productImgurl}
                                                                        width={"80px"}
                                                                        height={"80px"}
                                                                        style={{boxShadow: "1px 1px 0 rgb(5 145 255 / 10%)"}}
                                                                    />
                                                                    <div className={"product-detail"}>
                                                                        <div
                                                                            className={"product-title"}>
                                                                            {item.productName}
                                                                        </div>
                                                                        <div
                                                                            className={"product-sku grey-sub-font"}>{item.productAttributes}
                                                                        </div>
                                                                        <div
                                                                            className={"product-amount grey-sub-font"}>x{item.productNum}
                                                                        </div>
                                                                    </div>
                                                                    <div className={"product-item-price"}>
                                                                        {/*${innerProductLoader.productSellerPrice.toFixed(2)}*/}
                                                                        ${(item.productSellerPrice).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Col>


                                        <Drawer
                                            title="协商历史"
                                            placement="right"
                                            onClose={onClose}
                                            open={openRefundHistoryDrawer}
                                            width={600}
                                        >
                                            <Timeline
                                                id={"history"}
                                                items={generateRefundHistory(order.negotiatesApp)}
                                            />
                                        </Drawer>
                                    </>
                                :
                                    <>
                                        <NotFound message={"找不到订单"}/>
                                    </>
                            }


                    </Row>

            }
        </>



    );
}


export default RefundDetail;