import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import ChatLoadingComponent from "./ChatLoadingComponent";
const Chat = (props) => {
    const navigate = useNavigate();

    // ------------------ 用来初始化 tim ------------------
    useEffect(() => {
        window.location.replace("/chat/portal" + window.location.search)
    }, [])

    return (
        <ChatLoadingComponent/>
    );

}

export default Chat;
