import React from 'react';
import ChatPortal from "../pages/chat/ChatPortal";
const Chat = (props) => {

    const [forceRender, setForceRender] = React.useState(0);

    return (
        <ChatPortal forceRender={forceRender} setForceRender={setForceRender}/>
    );

}

export default Chat;
