import React, {useEffect, useState} from "react";
import ConfettiExplosion from "react-confetti-explosion";
import {bucketURL} from "../data/env";



const EasterEggExploring = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    }, []);

    // 放彩蛋彩带
    const [isExploding, setIsExploding] = React.useState(0);


    return (
        <>
            <img
                src={varBucketURL + "/assets/logo-large-with-context.svg"}
                width={"250px"}
                style={{marginBottom: "40px", cursor: "pointer"}}
                onClick={() => {
                    setIsExploding(isExploding + 1)
                }}
            ></img>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    zIndex: "99999"
                }}
            >
                {
                    isExploding > 1
                    &&
                    <ConfettiExplosion
                        duration={5000}
                        force={1}
                        width={2000}
                    />
                }
            </div>
        </>
    );
}


export default EasterEggExploring;