import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Divider,
    Empty,
    Image,
    notification,
    Row,
    Tabs,
    Skeleton,
    Modal,
    QRCode,
    message,
    Spin
} from "antd";
import Search_pg from "./Search";
import $ from "jquery";
import {findSellStore, getCategory} from "../data/Product-Service-Repo";
import Loading from "../components/Loading";
import {Link, useNavigate} from "react-router-dom";
import NoData from "../components/NoData";
import {Carousel} from 'react-responsive-carousel';
import NotFound from "../components/NotFound";
import {getBase64ImageFromUrl, unixDateToHumanDate} from "../data/tool";
import {bucketURL} from "../data/env";
import {CloseOutlined, LoadingOutlined, MessageFilled, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {deleteLogicSellerStoreFavorite, saveSellerStoreFavorite} from "../data/Customer-Service-Repo";
import html2canvas from "html2canvas";


/**
 *
 * Doc comment
 * searchOptionTop 是从首页传来的 props, 他用来设置搜索框当中的「商铺/全站搜索」的 position: absolute top 值
 * 当首页查找到热搜字时会将其设置为 38px，如果没有的话会设置成 49px
 *
 */
const Store = (props) => {
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [messageApi, messageContextHolder] = message.useMessage();
    const navigate = useNavigate();


    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    }, []);


    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    // 调整位置
    useEffect(() => {
        if(props.searchOptionTop !== 0){
            // 先调整搜索选项位置
            $("#store-search-option .ant-select").css("top", props.searchOptionTop)

            // 调整 #search-header #search input 的 padding，避免输入的内容跑到搜索选项下方
            $("#search-header #search input").css("padding-right", "140px")
        }
    }, [props.searchOptionTop]);

    // 商铺信息存储
    const [store, setStore] = useState([]);
    const [storeProductLength, setStoreProductLength] = useState(0);
    const [storeFavoriteStatus, setStoreFavoriteStatus] = useState(0); // 0: 未关注 1: 已关注
    const [storeFavortieCount, setStoreFavoriteCount] = useState(0); // 商铺关注数
    const [isStoreVacationModeModal, setIsStoreVacationModeModal] = useState(false); // 商铺休假模式

    // 类目表存储
    const [categoryList, setCategoryList] = useState([]);

    // 加载及显示页面
    const [isLoading, setIsLoading] = useState(true);
    const [pageVisible, setPageVisible] = useState(true);

    // 商铺头像 base64 for 分享图卡
    const [storeIcoBase64, setStoreIcoBase64] = useState(null);
    // 商铺头像 base64 for 分享图卡
    const [storeBackgroundBase64, setStoreBackgroundBase64] = useState(null);

    // logo base64 for 分享图卡
    const [logoBase64, setLogoBase64] = useState(null);


    // 获取商铺信息
    useEffect(() => {
        async function load() {
            const result = await findSellStore(params.id);

            if(result.code === 200){
                // 确认一下商铺当前是否可用
                if(result.data.isDel === 1 || result.data.status === 2){ // 不可用
                    // 关闭加载并提示错误信息
                    setIsLoading(false);
                    setPageVisible(false);

                } else { // 可用
                    setStore(result.data);
                    setIsLoading(false);
                    setStoreProductLength(result.data.productList.length)
                    setStoreFavoriteStatus(result.data.isFavorite);
                    setStoreFavoriteCount(result.data.favoriteCount)

                    // 判断一下用户是否，已经关闭了 vacation modal
                    if(!params.vacationModalClosed){
                        setIsStoreVacationModeModal(result.data.vacationMode === 1);
                    }


                    // 生成分享图卡用的 base64
                    // 商铺底图
                    // 判断是否有底图，没有的话使用预设底图
                    var storeBackground = result.data.storeImg !== "" ? result.data.storeImg : varBucketURL + "/assets/background_store_default.jpg";
                    getBase64ImageFromUrl(storeBackground)
                        .then(result => {
                            setStoreBackgroundBase64(result)
                        })
                        .catch(err => console.error(err));


                    // 商铺头像
                    // 判断是否有头像，没有的话使用预设头像
                    var storeIco = result.data.storeIco !== null ? result.data.storeIco : varBucketURL + "/shopmy_buyer_desktop/assets/default_profile.svg"
                    getBase64ImageFromUrl(storeIco)
                        .then(result => setStoreIcoBase64(result))
                        .catch(err => console.error(err));


                    // logo base64 for 分享图卡
                    getBase64ImageFromUrl(varBucketURL + "/assets/logo-classic.svg")
                        .then(result => setLogoBase64(result))
                        .catch(err => console.error(err));



                    // -------------------------------- 不完美的解决方案：取类目表 --------------------------------
                    // 用于显示商铺类目页面的类目图标。
                    // 因为后端在 findSellerStore 里面没有返回类目图标，所以这里要重新取一下
                    const category = await getCategory();
                    if(category.code === 200){
                        // 暂存 array
                        const tempCategory = [];

                        // 处理拿到的数据
                        // loop 主类目
                        category.data.forEach((mainCategory, index) => {
                            // loop 子类目
                            mainCategory.categoryList.forEach((subCategory, index) => {
                                // 把字类目推到暂存 array
                                tempCategory.push(subCategory);
                            })
                        })

                        // 存到 state
                        setCategoryList(tempCategory);
                    }
                    // -------------------------------- 不完美的解决方案：取类目表 --------------------------------
                }
            } else {
                // 关闭加载并提示错误信息
                setIsLoading(false);
                setPageVisible(false);
            }

        }

        if(varBucketURL !== null){
            load();
        }

    }, [varBucketURL]); // 因为 base64 img 依赖 varBucketURL，所以等 varBucketURL ready 在开始


    // -------------------------------- 不完美的解决方案：取类目表 --------------------------------
    // 不完美的解决方案：用类目id找类目图
    function findCategoryImgById(id){
        for(let i = 0; i < categoryList.length; i++){
            if(categoryList[i].id === id){
                return categoryList[i].imgUrl;
            }
        }
    }
    // -------------------------------- 不完美的解决方案：取类目表 --------------------------------



    // 商铺 and 类目 tab
    const tabItems = [
        {
            key: 'store',
            label: `商品`,
            children:
                <>
                    <div id={"store-product"}>
                        <div
                            className={"list-product"}
                        >
                            <div
                                style={{
                                    background: "#FFFFFF",
                                    padding: "0px 25px 25px 25px"
                                }}
                            >
                                {
                                    storeProductLength !== 0
                                        ?
                                        <Search_pg renderType={"store"} storeId={params.id}/>
                                        :
                                        <NoData message={"该商铺尚未上传商品"}/>
                                }
                            </div>
                        </div>
                    </div>
                </>,
        },
        {
            key: 'category',
            label: `类目`,
            children:
                <>
                    <div id={"store-category"}>
                        <div
                            className={"list-product"}
                        >
                            <div
                                style={{
                                    background: "#FFFFFF",
                                    padding: "0px 25px 25px 25px"
                                }}
                            >
                                {
                                    storeProductLength !== 0
                                        ?
                                        <>
                                            {
                                               store.categoryList.map((mainCategory, index) => {
                                                   return (
                                                       <div className={"main-category-container"}>
                                                           <Link to={`/search?sellerStoreId=${params.id}&firstStairId=${mainCategory.categoryId}&prefix=搜索 ${store.storeName} 里所有 ${mainCategory.name}类目 的商品`} className={"main-title"}>{mainCategory.name} &nbsp;<span className={"more-icon"}><RightOutlined /></span></Link>

                                                           <div className={"sub-category-collection"}>
                                                               {
                                                                   mainCategory.categoryList.map((subCategory, index) => {
                                                                       return (
                                                                           <Link to={`/search?sellerStoreId=${params.id}&firstStairId=${subCategory.id}&prefix=搜索 ${store.storeName} 里所有 ${mainCategory.name}类目 的商品`} className={"sub-category-container"}>
                                                                               <img className={"sub-img"} src={findCategoryImgById(subCategory.id)}></img>
                                                                               <Skeleton.Avatar active={true} size={70} shape={"circle"} />
                                                                               <span className={"sub-name"}>{subCategory.name} &nbsp;<span className={"more-icon"}><RightOutlined /></span></span>
                                                                           </Link>
                                                                       )
                                                                   })
                                                               }
                                                           </div>


                                                       </div>
                                                   )
                                               })
                                            }

                                        </>
                                        :
                                        <NoData message={"该商铺尚未上传商品"}/>
                                }
                            </div>
                        </div>
                    </div>
                </>,
        },
    ];


    // -------------------------------- Share ----------------------------------
    // share modal control
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    // share modal
    function ShareModal() {
        const handleCancel = () => {
            setIsShareModalOpen(false);
        };

        const shareLink = "https://share.shopmy.com.au?type=store&id=" + store.id;


        // 商品图卡的图片因为 CORS 问题，需要在 shopmy.com.au 或 shopmy.top 域才可以显示
        return (
            <Modal title="分享商铺"
                   open={isShareModalOpen}
                   onCancel={handleCancel}
                   footer={[]}
                   width={600}
            >
                <div id={"share-card"}>
                    {/*底图*/}
                    <div
                        style={{
                            backgroundImage: "url(" + storeBackgroundBase64 + ")",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}
                        id={"product-img"}
                    >
                    </div>

                    {/*底图的灰色遮照，不能用 filter 因为 html2canvas 不支持*/}
                    <div
                        style={{
                            background: "rgb(0 0 0 / 59%)",
                            marginTop: "-210px",
                        }}
                        id={"product-img"}
                    >
                    </div>

                    {/*头像*/}
                    <div
                        style={{
                            backgroundImage: "url(" + storeIcoBase64 + ")",
                            position: "absolute",
                            top: "152px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundSize: "91px",
                            borderRadius: "442px",
                            width: "91px",
                            height: "91px",
                        }}
                        id={"product-img"}
                    >
                    </div>
                    <span id={"product-name"}>{store.storeName}</span>
                    <br/>
                    <QRCode value={shareLink} size={120}/>
                    <div
                        style={{
                            backgroundImage: "url(" + logoBase64 + ")",
                        }}
                        id={"logo"}
                    >
                    </div>
                </div>
                <div id={"share-card-btn"}>
                    <div
                        onClick={() => {
                            navigator.clipboard.writeText(shareLink);
                            message.success("已拷贝分享链接到剪贴板");
                        }}
                    >
                        <img src={varBucketURL + "/assets/icon-share-card-copy.svg"} width={"60px"}></img>
                        <span>拷贝分享链接</span>
                    </div>
                    <div
                        onClick={() => {
                            // 加载图卡用的加载动画
                            messageApi.open({
                                type: 'loading',
                                content: '正在生成图卡，请稍后...',
                                duration: 0,
                            });
                            // 这边用不正规的方式处理加载 spinner
                            $("#share-card-btn .ant-spin.ant-spin-spinning").attr("style", "display: block");

                            // 五秒后自动销毁加载动画
                            setTimeout(messageApi.destroy, 3000);


                            // 将图卡从 canvas 上转换成图片
                            html2canvas(
                                document.querySelector("#share-card"),
                                {
                                    allowTaint: true,
                                }
                            )
                                .then(canvas => {
                                    // create canvas in canvas container
                                    var canvasContainer = document.getElementById("canvas-container");
                                    canvasContainer.appendChild(canvas)

                                    /// create an "off-screen" anchor tag
                                    var lnk = document.createElement('a'), e;

                                    /// the key here is to set the download attribute of the a tag
                                    lnk.download = "share";

                                    /// convert canvas content to data-uri for link. When download
                                    /// attribute is set the content pointed to by link will be
                                    /// pushed as "download" in HTML5 capable browsers
                                    lnk.href = canvas.toDataURL("image/png;base64");

                                    /// create a "fake" click-event to trigger the download
                                    if (document.createEvent) {
                                        e = document.createEvent("MouseEvents");
                                        e.initMouseEvent("click", true, true, window,
                                            0, 0, 0, 0, 0, false, false, false,
                                            false, 0, null);

                                        lnk.dispatchEvent(e);
                                    } else if (lnk.fireEvent) {
                                        lnk.fireEvent("onclick");
                                    }

                                    // 这边用不正规的方式处理加载 spinner，关闭加载
                                    $("#share-card-btn .ant-spin.ant-spin-spinning").attr("style", "display: none !important;");


                                    // 销毁 canvas
                                    canvasContainer.removeChild(canvas);
                                });
                        }}
                    >
                        <img src={varBucketURL + "/assets/icon-share-card-download.svg"} width={"60px"}></img>
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 28,
                                    }}
                                    spin
                                />
                            }
                        />
                        <span>下载分享图卡</span>
                    </div>

                </div>

            </Modal>
        )
    }


    return (
        <>
            { notificationContextHolder }
            { messageContextHolder }
            {
                isLoading
                    ?
                        <>
                            <Loading/>
                        </>
                    :
                        <>
                            {
                                pageVisible
                                    ?
                                        <div className={"safe-area"} id={"store-page"}>
                                            <ShareModal/>
                                            <Modal
                                                title="商铺休假中"
                                                open={isStoreVacationModeModal}
                                                onCancel={()=>{setIsStoreVacationModeModal(false)}}
                                                footer={[
                                                    <Button
                                                        style={{width: "100%"}}
                                                        key="submit"
                                                        type="primary"
                                                        onClick={()=>{
                                                            setIsStoreVacationModeModal(false);
                                                            // 当用户已经关闭了 vacation modal 时把他添加到 url 里面，避免下一次 render 时再次出现
                                                            navigate("/store" + window.location.search + "&vacationModalClosed=true", {replace: true});
                                                        }}
                                                    >
                                                        确定
                                                    </Button>
                                                ]}
                                            >
                                                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                                    <img src={varBucketURL + "/assets/icon-store-vocation.svg"}></img>
                                                    <p>商铺正在休假，您可以浏览，但无法下单该商铺商品!</p>
                                                </div>
                                            </Modal>

                                            <Row>
                                                <Col
                                                    span={
                                                        // if storeRemark and bannerList is empty, then set span to 24
                                                        store.storeRemark === "" && store.bannerList.length === 0
                                                            ?
                                                                24
                                                            :
                                                                12

                                                    }
                                                    className={"item-container"}
                                                    id={"store-info"}
                                                >
                                                    <Skeleton.Image active={true} />
                                                    <div id={"profile"} style={{backgroundImage: `url(${store.storeImg !== "" ? store.storeImg : varBucketURL + "/assets/background_store_default.jpg"})`}} ></div>
                                                    <div id={"info"}>
                                                        <div className={
                                                            // if storeRemark and bannerList is empty, then set center-elements
                                                            store.storeRemark === "" && store.bannerList.length === 0
                                                                ?
                                                                    "center-elements"
                                                                :
                                                                    ""
                                                            }
                                                        >
                                                            <div>
                                                                <img id={"avatar"} src={store.storeIco !== null ? store.storeIco : varBucketURL + "/shopmy_buyer_desktop/assets/default_profile.svg"}></img>
                                                                <div>
                                                                    <span id={"name"} style={{margin: "10px 0px", display: "block"}}>{store.storeName}</span>
                                                                    <div style={{margin: "10px 0px"}}>
                                                                        <span id={"rating"}>商铺评分<span className={"color-shopmy-orange"}>&nbsp;&nbsp;{store.score}&nbsp;&nbsp;&nbsp;</span></span>
                                                                        <span id={"follower"}>关注人数<span className={"color-shopmy-orange"}>&nbsp;&nbsp;{storeFavortieCount}&nbsp;&nbsp;</span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br/>

                                                            <div>
                                                                <Button
                                                                    type={
                                                                        storeFavoriteStatus === 1
                                                                            ?
                                                                                "primary"
                                                                            :
                                                                                "default"
                                                                    }
                                                                    style={{
                                                                        marginRight: "0px",
                                                                        width: "100%"
                                                                    }}
                                                                    size={"small"}
                                                                    onClick={async () => {
                                                                        var result;

                                                                        if (storeFavoriteStatus === 0) {
                                                                            // 关注商铺
                                                                            result = await saveSellerStoreFavorite(params.id);
                                                                        } else {
                                                                            // 取消关注
                                                                            result = await deleteLogicSellerStoreFavorite(params.id);
                                                                        }

                                                                        if (result.code === 200) {
                                                                            if (storeFavoriteStatus === 1) {
                                                                                setStoreFavoriteStatus(0);
                                                                                setStoreFavoriteCount(storeFavortieCount - 1)
                                                                                notificationApi.success({
                                                                                    message: `提示信息`,
                                                                                    description: "取消关注成功",
                                                                                    placement: "bottomRight",
                                                                                    duration: 2,
                                                                                });

                                                                            } else {
                                                                                setStoreFavoriteStatus(1);
                                                                                setStoreFavoriteCount(storeFavortieCount + 1)
                                                                                notificationApi.success({
                                                                                    message: `提示信息`,
                                                                                    description: "关注成功",
                                                                                    placement: "bottomRight",
                                                                                    duration: 2,
                                                                                });
                                                                            }

                                                                        } else {
                                                                            notificationApi.error({
                                                                                message: `失敗`,
                                                                                description: params.msg,
                                                                                placement: "bottomRight",
                                                                                duration: 2,
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        storeFavoriteStatus === 0
                                                                            ?
                                                                                <>
                                                                                    <PlusOutlined />&nbsp;关注商铺
                                                                                </>

                                                                            :
                                                                                <>
                                                                                    <CloseOutlined />&nbsp;取消关注
                                                                                </>

                                                                    }

                                                                </Button>

                                                                {/*不能提供私信因为没传 customerId*/}
                                                                {/*<Button*/}
                                                                {/*    style={{*/}
                                                                {/*        marginRight: "0px",*/}
                                                                {/*        marginLeft: "30px",*/}
                                                                {/*        width: "120px"*/}
                                                                {/*    }}*/}
                                                                {/*    size={"small"}*/}
                                                                {/*    onClick={()=>{*/}
                                                                {/*        window.open("/chat/portal?conversationUserId=" + 123, "_blank")*/}
                                                                {/*    }}*/}
                                                                {/*>*/}
                                                                {/*    /!*TODO: 私信商铺*!/*/}
                                                                {/*    <MessageFilled />&nbsp;私信商铺*/}
                                                                {/*</Button>*/}
                                                            </div>
                                                            <br/>
                                                            <div>
                                                                <Button
                                                                    onClick={()=>{
                                                                        setIsShareModalOpen(true);
                                                                    }}
                                                                    style={{
                                                                        marginRight: "0px",
                                                                        width: "270px"
                                                                    }}
                                                                    size={"small"}
                                                                >
                                                                    分享商铺
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    // only render when there are either storeRemark or bannerList
                                                    store.storeRemark !== "" || store.bannerList.length !== 0
                                                        ?
                                                            <>
                                                                <Col span={1}></Col>

                                                                <Col
                                                                    span={12}
                                                                    className={"item-container"}
                                                                    id={"store-detail"}
                                                                >
                                                                    {
                                                                        store.bannerList.length !== 0 &&
                                                                        <>
                                                                            <Carousel
                                                                                showArrows={true}
                                                                                showIndicators={false}
                                                                                showThumbs={false}
                                                                                useKeyboardArrows={true}
                                                                                stopOnHover={false}
                                                                                swipeable={true}
                                                                                dynamicHeight={false}
                                                                                emulateTouch={false}
                                                                                autoFocus={false}
                                                                                autoPlay={false}
                                                                                showStatus={false}
                                                                                infiniteLoop={true}
                                                                            >
                                                                                {
                                                                                    store.bannerList.map((img, index) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {/* this is the real img provides preview functions */}
                                                                                                <Skeleton.Image active={true} />
                                                                                                <Image src={img.fileUrl}/>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Carousel>
                                                                            <br/>
                                                                        </>
                                                                    }


                                                                    {
                                                                        store.storeRemark === ""
                                                                            ?
                                                                                <></>
                                                                            :
                                                                                <>
                                                                                    <div className={"store-remark"}>
                                                                                        <span>{store.storeRemark}</span>
                                                                                    </div>
                                                                                </>
                                                                    }

                                                                    <br/>
                                                                    <span className={"color-shopmy-orange"}>商铺页面更新时间：{unixDateToHumanDate(store.updateDate)}</span>


                                                                    {/*<div>*/}
                                                                    {/*</div>*/}

                                                                    {/*<div style={{display: "flex", alignItems: "flex-start", width: "100%"}}>*/}
                                                                    {/*    {*/}
                                                                    {/*        // hide Carousel area when bannerList is empty*/}
                                                                    {/*        store.bannerList.length === 0*/}
                                                                    {/*            ?*/}
                                                                    {/*                <></>*/}
                                                                    {/*            :*/}
                                                                    {/*                <div>*/}
                                                                    {/*                    <Carousel*/}
                                                                    {/*                        showArrows={true}*/}
                                                                    {/*                        showIndicators={false}*/}
                                                                    {/*                        showThumbs={false}*/}
                                                                    {/*                        useKeyboardArrows={true}*/}
                                                                    {/*                        stopOnHover={false}*/}
                                                                    {/*                        swipeable={true}*/}
                                                                    {/*                        dynamicHeight={false}*/}
                                                                    {/*                        emulateTouch={false}*/}
                                                                    {/*                        autoFocus={false}*/}
                                                                    {/*                        autoPlay={false}*/}
                                                                    {/*                        showStatus={false}*/}
                                                                    {/*                        infiniteLoop={true}*/}
                                                                    {/*                    >*/}
                                                                    {/*                        {*/}
                                                                    {/*                            store.bannerList.map((img, index) => {*/}
                                                                    {/*                                return (*/}
                                                                    {/*                                    <div>*/}
                                                                    {/*                                        /!* this is the real img provides preview functions *!/*/}
                                                                    {/*                                        <Skeleton.Image active={true} />*/}
                                                                    {/*                                        <Image src={img.fileUrl}/>*/}
                                                                    {/*                                    </div>*/}
                                                                    {/*                                );*/}
                                                                    {/*                            })*/}
                                                                    {/*                        }*/}
                                                                    {/*                    </Carousel>*/}
                                                                    {/*                    <br/>*/}
                                                                    {/*                    <span className={"color-shopmy-orange"}>商铺页面更新时间：{unixDateToHumanDate(store.updateDate)}</span>*/}
                                                                    {/*                </div>*/}
                                                                    {/*    }*/}


                                                                    {/*    {*/}
                                                                    {/*        // storeRemark and bannerList isn't empty, then render divider*/}
                                                                    {/*        store.storeRemark !== "" && store.bannerList.length !== 0*/}
                                                                    {/*            ?*/}
                                                                    {/*                <Divider type={"vertical"}/>*/}
                                                                    {/*            :*/}
                                                                    {/*                <></>*/}
                                                                    {/*    }*/}


                                                                    {/*    {*/}
                                                                    {/*        // display storeRemark or empty message*/}
                                                                    {/*        store.storeRemark === ""*/}
                                                                    {/*            ?*/}
                                                                    {/*                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"商铺尚未设置描述"} style={{width: "460px"}}/>*/}
                                                                    {/*            :*/}
                                                                    {/*                <div>*/}
                                                                    {/*                    <div className={"store-remark"} style={{width:"70%"}}>*/}
                                                                    {/*                        <span>{store.storeRemark}</span>*/}
                                                                    {/*                    </div>*/}
                                                                    {/*                    {*/}
                                                                    {/*                        // if there are no bannerList, then render update time here*/}
                                                                    {/*                        store.bannerList.length === 0*/}
                                                                    {/*                            ?*/}
                                                                    {/*                            <span className={"color-shopmy-orange"} style={{marginTop: "15px", display: "block"}}>商铺页面更新时间：{unixDateToHumanDate(store.updateDate)}</span>*/}
                                                                    {/*                            :*/}
                                                                    {/*                            <></>*/}
                                                                    {/*                    }*/}

                                                                    {/*                </div>*/}
                                                                    {/*    }*/}


                                                                    {/*    {*/}
                                                                    {/*        // display no banner message when bannerList is empty*/}
                                                                    {/*        store.bannerList.length === 0*/}
                                                                    {/*            ?*/}
                                                                    {/*                <>*/}
                                                                    {/*                    <Divider type={"vertical"}/>*/}
                                                                    {/*                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"商铺尚未设置Banner"} style={{width: "566px"}}/>*/}
                                                                    {/*                </>*/}

                                                                    {/*            :*/}
                                                                    {/*                <></>*/}
                                                                    {/*    }*/}

                                                                    {/*</div>*/}
                                                                </Col>
                                                            </>
                                                        :
                                                            <></>
                                                }
                                            </Row>

                                            <Tabs
                                                defaultActiveKey="1"
                                                items={tabItems}
                                                id={"store-tabs"}
                                                size={"middle"}
                                                centered={true}
                                            />

                                            <div id={"canvas-container"}></div>

                                        </div>


                                    :
                                        <>
                                            <NotFound message={"找不到商铺，或此商铺以被冻结/删除"}/>
                                        </>
                            }
                        </>
            }
        </>

    );
}


export default Store;