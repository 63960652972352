import axios from "axios";
import beforeReturn from "./apiControllers";

// --- Constants ----------------
const API_HOST = "https://api.shopmy.com.au/api/v1";
const SERVICE_NAME = "/os";

axios.defaults.headers.common['X-Auth-Lang'] = `zh`;
axios.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('Access-Token');
axios.defaults.headers.common['X-Auth-Type'] = `app`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = `localhost`;


async function getOmsCouponList(storeId, index){
    const request = {
        "page": {
            "index" : index,
        },
        "storeId": storeId,
        "status" : 1
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscoupon/findOmsCouponList", request);
    return response.data;
}

// 领取优惠券
async function doReceiveOmsCoupon(id){
    const request = {
        "id": id
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscoupon/doReceiveOmsCoupon", request);

    beforeReturn(response.data);
    return response.data;
}


// 订单状态（0：预订单、10：待付款、20：待出货、30：配送中、40：待完成、50：待评价、60：退货/退款、70：已取消、80：已完成）
async function findOmsOrderList(key, orderStatus, index){
    const request = {
        "page": {
            "index" : index,
            "paging" : true,
            "size" : 10
        },
        "orderStatus": orderStatus,
        "key" : key
    }
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/findOmsOrderList", request);

    beforeReturn(response.data);
    return response.data;

}


// 查询订单
async function findOmsOrder(couponReceiveIds, id, receiverAddress) {
    const request = {
        "couponReceiveIds": couponReceiveIds,
        "id": id,
        "receiverAddress": receiverAddress
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/findOmsOrder", request);

    beforeReturn(response.data);
    return response.data;

}


// 查询退款单
async function findOmsOrderRefundInfo(orderId) {
    const request = {
        "orderId": orderId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorderrefund/findOmsOrderRefundInfo", request);

    beforeReturn(response.data);
    return response.data;

}


// 确认收货
async function doOmsOrderReceipt(id){
    const request = {
        "id": id
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderReceipt", request);

    beforeReturn(response.data);
    return response.data;

}


// 延长收货时间
async function doOmsOrderReceiptTime(id){
    const request = {
        "id": id
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderReceiptTime", request);

    beforeReturn(response.data);
    return response.data;

}

// ---- 结算
// 建立预售订单（先在购物车或商品页面建立预售订单）
async function preOrder(carIds, products) {
    const request = {
        "carIds": carIds,
        "products": products
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/preOrder", request);

    beforeReturn(response.data);
    return response.data;

}

// 提交订单
async function submitOrder(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/submitOrder", request);

    beforeReturn(response.data);
    return response.data;

}


// 利用订单查找可用的优惠券
async function findOmsCouponByOrderList(orderId, couponReceiveIds) {
    const request = {
        "orderId": orderId,
        "couponReceiveIds": couponReceiveIds
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscoupon/findOmsCouponByOrderList", request);

    beforeReturn(response.data);
    return response.data;

}


// 使用优惠码领取优惠券
async function doReceiveOmsCouponCode(code) {
    const request = {
        "code": code
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscoupon/doReceiveOmsCouponCode", request);

    beforeReturn(response.data);
    return response.data;

}

//https://api.shopmy.com.au/api/v1/os/app/omscoupon/findMyOmsCouponList
// 查询我的优惠券
async function findMyOmsCouponList(queryType, index) {
    const request = {
        "page": {
            "index" : index,
        },
        "queryType": queryType
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omscoupon/findMyOmsCouponList", request);

    beforeReturn(response.data);
    return response.data;

}



// 支付订单
async function payOrder(orderId, payType) {
    const request = {
        "orderId": orderId,
        "payType": payType,
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/payOrder", request);

    beforeReturn(response.data);
    return response.data;

}

// Stripe 支付回调
async function orderPayNotifyCard(status, orderId) {
    const request = {
        "status": status,
        "orderId": orderId,
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/omsorder/notify/orderPayNotifyCard", request);

    beforeReturn(response.data);
    return response.data;

}


// 保存购物车
async function saveOmsOrderCar(request) {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/saveOmsOrderCar", request);

    beforeReturn(response.data);
    return response.data;

}


// ---- 购物车
// 查询购物车
async function findOmsOrderCarAppList() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/findOmsOrderCarAppList");

    beforeReturn(response.data);
    return response.data;

}

// 勾选/取消勾选所有购物车的商品
async function doOmsOrderCarAllSelected(isSelected) {
    const request = {
        "isSelected": isSelected
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/doOmsOrderCarAllSelected", request);

    beforeReturn(response.data);
    return response.data;

}

// 勾选/取消勾选「店铺中」所有购物车的商品
async function doOmsOrderCarStoreSelected(storeId, isSelected) {
    const request = {
        "id": storeId,
        "isSelected": isSelected
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/doOmsOrderCarStoreSelected", request);

    beforeReturn(response.data);
    return response.data;

}

// 整单加入购物车
async function saveOmsOrderCarByOrder(orderId) {
    const request = {
        "orderId": orderId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/saveOmsOrderCarByOrder", request);

    beforeReturn(response.data);
    return response.data;

}

// 移入收藏夹
// 注意：这边的 ids 都是一个 string 合集
async function moveOmsOrderCar(ids) {
    const request = {
        "ids": ids
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/moveOmsOrderCar", request);

    beforeReturn(response.data);
    return response.data;

}

// 删除购物车
// 注意：这边的 ids 都是一个 string 合集
async function deleteOmsOrderCar(ids) {
    const request = {
        "ids": ids
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/deleteOmsOrderCar", request);

    beforeReturn(response.data);
    return response.data;

}

// 修改购物车的商品
// 这里的 id 是购物车返回的 products.id
// productSkuId 是欲更换的商品的 skuId
async function editOmsOrderCar(id, isSelected, productNum, productSkuId) {
    const request = {
        "id": id,
        "isSelected": isSelected,
        "productNum": productNum,
        "productSkuId": productSkuId
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsordercar/editOmsOrderCar", request);

    beforeReturn(response.data);
    return response.data;

}



// 取消订单原因列表
async function findCancelReasonList() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/findCancelReasonList");

    beforeReturn(response.data);
    return response.data;

}


// 退款原因列表
async function findRefundReasonList() {
    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/findRefundReasonList");

    beforeReturn(response.data);
    return response.data;

}


// 取消订单
async function doOmsOrderCancel(id, orderCancelRemark) {
    const request = {
        "id": id,
        "orderCancelRemark": orderCancelRemark
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderCancel", request);

    beforeReturn(response.data);
    return response.data;

}


// 申请退款
async function doOmsOrderApplyRefund(id, orderRefundDescription, orderRefundImages, orderRefundRemark) {
    const request = {
        "id": id,
        "orderRefundDescription": orderRefundDescription,
        "orderRefundImages": orderRefundImages,
        "orderRefundRemark": orderRefundRemark
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderApplyRefund", request);

    beforeReturn(response.data);
    return response.data;

}


// 转交判定中心
// 这边的 id 可以是订单id，也可以是退款单id
async function doOmsOrderRuling(id, email, mobile, name, orderRefundDescription, orderRefundImages){
    const request = {
        "id": id,
        "email": email,
        "mobile": mobile,
        "name": name,
        "orderRefundDescription": orderRefundDescription,
        "orderRefundImages": orderRefundImages
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderRuling", request);

    beforeReturn(response.data);
    return response.data;

}


// 撤销退款
// 这边的 id 一定要是退款单id
async function doOmsOrderRevokeRefund(id) {
    const request = {
        "id": id
    }

    const response = await axios.post(API_HOST + SERVICE_NAME + "/app/omsorder/doOmsOrderRevokeRefund", request);

    beforeReturn(response.data);
    return response.data;

}


export {
    getOmsCouponList,
    doReceiveOmsCoupon,
    findOmsOrderList,
    findOmsOrder,
    doOmsOrderReceipt,
    doOmsOrderReceiptTime,
    preOrder,
    saveOmsOrderCar,
    findCancelReasonList,
    findRefundReasonList,
    doOmsOrderCancel,
    doOmsOrderApplyRefund,
    findOmsOrderRefundInfo,
    doOmsOrderRuling,
    doOmsOrderRevokeRefund,
    findOmsOrderCarAppList,
    doOmsOrderCarAllSelected,
    doOmsOrderCarStoreSelected,
    saveOmsOrderCarByOrder,
    moveOmsOrderCar,
    deleteOmsOrderCar,
    editOmsOrderCar,
    payOrder,
    submitOrder,
    orderPayNotifyCard,
    findOmsCouponByOrderList,
    doReceiveOmsCouponCode,
    findMyOmsCouponList
};