import React, {useState, useEffect} from "react";

import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';

// package component
import {BrowserRouter, NavLink, Routes, Route, Link} from 'react-router-dom';
import {Col, Row, ConfigProvider, Divider, Button} from "antd";

// assets
import './App.css';

// page component
import Home from './pages/Home.js';
import Product from './pages/Product.js';
import Search_pg from './pages/Search.js';
import Search_bar from './components/Search_bar.js';
import Category from './pages/Category.js';
import Cart from './pages/checkout/Cart.js';
import ChatPortal from "./pages/chat/ChatPortal";

import Profile from './pages/Profile.js';
import Store from './pages/Store.js';



import OrderList from "./pages/profile/order/OrderList";
import OrderDetail from "./pages/profile/order/OrderDetail";
import RefundDetail from "./pages/profile/order/RefundDetail";
import Comment from "./pages/profile/order/Comment";

import Notification from "./pages/profile/Notification";
import Coupon from "./pages/profile/Coupon";


// open store
import StoreMain from './pages/store/Main';

// external component
import Account from './external/Account.js';

// proxy js is a blank page use to solve cannot rerender issue.
import SearchAssistance from './components/SearchAssistance.js';


// data processor
import {bucketURL} from "./data/env";
import {getPopularSearchKeywords} from "./data/Product-Service-Repo.js";
import ScrollToTop from "./components/scrollToTop";
import {getUserName, checkIsTokenExpire} from "./data/tool";
import {logout} from "./data/Customer-Service-Repo";
import Confirm from "./pages/checkout/Confirm";
import PaymentResult from "./pages/checkout/PaymentResult";

import DownloadAppNotifyPopout from "./components/DownloadAppNotifyPopout";
import HeaderToolBox from "./components/HeaderToolBox";
import Address from "./pages/profile/account/Address";
import ChangeLoginPassword from "./pages/profile/account/ChangeLoginPassword";
import UserProfile from "./pages/profile/account/UserProfile";
import ChatLoader from "./pages/chat/ChatLoader";
import {ExportOutlined, MessageFilled, UserOutlined} from "@ant-design/icons";
import ConfettiExplosion from "react-confetti-explosion";
import EasterEggExploring from "./components/EasterEggExploring";
import OfficialCoupon from "./pages/OfficialCoupon";
import ChatPopout from "./pages/chat/ChatPopout";


function App() {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    }, []);


    const [accessToken, setAccessToken] = useState(null);
    const [user, setUser] = useState([]);

    useEffect(() => {
        // when there are valid user set token and user object on state
        async function load() {
            if (window.localStorage.getItem('Access-Token') != null) {
                setAccessToken(window.localStorage.getItem('Access-Token'));
                setUser(JSON.parse(window.localStorage.getItem('User')));

                await checkIsTokenExpire();
            }
        }

        load()
    }, []);


    // do once we get token
    // 热搜词
    const [popularSearchKeyword, setPopularSearchKeyword] = useState([]);
    const [popularSearchKeywordLength, setPopularSearchKeywordLength] = useState(0);

    // 搜索框，选择搜索类型的高度
    const [searchOptionTop, setSearchOptionTop] = useState(0);


    useEffect(() => {
        if (accessToken !== null) {
            async function loadPopularSearchKeyword() {
                const data = await getPopularSearchKeywords();


                if (data.data.length === 0 || data.code !== 200) {
                    setPopularSearchKeywordLength(0);

                    // 商铺页面，搜索框，选择搜索类型：如果没有 popular search keyword，search bar search option框往上移动
                    setSearchOptionTop("49px")

                } else {

                    let plainData = [];
                    plainData = data.data;

                    plainData.forEach(function (item, index) {
                        popularSearchKeyword.push(item)
                    });

                    setPopularSearchKeywordLength(popularSearchKeyword.length)

                    // 商铺页面，搜索框，选择搜索类型：如果有 popular search keyword 需要把 search bar search option 的高度就不用那么高
                    setSearchOptionTop("38px")
                }
            }

            loadPopularSearchKeyword();
        }
    }, [accessToken]);


    // 退出登录
    async function onClickLogout() {
        const result = await logout();
    }


    // 提示下载 app 的谈窗
    const [isAppDownloadModalOpen, setIsAppDownloadModalOpen] = useState(false);


    // 强制 render
    const [forceRender, setForceRender] = useState(0);


    // 顶部的第二个 header
    // 因为有一些页面要移除掉原有的 header 的内容（例如：搜索、购物车等等...）

    // 记得把 forceRender 和 setForceRender 用 props 传给相应的 component
    // 同时要 rerender 的页面加上 useEffect
    /*
     *   useEffect(() => {
     *
     *      // ------------------ 用来 rerender sub header ------------------
     *      // ----- 初始 -----
     *      props.setForceRender(props.forceRender+1);
     *
     *      // ----- 离开页面 -----
     *      const handleBeforeUnload = () => {
     *          // 值不可以跟上面一开始的一样，不然系统识别不到 state change 请求
     *          props.setForceRender(props.forceRender-1);
     *      };
     *
     *      // handles when page is unloaded
     *      window.addEventListener("beforeunload", handleBeforeUnload);
     *
     *      // cleanup function handles when component unmounts
     *      return () => {
     *          window.removeEventListener("beforeunload", handleBeforeUnload);
     *
     *          handleBeforeUnload();
     *      };
     *   }, [])
     */
    const [specialHeader, setSpecialHeader] = useState("");
    function RenderSubHeader() {
        var location = window.location.pathname;
        var title = ""
        var isSpecialHeader = "";

        // 判断特殊功能 header 的标题
        switch (location) {
            case "/checkout/confirm":
                title = "结算"
                isSpecialHeader = "function";
                setSpecialHeader("function");
                break;

            case "/checkout/result":
                title = "结算"
                isSpecialHeader = "function";
                setSpecialHeader("function");
                break;

            case "/chat/portal":
                title = "享买私信"
                isSpecialHeader = "chat";
                setSpecialHeader("chat");
                break;


            default:
                setSpecialHeader("normal");
                break;

        }

        // 判断 render 的 header
        if (isSpecialHeader === "function") {
            return (
                <Row id="search-header" className={"header chat-header"} style={{height: "90px"}}>
                    <Row className={"safe-area"}>
                        <Col span={6} id={"logo"}>
                            <NavLink
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%"
                                }}
                                to="/"
                            >
                                <img src={varBucketURL + "/assets/logo.svg"} alt="logo" width="160px"></img>
                                <Divider
                                    type={"vertical"}
                                    style={{
                                        height: "30px",
                                        margin: "0 20px",
                                        backgroundColor: "#ffffff"
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "500",
                                        color: "#ffffff"
                                    }}
                                >
                                    {title}
                                </span>
                            </NavLink>
                        </Col>
                    </Row>
                </Row>
            );
        } else if(isSpecialHeader === "chat"){
            return (
                <Row
                    id="search-header"
                    className={"header"}
                    style={{
                        height: "70px",
                        boxShadow: '0 1px 4px rgba(0,0,0,.12)',
                        position: "relative",
                        zIndex: "1",
                        padding: "0px 60px 0px 60px"
                    }}
                >
                    <Row>
                        <Col span={24} id={"logo"}>
                            <NavLink
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%"
                                }}
                                to="/"
                            >
                                <img src={varBucketURL + "/assets/logo.svg"} alt="logo" width="160px"></img>
                                <Divider
                                    type={"vertical"}
                                    style={{
                                        height: "30px",
                                        margin: "0 20px",
                                        backgroundColor: "#ffffff"
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "500",
                                        color: "#ffffff"
                                    }}
                                >
                                    {title}
                                </span>
                            </NavLink>
                        </Col>
                    </Row>
                </Row>
            );

        } else {
            return (
                <Row id="search-header" className={"header"}>
                    <Row className={"safe-area"}>
                        <Col span={6} id={"logo"}>
                            <NavLink to="/">
                                <img src={varBucketURL + "/assets/logo.svg"} alt="logo" width="200px"></img>
                            </NavLink>
                        </Col>

                        <Col span={12} id={"search"} style={
                            popularSearchKeywordLength !== 0
                                ? {paddingTop: "13px"}
                                : {paddingTop: "0px"}
                        }
                        >
                            <Search_bar token={accessToken}/>
                            {
                                // print 6 keyword only
                                popularSearchKeywordLength !== 0 &&
                                <div id={"popular"}>
                                    <strong style={{whiteSpace: "nowrap"}}>热门搜索：</strong>
                                    <>
                                        {
                                            popularSearchKeyword.map((builderPoplarKey, index) => {
                                                return (
                                                    <>
                                                        {
                                                            // print 6 for home page only
                                                            index < 6 &&
                                                            <NavLink
                                                                to={"/proxy?popularKeyword=true&key=" + builderPoplarKey.keyword}
                                                                className={"item"}>{builderPoplarKey.keyword}</NavLink>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </>


                                </div>
                            }
                        </Col>

                        <Col span={6} id={"tool"}>
                            <HeaderToolBox/>
                        </Col>
                    </Row>
                </Row>
            );
        }


    }



    // chat dialog
    const [chatOpen, setChatOpen] = useState(false);

    useEffect(() => {
        console.log("chatOpen: ", chatOpen)
    }, [chatOpen])

    function setClose(){
        setChatOpen(false);
    }



    return (
        <div className="App">
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#f46e12',
                    },
                }}
                locale={locale}
            >
                <BrowserRouter>
                    {
                        window.localStorage.getItem('Access-Token') === null
                            ? <>
                                {
                                    /* 不是 chat portal 的登录介面显示 pre-header*/
                                    window.location.pathname !== "/chat/portal"
                                    &&
                                    <>
                                        {/*立即下载 app 的弹出窗*/}
                                        <DownloadAppNotifyPopout setIsAppDownloadModalOpen={setIsAppDownloadModalOpen}
                                                                 isAppDownloadModalOpen={isAppDownloadModalOpen}/>

                                        {/* --------- PRE HEADER ---------- */}
                                        <Row id="pre-header" className={"header"}>
                                            <Row className={"safe-area"}>
                                                <Col span={10} id={"intro"}>
                                                    <p onClick={() => setIsAppDownloadModalOpen(true)}
                                                       style={{cursor: "pointer"}}>下载享买购物 APP 用更低的价钱买想要的商品</p>
                                                </Col>


                                                <Col span={14} id={"menu"}>
                                                    <div className={"item"}>
                                                        {
                                                            // standalone 的 chat 显示登录信息
                                                            window.location.pathname === "/chat/portal" ? <p><UserOutlined />&nbsp;您现在以商铺身分自动登录私信系统</p> : <p>您好，请先登录或注册</p>
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                        {/* --------- PRE HEADER ---------- */}
                                    </>

                                }

                                {
                                    // standalone 的 chat 显示 header
                                    window.location.pathname === "/chat/portal" && <RenderSubHeader/>
                                }

                                <Account varBucketURL={varBucketURL}/>
                            </>

                            : <>
                                {/*立即下载 app 的弹出窗*/}
                                <DownloadAppNotifyPopout setIsAppDownloadModalOpen={setIsAppDownloadModalOpen}
                                                         isAppDownloadModalOpen={isAppDownloadModalOpen}/>

                                {/* ------------- HEADER -------------- */}
                                <div className={"app-loading-container"}>
                                </div>
                                {/* ------------- HEADER -------------- */}


                                {/* --------- PRE HEADER ---------- */}
                                <Row id="pre-header" className={"header"}>
                                    <Row className={"safe-area"}>
                                        <Col span={10} id={"intro"}>
                                            <p onClick={() => setIsAppDownloadModalOpen(true)}
                                               style={{cursor: "pointer"}}>下载享买购物 APP 用更低的价钱买想要的商品</p>
                                        </Col>

                                        <Col span={14} id={"menu"}>
                                            <div className={"item"}>
                                                <p>您好，{getUserName(user.name, user.mobile)}&nbsp;&nbsp;|<span
                                                    style={{cursor: "pointer"}}
                                                    onClick={onClickLogout}>&nbsp;&nbsp;退出登录</span></p></div>


                                            <NavLink to="/profile/order/list">
                                                <div className={"item"}><p className={"color-shopmy-orange"}><img
                                                    src={varBucketURL + "/assets/icon-order.svg"} alt="order"
                                                    width="18px"
                                                    style={{marginRight: '4px'}}></img>我的订单
                                                </p></div>
                                            </NavLink>

                                            <NavLink to="https://help.shopmy.com.au" target="_blank">
                                                <div className={"item"}><p className={"color-shopmy-orange"}><img
                                                    src={varBucketURL + "/assets/icon-help.svg"} alt="help"
                                                    width="18px"
                                                    style={{marginRight: '4px'}}></img>帮助中心
                                                </p></div>
                                            </NavLink>

                                            <div className={"item"}><p className={"color-shopmy-orange"}><img
                                                src={varBucketURL + "/assets/icon-location.svg"}
                                                alt="location"
                                                width="18px"
                                                style={{marginRight: '4px'}}></img>墨尔本
                                            </p></div>
                                        </Col>
                                    </Row>
                                </Row>
                                {/* --------- PRE HEADER ---------- */}

                                {/* ------------- HEADER -------------- */}
                                <RenderSubHeader forceRender={forceRender}/>
                                {/* ------------- HEADER -------------- */}


                                {/* ------------- BODY -------------- */}

                                <ScrollToTop/>
                                {
                                    window.location.pathname !== "/chat/portal" &&
                                    <Button
                                        size={"large"}
                                        type={"primary"}
                                        className={"chat-button"}
                                        style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                                        onClick={()=>{
                                            // window.open("/chat/portal","_blank")
                                            setChatOpen(true)
                                        }}
                                    >
                                        <MessageFilled />
                                        &nbsp;享买私信
                                    </Button>
                                }
                                <ChatPopout chatOpen={chatOpen} setClose={()=>{setClose()}}/>
                                <Routes>
                                    <Route path="*" element={<Home/>}/>
                                    <Route path="/" element={<Home token={accessToken} user={user}/>}/>
                                    <Route path="product" element={<Product token={accessToken} user={user} renderType={"product"}/>}/>
                                    <Route path="search" element={<Search_pg token={accessToken} user={user} renderType={"search"}/>}/>
                                    <Route path="category" element={<Category token={accessToken} user={user}/>}/>
                                    <Route path="store" element={<Store token={accessToken} user={user} searchOptionTop={searchOptionTop}/>}/>
                                    <Route path="cart" element={<Cart token={accessToken} user={user}/>}/>
                                    <Route path="officialDiscount" element={<OfficialCoupon token={accessToken} user={user}/>}/>

                                    <Route path="/chat" element={<ChatLoader token={accessToken} user={user} setForceRender={setForceRender} forceRender={forceRender}/>}/>
                                    <Route path="/chat/portal" element={<ChatPortal token={accessToken} user={user} setForceRender={setForceRender} forceRender={forceRender}/>}/>

                                    <Route path="profile" element={<Profile token={accessToken} user={user}/>}>
                                        <Route path="/profile/account/profile" element={<UserProfile/>} token={accessToken} user={user}/>
                                        <Route path="/profile/account/change-login-password" element={<ChangeLoginPassword/>} token={accessToken} user={user}/>
                                        <Route path="/profile/account/address" element={<Address/>} token={accessToken} user={user}/>

                                        <Route path="/profile/order/list" element={<OrderList/>} token={accessToken} user={user}/>
                                        <Route path="/profile/order/order-detail" element={<OrderDetail/>} token={accessToken} user={user}/>
                                        <Route path="/profile/order/refund-detail" element={<RefundDetail/>} token={accessToken} user={user}/>
                                        <Route path="/profile/order/comment" element={<Comment/>} token={accessToken} user={user}/>

                                        <Route path="/profile/notification" element={<Notification/>} token={accessToken} user={user}/>
                                        <Route path="/profile/coupon" element={<Coupon/>} token={accessToken} user={user}/>
                                    </Route>
                                    <Route path="/checkout/confirm" element={<Confirm token={accessToken} user={user} setForceRender={setForceRender} forceRender={forceRender}/>}/>
                                    <Route path="/checkout/result" element={<PaymentResult token={accessToken} user={user} setForceRender={setForceRender} forceRender={forceRender}/>}/>

                                    <Route path="proxy" element={<SearchAssistance token={accessToken} user={user}/>}/>

                                    <Route path="seller" element={<StoreMain token={accessToken} user={user}/>}/>
                                </Routes>
                                {/* ------------- BODY -------------- */}

                                {
                                    specialHeader !== "chat" &&
                                    <Row className="footer">
                                        <Col span={24} style={{}}>
                                            <EasterEggExploring/>
                                            <p>
                                                <Link
                                                    to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e9%9a%90%e7%a7%81%e6%9d%a1%e6%ac%be/"
                                                    target="_blank">隐私政策</Link>
                                                <Link
                                                    to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e6%9c%8d%e5%8a%a1%e6%9d%a1%e6%ac%be/"
                                                    target="_blank">使用条款</Link>
                                                <Link
                                                    to="https://help.shopmy.com.au/kb/%e4%ba%ab%e4%b9%b0%e7%89%a9%e6%b5%81%e6%9d%a1%e6%ac%be/"
                                                    target="_blank">金物流服务条款</Link>
                                                <Link to="https://help.shopmy.com.au" target="_blank">帮助中心</Link>
                                            </p>
                                            <p>SHOPMY PTY LTD 2020-2023 © All Rights Reserved | ACN: 637 513 369</p>

                                        </Col>
                                    </Row>

                                }

                            </>
                    }
                </BrowserRouter>
            </ConfigProvider>

        </div>
    );
}

export default App;
