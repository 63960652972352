import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Upload} from "antd";
import {
    doOmsOrderApplyRefund,
    doOmsOrderCancel, doOmsOrderRevokeRefund, doOmsOrderRuling,
    findCancelReasonList,
    findRefundReasonList
} from "../../../../data/Order-Service-Repo";
import {uploadFilesSync} from "../../../../data/User-Service-Repo";
import {PlusOutlined} from "@ant-design/icons";


// props: order ([array]) -> 请务必传 findOmsOrder 的订单数据 / 唯独撤销退货退款才传 findOmsOrderRefund 的退款单数据
// props: open (true or false)
// props: onCancel (method)
// props: type (int) (1: 退款, 2: 判定中心, 3:取消订单, 4: 撤销退货退款)
const SubmitRequest = (props) => {
    const [form] = Form.useForm();



    // upload btn
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    // check selected file type
    const checkFileType = (file) => {
        const isPNG = file.type === 'image/png';
        const isJPEG = file.type === 'image/jpeg';
        const isHEIC = file.type === 'image/heic';

        if (!isPNG && !isHEIC && !isJPEG) {
            message.error('您只可以上传图片（PNG, JPEG, JPG, HEIC）');
            return Upload.LIST_IGNORE;
        }   else {
            return true;
        }
    }



    // --- preview box ---
    const [isPreviewBoxOpen, setIsPreviewBoxOpen] = useState(false);
    const [previewImageURL, setPreviewImageURL] = useState("");

    function PreviewBox(){
        return (
            <Modal title="图片预览" width={600} open={isPreviewBoxOpen} onCancel={handlePreviewBoxCancel} footer={null}>
                <img
                    width={"100%"}
                    src={previewImageURL}
                    style={{
                        width: "60%",
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                >

                </img>
            </Modal>
        );
    }
    const handlePreviewBoxCancel = () => {
        setIsPreviewBoxOpen(false);
    };

    const handlePreviewBoxOpen = (data) => {
        setIsPreviewBoxOpen(true);
        setPreviewImageURL(data.url);
    };




    // --------- 退款 ----------
    function Refund(){
        // -------- 上传图片
        const [uploadFile, setUploadFile] = useState([]);
        const handleFileRemove = async (data) => {
            var array = []

            // don't know why splice comes up with wired result, so I am using the other way to remove element from array
            uploadFile.forEach(element => {
                if(element.uid != data.uid){
                    array.push(element)
                }
            });

            setUploadFile(array);
        }

        const handleFileChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setUploadFile(uploadFile.concat(request));

            var result = await uploadFilesSync(data);

            if(uploadFile.length === 0){
                setUploadFile(result);
            } else {
                setUploadFile(uploadFile.concat(result));
            }
        }



        // -------- 退货退款原因
        const [refundReason, setRefundReason] = useState([]);
        useEffect(() => {
            async function load() {
                var result = await findRefundReasonList();

                if (result.code === 200) {
                    var options = [];
                    result.data.forEach((item) => {
                        options.push({
                            label: item,
                            value: item,
                        });
                    });
                    setRefundReason(options);
                }
            }
            load();
        },[]);




        // -------- 用户输入的退款原因
        const [refundReasonInput, setRefundReasonInput] = useState("");
        // 用户输入的退款描述
        const [refundDescriptionInput, setRefundDescriptionInput] = useState("");
        // 再次确认使否发起的 popconfirm
        const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(false);



        // 确认按钮 loading
        const [confirmLoading, setConfirmLoading] = useState(false);
        // -------- 提交退款申请
        async function handleSubmit() {
            setConfirmLoading(true);

            // 要先把上传的图片数据处理成后端接受的
            var images = [];
            uploadFile.forEach((item) => {
                images.push(item.url);
            })


            var result = await doOmsOrderApplyRefund(props.order.id, refundDescriptionInput, images, refundReasonInput)

            if (result.code === 200) {
                window.location.href = "/profile/order/refund-detail?id=" + props.order.id + "&msg=提交退货退款申请成功，请等待商铺回应！";
            } else {
                message.error(result.message);
            }

            setConfirmLoading(false);
        }

        return (
            <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={()=> {
                    // 确认必填项都没问题开启二次确认弹窗
                    setIsPopConfirmOpen(true)
                }}
                className={"order-submit-request-form"}
            >
                <Form.Item
                    name="reason"
                    label="选择退款原因"
                    rules={[
                        {
                            required: true,
                            message: "请填写此必填项",
                        },
                    ]}
                >
                    <Select
                        placeholder="请选择..."
                        optionFilterProp="children"
                        onChange={(value)=> {
                            setRefundReasonInput(value)
                        }}
                        options={refundReason}
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="原因描述"

                >
                    <Input.TextArea
                        placeholder="补充说明，帮助商铺更好的了解商品问题"
                        rows={4}
                        onChange={(e)=> {
                            setRefundDescriptionInput(e.target.value)
                        }}
                    >

                    </Input.TextArea>
                </Form.Item>

                <Form.Item
                    name="cancelImage"
                    label="证明图片"
                >
                    <Upload
                        listType="picture-card"
                        fileList={uploadFile}
                        onRemove={handleFileRemove}
                        customRequest={handleFileChange}
                        onPreview={handlePreviewBoxOpen}
                        beforeUpload={checkFileType}
                    >
                        {uploadFile.length >= 6 ? null : uploadButton}
                    </Upload>
                </Form.Item>

                <br/>
                <br/>


                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div>
                        <span>预计退款金额: </span>
                        <span
                            style={{
                                color: "#f46e12",
                                fontSize: "15px",
                            }}
                        >
                            {
                                props.order.estimateRefundPrice !== null &&
                                "$" + props.order.estimateRefundPrice.toFixed(2)
                            }

                        </span>

                        {/*TODO:这边应该显示退款细向，但是移动端都没做，而且后端也没有相应数据*/}
                        {/*<span>*/}
                        {/*    總付款 $12000.00，扣除手續費 $1000.00 及運費 $800.00 */}
                        {/*</span>*/}
                    </div>
                    <div style={{
                        marginLeft: "auto",
                        display: "flex",
                        gap: 8
                    }}>
                        <Form.Item style={{margin: "0"}}>
                            <Button key="close" onClick={props.onCancel}>
                                暂不申请
                            </Button>
                        </Form.Item>
                        <Form.Item style={{margin: "0"}}>
                            <Button type={"primary"} htmlType="submit">
                                提交申请
                            </Button>

                            <Popconfirm
                                className={"refund-popconfirm"}
                                placement="right"
                                title={"确认提交申请？"}
                                open={isPopConfirmOpen}
                                description={
                                    props.order.orderRefundCount === 0
                                    /* 第一次发起的提示信息 */
                                    ? `如果商铺同意或超时未回复您的申请，系统将把款项返回给您。如果商铺拒绝您，可以修改申请再次发起。您一共有 3 次发起退款申请的机会，请问要发起吗？`

                                    /* 第一次以后发起的提示信息，最多只能发三次，orderRefundCount是发起次数，相减后等于剩馀次数*/
                                    : `您剩下 ${3 - props.order.orderRefundCount} 次申请退款的机会，达到退款发起上限后，将只能转交判定中心，请问要再次发起申请吗？`

                                }
                                okButtonProps={{
                                    loading: confirmLoading,
                                    type: "primary",
                                }}
                                onConfirm={handleSubmit}
                                onCancel={()=>setIsPopConfirmOpen(false)}
                                okText="确认发起"
                                cancelText="取消"

                            >
                            </Popconfirm>
                        </Form.Item>
                    </div>
                </div>

            </Form>
        );
    }

    // --------- 取消订单 ----------
    function Cancel(){
        // 取消原因选项
        const [cancelReason, setCancelReason] = useState([]);
        useEffect(() => {
            async function load() {
                var result = await findCancelReasonList();

                if (result.code === 200) {
                    var options = [];
                    result.data.forEach((item) => {
                        options.push({
                            label: item,
                            value: item,
                        });
                    });
                    setCancelReason(options);
                }
            }
            load();
        },[]);



        // 用户输入的取消原因
        const [cancelReasonInput, setCancelReasonInput] = useState("");

        // 确认按钮 loading
        const [confirmLoading, setConfirmLoading] = useState(false);
        // 提交取消申请
        async function handleSubmit() {
            setConfirmLoading(true);

            var result = await doOmsOrderCancel(props.order.id, cancelReasonInput)

            if (result.code === 200) {
                window.location.href = "/profile/order/order-detail?id=" + props.order.id + "&msg=成功！系统以为您取消订单。";
            } else {
                message.error(result.message);
            }

            setConfirmLoading(false);
        }


        useEffect(() => {
            console.log(cancelReasonInput)
        },[cancelReasonInput])



        return(
            <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
                className={"order-submit-request-form"}
            >
                <Form.Item
                    name="cancelReason"
                    label="选择取消原因"
                    rules={[
                        {
                            required: true,
                            message: "请填写此必填项",
                        },
                    ]}
                >
                    <Select
                        placeholder="请选择..."
                        optionFilterProp="children"
                        onChange={(value)=> {
                            setCancelReasonInput(value)
                        }}
                        options={cancelReason}
                    />
                </Form.Item>

                <Space
                    className={"order-submit-request-footer"}
                >
                    <Form.Item>
                        <Button key="close" onClick={props.onCancel}>
                            暂不申请
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={confirmLoading}>
                            提交申请
                        </Button>
                    </Form.Item>
                </Space>

            </Form>
        );
    }

    // --------- 判定中心 ----------
    function Audit(){
        // -------- 上传图片
        const [uploadFile, setUploadFile] = useState([]);
        const handleFileRemove = async (data) => {
            var array = []

            // don't know why splice comes up with wired result, so I am using the other way to remove element from array
            uploadFile.forEach(element => {
                if(element.uid != data.uid){
                    array.push(element)
                }
            });

            setUploadFile(array);
        }

        const handleFileChange = async (data) => {
            // fake loading
            let request = [{
                uid: '-xxx',
                percent: 99,
                name: "uploading",
                status: 'uploading',
            }]
            setUploadFile(uploadFile.concat(request));

            var result = await uploadFilesSync(data);

            if(uploadFile.length === 0){
                setUploadFile(result);
            } else {
                setUploadFile(uploadFile.concat(result));
            }
        }



        // 用户输入
        const [nameInput, setNameInput] = useState(""); //姓名
        const [phoneInput, setPhoneInput] = useState(""); //手机号
        const [emailInput, setEmailInput] = useState(""); //电子邮箱
        const [refundDescriptionInput, setRefundDescriptionInput] = useState("");

        // 再次确认使否发起的 popconfirm
        const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(false);

        // 确认按钮的倒计时
        // 因为我们设计了确认按钮要三秒后才可以按
        const [countBtnCountDown, setCountBtnCountDown] = useState(0);



        // 确认按钮 loading
        const [confirmLoading, setConfirmLoading] = useState(false);
        // -------- 提交退款申请
        async function handleSubmit() {
            setConfirmLoading(true);

            // 要先把上传的图片数据处理成后端接受的
            var images = [];
            uploadFile.forEach((item) => {
                images.push(item.url);
            })


            var result = await doOmsOrderRuling(props.order.id, emailInput, phoneInput, nameInput, refundDescriptionInput, images)

            if (result.code === 200) {
                window.location.href = "/profile/order/refund-detail?id=" + props.order.id + "&msg=成功转交判定中心！";
            } else {
                message.error(result.message);
            }

            setConfirmLoading(false);
        }


        return (
            <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={async () => {
                    // 确认必填项都没问题开启二次确认弹窗
                    setIsPopConfirmOpen(true)

                    // 如果二次确认弹窗还开着，重新点击提交申请不会创造出第二份倒计时
                    if(isPopConfirmOpen === false){

                        // 定义倒计时时间
                        var count = 3;

                        // 开始倒计时
                        countdown();

                        function countdown(){
                            // 如果没有归 0，就继续倒计时
                            if(count !== -1){
                                //保存记录
                                setCountBtnCountDown(count);


                                var timer = setTimeout(countdown, 1000);
                                count--; // decrease the timer
                            }
                        }


                    }

                }}
                className={"order-submit-request-form"}
            >


                <Form.Item
                    name="name"
                    label="真实姓名"
                    rules={[
                        {
                            required: true,
                            message: '请填写此项目',
                        }
                    ]}
                >
                    <Input
                        placeholder="请输入真实姓名..."
                        rows={4}
                        onChange={(e)=> {
                            setNameInput(e.target.value)
                        }}
                    >

                    </Input>
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="手机号"
                    rules={[
                        {
                            required: true,
                            message: '请填写此项目',
                        },
                        // 自定义校验规则
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if(value != "" && !value.match(/^04[0-9]{8}$/)){
                                    return Promise.reject("手机号不正确（04 开头，共 10 码数字）"); // The validator should always return a promise on both success and error
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <Input
                        placeholder="请输入手机号..."
                        rows={4}
                        onChange={(e)=> {
                            setPhoneInput(e.target.value)
                        }}
                    >

                    </Input>
                </Form.Item>

                <Form.Item
                    name="email"
                    label="电子邮箱"
                    rules={[
                        {
                            required: true,
                            message: '请填写此项目',
                        },
                        {
                            type: 'email',
                            message: '请输入正确的电子邮箱',
                        }
                    ]}
                >
                    <Input
                        placeholder="请输入电子邮箱..."
                        rows={4}
                        onChange={(e)=> {
                            setEmailInput(e.target.value)
                        }}
                    >

                    </Input>
                </Form.Item>

                <Form.Item
                    name="description"
                    label="证明描述"
                    rules={[
                        {
                            required: true,
                            message: '请填写此项目',
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder="补充说明，帮助商铺更好的了解商品问题"
                        rows={4}
                        onChange={(e)=> {
                            setRefundDescriptionInput(e.target.value)
                        }}
                    >

                    </Input.TextArea>
                </Form.Item>

                <Form.Item
                    name="cancelImage"
                    label="证明图片"
                >
                    <Upload
                        listType="picture-card"
                        fileList={uploadFile}
                        onRemove={handleFileRemove}
                        customRequest={handleFileChange}
                        onPreview={handlePreviewBoxOpen}
                        beforeUpload={checkFileType}
                    >
                        {uploadFile.length >= 6 ? null : uploadButton}
                    </Upload>
                </Form.Item>

                <br/>
                <br/>


                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div>
                        <span></span>
                        <span
                            style={{
                                color: "#f46e12",
                                fontSize: "15px",
                            }}
                        >

                        </span>


                    </div>
                    <div style={{
                        marginLeft: "auto",
                        display: "flex",
                        gap: 8
                    }}>
                        <Form.Item style={{margin: "0"}}>
                            <Button key="close" onClick={props.onCancel}>
                                暂不申请
                            </Button>
                        </Form.Item>
                        <Form.Item style={{margin: "0"}}>
                            <Button type={"primary"} htmlType="submit">
                                提交申请
                            </Button>

                            <Popconfirm
                                className={"refund-popconfirm"}
                                placement="right"
                                title={"确认提交申请？"}
                                open={isPopConfirmOpen}
                                description={
                                    "请确认您的证明资料是否详细，判定将耗时 7-14 个工作日，在判定期间，享买会透过电子邮箱或手机号请您提供进一步的证明。当您按下，享买会透过电子邮箱或手机号请您提供进一步的证明。当您按下“确认”后代表您无条件同意享买判定中心所判定的结果、服务条款及帮助中心的判定条款（详情请点“帮助”），转交一但送出不可撤回，您只可转交一次判定中心，请问要转交吗？"
                                }
                                okButtonProps={{
                                    loading: confirmLoading,
                                    type: "primary",
                                    disabled: countBtnCountDown !== 0
                                }}
                                onConfirm={handleSubmit}
                                onCancel={()=>setIsPopConfirmOpen(false)}
                                okText={
                                    countBtnCountDown === 0
                                    ? "确认发起"
                                    : "确认发起 " + countBtnCountDown + "s"
                                }
                                cancelText="取消"

                            >
                            </Popconfirm>
                        </Form.Item>
                    </div>
                </div>

            </Form>
        );
    }

    // --------- 判定中心 ----------
    function RevokeRefund(){

        // 确认按钮 loading
        const [confirmLoading, setConfirmLoading] = useState(false);
        // -------- 提交退款申请
        async function handleSubmit() {
            setConfirmLoading(true);

            var result = await doOmsOrderRevokeRefund(props.order.id)

            if (result.code === 200) {
                window.location.href = "/profile/order/order-detail?id=" + props.order.orderId + "&msg=退款已成功撤销！";
            } else {
                message.error(result.message);
            }

            setConfirmLoading(false);
        }


        return (
            <>
                <span>
                    {/* 最多三次，减去已发起次数，等于剩馀次数 */}
                    取消退款申请后您还有 { 3 - props.order.orderRefundCount} 次机会重新发起，请问是否继续？
                </span>

                <br/>
                <br/>

                <div style={{
                    marginLeft: "auto",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 8
                }}>
                    <Button
                        onClick={props.onCancel}
                    >
                        暂不撤回
                    </Button>

                    <Button
                        type={"primary"}
                        onClick={()=>{handleSubmit()}}
                        loading={confirmLoading}
                    >
                        确认撤回
                    </Button>
                </div>
            </>


        );
    }



    function getTitle(){
        if(props.type === 1){
            if(props.order.orderRefundCount === 0){
                // 第一次发起
                return "申请退货退款"
            } else {
                // 第二次发起
                return "再次发起退货退款"
            }
        }
        else if(props.type === 2){
            return "轉交判定中心"
        }
        else if(props.type === 3){
            return "取消订单"
        }
        else if(props.type === 4){
            return "撤销退货退款"
        }
    }


    return (
            <Modal
                open={props.open}
                title={getTitle()}
                onCancel={props.onCancel}
                maskClosable={false}
                footer={[]}
                className={"order-submit-request-modal"}
                destroyOnClose
            >
                <PreviewBox/>

                {
                    props.type === 1 &&
                    <Refund/>
                }

                {
                    props.type === 2 &&
                    <Audit/>
                }

                {
                    props.type === 3 &&
                    <Cancel/>
                }
                {
                    props.type === 4 &&
                    <RevokeRefund/>
                }
            </Modal>
    );
}


export default SubmitRequest;