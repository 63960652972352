import React, {useEffect, useState} from "react";
import {Button, Divider, Modal, Row} from "antd";
import {bucketURL} from "../data/env";

const DownloadAppNotifyPopout = (props) => {
    // get s3 bucket url once load
    const [varBucketURL, getBucketURL] = useState(null);
    useEffect(() => {
        getBucketURL(bucketURL);
    }, []);

    return (
        <Modal
            open={props.isAppDownloadModalOpen}
            onCancel={()=> props.setIsAppDownloadModalOpen(false)}
            footer={[]}
            width={750}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
            className={"app-download-modal"}
        >
            <>
                {/* 这个是 header 下载 app 提示谈窗*/}
                <img src={varBucketURL + "/assets/banner-app-1.png"} alt="order" width={700}></img>
                <Divider
                    style={{
                        marginTop: "0px",
                    }}
                />
                <Button
                    type={"primary"}
                    shape={"round"}
                    style={{
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                    onClick={()=> props.setIsAppDownloadModalOpen(false)}
                >
                    关闭
                </Button>
            </>
        </Modal>
        );
}


export default DownloadAppNotifyPopout;