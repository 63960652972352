import React from 'react';
import ChatPortal from "./ChatPortal";
import {Modal} from "antd";

const ChatPopout = (props) => {

    return (
        <Modal
            open={props.chatOpen}
            style={{
                top: "10px",
                padding: "0px",
            }}
            bodyStyle={{
                padding: "30px 0px 0px 0px"
            }}
            width={1500}
            footer={[]}
            onCancel={
                props.setClose
            }
        >
            <ChatPortal setForceRender={()=>{return;}} forceRender={0}/>
        </Modal>
    );

}

export default ChatPopout;
